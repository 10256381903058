import { UserData } from '../../services/auth/interfaces';

export interface UserState {
  userData: UserData,
  logged: boolean;
}

export const INITIAL_STATE: UserState = {
  userData: {
    enabled: false,
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    optionalEmail: '',
    allowNotifications: false,
    avatar: '',
    birthdate: '',
    jwt: '',
    roles: [],
    userName: '',
    refreshToken: '',
    language: 'ES',
    firstLogin: false,
    company: '',
    workPosition: '',
    workingHoursFrom: '',
    workingHoursTo: '',
    verified: false
  },
  logged: false
};
