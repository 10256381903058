import React, { memo } from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { getDayNameFromDate, getLanguageCodeByLanguage } from '../../../../utils';
import { DayCalendar } from '../../interfaces';
import { DayStyles as styles } from './styles';

interface Props {
  item: DayCalendar,
  onPress: any;
  language: string;
}

const Day = ({ item, onPress, language }: Props): React.ReactElement => (
  <TouchableOpacity onPress={onPress} style={item.selected ? styles.selectedContainer : styles.container}>
    <Text style={item.selected ? styles.selectedDayName : styles.dayName}>{getDayNameFromDate(item.date, 'short', getLanguageCodeByLanguage(language)).toUpperCase().substring(0, 3)}</Text>
    <Text style={item.selected ? styles.selectedDayNumber : styles.dayNumber}>{item.date.date()}</Text>
  </TouchableOpacity>
);

export default memo(Day);
