import React, { useState } from 'react';
import {
  View, Text, useWindowDimensions, TextInput, TouchableOpacity
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/Feather';
import { Button } from 'react-native-elements';
import { useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form';
import { selectUserInfo } from '../../../../redux/user';
import styles, { functionStyles } from './styles';
import { showToastError, showToastSuccess } from '../../../../utils/toast';
import { PASSWD_VALIDATION } from '../../../../utils/rules';
import { authValidatePassword } from '../../../../services/auth';
import {
  userModifyUserPasswordInit, userModifyUserPasswordConfirm, userModifyUserPasswordChange, userModifyUserPasswordConfirmChange
} from '../../../../services/user';
import { Colors } from '../../../../configs/enums';
import { selectLanguageKeys } from '../../../../redux/language';

export default (): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const getLabel = useSelector(selectLanguageKeys);
  const navigation = useNavigation();
  const userInfo = useSelector(selectUserInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [hideSecondPassword, setHideSecondPassword] = useState(true);
  const [hideThirdPassword, setHideThirdPassword] = useState(true);

  const {
    handleSubmit, control, formState: { errors }
  } = useForm({
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      repeatedPassword: ''
    },
    shouldFocusError: true
  });

  const onToggleSwitch = () => {
    setHidePassword(!hidePassword);
  };

  const onToggleSecondSwitch = () => {
    setHideSecondPassword(!hideSecondPassword);
  };

  const onToggleThirdSwitch = () => {
    setHideThirdPassword(!hideThirdPassword);
  };

  const onChangePassword = async (data: {oldPassword: string, newPassword: string, repeatedPassword: string}) => {
    setIsLoading(true);
    if (data.newPassword === data.repeatedPassword) {
      const isValidatePassword = await authValidatePassword(data.newPassword);
      if (isValidatePassword.error) {
        showToastError(getLabel('profile_toast_error_validation_title'), isValidatePassword.errorMessage);
      } else {
        const response = await userModifyUserPasswordInit(userInfo.jwt);
        if (response.error) {
          showToastError(getLabel('changePassword_toast_error_title'), getLabel(response.errorMessage!));
        } else {
          const response = await userModifyUserPasswordConfirm({ current: data.oldPassword }, userInfo.jwt);
          if (response.error) {
            showToastError(getLabel('changePassword_toast_error_title'), getLabel(response.errorMessage!));
          } else {
            const response = await userModifyUserPasswordChange(userInfo.jwt);
            if (response.error) {
              showToastError(getLabel('changePassword_toast_error_title'), getLabel(response.errorMessage!));
            } else {
              const response = await userModifyUserPasswordConfirmChange({ newPassword: data.newPassword }, userInfo.jwt);
              if (response.error) {
                showToastError(getLabel('changePassword_toast_error_title'), getLabel(response.errorMessage!));
              } else {
                showToastSuccess(getLabel('changePassword_toast_success_title'), getLabel('changePassword_toast_success_text'));
                navigation.goBack();
              }
            }
          }
        }
      }
    } else {
      showToastError(getLabel('changePassword_toast_success_title'), getLabel('changePassword_toast_error_match_text'));
    }

    setIsLoading(false);
  };

  return (
    <KeyboardAwareScrollView style={styles.container}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View style={styles.titleContainer}>
          <Text style={styles.text}>{getLabel('profile_button_changePassword')}</Text>
        </View>
        <View style={styles.controllersContainer}>
          <Text style={styles.errorText}>{errors?.oldPassword?.message}</Text>
          <Controller
            name="oldPassword"
            control={control}
            rules={PASSWD_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_8'),
              getLabel('rules_max_length_32'),
              getLabel('rules_password_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.subInputContainer}>
                <TextInput
                  style={[styles.input, errors?.oldPassword?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  autoCorrect={false}
                  secureTextEntry={hidePassword}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_password_old')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
                <TouchableOpacity
                  style={styles.buttonHideIcon}
                  onPress={onToggleSwitch}
                >
                  <Icon
                    style={styles.hideIcon}
                    name={!hidePassword ? 'eye' : 'eye-off'}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
          <Text style={styles.errorText}>{errors?.newPassword?.message}</Text>
          <Controller
            name="newPassword"
            control={control}
            rules={PASSWD_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_8'),
              getLabel('rules_max_length_32'),
              getLabel('rules_password_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.subInputContainer}>
                <TextInput
                  style={[styles.input, errors?.newPassword?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  autoCorrect={false}
                  secureTextEntry={hideSecondPassword}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_password_new')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
                <TouchableOpacity
                  style={styles.buttonHideIcon}
                  onPress={onToggleSecondSwitch}
                >
                  <Icon
                    style={styles.hideIcon}
                    name={!hideSecondPassword ? 'eye' : 'eye-off'}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
          <Text style={styles.errorText}>{errors?.repeatedPassword?.message}</Text>
          <Controller
            name="repeatedPassword"
            control={control}
            rules={PASSWD_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_8'),
              getLabel('rules_max_length_32'),
              getLabel('rules_password_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.subInputContainer}>
                <TextInput
                  style={[styles.input, errors?.repeatedPassword?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  autoCorrect={false}
                  secureTextEntry={hideThirdPassword}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_password_repeat')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
                <TouchableOpacity
                  style={styles.buttonHideIcon}
                  onPress={onToggleThirdSwitch}
                >
                  <Icon
                    style={styles.hideIcon}
                    name={!hideThirdPassword ? 'eye' : 'eye-off'}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.acceptButton}
            titleStyle={styles.acceptTextButton}
            onPress={handleSubmit(onChangePassword)}
            title={getLabel('profile_button_accept')}
            loading={isLoading}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
