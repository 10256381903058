import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../../../configs/constants';
import { Colors, Fonts } from '../../../../configs/enums';

const ChangePasswordStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  titleContainer: {
    marginTop: 20
  },
  middleContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  textContainer: {
    width: '100%',
    alignItems: 'flex-start'
  },
  controllersContainer: {
    alignItems: 'center',
    paddingVertical: 10,
    justifyContent: 'space-around'
  },
  bottomContainer: {
    alignItems: 'center',
    paddingVertical: 20
  },
  backgroundPic: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  icon: {
    fontSize: 32
  },
  text: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignSelf: 'flex-start'
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    backgroundColor: Colors.BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  valid: {
    borderWidth: 1
  },
  invalid: {
    borderColor: Colors.ERROR_COLOR
  },
  buttonContainer: {
    width: '100%'
  },
  acceptButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  acceptTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  errorText: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 10,
    alignSelf: 'flex-start',
    marginTop: 8
  },
  subInputContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  hideIcon: {
    fontSize: 18
  },
  buttonHideIcon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingRight: 15
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  })
};

export default ChangePasswordStyles;
