import React from 'react';
import { Text } from 'react-native';

interface Props {
  setDate: (date: string) => void;
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  limitDateYears: number;
}

export const MobilePicker: React.FC<Props> = ({
  setDate, isVisible, setIsVisible, limitDateYears
}) => (
  <Text>Picker does not work on web</Text>
);
