import { StyleSheet } from 'react-native';
import { Colors, Fonts } from '../../../../configs/enums';

const SubscriptionCardStyles = StyleSheet.create({
  container: {
    marginVertical: 15
  },
  cardContainer: {
    padding: 15,
    borderRadius: 22,
    height: 120,
    borderWidth: 2
  },
  cardTitle: {
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: 18,
    color: Colors.PRIMARY_TEXT_COLOR
  },
  cardPremiumTitlePriceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
});

export default SubscriptionCardStyles;
