import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../rootReducer';
import { INITIAL_STATE, ISchedule } from './state';

const scheduleSlice = createSlice({
  name: 'schedules',
  initialState: INITIAL_STATE,
  reducers: {
    scheduleSetData: (state, action: PayloadAction<ISchedule[]>) => {
      state.schedules = action.payload;
    },
    scheduleSetSelectedSchedule: (state, action: PayloadAction<ISchedule>) => {
      state.selectedSchedule = action.payload;
    },
    scheduleResetState: () => INITIAL_STATE
  }
});

export const {
  scheduleSetData,
  scheduleSetSelectedSchedule,
  scheduleResetState
} = scheduleSlice.actions;

export default scheduleSlice.reducer;

export const selectSchedules = (state: RootState) => state.schedules;

export const selectSelectedSchedule = (state: RootState) => state.schedules.selectedSchedule;
