import React, { useState } from 'react';
import {
  View, Text, TextInput, useWindowDimensions, Pressable, TouchableOpacity
} from 'react-native';
import { Button } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import Icon from 'react-native-vector-icons/Feather';
import { useForm, Controller } from 'react-hook-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch, useSelector } from 'react-redux';
import {
  USER_STORAGE_KEY, CALENDAR_STORAGE_KEY, DEV_MODE, USER_BILLING_INFO
} from '@env';
import Purchases from 'react-native-purchases';
import { MAIL_VALIDATION, PASSWD_VALIDATION } from '../../utils/rules';
import styles, { functionStyles } from './styles';
import Routes from '../../navigation/Routes';
import { showToastError } from '../../utils/toast';
import { areLoginInputsValid, authUserSignIn } from '../../services/auth';
import {
  userSetData, userSetLogged
} from '../../redux/user';
import {
  applyTrimToFields,
  checkUserLanguage,
  isMobile,
  isObjectEmpty, isPremiumAccount, isProAccount, storeAsyncStorageData
} from '../../utils/index';
import { Colors } from '../../configs/enums';
import { scheduleGetAll } from '../../services/schedule';
import { scheduleSetData, scheduleSetSelectedSchedule } from '../../redux/schedules';
import { DEFAULT_SCHEDULE, ISchedule } from '../../redux/schedules/state';
import { selectLanguageKeys } from '../../redux/language';
import { checkUserPhoto } from '../../utils/extras';
import { userGetBillingInfo, userSendPuthToken } from '../../services/user';
import { billingSetData } from '../../redux/billing';
import { selectGlobalAppExpoPushToken } from '../../redux/global';

export default (): React.ReactElement => {
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const [isSwitchOn, setSwitchOn] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const getLabel = useSelector(selectLanguageKeys);

  const expoPushToken = useSelector(selectGlobalAppExpoPushToken);

  const {
    handleSubmit, control, formState: { errors }
  } = useForm({
    defaultValues: DEV_MODE === '1' ? {
      email: 'test@test.com',
      password: 'Test123123123'
    } : {
      email: '',
      password: ''
    },
    shouldFocusError: true
  });

  const onToggleSwitch = () => {
    setSwitchOn(!isSwitchOn);
    setHidePassword(!hidePassword);
  };

  const onLogIn = async (data: {email: string, password: string}) => {
    try {
      setIsLoading(true);
      data.email = data.email.toLowerCase();
      applyTrimToFields(data, ['email', 'password']);
      const validatedInputsResponse = await areLoginInputsValid(data);
      if (validatedInputsResponse) {
        const response = await authUserSignIn(data);
        if (response.error) {
          showToastError(getLabel('login_toast_error_title'), getLabel('login_toast_error_message'));
        } else {
          const {
            jwt, roles, language
          } = response.response!;
          dispatch(userSetData(response.response!));
          if (isMobile) await Purchases.logIn(response.response?.email!);

          await storeAsyncStorageData(response.response, USER_STORAGE_KEY);

          await checkUserLanguage(dispatch, language);

          await checkUserPhoto(dispatch, jwt);

          await userSendPuthToken(expoPushToken!, response.response!.jwt);

          if (isPremiumAccount(roles)) {
            const billingResponse = await userGetBillingInfo(jwt);

            if (billingResponse.response) {
              dispatch(billingSetData(billingResponse.response));
              await storeAsyncStorageData(billingResponse.response, USER_BILLING_INFO);
            } else {
              showToastError(getLabel('error'), getLabel('update_payment_error_title'));
            }

            if (isProAccount(roles)) {
              const schedulesFromApi = await scheduleGetAll(jwt);
              if (!isObjectEmpty(schedulesFromApi.response)) {
                const newSchedules: ISchedule[] = [];
                newSchedules.push(DEFAULT_SCHEDULE);
                dispatch(scheduleSetData(newSchedules.concat(schedulesFromApi.response!)));
                dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
                await storeAsyncStorageData(newSchedules.concat(schedulesFromApi.response!), CALENDAR_STORAGE_KEY);
              } else {
                dispatch(scheduleSetData([DEFAULT_SCHEDULE]));
                dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
              }
            }
          } else {
            dispatch(scheduleSetData([DEFAULT_SCHEDULE]));
            dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
          }
          dispatch(userSetLogged(true));
        }
      } else {
        showToastError(getLabel('login_toast_error_validation'), getLabel('login_toast_error_validation_message'));
      }
      setIsLoading(false);
    } catch (err: any) {
      showToastError(getLabel('login_toast_error_validation'), err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const goRegister = () => navigation.navigate(Routes.Register);

  const goRecoverPassword = () => navigation.navigate(Routes.RecoverPassword);

  return (
    <KeyboardAwareScrollView keyboardShouldPersistTaps="always" style={styles.container} bounces={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View style={styles.middleContainer}>
          <View style={styles.textContainer}>
            <Icon
              style={styles.icon}
              name="user"
            />
            <Text style={styles.title}>{getLabel('login_title')}</Text>
            <Text style={styles.text}>
              {getLabel('login_subTitle')}
            </Text>
          </View>
        </View>
        <View style={styles.controllersContainer}>
          <Text style={styles.errorText}>{errors?.email?.message}</Text>
          <Controller
            name="email"
            control={control}
            rules={MAIL_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_6'),
              getLabel('rules_max_length_31'),
              getLabel('rules_mail_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[styles.input, errors?.email?.message ? styles.invalid : styles.valid]}
                blurOnSubmit
                onBlur={onBlur}
                onChangeText={(inputValue) => onChange(inputValue)}
                value={value}
                placeholder={getLabel('placeholder_email')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                autoCorrect={false}
                autoCompleteType="email"
              />
            )}
          />
          <Text style={styles.errorText}>{errors?.password?.message}</Text>
          <Controller
            name="password"
            control={control}
            rules={PASSWD_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_8'),
              getLabel('rules_max_length_32'),
              getLabel('rules_password_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.subInputContainer}>
                <TextInput
                  style={[styles.input, errors?.password?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  autoCorrect={false}
                  secureTextEntry={hidePassword}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_password')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
                <TouchableOpacity
                  style={styles.buttonHideIcon}
                  onPress={onToggleSwitch}
                >
                  <Icon
                    style={styles.hideIcon}
                    name={isSwitchOn ? 'eye' : 'eye-off'}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.loginButton}
            titleStyle={styles.loginTextButton}
            onPress={handleSubmit(onLogIn)}
            title={getLabel('login_enter_button')}
            loading={isLoading}
          />
          <View style={styles.forgotPasswordContainer}>
            <Pressable onPress={goRecoverPassword}>
              <Text style={styles.forgotPasswordText}>{getLabel('login_forgot_password')}</Text>
            </Pressable>
          </View>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.registerButton}
            titleStyle={styles.registerTextButton}
            onPress={goRegister}
            title={getLabel('login_register_button')}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
