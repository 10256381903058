import { StyleSheet } from 'react-native';
import { Colors } from '../../../../configs/enums';

export const ModeStyles = StyleSheet.create({
  mainContainer: {
    borderColor: Colors.PRIMARY_BUTTON_COLOR,
    borderWidth: 1,
    borderRadius: 10,
    width: 180,
    height: 30,
    justifyContent: 'center',
    alignSelf: 'flex-end',
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  text: {
    textAlign: 'center'
  }
});
