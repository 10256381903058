import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const InvitedCalendar = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  middleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 150
  },
  backgroundPic: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center'
  },
  title: {
    fontSize: 30,
    fontFamily: Fonts.MANROPE_BOLD
  },
  actionButton: {
    width: 80,
    height: 80,
    borderRadius: 50,
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignSelf: 'flex-end'
  },
  actionButtonView: {
    position: 'absolute',
    bottom: 20,
    right: 20
  },
  titleName: {
    fontFamily: Fonts.MANROPE_REGULAR,
    fontSize: 22
  },
  titleView: {
    marginTop: 20,
    flexDirection: 'row',
    alignContent: 'center'
  },
  calendarIcon: {
    fontSize: 22,
    alignSelf: 'center',
    marginRight: 5
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 5
  })
};

export default InvitedCalendar;
