import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { View } from 'react-native';
import { calculateHourStart } from '../../../../utils';
import { functionStyles } from './styles';

function useTime() {
  const [dayTime, setDayTime] = useState(calculateHourStart(`${dayjs().hour()}:${dayjs().minute()}`, true));
  const [weekTime, setWeekTime] = useState(calculateHourStart(`${dayjs().hour()}:${dayjs().minute()}`, false));

  useEffect(() => {
    const interval = setInterval(() => {
      const day = dayjs();

      setDayTime(calculateHourStart(`${day.hour()}:${day.minute()}`, true));
      setWeekTime(calculateHourStart(`${day.hour()}:${day.minute()}`, false));
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return { dayTime, weekTime };
}

interface Props {
  dayMode: boolean
}

const HourLine = ({ dayMode }: Props): React.ReactElement => {
  const dayModeTime = useTime().dayTime;
  const weekModeTime = useTime().weekTime;

  return (
    <View style={functionStyles.currentHourLine(dayMode ? dayModeTime : weekModeTime)} />
  );
};

export default HourLine;
