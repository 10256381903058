import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ILanguagesKeysResponse } from '../../services/language/interfaces';
import type { RootState } from '../rootReducer';
import { ILanguageState, INITIAL_STATE } from './state';

const languageSlice = createSlice({
  name: 'language',
  initialState: INITIAL_STATE,
  reducers: {
    languageUpdateSelectedLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    },
    languageUpdateKeys: (state, action: PayloadAction<ILanguagesKeysResponse>) => {
      state.keys = action.payload;
    },
    languageUpdateState: (state, action: PayloadAction<ILanguageState>) => {
      state.keys = action.payload.keys;
      state.selectedLanguage = action.payload.selectedLanguage;
    }
  }
});

export const {
  languageUpdateKeys,
  languageUpdateSelectedLanguage,
  languageUpdateState
} = languageSlice.actions;

export default languageSlice.reducer;

export const selectLanguageKeys = (state: RootState) => (key: string) => {
  try {
    if (!state.language.keys) {
      return '';
    }

    if (!state.language.keys[key]) {
      return '';
    }

    if (Array.isArray(state.language.keys[key]) && state.language.keys[key]?.length === 0) {
      return [];
    }

    return state?.language?.keys[key];
  } catch (error) {
    return '';
  }
};

export const selectLanguageSelectedLanguage = (state: RootState) => state.language.selectedLanguage;
