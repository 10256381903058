import React, {
  useEffect,
  useState
} from 'react';
import {
  View,
  Text,
  useWindowDimensions,
  Linking
} from 'react-native';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import axios from 'axios';
import { Button } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';

import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Modal from 'react-native-modal';
import Icon from 'react-native-vector-icons/Feather';
import { Picker } from '@react-native-picker/picker';
import { USER_LANGUAGE_KEY } from '@env';
import { lenguagesGetKeys } from '../../services/language';
import {
  languageUpdateKeys,
  languageUpdateSelectedLanguage,
  selectLanguageKeys,
  selectLanguageSelectedLanguage
} from '../../redux/language';
import Routes from '../../navigation/Routes';
import styles, {
  functionStyles
} from './styles';
import {
  isWeb,
  isIos,
  removeAsyncStorageData,
  storeAsyncStorageData,
  isMobile
} from '../../utils';
import {
  Colors,
  Languages
} from '../../configs/enums';
import Logo from '../../svg/logo';
import {
  globalSetStartupLinkProcessed,
  selectStartupLinkProcessed
} from '../../redux/global';
import { showToastError } from '../../utils/toast';

export default (): React.ReactElement => {
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const userLanguage = useSelector(selectLanguageSelectedLanguage);
  const startupLinkProcessed = useSelector(selectStartupLinkProcessed);

  const getLabel = useSelector(selectLanguageKeys);

  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(userLanguage);

  const toggleModalLanguage = () => {
    setIsModalVisible(!isModalVisible);
  };

  const changeLanguage = async (itemValue: string) => {
    setSelectedLanguage(itemValue);
    const language = await lenguagesGetKeys(itemValue);

    if (language.response) {
      dispatch(languageUpdateKeys(language.response));
      dispatch(languageUpdateSelectedLanguage(itemValue));

      await removeAsyncStorageData(USER_LANGUAGE_KEY);
      await storeAsyncStorageData({ keys: language.response, selectedLanguage: itemValue }, USER_LANGUAGE_KEY);
    }
  };

  const goLogin = () => navigation.navigate(Routes.Login);

  const goRegister = () => navigation.navigate(Routes.Register);

  async function handleIncomingLink(event: any) {
    const path = event.url;
    if (!path.includes('www.2meetup.app')) {
      try {
        navigation.navigate('EventFromIcs', { path });
      } catch (err) {
        showToastError('Error ', 'Cannot access to ics processing');
        console.info('Err !path.includes(\'www.2meetup.app\') ', err);
      }
    } else if (path.includes('www.2meetup.app/Schedule')) {
      try {
        const response = await axios.get(path);
        const redirectUrl = response.request.responseURL;
        await Linking.openURL(redirectUrl);
      } catch (err) {
        console.info('Err path.includes(\'www.2meetup.app/Schedule\') ', err);
      }
    }
  }

  async function processStartupLink() {
    const initialUrl = await Linking.getInitialURL();
    if (initialUrl) {
      dispatch(globalSetStartupLinkProcessed(true));
      await handleIncomingLink({ url: initialUrl });
    }
  }

  useEffect(() => {
    if (!startupLinkProcessed && isMobile) processStartupLink();
  }, []);

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView bounces={false}>
        <View style={functionStyles.mainContainer(windowDimensions.width)}>
          <View style={styles.logoContainer}>
            <View style={styles.mainLogo}>
              <Logo width={150} height={150} />
            </View>
            {isWeb ? (
              <Picker
                style={styles.picker}
                selectedValue={selectedLanguage}
                onValueChange={changeLanguage}
              >
                {getLabel('landing_language_label').map((language: string | undefined, index: number) => (
                  <Picker.Item key={index} label={language} value={Object.keys(Languages)[index]} />
                ))}
              </Picker>
            ) : (
              <View>
                { isIos ? (
                  <View style={styles.mobilePickercontainer}>
                    <Text style={{ paddingRight: 5 }}>{selectedLanguage}</Text>
                    <Icon
                      style={styles.dropdownIcon}
                      name="chevron-down"
                      onPress={toggleModalLanguage}
                    />
                  </View>
                ) : (
                  <Picker
                    selectedValue={selectedLanguage}
                    onValueChange={changeLanguage}
                    style={styles.androidPicker}
                    dropdownIconColor={Colors.TERTIARY_TEXT_COLOR}
                    dropdownIconRippleColor={Colors.TERTIARY_TEXT_COLOR}
                    enabled
                  >
                    {getLabel('landing_language_label').map((language: string | undefined, index: number) => (
                      <Picker.Item key={index} label={language} value={Object.keys(Languages)[index]} />
                    ))}
                  </Picker>
                )}
              </View>
            )}
          </View>
          <View style={styles.middleContainer}>
            <View style={styles.textContainer}>
              <Icon
                style={styles.icon}
                name="calendar"
              />
              <Text style={styles.title}>{getLabel('landing_title')}</Text>
              <Text style={styles.text}>
                {getLabel('landing_subTitle')}
              </Text>
            </View>
          </View>
          <View style={styles.bottomContainer}>
            <View style={styles.subBottomContainer}>
              <Button
                containerStyle={styles.buttonContainer}
                buttonStyle={styles.registerButton}
                titleStyle={styles.registerTextButton}
                onPress={goRegister}
                title={getLabel('landing_register_button')}
              />
            </View>
            <View style={styles.subBottomContainer}>
              <Button
                containerStyle={styles.buttonContainer}
                buttonStyle={styles.isRegisterButton}
                titleStyle={styles.isRegisterTextButton}
                onPress={goLogin}
                title={getLabel('landing_login_button')}
              />
            </View>
          </View>
          <Modal
            isVisible={isModalVisible}
            backdropTransitionOutTiming={0}
            deviceHeight={windowDimensions.height}
            deviceWidth={windowDimensions.width}
            backdropOpacity={0}
            onBackdropPress={toggleModalLanguage}
            animationIn="fadeIn"
            animationOut="fadeOut"
          >
            <View style={styles.modalContainer}>
              <Picker
                selectedValue={selectedLanguage}
                onValueChange={changeLanguage}
              >
                {getLabel('landing_language_label').map((language: string | undefined, index: number) => (
                  <Picker.Item key={index} label={language} value={Object.keys(Languages)[index]} />
                )) }
              </Picker>
              <Button
                containerStyle={styles.buttonContainer}
                buttonStyle={styles.registerButton}
                titleStyle={styles.registerTextButton}
                onPress={toggleModalLanguage}
                title={getLabel('landing_modal_button')}
              />
            </View>
          </Modal>
        </View>
      </KeyboardAwareScrollView>
    </View>
  );
};
