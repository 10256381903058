import React, { useCallback, useEffect, useState } from 'react';
import {
  View, Text, TextInput, useWindowDimensions, Pressable, Switch
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/Feather';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'react-native-elements';
import Modal from 'react-native-modal';
import { useSelector, useDispatch } from 'react-redux';
import { Picker } from '@react-native-picker/picker';
import dayjs from 'dayjs';
import { IEventCreateExternal } from '../../services/events/interface';
import NativePicker from '../../components/NativePicker';
import styles, { functionStyles } from './style';
import { selectUserInfo, selectUserLogged } from '../../redux/user';
import { selectEventParticipants, eventResetState } from '../../redux/event';
import { showToastError, showToastInfo, showToastSuccess } from '../../utils/toast';
import { CustomModal } from '../../components/CustomModal';
import { WebPicker } from '../../components/WebPicker';
import { MobilePicker } from '../../components/MobilePicker';
import {
  isWeb, getOneHourMore, isDateInPast, applyTrimToFields
} from '../../utils';
import {
  TIME_HOURS_REGEX
} from '../../utils/constants';
import {
  MAIL_VALIDATION, EVENT_TITLE_VALIDATION, EVENT_DATE_VALIDATION, EVENT_DESCRIPTION_VALIDATION, NAME_VALIDATION
} from '../../utils/rules';
import Routes from '../../navigation/Routes';
import { Colors, OCCASIONTYPE } from '../../configs/enums';
import { eventCreateExternal } from '../../services/events';
import { globalSetMustResetCalendar } from '../../redux/global';
import { selectLanguageKeys } from '../../redux/language';
import NativeDateTimePicker from '../../components/NativeDateTimePicker';

interface DataForm {
    scheduleUuid: string;
    title: string;
    email: string;
    firstName: string;
    lastName: string;
    eventDate: string;
    occasionMeetData: string;
    emailsGuest: string;
    description: string;
}

interface Props {
  route: {
    params: {
      hashLink: string,
      startTime: string | undefined,
      startTimeNumber: number | undefined,
      dayMode: boolean,
      date: string
    }
  };
}

export default ({ route }: Props): React.ReactElement => {
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const selectedParticipants = useSelector(selectEventParticipants);
  const getLabel = useSelector(selectLanguageKeys);
  const {
    jwt, email, firstName, lastName
  } = useSelector(selectUserInfo);
  const isUserLogged = useSelector(selectUserLogged);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateVisible, setDateVisible] = useState<boolean>(false);
  const [isAllDayLong, setisAllDayLong] = useState<boolean>(false);
  const [isModalSetTimeVisible, setIsModalSetTimeVisible] = useState(false);
  const [beginTime, setBeginTime] = useState(route.params.startTime ? route.params.startTime : '09:00');
  const [endTime, setEndTime] = useState(route.params.startTime ? getOneHourMore(route.params.startTime) : '18:00');
  const [errorMessaje, setErrorMessaje] = useState('');
  const [showInnerModalBeginTime, setShowInnerModalBeginTime] = useState(false);
  const [showInnerModalEndTime, setShowInnerModalEndTime] = useState(false);

  const [modalOccasionTypeIOS, setModalOcassionTypeIOS] = useState(false);

  const [timePlaceholder, setTimePlaceholder] = useState(!route.params);

  const [occasionType, setOccasionType] = useState('NONE');

  const [prevBeginTime, setPrevBeginTime] = useState('');

  const defaultValues = {
    scheduleUuid: '',
    title: '',
    email: '',
    firstName: '',
    lastName: '',
    eventDate: route.params.date,
    occasionMeetData: '',
    emailsGuest: '',
    description: ''
  };

  const handleKeydown = useCallback(
    (event: any) => {
      if (event.key === 'Escape') {
        navigation.goBack();
      }
    },
    []
  );

  useEffect(() => {
    if (isWeb) {
      window.addEventListener('keydown', handleKeydown);
    }

    return () => {
      if (isWeb) {
        window.removeEventListener('keydown', handleKeydown);
      }
    };
  }, []);

  const {
    handleSubmit, control, formState: { errors }, setValue, getValues
  } = useForm({
    defaultValues
  });

  useEffect(() => {
    setValue('email', email);
    setValue('firstName', firstName);
    setValue('lastName', lastName);
  }, []);

  const goBackScreen = () => {
    if (isUserLogged) {
      dispatch(globalSetMustResetCalendar(true));
      navigation.reset({
        index: 0,
        routes: [{
          key: 'Main-1',
          name: 'Root',
          params: {
            screen: 'Main',
            params: {
              screen: 'HomeStack'
            }
          }
        }]
      });
    } else {
      navigation.reset({
        index: 0,
        routes: [{
          key: 'Landing-1',
          name: 'Landing'
        }]
      });
    }
  };

  const showCalendar = () => {
    setDateVisible(true);
  };

  const openTimeModal = () => {
    setIsModalSetTimeVisible(!isModalSetTimeVisible);
    if (!isModalSetTimeVisible) {
      setPrevBeginTime(beginTime);
    }
  };

  const closeTime = () => {
    setIsModalSetTimeVisible(!isModalSetTimeVisible);
    setErrorMessaje('');
    setTimePlaceholder(true);
    setBeginTime('09:00');
    setEndTime('18:00');
  };

  const onChangeBeginTime = (newBeginTime: string) => {
    const defaultDate = dayjs().format('YYYY-MM-DD');

    const minutesDiff = dayjs(`${defaultDate} ${newBeginTime}`).diff(`${defaultDate} ${prevBeginTime}`, 'minutes');

    const newEndTime = dayjs(`${defaultDate} ${endTime}`).add(minutesDiff, 'minutes').format('HH:mm');

    setPrevBeginTime(newBeginTime);
    setBeginTime(newBeginTime);

    const newBeginHour = Number(newBeginTime.split(':')[0]);
    const newEndHour = Number(newEndTime.split(':')[0]);

    if (newBeginHour > newEndHour) {
      setEndTime('23:59');
    } else {
      setEndTime(newEndTime);
    }
  };

  const onChangeEndTime = (value: string) => {
    setEndTime(value);
  };

  const addTime = () => {
    if (beginTime.match(TIME_HOURS_REGEX) === null || endTime.match(TIME_HOURS_REGEX) === null) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_validTime'));
    } else if (Number(beginTime.replace(':', '')) > Number(endTime.replace(':', ''))) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_beforeTime'));
    } else if (Number(beginTime.replace(':', '')) === Number(endTime.replace(':', ''))) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_matchingTimes'));
    } else {
      setIsModalSetTimeVisible(false);
      setTimePlaceholder(false);
      setErrorMessaje('');
    }
  };

  const allowIsAllDay = () => {
    setisAllDayLong(!isAllDayLong);
  };

  const onSetOccasionType = (value: string) => {
    setOccasionType(value);
  };

  const toggleOcassionTypeModal = () => {
    setModalOcassionTypeIOS(!modalOccasionTypeIOS);
  };

  const addPeople = () => {
    const eventDate = getValues('eventDate');

    if (eventDate === '') {
      showToastInfo(getLabel('global_warning'), getLabel('event_warning_choose_date'));
    } else {
      navigation.navigate(Routes.AddPeople, { from: beginTime, to: endTime, eventDate });
    }
  };

  const addEvent = async (data: DataForm) => {
    if (isDateInPast(data.eventDate)) {
      showToastError(getLabel('error'), getLabel('backend_error_event_is_not_present_or_future'));
      setIsLoading(false);
      return;
    }

    applyTrimToFields(data, ['email', 'firstName', 'lastName']);
    setIsLoading(true);
    if (timePlaceholder && !isAllDayLong) {
      showToastError(getLabel('event_time_title'), getLabel('event_warning_choose_time'));
    } else {
      const request: IEventCreateExternal = {
        firstName: data.firstName,
        lastName: data.lastName,
        scheduleHashLink: route.params.hashLink,
        title: data.title,
        eventDate: data.eventDate,
        emailsGuest: selectedParticipants.map((participant) => participant.email),
        occasionType,
        description: data.description,
        ownerEmail: data.email,
        ...(!isAllDayLong) && { hourRange: { from: beginTime, to: endTime } },
        ...(isAllDayLong) && { hourRange: { from: '09:00', to: '18:00' } },
        ...(occasionType !== OCCASIONTYPE.NONE) && { occasionMeetData: data.occasionMeetData }
      };

      const response = await eventCreateExternal(request, jwt);
      if (response.error) {
        showToastError(getLabel('event_creation_error_title'), getLabel(response.errorMessage!));
      } else {
        dispatch(eventResetState());
        showToastSuccess(getLabel('event_created_title'), getLabel('event_success_creation'));
        goBackScreen();
      }
    }
    setIsLoading(false);
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false} showsVerticalScrollIndicator={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View style={styles.headerContainer}>
          <Text style={styles.title}>{getLabel('event_title')}</Text>
        </View>
        <View style={styles.controllersContainer}>
          <Text style={styles.errorText}>{errors?.title?.message}</Text>
          <Controller
            name="title"
            rules={EVENT_TITLE_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_max_length_30')
            )}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[styles.input, errors?.title?.message ? styles.invalid : styles.valid]}
                onBlur={onBlur}
                onChangeText={(text) => onChange(text)}
                value={value}
                placeholder={getLabel('placeholder_event_title')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
              />
            )}
          />
          <Text style={styles.errorText}>{errors?.eventDate?.message}</Text>
          <Controller
            name="eventDate"
            rules={EVENT_DATE_VALIDATION(getLabel('rules_obligatory'))}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Pressable style={{ width: '100%' }} onPress={showCalendar}>
                <View pointerEvents="none">
                  <TextInput
                    style={[
                      styles.input,
                      errors?.eventDate?.message
                        ? styles.invalid : styles.valid]}
                    onChange={onChange}
                    value={value}
                    placeholder={getLabel('placeholder_select_date')}
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  />
                  <View>
                    {isWeb ? (
                      <WebPicker setDate={onChange} isVisible={dateVisible} setIsVisible={setDateVisible} limitDateYears={-100} />
                    ) : (
                      <MobilePicker setDate={onChange} isVisible={dateVisible} setIsVisible={setDateVisible} limitDateYears={0} startDate={dayjs(route.params.date)} />
                    )}
                  </View>
                </View>
              </Pressable>
            )}
          />
          <Pressable style={isWeb ? styles.timeImput : styles.timeImputIOS} onPress={isAllDayLong ? () => {} : openTimeModal}>
            <View pointerEvents="none">
              <TextInput
                style={[
                  styles.input,
                  isAllDayLong
                    ? styles.allDayLong : styles.valid]}
                value={timePlaceholder ? '' : `${`${beginTime} ${getLabel('to_connector')} ${endTime}`}hs`}
                placeholder={getLabel('placeholder_select_time')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
              />
            </View>
          </Pressable>
          <View style={isWeb ? [styles.input, styles.subInputAllowAllDay] : styles.allowNotificationsContainer}>
            <Text style={styles.textAllowNotifications}>{getLabel('event_switch')}</Text>
            <Switch
              trackColor={{ false: '#767577', true: '#31267C69' }}
              thumbColor={isAllDayLong ? '#305fab' : '#f4f3f4'}
              ios_backgroundColor="#31267C69"
              onValueChange={allowIsAllDay}
              value={isAllDayLong}
            />
          </View>

          <Text style={styles.errorText}>{errors?.email?.message}</Text>
          <Controller
            name="email"
            rules={MAIL_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_6'),
              getLabel('rules_max_length_31'),
              getLabel('rules_mail_regex')
            )}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[styles.input, errors?.email?.message ? styles.invalid : styles.valid]}
                onBlur={onBlur}
                onChangeText={(text) => onChange(text)}
                value={value}
                placeholder={getLabel('placeholder_email')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                editable={!isUserLogged}
                autoCompleteType="email"
              />
            )}
          />

          <Text style={styles.errorText}>{errors?.firstName?.message}</Text>
          <Controller
            name="firstName"
            rules={NAME_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_2'),
              getLabel('rules_max_length_31')
            )}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[styles.input, errors?.firstName?.message ? styles.invalid : styles.valid]}
                onBlur={onBlur}
                onChangeText={(text) => onChange(text)}
                value={value}
                placeholder={getLabel('placeholder_firstName')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                editable={!isUserLogged}
              />
            )}
          />

          <Text style={styles.errorText}>{errors?.lastName?.message}</Text>
          <Controller
            name="lastName"
            rules={NAME_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_2'),
              getLabel('rules_max_length_31')
            )}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[styles.input, errors?.lastName?.message ? styles.invalid : styles.valid]}
                onBlur={onBlur}
                onChangeText={(text) => onChange(text)}
                value={value}
                placeholder={getLabel('placeholder_lastName')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                editable={!isUserLogged}
              />
            )}
          />

          <View style={styles.someMargin}>
            <Text style={styles.labelText}>{getLabel('event_type_meet')}</Text>
            <NativePicker
              data={getLabel('occasion_type_info')}
              value={occasionType}
              setValue={onSetOccasionType}
            />
          </View>

          {occasionType !== OCCASIONTYPE.NONE && (
            <View style={[styles.pressableWidth]}>
              <Text style={styles.errorText}>{errors?.occasionMeetData?.message}</Text>
              <Controller
                name="occasionMeetData"
                rules={{
                  maxLength: {
                    value: 500,
                    message: getLabel('rules_max_length_500')
                  }
                }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[styles.input, errors?.occasionMeetData?.message ? styles.invalid : styles.valid]}
                    onBlur={onBlur}
                    onChangeText={(text) => onChange(text)}
                    value={value}
                    placeholder={getLabel('placeholder_type_meet')}
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  />
                )}
              />
            </View>
          )}

          <Pressable style={[styles.addPeopleContainer, selectedParticipants.length > 0 ? styles.addPeopleYesContainer : styles.addPeopleNoContainer]} onPress={addPeople}>
            <Text style={[styles.addPeopleText, selectedParticipants.length > 0 ? styles.addPeopleYesText : styles.addPeopleNoText]}>
              {selectedParticipants.length > 0 ? selectedParticipants.map((participant) => `${participant.email}; `) : getLabel('event_add_people')}
            </Text>
          </Pressable>
        </View>
        <View style={styles.middleContainer}>
          <Text style={styles.subTitle}>{getLabel('event_subTitle')}</Text>
          <Text style={styles.errorText}>{errors?.description?.message}</Text>
          <Controller
            name="description"
            rules={EVENT_DESCRIPTION_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_max_length_500')
            )}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                multiline
                numberOfLines={4}
                textAlignVertical="top"
                style={[styles.input, { height: 100 }, errors?.description?.message ? styles.invalid : styles.valid]}
                onBlur={onBlur}
                onChangeText={(text) => onChange(text)}
                value={value}
                placeholder={getLabel('placeholder_add_description')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
              />
            )}
          />
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={handleSubmit((data) => addEvent(data))}
            title={getLabel('event_button_add_event')}
            loading={isLoading}
          />
        </View>

        <CustomModal isVisible={isModalSetTimeVisible}>
          <View style={styles.modalContainer}>
            <View style={styles.modalTitleContainer}>
              <Text style={styles.modalTitle}>{getLabel('agendaOrganizer_modal_title')}</Text>
              <Button
                onPress={closeTime}
                type="clear"
                icon={(
                  <Icon
                    style={styles.modalIcon}
                    name="x-circle"
                  />
              )}
              />
            </View>
            <View style={styles.pickerTimeContainer}>
              <View style={styles.divisorSpace}>
                <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_start_time')}</Text>
                <NativeDateTimePicker
                  value={beginTime}
                  setValue={onChangeBeginTime}
                  inputStyle={styles.pickerContainer}
                  mode="time"
                />
              </View>

              <View style={styles.divisorSpace}>
                <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_end_time')}</Text>
                <NativeDateTimePicker
                  value={endTime}
                  setValue={onChangeEndTime}
                  inputStyle={styles.pickerContainer}
                  mode="time"
                />
              </View>
            </View>
            <View>
              <Text style={styles.errorMessage}>{errorMessaje}</Text>
            </View>
            <View>
              <Button
                containerStyle={styles.modalButtonContainer}
                buttonStyle={styles.addEventButton}
                titleStyle={styles.addEventTextButton}
                onPress={addTime}
                title={getLabel('agendaOrganizer_modal_button')}
              />
            </View>
          </View>
        </CustomModal>

        <Modal
          isVisible={modalOccasionTypeIOS}
          backdropTransitionOutTiming={0}
          deviceHeight={windowDimensions.height}
          deviceWidth={windowDimensions.width}
          backdropOpacity={0}
          onBackdropPress={toggleOcassionTypeModal}
          animationIn="fadeIn"
          animationOut="fadeOut"
        >
          <View style={styles.modalContainer}>
            <Picker
              style={styles.picker}
              selectedValue={occasionType}
              onValueChange={(occasion) => setOccasionType(occasion)}
            >
              {Object.keys(OCCASIONTYPE).map((element, index) => (
                <Picker.Item key={index} value={element} />
              ))}
            </Picker>
            <Button
              containerStyle={styles.buttonReminderContainer}
              buttonStyle={styles.closeReminderButton}
              titleStyle={styles.closeReminderTextButton}
              onPress={toggleOcassionTypeModal}
              title={getLabel('landing_modal_button')}
            />
          </View>
        </Modal>
      </View>
    </KeyboardAwareScrollView>
  );
};
