import { API_METHOD, fetcher } from '..';
import { LANGUAGE_CODES, LANGUAGE_KEYS } from '../api';
import { FetcherResponse } from '../FetcherResponse';
import { ILanguagesCodesResponse, ILanguagesKeysResponse } from './interfaces';

export const lenguagesGetCodes = async () : Promise<FetcherResponse<ILanguagesCodesResponse>> => {
  try {
    const response = await fetcher<ILanguagesCodesResponse>(API_METHOD.GET, LANGUAGE_CODES);
    return response;
  } catch (error: any) {
    return error;
  }
};

export const lenguagesGetKeys = async (language: string) : Promise<FetcherResponse<ILanguagesKeysResponse>> => {
  try {
    const response = await fetcher<ILanguagesKeysResponse>(API_METHOD.GET, `${LANGUAGE_KEYS}/${language}`);
    return response;
  } catch (error: any) {
    return error;
  }
};
