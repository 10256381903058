import {
  configureStore,
  Action
} from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import rootReducer, { RootState } from './rootReducer';

import reactotronMiddleware from '../configs/ReactToTron/ReactotronConfig';

const enhancers: Array<any> = [];

if (__DEV__) {
  if (reactotronMiddleware.createEnhancer) {
    enhancers.push(reactotronMiddleware.createEnhancer());
  }
}

const store = configureStore({
  reducer: rootReducer,
  enhancers
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export default store;
