import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../../configs/constants';
import { Colors, Fonts, STATUS } from '../../../configs/enums';

const RecoverPassStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  backgroundPic: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  firstContainer: {},
  middleContainer: {
    marginTop: 10
  },
  mainTitle: {
    fontSize: 25,
    fontFamily: Fonts.MANROPE_BOLD
  },
  scondaryTitlesContainer: {
    paddingBottom: 15
  },
  scondaryTitles: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD
  },
  infoContainer: {
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginBottom: 15
  },
  descriptionHeight: {
    height: 80
  },
  bottomContainer: {
    marginTop: 10
  },
  subBottomContainer: {
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
    marginBottom: 15
  },
  mainButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    width: '100%',
    height: 50
  },
  deleteButton: {
    backgroundColor: Colors.DELETE_BUTTON_COLOR
  },
  mainTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  modalContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1
  },
  modalTitleContainer: {
    flexDirection: 'column',
    justifyContent: 'center'
  },
  modalIcon: {
    fontSize: 25,
    alignSelf: 'flex-end',
    marginLeft: 5,
    paddingHorizontal: 50,
    marginTop: 50
  },
  modalTitle: {
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: 22,
    paddingBottom: 20,
    alignSelf: 'center',
    textAlign: 'center'
  },
  modalChangeContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginHorizontal: 10
  },
  modalButtonContainer: {
    width: '100%',
    paddingHorizontal: 50,
    marginTop: 50,
    marginBottom: 30
  },
  pickerContainer: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  pickersContainer: {
    marginHorizontal: 60
  },
  divisorSpace: {
    marginTop: 20
  },
  hoursContainer: {
    flexDirection: 'row'
  },
  hourInfoContainer: {
    width: 150,
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginBottom: 15,
    marginRight: 10
  },
  addPeopleContainer: {
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderWidth: 1,
    height: 50
  },
  addPeopleYesContainer: {
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderColor: Colors.INPUT_BORDER_COLOR
  },
  addPeopleText: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25
  },
  addPeopleYesText: {
    color: Colors.SECONDARY_TEXT_COLOR
  },
  modalGuestsContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 40,
    paddingHorizontal: 50,
    marginHorizontal: 20
  },
  title: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    alignSelf: 'center'
  },
  modalParticipanTitle: {
    fontSize: 22,
    textAlign: 'center',
    alignSelf: 'flex-start',
    paddingBottom: 15
  },
  participantList: {
    maxHeight: 300
  },
  modalInfoContainer: {
    flexDirection: 'row',
    paddingVertical: 5,
    alignItems: 'center'
  },
  modalParticipantsContainer: {
    flexDirection: 'column',
    flex: 1
  },
  modalParticipantEmail: {
    fontSize: 14,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  viewCircle: {
    height: 30,
    width: 30,
    borderRadius: 15,
    marginLeft: 5
  },
  aproved: {
    backgroundColor: Colors.NOTIFICATION_APROVED
  },
  reject: {
    backgroundColor: Colors.NOTIFICATION_REJECTED
  },
  pending: {
    backgroundColor: Colors.NOTIFICATION_PENDING
  },
  modalButton: {
    marginTop: 35
  },
  addEventButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  addEventTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  addPeopleNoContainer: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    borderColor: Colors.PRIMARY_BUTTON_COLOR
  },
  addPeopleNoText: {
    color: Colors.PRIMARY_TEXT_COLOR
  },
  labelText: {
    fontSize: 12,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  modalAcceptRejectContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginHorizontal: 10
  },
  someMargin: {
    marginTop: 10
  },
  modalAcceptRejectTitle: {
    fontSize: 20,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignSelf: 'center'
  },
  subTitleReject: {
    marginTop: 10,
    fontSize: 16,
    fontFamily: Fonts.MANROPE_REGULAR,
    alignSelf: 'center'
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_REGULAR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1,
    color: Colors.SECONDARY_TEXT_COLOR
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  }),
  getStatusColor: (status: STATUS) => {
    switch (status) {
      case STATUS.ACCEPTED:
        return {
          backgroundColor: Colors.NOTIFICATION_APROVED
        };
      case STATUS.NOT_ACCEPTED:
        return {
          backgroundColor: Colors.NOTIFICATION_REJECTED
        };
      case STATUS.PENDING:
        return {
          backgroundColor: Colors.NOTIFICATION_PENDING
        };
      default:
        return {
          backgroundColor: Colors.NOTIFICATION_PENDING
        };
    }
  }
};

export default RecoverPassStyles;
