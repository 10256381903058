import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  useWindowDimensions
} from 'react-native';

import { Button } from 'react-native-elements';
import { userSendSupport } from '../../services/user';
import styles, { functionStyles } from './styles';
import { Colors } from '../../configs/enums';
import { showToastError, showToastInfo, showToastSuccess } from '../../utils/toast';

const SupportScreen = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const windowDimensions = useWindowDimensions();

  const handleSubmit = async () => {
    if (name && email && message) {
      try {
        setLoading(true);
        await userSendSupport(name, email, message);
        showToastSuccess('Success', 'Form submitted successfully');
      } catch (err) {
        showToastError('Error', 'Error sending the form');
      } finally {
        setLoading(false);
      }
    } else {
      showToastInfo('Info', 'Please fill in all the fields');
    }
  };

  return (
    <View style={styles.container}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <Text style={styles.titleLabel}>Support</Text>

        <Text style={styles.labelText}>Name</Text>
        <TextInput
          style={[styles.input]}
          maxLength={64}
          onChangeText={setName}
          value={name}
          placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
        />

        <Text style={styles.labelText}>Email</Text>
        <TextInput
          style={[styles.input]}
          maxLength={64}
          onChangeText={setEmail}
          value={email}
          placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
        />

        <Text style={styles.labelText}>Message</Text>
        <TextInput
          style={[styles.input]}
          maxLength={400}
          onChangeText={setMessage}
          value={message}
          multiline
          numberOfLines={4}
          placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
        />

        <Button
          loading={loading}
          containerStyle={styles.modalButtonContainer}
          buttonStyle={styles.addEventButton}
          titleStyle={styles.addEventTextButton}
          onPress={handleSubmit}
          title="Submit"
        />
      </View>
    </View>
  );
};

export default SupportScreen;
