import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const ProfileStyles = StyleSheet.create({
  mainContainer: {
    flex: 1
  },
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  topContainer: {
    marginBottom: 20,
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'center'
  },
  avatarContainer: {
  },
  userContainer: {
    alignItems: 'flex-start',
    alignContent: 'space-around',
    flex: 1
  },
  avatarImage: {
    height: 120,
    width: 120,
    borderRadius: 99,
    margin: 10,
    borderColor: Colors.PRIMARY_BUTTON_COLOR,
    borderWidth: 2,
    alignSelf: 'center'
  },
  actionButtonView: {
    position: 'absolute',
    bottom: 12,
    right: 12
  },
  actionButton: {
    width: 35,
    height: 35,
    borderRadius: 50,
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignSelf: 'flex-end'
  },
  textUserName: {
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 18
  },
  textUserEmail: {
    fontFamily: Fonts.MANROPE_REGULAR,
    fontSize: 12
  },
  middleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 15
  },
  textContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center'
  },
  icon: {
    fontSize: 20,
    alignSelf: 'center'
  },
  text: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    flex: 1
  },
  controllersContainer: {
    width: '100%'
  },
  errorText: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 10,
    alignSelf: 'flex-start',
    marginTop: 8
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    color: Colors.SECONDARY_TEXT_COLOR
  },
  valid: {
    borderWidth: 1
  },
  invalid: {
    borderColor: Colors.ERROR_COLOR
  },
  subInputContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  hideIcon: {
    fontSize: 18,
    opacity: 0.33,
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingRight: 15
  },

  bottomContainer: {
    alignItems: 'center'
  },
  subBottomContainer: {
    width: '100%'
  },
  buttonContainer: {
    width: '100%',
    marginBottom: 15
  },
  registerButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    width: '100%',
    height: 50
  },
  deleteButton: {
    backgroundColor: Colors.DELETE_BUTTON_COLOR
  },
  registerTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  textNotifications: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    marginBottom: 20
  },
  allowNotificationsContainer: {
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginBottom: 15
  },
  textAllowNotifications: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignSelf: 'center',
    flex: 1
  },
  picker: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    paddingVertical: 10,
    paddingHorizontal: 20,
    textAlign: 'left',
    borderRadius: 10,
    marginTop: 10,
    width: '100%',
    borderWidth: 1
  },
  mobilePickercontainer: {
    flexDirection: 'row',
    padding: 10,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    marginTop: 20
  },
  dropdownIcon: {
    fontSize: 18,
    opacity: 0.33
  },
  modalContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 15,
    marginHorizontal: 20
  },
  androidPickercontainer: {
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    marginTop: 20
  },
  modalDeleteContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 40,
    paddingHorizontal: 50,
    marginHorizontal: 20
  },
  title: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    alignSelf: 'center'
  },
  modalPhotoContainer: {
    justifyContent: 'flex-end',
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 15
  },
  photoIconsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-evenly'
  },
  subPhotoIconsContainer: {
    alignItems: 'center'
  },
  photoTitle: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    paddingBottom: 20,
    textAlign: 'center'
  },
  photoText: {
    fontSize: 14,
    fontFamily: Fonts.MANROPE_REGULAR,
    paddingTop: 3
  },
  photoIcons: {
    width: 45,
    height: 45,
    borderRadius: 50,
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignSelf: 'flex-end'
  },
  toolTipContainer: {
    borderRadius: 10
  },
  toolTipText: {
    fontSize: 12,
    fontFamily: Fonts.MANROPE_LIGTH
  },
  toolTipSettingsIcon: {
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderRadius: 5
  },
  loadingImage: {
    marginTop: 55
  },
  loadingImageContainer: {
    flexDirection: 'row',
    position: 'absolute',
    alignItems: 'center',
    alignSelf: 'center'
  },
  versionCode: {
    alignSelf: 'center',
    marginVertical: 10,
    fontSize: 14
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  }),
  modalPhotoWebContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  })
};

export default ProfileStyles;
