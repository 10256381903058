import { RootNavigationRoute } from './helpers';

export type RouteType = keyof RootNavigationRoute;

const Routes: {[P in RouteType]: P} = {
  Core: 'Core',
  Auth: 'Auth',
  Main: 'Main',
  Root: 'Root',
  Landing: 'Landing',
  Login: 'Login',
  Register: 'Register',
  RecoverPassword: 'RecoverPassword',
  OneTimePasscode: 'OneTimePasscode',
  NewPassword: 'NewPassword',
  HomeStack: 'HomeStack',
  Home: 'Home',
  AgendaStack: 'AgendaStack',
  Schedules: 'Schedules',
  AgendaOrganizer: 'AgendaOrganizer',
  ProfileStack: 'ProfileStack',
  Profile: 'Profile',
  ChangeEmail: 'ChangeEmail',
  ChangeOptionalEmail: 'ChangeOptionalEmail',
  ChangePassword: 'ChangePassword',
  DeleteAccount: 'DeleteAccount',
  Event: 'Event',
  EventExternal: 'EventExternal',
  AddPeople: 'AddPeople',
  ViewEvent: 'ViewEvent',
  InvitedCalendarStack: 'InvitedCalendarStack',
  InvitedCalendar: 'InvitedCalendar',
  NotificationsStack: 'NotificationsStack',
  Notifications: 'Notifications',
  DetailNotification: 'DetailNotification',
  AdditionalInfo: 'AdditionalInfo',
  Payments: 'Payments',
  PaymentsHome: 'PaymentsHome',
  FirstTimeStack: 'FirstTimeStack',
  EventDetailsExternal: 'EventDetailsExternal',
  Checkout: 'Checkout',
  CheckoutFinished: 'CheckoutFinished',
  VerifyAccount: 'VerifyAccount',
  Support: 'Support',
  EventFromIcs: 'EventFromIcs',
  Help: 'Help'
};

export default Routes;
