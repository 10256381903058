import React, { useState } from 'react';
import {
  Linking, Text, TouchableOpacity, View
} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';

import WebView from 'react-native-webview';
import { useSelector } from 'react-redux';
import { selectLanguageKeys } from '../../redux/language';
import { isMobile } from '../../utils';
import styles from './styles';

const TERMS_CONDITIONS_URL = 'https://www.2meetup.app/termsconditions';
const PRIVACY_TERMS_URL = 'https://www.2meetup.app/privacy';

function Help() {
  const [showWeb, setShowWeb] = useState(false);
  const [urlToShow, setUrlToShow] = useState<string | null>(null);
  const getLabel = useSelector(selectLanguageKeys);

  function onOpenPrivacyTerms() {
    if (isMobile) {
      setUrlToShow(PRIVACY_TERMS_URL);
      setShowWeb(true);
    } else {
      Linking.openURL(PRIVACY_TERMS_URL);
    }
  }

  function onOpenTermsAndConditions() {
    if (isMobile) {
      setUrlToShow(TERMS_CONDITIONS_URL);
      setShowWeb(true);
    } else {
      Linking.openURL(TERMS_CONDITIONS_URL);
    }
  }

  return (
    <View style={styles.container}>
      {showWeb && urlToShow ? (
        <>
          <TouchableOpacity
            style={styles.closeWebView}
            onPress={() => setShowWeb(false)}
          >
            <Icon
              style={{ fontSize: 32 }}
              name="x"
              color="black"
            />
          </TouchableOpacity>
          <WebView
            source={{ uri: urlToShow }}
          />
        </>
      ) : (
        <>
          <TouchableOpacity
            onPress={() => onOpenTermsAndConditions()}
            style={styles.pressableText}
          >
            <Text style={styles.text}>{getLabel('terms_conditions')}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => onOpenPrivacyTerms()}
            style={styles.pressableText}
          >
            <Text style={styles.text}>{getLabel('privacy_terms')}</Text>
          </TouchableOpacity>
        </>
      )}
    </View>
  );
}

export default Help;
