import React, { useEffect, useMemo, useState } from 'react';
import {
  View, Text, TextInput, useWindowDimensions, Pressable, Switch, ScrollView
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/Feather';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'react-native-elements';
import Modal from 'react-native-modal';
import { useSelector, useDispatch } from 'react-redux';
import ContentLoader, { Rect } from 'react-content-loader/native';
import dayjs from 'dayjs';
import {
  EventDetails,
  IAcceptOrRejectEventInternalRequest,
  IAcceptRejectEventExternalRequest,
  IAppointmentChangeInternal,
  IEventEdit
} from '../../../../services/events/interface';
import { EventLight } from '../../../../components/Calendar/interfaces';
import styles, { functionStyles } from './styles';
import { selectUserInfo } from '../../../../redux/user';
import { selectEventParticipants, eventSetParticipants, eventResetState } from '../../../../redux/event';
import { showToastError, showToastInfo, showToastSuccess } from '../../../../utils/toast';
import { CustomModal } from '../../../../components/CustomModal';
import { WebPicker } from '../../../../components/WebPicker';
import { MobilePicker } from '../../../../components/MobilePicker';
import {
  getHourSubtracted, isDateInPast, isWeb, removeNotification, scheduleLocalNotification
} from '../../../../utils';
import {
  TIME_HOURS_REGEX
} from '../../../../utils/constants';
import {
  EVENT_TITLE_VALIDATION, EVENT_DATE_VALIDATION, EVENT_DESCRIPTION_VALIDATION
} from '../../../../utils/rules';
import Routes from '../../../../navigation/Routes';
import {
  Colors, OCCASIONTYPE, PERIODICITY_END, STATUS, PERIODICITY, TYPEOFEVENT, TYPEOFNOTIFICATION
} from '../../../../configs/enums';
import {
  acceptOrRejectExternalInvite,
  acceptOrRejectInternalInvite,
  appointmentInternalChange,
  eventDelete,
  eventEdit,
  getEventDetails
} from '../../../../services/events';
import { globalSetMustResetCalendar, globalSetMustResetInvites } from '../../../../redux/global';
import NativePicker from '../../../../components/NativePicker';
import { selectLanguageKeys } from '../../../../redux/language';
import NativeDateTimePicker from '../../../../components/NativeDateTimePicker';

interface DataEditForm {
  title: string;
  eventDate: string;
  hourRange: string;
  occasionMeetData: string;
  minutesBeforeToBlock: number;
  minutesAfterToBlock: number;
  alarmMinutesBefore: number;
  emailsGuest: string[];
  description: string | undefined;
}

const ViewEventLoader = (width: number) => (
  <View>
    <ContentLoader
      speed={2}
      width={isWeb ? 600 : width}
      height={700}
      viewBox={isWeb ? '0 00 600 700' : `0 00 ${width} 700`}
      backgroundColor={Colors.BACKGROUND_LOADER_COLOR}
      foregroundColor={Colors.FOREGROUND_LOADER_COLOR}
    >
      <Rect x="0" y="20" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="80" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="140" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="200" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="260" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="320" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="380" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="440" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="500" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="560" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="620" rx="4" ry="4" width="90%" height="40" />
    </ContentLoader>
  </View>
);

interface Props {
  navigation: any;
  route: {
    params: {
      params: EventLight
    }
  };
}

export default ({ navigation, route }: Props): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const { jwt } = useSelector(selectUserInfo);
  const dispatch = useDispatch();

  const selectedParticipants = useSelector(selectEventParticipants);
  const getLabel = useSelector(selectLanguageKeys);

  const eventParams = useMemo(() => route.params.params, []);

  const defaultValues = {
    title: eventParams.title!,
    eventDate: '',
    hourRange: '',
    occasionMeetData: '',
    minutesBeforeToBlock: 0,
    minutesAfterToBlock: 0,
    alarmMinutesBefore: 0,
    emailsGuest: selectedParticipants.map((participant) => participant.email),
    description: eventParams.description
  };

  const {
    handleSubmit, control, formState: { errors }, setValue, getValues
  } = useForm({
    defaultValues
  });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
  const [dateVisible, setDateVisible] = useState<boolean>(false);
  const [isModalSetTimeVisible, setIsModalSetTimeVisible] = useState(false);
  const [beginTime, setBeginTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [errorMessaje, setErrorMessaje] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [openTrashCanModal, setOpenTrashCanModal] = useState(false);
  const [openModalShowParticipants, setOpenModalShowParticipants] = useState(false);
  const [isBookedTime, setIsBookedTime] = useState(false);
  const [preTimeEvent, setPreTimeEvent] = useState(0);
  const [postTimeEvent, setPostTimeEvent] = useState(0);

  const [occasionType, setOccasionType] = useState('NONE');

  const [meetingReminder, setMeetingReminder] = useState(0);

  const [openModalAcceptInvite, setOpenModalAcceptInvite] = useState(false);
  const [openModalRejectInvite, setOpenModalRejectInvite] = useState(false);
  const [openModalChangeInvite, setOpenModalChangeInvite] = useState(false);
  const [isLoadingActionOfAcceptRejectChange, setIsLoadingActionOfAcceptRejectChange] = useState(false);

  const [startNewTime, setStartNewTime] = useState('09:00');
  const [endNewTime, setEndNewTime] = useState('18:00');
  const [newDate, setNewDate] = useState(dayjs().year(dayjs().year()).format('YYYY-MM-DD'));

  const [isExternal, setIsExternal] = useState(false);
  const [eventData, setEventData] = useState<EventDetails>();

  const [periodicityType, setPeriodicityType] = useState(PERIODICITY.NEVER);
  const [periodicityEndType, setPeriodicityEndType] = useState(PERIODICITY_END.NEVER);
  const [periodicityEndDate, setPeriodicityEndDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [dateVisiblePeriodicity, setDateVisiblePeriodicity] = useState<boolean>(false);

  const [prevBeginTime, setPrevBeginTime] = useState('');

  const [modalEventCreateError, setModalEventCreateError] = useState(false);
  const [guestsToNotInvite, setGuestsToNotInvite] = useState<string[]>([]);

  useEffect(() => {
    const eventGetController = new AbortController();
    const appointmentsGetController = new AbortController();
    (async () => {
      let wasAnError: boolean = false;

      const eventDetailsResponse = await getEventDetails(eventParams.uuid!, jwt);

      if (eventDetailsResponse.error) {
        wasAnError = true;
        showToastError(getLabel('error'), getLabel(eventDetailsResponse.errorMessage!));
        navigation.goBack();
      }

      const eventDetails = eventDetailsResponse.response;

      if (!wasAnError && eventDetails) {
        setBeginTime(eventDetails.range.from);
        setEndTime(eventDetails.range.to);

        setEventData(eventDetails);
        setIsExternal(eventDetails.typeOfEvent === TYPEOFEVENT.EXTERNAL);
        setValue('eventDate', eventDetails.date);
        setValue('hourRange', `${eventDetails.range.from} ${getLabel('to_connector')} ${eventDetails.range.to}hs`);
        setOccasionType(eventDetails.occasionType);

        eventDetails.periodicity && setPeriodicityType(eventDetails.periodicityType! as PERIODICITY);

        if (eventDetails.periodicity && eventDetails.periodicityTo) {
          setPeriodicityEndType(PERIODICITY_END.IN_DATE);
          setPeriodicityEndDate(eventDetails.periodicityTo);
        }

        !eventDetails.periodicity && setPeriodicityEndType(PERIODICITY_END.NEVER);

        eventDetails.occasionType !== OCCASIONTYPE.NONE && setValue('occasionMeetData', eventDetails.occasionMeetData!);

        if (eventDetails.typeOfEvent === TYPEOFEVENT.INTERNAL) {
          (eventDetails.minutesBeforeToBlock !== 0 || eventDetails.minutesAfterToBlock !== 0) && setIsBookedTime(true);
          eventDetails.minutesBeforeToBlock !== 0 && setPreTimeEvent(eventDetails.minutesBeforeToBlock);
          eventDetails.minutesAfterToBlock !== 0 && setPostTimeEvent(eventDetails.minutesAfterToBlock);
          eventDetails.alarmMinutesBefore !== 0 && setMeetingReminder(eventDetails.alarmMinutesBefore);
          setValue('minutesBeforeToBlock', eventDetails.minutesBeforeToBlock);
          setValue('minutesAfterToBlock', eventDetails.minutesAfterToBlock);
          setValue('alarmMinutesBefore', eventDetails.alarmMinutesBefore);
        }

        if (eventDetails.guestsEmails && eventDetails.guestsEmails.length > 0) {
          dispatch(eventSetParticipants(eventDetails.guestsEmails.map((guest) => ({ email: guest.email, status: guest.status }))));
        }
        setIsLoading(false);
      }
    })();

    return () => {
      eventGetController.abort();
      appointmentsGetController.abort();
    };
  }, []);

  const successEventAcceptRejectChange = () => {
    dispatch(globalSetMustResetCalendar(true));
    dispatch(globalSetMustResetInvites(true));
    setIsLoadingActionOfAcceptRejectChange(false);
    navigation.goBack();
  };

  const failedEventAcceptRejectChange = () => {
    dispatch(globalSetMustResetCalendar(true));
    dispatch(globalSetMustResetInvites(true));
    setIsLoadingActionOfAcceptRejectChange(false);
  };

  const allowEdit = () => {
    setEditMode(!editMode);
  };

  const allowDelete = () => {
    setOpenTrashCanModal(!openTrashCanModal);
  };

  const showCalendar = () => {
    setDateVisible(true);
  };

  const openTimeModal = () => {
    setIsModalSetTimeVisible(!isModalSetTimeVisible);
    if (!isModalSetTimeVisible) {
      setPrevBeginTime(beginTime);
    }
  };

  const closeTime = () => {
    setIsModalSetTimeVisible(!isModalSetTimeVisible);
    setErrorMessaje('');
    setBeginTime('09:00');
    setEndTime('18:00');
  };

  const onChangeBeginTime = (newBeginTime: string) => {
    const defaultDate = dayjs().format('YYYY-MM-DD');

    const minutesDiff = dayjs(`${defaultDate} ${newBeginTime}`).diff(`${defaultDate} ${prevBeginTime}`, 'minutes');

    const newEndTime = dayjs(`${defaultDate} ${endTime}`).add(minutesDiff, 'minutes').format('HH:mm');

    setPrevBeginTime(newBeginTime);
    setBeginTime(newBeginTime);

    const newBeginHour = Number(newBeginTime.split(':')[0]);
    const newEndHour = Number(newEndTime.split(':')[0]);

    if (newBeginHour > newEndHour) {
      setEndTime('23:59');
    } else {
      setEndTime(newEndTime);
    }
  };

  const onChangeEndTime = (value:string) => {
    setEndTime(value);
  };

  const addTime = () => {
    if (beginTime.match(TIME_HOURS_REGEX) === null || endTime.match(TIME_HOURS_REGEX) === null) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_validTime'));
    } else if (Number(beginTime.replace(':', '')) > Number(endTime.replace(':', ''))) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_beforeTime'));
    } else if (Number(beginTime.replace(':', '')) === Number(endTime.replace(':', ''))) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_matchingTimes'));
    } else {
      setIsModalSetTimeVisible(false);
      setErrorMessaje('');
    }
  };

  const addPeople = () => {
    const eventDate = getValues('eventDate');

    if (eventDate === '') {
      showToastInfo(getLabel('global_warning'), getLabel('event_warning_choose_date'));
    } else {
      navigation.navigate(Routes.AddPeople, { from: beginTime, to: endTime, eventDate });
    }
  };

  const confirmEventEdit = async (data: DataEditForm) => {
    if (isDateInPast(data.eventDate)) {
      showToastError(getLabel('error'), getLabel('backend_error_event_is_not_present_or_future'));
      setIsLoading(false);
      return;
    }

    if (periodicityType !== PERIODICITY.NEVER
      && periodicityEndType !== PERIODICITY_END.NEVER
      && isDateInPast(periodicityEndDate)) {
      showToastError(getLabel('error'), getLabel('backend_error_periodicityto_is_not_present_or_future'));
      setIsLoading(false);
      return;
    }

    setIsLoadingEdit(true);

    if (!isDateInPast(data.eventDate)) {
      const request: IEventEdit = {
        uuid: eventData!.uuid,
        title: data.title,
        eventDate: data.eventDate,
        emailsGuest: selectedParticipants.map((participant) => participant.email),
        alarmMinutesBefore: meetingReminder,
        occasionType,
        hourRange: { from: beginTime, to: endTime },
        description: data.description ?? '',
        ...(isBookedTime) && { minutesBeforeToBlock: preTimeEvent, minutesAfterToBlock: postTimeEvent },
        ...(!isBookedTime) && { minutesBeforeToBlock: 0, minutesAfterToBlock: 0 },
        ...(occasionType !== OCCASIONTYPE.NONE) && { occasionMeetData: data.occasionMeetData },
        periodicity: periodicityType !== PERIODICITY.NEVER,
        ...(periodicityType !== PERIODICITY.NEVER) && { periodicityType },
        ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
      };

      const response = await eventEdit(request, jwt);
      if (response.error) {
        showToastError(getLabel('event_edition_error_title'), getLabel('event_edit_error'));
      } else {
        if (response?.response?.code && response.response.code === 'backend_error_guest_not_available') {
          setGuestsToNotInvite(response?.response?.guests || []);
          setModalEventCreateError(true);
        } else {
          await removeNotification(eventData!.uuid, TYPEOFNOTIFICATION.ALARM);
          await removeNotification(eventData!.uuid, TYPEOFNOTIFICATION.DEFAULT);
          await removeNotification(eventData!.uuid, TYPEOFNOTIFICATION.PRE);

          if (!isWeb) {
            await scheduleLocalNotification({
              title: data.title,
              body: data.description ?? '',
              date: data.eventDate,
              hour: getHourSubtracted(data.eventDate, beginTime, 0),
              uuid: response.response?.uuid!,
              type: TYPEOFNOTIFICATION.DEFAULT,
              periodicity: periodicityType !== PERIODICITY.NEVER,
              ...(periodicityType !== PERIODICITY.NEVER) && { typeOfPeriodicity: periodicityType },
              ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
            });
          }

          if (meetingReminder !== 0 && !isWeb) {
            await scheduleLocalNotification({
              title: data.title,
              body: data.description ?? '',
              date: data.eventDate,
              hour: getHourSubtracted(data.eventDate, beginTime, meetingReminder),
              uuid: response.response?.uuid!,
              type: TYPEOFNOTIFICATION.ALARM,
              periodicity: periodicityType !== PERIODICITY.NEVER,
              ...(periodicityType !== PERIODICITY.NEVER) && { typeOfPeriodicity: periodicityType },
              ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
            });
          }

          dispatch(eventResetState());
          dispatch(globalSetMustResetCalendar(true));
          showToastSuccess(getLabel('event_edited_title'), getLabel('event_edit_success'));
          navigation.goBack();
        }
      }
    } else {
      showToastError(getLabel('error'), getLabel('event_past_error_title'));
    }

    setIsLoadingEdit(false);
  };

  const confirmEventDeletion = async () => {
    setIsLoadingDelete(true);
    const response = await eventDelete(eventData!.uuid, jwt);

    if (response.error) {
      showToastError(getLabel('error'), getLabel('event_delete_error'));
    } else {
      await removeNotification(eventData!.uuid, TYPEOFNOTIFICATION.ALARM);
      await removeNotification(eventData!.uuid, TYPEOFNOTIFICATION.DEFAULT);
      await removeNotification(eventData!.uuid, TYPEOFNOTIFICATION.PRE);

      dispatch(eventResetState());
      dispatch(globalSetMustResetCalendar(true));
      showToastSuccess(getLabel('event_deleted_title'), getLabel('event_delete_success'));
      navigation.goBack();
    }
    setIsLoadingDelete(false);
  };

  const cancelEdit = () => {
    dispatch(eventResetState());
    setEditMode(false);
  };

  const openParticipantsModal = () => {
    setOpenModalShowParticipants(!openModalShowParticipants);
  };

  const onSetOccasionType = (value: string) => {
    setOccasionType(value);
  };

  const bookPrePostEvent = () => {
    setIsBookedTime(!isBookedTime);
  };

  const onSetPreTimeEvent = (value: number) => {
    setPreTimeEvent(value);
  };

  const onSetPostTimeEvent = (value: number) => {
    setPostTimeEvent(value);
  };

  const onSetReminder = (value: number) => {
    setMeetingReminder(value);
  };

  const showCalendarPeriodicity = () => {
    setDateVisiblePeriodicity(true);
  };

  const onAcceptInvite = async () => {
    setIsLoadingActionOfAcceptRejectChange(true);
    let response;

    if (isExternal) {
      const request: IAcceptRejectEventExternalRequest = {
        uuid: eventData!.uuid,
        alarmMinutesBefore: meetingReminder,
        minutesBeforeToBlock: preTimeEvent,
        minutesAfterToBlock: postTimeEvent,
        accept: true
      };

      response = await acceptOrRejectExternalInvite(request, jwt);
    } else {
      const request: IAcceptOrRejectEventInternalRequest = {
        scheduleUuid: eventData!.scheduleUuid,
        occasionUuid: eventData!.uuid,
        alarmMinutesBefore: meetingReminder,
        minutesBeforeToBlock: preTimeEvent,
        minutesAfterToBlock: postTimeEvent,
        accept: true
      };

      response = await acceptOrRejectInternalInvite(request, jwt);
    }

    if (response.response) {
      showToastSuccess(getLabel('success'), getLabel('event_accept_success'));
      successEventAcceptRejectChange();
    } else {
      showToastError(getLabel('error'), getLabel('event_accept_error'));
      failedEventAcceptRejectChange();
    }

    setIsLoadingActionOfAcceptRejectChange(false);
    setOpenModalAcceptInvite(false);
  };

  const onRejectInvite = async () => {
    setIsLoadingActionOfAcceptRejectChange(true);
    let response;

    if (isExternal) {
      const request: IAcceptRejectEventExternalRequest = {
        uuid: eventData!.uuid,
        accept: false
      };

      response = await acceptOrRejectExternalInvite(request, jwt);
    } else {
      const request: IAcceptOrRejectEventInternalRequest = {
        occasionUuid: eventData!.uuid,
        accept: false
      };

      response = await acceptOrRejectInternalInvite(request, jwt);
    }

    if (response.response) {
      showToastSuccess(getLabel('success'), getLabel('event_reject_success'));
      successEventAcceptRejectChange();
    } else {
      showToastError(getLabel('error'), getLabel('event_reject_error'));
      failedEventAcceptRejectChange();
    }

    setIsLoadingActionOfAcceptRejectChange(false);
    setOpenModalRejectInvite(false);
  };

  const modifyEventTimeDate = async () => {
    setIsLoadingActionOfAcceptRejectChange(true);
    const request: IAppointmentChangeInternal = {
      occasionUuid: eventData!.uuid,
      range: {
        from: startNewTime,
        to: endNewTime
      },
      date: newDate
    };

    const response = await appointmentInternalChange(request, jwt);

    if (response.response) {
      showToastSuccess(getLabel('success'), getLabel('event_modify_success'));
      successEventAcceptRejectChange();
    } else {
      showToastError(getLabel('error'), getLabel('event_reject_error'));
      failedEventAcceptRejectChange();
    }
    setIsLoadingActionOfAcceptRejectChange(false);
  };

  const renderInviteLogicButtons = () => {
    if (eventData!.status === STATUS.PENDING) {
      return (
        <View>
          <Text style={[styles.subTitle, styles.someMargin]}>{getLabel('event_manage_invite')}</Text>
          <View style={styles.bottomContainer}>
            <View style={styles.subBottomContainer}>
              <Button
                containerStyle={styles.buttonContainer}
                buttonStyle={styles.mainButton}
                titleStyle={styles.mainTextButton}
                onPress={() => setOpenModalAcceptInvite(true)}
                title={getLabel('notificationsDetail_button_accept')}
              />
            </View>
            <View style={styles.subBottomContainer}>
              <Button
                containerStyle={styles.buttonContainer}
                buttonStyle={[styles.mainButton, styles.deleteButton]}
                titleStyle={styles.mainTextButton}
                onPress={() => setOpenModalRejectInvite(true)}
                title={getLabel('notificationsDetail_button_reject')}
              />
            </View>
          </View>
        </View>
      );
    }
    return null;
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false} showsVerticalScrollIndicator={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        {isLoading ? (
          ViewEventLoader(windowDimensions.width)
        ) : (
          <>
            <View style={styles.titleContainer}>
              {editMode ? (
                <View>
                  <Controller
                    name="title"
                    rules={EVENT_TITLE_VALIDATION(
                      getLabel('rules_obligatory'),
                      getLabel('rules_max_length_500')
                    )}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <TextInput
                        style={[styles.mainTitle, styles.titleInput]}
                        onBlur={onBlur}
                        onChangeText={(text) => onChange(text)}
                        value={value}
                        placeholder={getLabel('notification_details_title')}
                        placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                        editable={editMode}
                      />
                    )}
                  />
                </View>
              ) : (
                <View>
                  <Text style={styles.mainTitle}>{eventData!.title}</Text>
                </View>
              )}
              <Text style={styles.errorText}>{errors?.title?.message}</Text>
            </View>
            <View style={styles.middleContainer}>
              <View style={styles.textContainer}>
                <Text style={styles.subTitle}>{getLabel('eventDetail_subTitle_1')}</Text>
                {(eventData!.owner && !editMode) ? (
                  <Button
                    onPress={allowEdit}
                    type="clear"
                    icon={(
                      <Icon
                        style={[styles.iconEdit]}
                        name="edit"
                      />
                    )}
                  />
                ) : null}
              </View>
            </View>
            <View style={styles.controllersContainer}>
              {((eventParams.realFrom || eventParams.realTo) && !editMode) ? (
                <View>
                  <Text style={styles.labelSubTitleText}>{getLabel('agendaOrganizer_modal_begin')}</Text>
                  <>
                    <Text style={styles.labelText}>{getLabel('date_name')}</Text>
                    <Text style={styles.input}>
                      {
                      eventParams.realFrom ? (
                        eventParams.realFrom.split(' ')[0]
                      ) : (
                        eventParams.date
                      )
                      }
                    </Text>

                    <Text style={styles.labelText}>{getLabel('hour_name')}</Text>
                    <Text style={styles.input}>
                      {
                      eventParams.realFrom ? (
                        eventParams.realFrom.split(' ')[1].substring(0, 5)
                      ) : (
                        eventParams.range.from
                      )
                      }
                    </Text>
                  </>

                  <Text style={[styles.labelSubTitleText, styles.someMargin]}>{getLabel('agendaOrganizer_modal_end')}</Text>
                  <View>
                    <Text style={styles.labelText}>{getLabel('date_name')}</Text>
                    <Text style={styles.input}>
                      {
                      eventParams.realTo ? (
                        eventParams.realTo.split(' ')[0]
                      ) : (
                        eventParams.date
                      )
                      }
                    </Text>

                    <Text style={styles.labelText}>{getLabel('hour_name')}</Text>
                    <Text style={styles.input}>
                      {
                      eventParams.realTo ? (
                        eventParams.realTo.split(' ')[1].substring(0, 5)
                      ) : (
                        eventParams.range.to
                      )
                      }
                    </Text>
                  </View>
                </View>
              ) : (
                <>
                  <Text style={styles.errorText}>{errors?.eventDate?.message}</Text>

                  <Text style={styles.labelText}>{getLabel('date_name')}</Text>
                  <Controller
                    name="eventDate"
                    rules={EVENT_DATE_VALIDATION(getLabel('rules_obligatory'))}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Pressable style={styles.pressableWidth} onPress={editMode ? showCalendar : undefined}>
                        <View pointerEvents="none">
                          <TextInput
                            style={[
                              styles.input,
                              errors?.eventDate?.message
                                ? styles.invalid : styles.valid]}
                            onChange={onChange}
                            value={value}
                            placeholder={getLabel('placeholder_select_date')}
                            placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                          />
                          <View>
                            {isWeb ? (
                              <WebPicker
                                setDate={onChange}
                                isVisible={dateVisible}
                                setIsVisible={setDateVisible}
                                limitDateYears={-100}
                                startDate={dayjs()}
                              />
                            ) : (
                              <MobilePicker
                                setDate={onChange}
                                isVisible={dateVisible}
                                setIsVisible={setDateVisible}
                                limitDateYears={0}
                                startDate={dayjs()}
                              />
                            )}
                          </View>
                        </View>
                      </Pressable>
                    )}
                  />

                  <Pressable style={isWeb ? styles.timeImput : styles.timeImputIOS} onPress={editMode ? openTimeModal : undefined}>
                    <Text style={styles.labelText}>{getLabel('event_hour_range_title')}</Text>
                    <View pointerEvents="none">
                      <TextInput
                        style={[styles.input]}
                        value={`${`${beginTime} ${getLabel('to_connector')} ${endTime}`}hs`}
                        placeholder={getLabel('placeholder_select_time')}
                        placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                      />
                    </View>
                  </Pressable>
                </>
              )}
            </View>

            <View>
              <View style={styles.middleContainer}>
                <Text style={styles.subTitle}>{getLabel('event_block_time')}</Text>
                <View style={isWeb ? [styles.input, styles.subInputAllowAllDay] : styles.allowNotificationsContainer}>
                  <Text style={styles.textAllowNotifications}>{getLabel('event_switch_pre_post')}</Text>
                  <Switch
                    disabled={!editMode}
                    trackColor={{ false: '#767577', true: '#31267C69' }}
                    thumbColor={isBookedTime ? '#305fab' : '#f4f3f4'}
                    ios_backgroundColor="#31267C69"
                    onValueChange={editMode ? bookPrePostEvent : () => {}}
                    value={isBookedTime}
                  />
                </View>
                {isBookedTime ? (
                  <View>
                    <View style={styles.someMargin}>
                      <Text style={styles.labelText}>{getLabel('event_pre')}</Text>
                      <NativePicker
                        data={getLabel('book_time_info_before')}
                        value={preTimeEvent}
                        setValue={onSetPreTimeEvent}
                        enabled={!editMode}
                      />
                    </View>
                    <View style={styles.someMargin}>
                      <Text style={styles.labelText}>{getLabel('event_post')}</Text>
                      <NativePicker
                        data={getLabel('book_time_info_after')}
                        value={postTimeEvent}
                        setValue={onSetPostTimeEvent}
                        enabled={!editMode}
                      />
                    </View>
                  </View>
                ) : null}
              </View>
              <View style={styles.someMargin}>
                <Text style={styles.labelText}>{getLabel('event_alarm')}</Text>
                <NativePicker
                  data={getLabel('meet_time_reminder')}
                  value={meetingReminder}
                  setValue={onSetReminder}
                  enabled={!editMode}
                />
              </View>
            </View>

            <View style={styles.someMargin}>
              <Text style={styles.labelText}>{getLabel('event_type_meet')}</Text>
              <NativePicker
                data={getLabel('occasion_type_info')}
                value={occasionType}
                setValue={onSetOccasionType}
                enabled={!editMode}
              />
            </View>

            {isWeb && <View style={styles.someMargin} />}

            {occasionType !== OCCASIONTYPE.NONE && (
              <View style={[styles.pressableWidth]}>
                <Text style={styles.errorText}>{errors?.occasionMeetData?.message}</Text>
                <Controller
                  name="occasionMeetData"
                  rules={{
                    maxLength: {
                      value: 500,
                      message: getLabel('rules_max_length_500')
                    }
                  }}
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => {
                    if (editMode) {
                      return (
                        <TextInput
                          style={[styles.input, errors?.occasionMeetData?.message ? styles.invalid : styles.valid]}
                          onBlur={onBlur}
                          onChangeText={(text) => onChange(text)}
                          value={value}
                          placeholder={getLabel('placeholder_type_meet')}
                          placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                          editable={editMode}
                        />
                      );
                    }
                    return (
                      <Text
                        style={[styles.input, errors?.occasionMeetData?.message ? styles.invalid : styles.valid]}
                        selectable
                      >
                        {value}
                      </Text>
                    );
                  }}
                />
              </View>
            )}

            {/* Invitados */}
            <View style={styles.middleContainer}>
              <Text style={styles.subTitle}>{getLabel('eventDetail_subTitle_2')}</Text>

              {editMode ? (
                <Pressable style={[styles.addPeopleContainer, selectedParticipants.length > 0 ? styles.addPeopleYesContainer : styles.addPeopleNoContainer]} onPress={addPeople}>
                  <Text numberOfLines={1} style={[styles.addPeopleText, selectedParticipants.length > 0 ? styles.addPeopleYesText : styles.addPeopleNoText]}>
                    {selectedParticipants.length > 0
                      ? selectedParticipants.map((participant) => `${participant.email}; `)
                      : getLabel('event_add_people')}
                  </Text>
                </Pressable>
              ) : (
                <Pressable disabled={!eventData?.guestsEmails} style={[styles.addPeopleContainer, styles.addPeopleYesContainer]} onPress={openParticipantsModal}>
                  <Text numberOfLines={1} style={[styles.addPeopleText, styles.addPeopleYesText]}>
                    {eventData?.guestsEmails && eventData?.guestsEmails.length > 0
                      ? eventData?.guestsEmails.map((participant) => `${participant.email}; `)
                      : getLabel('event_empty_people')}
                  </Text>
                </Pressable>
              )}
            </View>

            {/* Periodicidad */}
            <View style={styles.middleContainer}>
              <Text style={styles.subTitle}>{getLabel('periodicity_repeat_title')}</Text>
              <View style={styles.someMargin}>
                <NativePicker
                  data={getLabel('occasion_periodicity')}
                  value={periodicityType}
                  setValue={setPeriodicityType}
                  enabled={!editMode}
                />
              </View>
            </View>

            {
              periodicityType !== PERIODICITY.NEVER && (
                <View style={styles.someMargin}>
                  <Text style={styles.labelText}>{getLabel('periodicity_end_title')}</Text>
                  <NativePicker
                    data={getLabel('occasion_periodicity_end')}
                    value={periodicityEndType}
                    setValue={setPeriodicityEndType}
                    enabled={!editMode}
                  />
                </View>
              )
            }

            {
              periodicityType !== PERIODICITY.NEVER
              && periodicityEndType === PERIODICITY_END.IN_DATE && (
                <View>
                  <View style={styles.someMargin}>
                    <Pressable disabled={!editMode} style={{ width: '100%' }} onPress={showCalendarPeriodicity}>
                      <View pointerEvents="none">
                        <Text style={styles.labelText}>{getLabel('periodicity_date_title')}</Text>
                        <TextInput
                          style={styles.input}
                          value={periodicityEndDate}
                        />
                        <View>
                          {isWeb ? (
                            <WebPicker
                              setDate={setPeriodicityEndDate}
                              isVisible={dateVisiblePeriodicity}
                              setIsVisible={setDateVisiblePeriodicity}
                              limitDateYears={-100}
                              startDate={dayjs()}
                            />
                          ) : (
                            <MobilePicker
                              setDate={setPeriodicityEndDate}
                              isVisible={dateVisiblePeriodicity}
                              setIsVisible={setDateVisiblePeriodicity}
                              limitDateYears={0}
                              startDate={dayjs()}
                            />
                          )}
                        </View>
                      </View>
                    </Pressable>
                  </View>
                </View>
              )
            }

            <View style={styles.middleContainer}>
              <Text style={[styles.subTitle, isWeb ? { marginBottom: 10 } : {}]}>{getLabel('event_subTitle')}</Text>
              <Text style={styles.errorText}>{errors?.description?.message}</Text>
              <Controller
                name="description"
                rules={EVENT_DESCRIPTION_VALIDATION(
                  getLabel('rules_obligatory'),
                  getLabel('rules_max_length_500')
                )}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => {
                  if (editMode) {
                    return (
                      <TextInput
                        textAlignVertical="top"
                        multiline
                        numberOfLines={4}
                        style={[styles.input, styles.descriptionHeight, errors?.description?.message ? styles.invalid : styles.valid]}
                        onBlur={onBlur}
                        onChangeText={(text) => onChange(text)}
                        value={value}
                        placeholder={editMode ? getLabel('placeholder_add_description') : ''}
                        placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                        editable={editMode}
                      />
                    );
                  } return (
                    <Text
                      style={[styles.input, styles.descriptionHeight, errors?.description?.message ? styles.invalid : styles.valid]}
                      selectable
                    >
                      {value}
                    </Text>
                  );
                }}
              />
            </View>
            {/* Aceptar, rechazar o sugerir cambio */}
            {renderInviteLogicButtons()}
            {(eventData!.status === STATUS.ACCEPTED) && (
              <View>
                <Button
                  containerStyle={[styles.buttonContainer, { marginTop: 20 }]}
                  buttonStyle={[styles.addEventButton, styles.deleteButton]}
                  titleStyle={styles.addEventTextButton}
                  onPress={allowDelete}
                  title="Borrar evento"
                  disabled={editMode}
                />
              </View>
            )}
            {editMode ? (
              <View style={styles.bottomContainer}>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={styles.addEventButton}
                  titleStyle={styles.addEventTextButton}
                  onPress={handleSubmit((data) => confirmEventEdit(data))}
                  title={getLabel('confirm_edit')}
                  loading={isLoadingEdit}
                />
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={[styles.addEventButton, styles.cancelButton]}
                  titleStyle={[styles.addEventTextButton, styles.cancelButtonText]}
                  onPress={handleSubmit(cancelEdit)}
                  title={getLabel('cancel_edit')}
                />
              </View>
            ) : null}
          </>
        )}
      </View>

      <CustomModal isVisible={openModalAcceptInvite}>
        <View style={styles.modalAcceptRejectContainer}>
          <Text style={styles.modalAcceptRejectTitle}>{getLabel('event_accept_meeting')}</Text>

          <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_pre')}</Text>
          <NativePicker
            data={getLabel('book_time_info_before')}
            value={preTimeEvent}
            setValue={onSetPreTimeEvent}
          />

          <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_post')}</Text>
          <NativePicker
            data={getLabel('book_time_info_after')}
            value={postTimeEvent}
            setValue={onSetPostTimeEvent}
          />
          <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_alarm')}</Text>
          <NativePicker
            data={getLabel('meet_time_reminder')}
            value={meetingReminder}
            setValue={onSetReminder}
          />

          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={onAcceptInvite}
            title={getLabel('generic_accept_button_title')}
            loading={isLoadingActionOfAcceptRejectChange}
          />
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.addEventButton, styles.deleteButton]}
            titleStyle={styles.addEventTextButton}
            onPress={() => setOpenModalAcceptInvite(false)}
            title={getLabel('cancel')}
            disabled={isLoadingActionOfAcceptRejectChange}
          />
        </View>
      </CustomModal>

      <CustomModal isVisible={openModalRejectInvite}>
        <View style={styles.modalAcceptRejectContainer}>
          <Text style={styles.modalAcceptRejectTitle}>{getLabel('event_reject_meeting')}</Text>
          <Text style={styles.subTitleReject}>{getLabel('event_reject_meeting_subtitle')}</Text>
          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={onRejectInvite}
            title={getLabel('confirm')}
            loading={isLoadingActionOfAcceptRejectChange}
          />
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.addEventButton, styles.deleteButton]}
            titleStyle={styles.addEventTextButton}
            onPress={() => setOpenModalRejectInvite(false)}
            title={getLabel('cancel')}
            disabled={isLoadingActionOfAcceptRejectChange}
          />
        </View>
      </CustomModal>

      <CustomModal
        isVisible={openModalChangeInvite}
      >
        <View style={styles.modalChangeContainer}>
          <Text style={styles.modalTitle}>{getLabel('notification_details_change_time')}</Text>
          <View style={styles.divisorSpace}>
            <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_start_time')}</Text>
            <NativeDateTimePicker
              value={startNewTime}
              setValue={setStartNewTime}
              inputStyle={styles.pickerContainer}
              mode="time"
            />
          </View>
          <View style={styles.divisorSpace}>
            <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_end_time')}</Text>
            <NativeDateTimePicker
              value={endNewTime}
              setValue={setEndNewTime}
              inputStyle={styles.pickerContainer}
              mode="time"
            />
          </View>
          <View style={styles.divisorSpace}>
            <Text style={[styles.labelText, styles.someMargin]}>{getLabel('notification_details_date')}</Text>
            <NativeDateTimePicker
              value={newDate}
              setValue={setNewDate}
              inputStyle={styles.pickerContainer}
              mode="date"
            />
          </View>
          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={modifyEventTimeDate}
            title={getLabel('confirm')}
            loading={isLoadingActionOfAcceptRejectChange}
          />
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.addEventButton, styles.deleteButton]}
            titleStyle={styles.addEventTextButton}
            onPress={() => setOpenModalChangeInvite(false)}
            title={getLabel('cancel')}
            disabled={isLoadingActionOfAcceptRejectChange}
          />
        </View>
      </CustomModal>

      <CustomModal isVisible={openTrashCanModal}>
        <View style={styles.modalDeleteContainer}>
          <Text style={[styles.title, { textAlign: 'center', paddingBottom: 50 }]}>{getLabel('eventDetail_delete_title')}</Text>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={confirmEventDeletion}
            title={getLabel('eventDetail_delete_button_accept')}
            loading={isLoadingDelete}
          />
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.addEventButton, styles.cancelButton]}
            titleStyle={[styles.addEventTextButton, styles.cancelButtonText]}
            onPress={allowDelete}
            title={getLabel('eventDetail_delete_button_cancel')}
            disabled={isLoadingDelete}
          />
        </View>
      </CustomModal>

      <CustomModal isVisible={isModalSetTimeVisible}>
        <View style={styles.modalContainer}>
          <View style={styles.modalTitleContainer}>
            <Text style={styles.modalTitle}>{getLabel('agendaOrganizer_modal_title')}</Text>
            <Button
              onPress={closeTime}
              type="clear"
              icon={(
                <Icon
                  style={styles.modalIcon}
                  name="x-circle"
                />
              )}
            />
          </View>
          <View style={styles.pickerTimeContainer}>
            <View style={styles.divisorSpace}>
              <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_start_time')}</Text>
              <NativeDateTimePicker
                value={beginTime}
                setValue={onChangeBeginTime}
                inputStyle={styles.pickerContainer}
                mode="time"
              />
            </View>

            <View style={styles.divisorSpace}>
              <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_end_time')}</Text>
              <NativeDateTimePicker
                value={endTime}
                setValue={onChangeEndTime}
                inputStyle={styles.pickerContainer}
                mode="time"
              />
            </View>
          </View>
          <View>
            <Text style={styles.errorMessage}>{errorMessaje}</Text>
          </View>
          <View>
            <Button
              containerStyle={styles.modalButtonContainer}
              buttonStyle={styles.addEventButton}
              titleStyle={styles.addEventTextButton}
              onPress={addTime}
              title={getLabel('agendaOrganizer_modal_button')}
            />
          </View>
        </View>
      </CustomModal>

      <CustomModal isVisible={openModalShowParticipants}>
        <View style={styles.modalDeleteContainer}>
          <Text style={[styles.title, styles.modalParticipanTitle]}>{getLabel('event_participants')}</Text>
          <ScrollView style={styles.participantList} bounces={false} showsVerticalScrollIndicator={false}>
            {selectedParticipants.map((participant) => (
              <View key={participant.email} style={styles.modalInfoContainer}>
                <View style={styles.modalParticipantsContainer}>
                  <Text style={styles.modalParticipantEmail}>{participant.email}</Text>
                </View>
                <View style={[styles.viewCircle, functionStyles.getStatusColor(participant.status)]} />
              </View>
            ))}
          </ScrollView>
          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={openParticipantsModal}
            title={getLabel('generic_accept_button_title')}
          />
        </View>
      </CustomModal>

      <Modal
        isVisible={modalEventCreateError}
        backdropTransitionOutTiming={0}
        deviceHeight={windowDimensions.height}
        deviceWidth={windowDimensions.width}
        backdropOpacity={0}
        onBackdropPress={() => setModalEventCreateError(false)}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <View style={styles.eventCreateErrorModalContainer}>
          <Text style={styles.eventCreateErrorTitleText}>
            {getLabel('event_guests_error_create')}
          </Text>
          <Text style={styles.eventCreateErrorSubTitleText}>
            {getLabel('event_guests_error_title')}
          </Text>
          {guestsToNotInvite && guestsToNotInvite.map((guest) => (
            <Text style={styles.guestText} key={guest}>{`${guest}`}</Text>
          ))}
          <Text style={styles.eventCreateErrorSubTitleText}>
            {getLabel('event_guests_error_subtitle')}
          </Text>
          <Button
            containerStyle={styles.guestsButtonContainer}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={() => setModalEventCreateError(false)}
            title={getLabel('agendaOrganizer_modal_button')}
          />
        </View>
      </Modal>
    </KeyboardAwareScrollView>
  );
};
