import { Range } from '../../services/scheduleSettings/interface';

export interface IAgendaOrganizerState {
  [key: string]: Range[] | null;
}

export const INITIAL_STATE: IAgendaOrganizerState = {
  mon: null,
  tues: null,
  wed: null,
  thurs: null,
  fri: null,
  sat: null,
  sun: null
};
