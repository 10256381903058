import React, { useState } from 'react';
import { Picker } from '@react-native-picker/picker';
import { Text, View } from 'react-native';
import dayjs from 'dayjs';
import { Button } from 'react-native-elements';
import { useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/Feather';
import { getArrayRange } from '../../utils';
import styles from './styles';
import { CustomModal } from '../CustomModal';
import { selectLanguageKeys } from '../../redux/language';

interface Props {
  setDate: (date: string) => void;
  isVisible: boolean;
  setIsVisible: (visible: boolean) => void;
  limitDateYears: number;
  startDate?: dayjs.Dayjs;
}

export const WebPicker: React.FC<Props> = ({
  setDate, isVisible, setIsVisible, limitDateYears, startDate
}) => {
  const BASE_YEAR = 1901;
  const MIN_AGE = limitDateYears;

  const [day, setDay] = useState(startDate ? dayjs(startDate).date() : dayjs().date());
  const [currentDate, setCurrentDate] = useState(startDate ?? dayjs());
  const getLabel = useSelector(selectLanguageKeys);

  const onChangeYear = (year: number) => {
    const date = dayjs().month(currentDate.month()).year(year);
    setCurrentDate(date);
    setDate(`${date.format('YYYY-MM')}-${day < 10 ? `0${day}` : day}`);
  };

  const onChangeMonth = (month: number) => {
    const date = dayjs().month(month).year(currentDate.year());
    setCurrentDate(date);
    setDate(`${date.format('YYYY-MM')}-${day < 10 ? `0${day}` : day}`);
  };

  const onChangeDay = (_day: number) => {
    setDay(_day);
    setDate(`${currentDate.format('YYYY-MM')}-${day < 10 ? `0${day}` : day}`);
  };

  const onAccept = () => {
    const date = dayjs().month(currentDate.month()).year(currentDate.year());
    setDate(`${date.format('YYYY-MM')}-${day < 10 ? `0${day}` : day}`);
    setIsVisible(false);
  };

  return (
    <CustomModal
      isVisible={isVisible}
    >
      <View style={styles.modalContainer}>
        <View style={styles.modalTitleContainer}>
          <Text style={styles.title}>{getLabel('component_webPicker_title')}</Text>
          <View>
            <Button
              onPress={() => setIsVisible(false)}
              type="clear"
              icon={(
                <Icon
                  style={styles.modalIcon}
                  name="x-circle"
                />
            )}
            />
          </View>
        </View>
        <View style={styles.pickerContainer}>
          <View style={styles.subPickerContainer}>
            <Text style={styles.regularText}>{getLabel('component_webPicker_day')}</Text>
            <Picker
              selectedValue={day}
              onValueChange={onChangeDay}
              style={styles.picker}
            >
              {getArrayRange(currentDate.daysInMonth(), 1).map((_day) => (
                <Picker.Item key={_day} label={_day < 10 ? `0${_day.toString()}` : _day.toString()} value={_day} />
              ))}
            </Picker>
          </View>
          <View style={styles.subPickerContainer}>
            <Text style={styles.regularText}>{getLabel('component_webPicker_month')}</Text>
            <Picker
              selectedValue={currentDate.month()}
              onValueChange={onChangeMonth}
              style={styles.picker}
            >
              {getArrayRange(12).map((month) => (
                <Picker.Item key={month} label={(month + 1).toString()} value={month} />
              ))}
            </Picker>
          </View>
          <View style={styles.subPickerContainer}>
            <Text style={styles.regularText}>{getLabel('component_webPicker_year')}</Text>
            <Picker
              selectedValue={currentDate.year()}
              onValueChange={onChangeYear}
              style={styles.picker}
            >
              {getArrayRange(101, dayjs().year() - (100 + MIN_AGE)).map((year) => (
                <Picker.Item key={year} label={year.toString()} value={year} />
              ))}
            </Picker>
          </View>

        </View>
        <Button
          containerStyle={styles.buttonContainer}
          buttonStyle={styles.closeButton}
          titleStyle={styles.closeTextButton}
          title={getLabel('component_webPicker_button')}
          onPress={onAccept}
        />
      </View>
    </CustomModal>
  );
};
