import React, { useState } from 'react';
import {
  View,
  Text,
  TextInput,
  useWindowDimensions,
  Image
} from 'react-native';
import { Button } from 'react-native-elements';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import Modal from 'react-native-modal';
import Icon from 'react-native-vector-icons/Feather';
import {
  useForm,
  Controller
} from 'react-hook-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as ImagePicker from 'expo-image-picker';
import { useNavigation } from '@react-navigation/native';
import { USER_STORAGE_KEY } from '@env';
import {
  selectUserInfo,
  userSetData,
  userSetAvatar
} from '../../../redux/user';
import styles, {
  functionStyles
} from './styles';
import {
  showToastError,
  showToastSuccess
} from '../../../utils/toast';
import Routes from '../../../navigation/Routes';
import { Colors } from '../../../configs/enums';
import {
  NAME_VALIDATION
} from '../../../utils/rules';
import {
  getPhoto,
  uploadPhoto
} from '../../../services/photo';
import { userModifyUserData } from '../../../services/user';
import { IUserDataModify } from '../../../services/user/interfaces';
import { storeAsyncStorageData } from '../../../utils';
import { selectLanguageKeys } from '../../../redux/language';
import {
  globalSetProfilePhoto
} from '../../../redux/global';

interface profileAdditionals {
  firstName: string,
  lastName: string,
  jobTitle: string,
  company: string,
}

export default (): React.ReactElement => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const getLabel = useSelector(selectLanguageKeys);
  const userInfo = useSelector(selectUserInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [modalOpenUploadPhoto, setModalOpenUploadPhoto] = useState(false);
  const [image, setImage] = useState<string | null>(null);
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  const defaultValues: profileAdditionals = {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    jobTitle: '',
    company: ''
  };

  const {
    handleSubmit, control, reset, formState: { errors }
  } = useForm({
    defaultValues
  });

  const onRegister = async (data: profileAdditionals) => {
    setIsLoading(true);

    const request: IUserDataModify = {
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      birthdate: userInfo.birthdate,
      phone: null,
      language: userInfo.language,
      company: data.company,
      workPosition: data.jobTitle,
      workingHoursFrom: '09:00',
      workingHoursTo: '18:00'
    };

    const response = await userModifyUserData(request, userInfo.jwt);

    if (response.error) {
      showToastError(getLabel('error'), getLabel('additional_info_update_error'));
    } else {
      dispatch(userSetData({ ...userInfo, ...request }));
      await storeAsyncStorageData({ ...userInfo, ...request }, USER_STORAGE_KEY);
      showToastSuccess(getLabel('information_modified_title'), getLabel('additional_info_update_success'));
    }

    if (image) {
      const responseUuid = await uploadPhoto(image, userInfo.jwt);
      if (responseUuid) {
        showToastSuccess(getLabel('success'), getLabel('profile_photo_upload_success'));
        dispatch(userSetAvatar(responseUuid));
        await storeAsyncStorageData({ ...userInfo, avatar: responseUuid }, USER_STORAGE_KEY);
        const responseImage = await getPhoto(userInfo.jwt);

        if (responseImage) {
          dispatch(globalSetProfilePhoto(responseImage));
        }
      } else {
        showToastError(getLabel('error'), getLabel('profile_photo_upload_failed'));
      }
    }

    navigation.navigate(Routes.Payments);
    setIsLoading(false);
  };

  const doItLater = () => {
    navigation.navigate(Routes.Payments);
  };

  const toggleModalOpenPhoto = () => {
    setModalOpenUploadPhoto(!modalOpenUploadPhoto);
  };

  const pickTakePhotoImage = async () => {
    const permit = await ImagePicker.requestCameraPermissionsAsync();
    if (permit.granted) {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.5
      });

      if (!result.cancelled) {
        setImage(result.uri);
        setShowDeleteButton(true);
      }
    } else {
      showToastError(getLabel('profile_toast_error_Permissions_title'), getLabel('profile_toast_error_Permissions_text_camera'));
    }
  };

  const pickGalleryImage = async () => {
    const permit = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (permit.granted) {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.5
      });

      if (!result.cancelled) {
        setImage(result.uri);
        setShowDeleteButton(true);
      }
    } else {
      showToastError(getLabel('profile_toast_error_Permissions_title'), getLabel('profile_toast_error_Permissions_text_gallery'));
    }
  };

  const confirmPhoto = () => {
    setShowDeleteButton(false);
    setModalOpenUploadPhoto(false);
  };

  const deletePhoto = () => {
    setShowDeleteButton(false);
    setImage(null);
  };

  return (
    <View style={styles.container}>

      <KeyboardAwareScrollView bounces={false}>
        <View style={
          functionStyles.mainContainer(windowDimensions.width)
        }
        >
          <View style={styles.firstContainer}>
            <View style={styles.textContainer}>
              <Icon
                style={styles.icon}
                name="user"
              />
              <Text style={styles.title}>{getLabel('additional_info_title')}</Text>
            </View>
          </View>

          <View style={styles.controllersContainer}>
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={image ? [styles.registerButton, styles.changePhoto] : styles.registerButton}
              titleStyle={image ? [styles.registerTextButton, styles.doItLaterText] : styles.registerTextButton}
              onPress={toggleModalOpenPhoto}
              title={image ? getLabel('photo_upload_title_change') : getLabel('photo_upload_title')}
              disabled={isLoading}
            />
            <Text style={styles.errorText}>{errors?.firstName?.message}</Text>
            <Controller
              name="firstName"
              rules={NAME_VALIDATION(
                getLabel('rules_obligatory'),
                getLabel('rules_min_length_2'),
                getLabel('rules_max_length_31')
              )}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.firstName?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_firstName')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
              )}
            />
            <Text style={styles.errorText}>{errors?.lastName?.message}</Text>
            <Controller
              name="lastName"
              rules={NAME_VALIDATION(
                getLabel('rules_obligatory'),
                getLabel('rules_min_length_2'),
                getLabel('rules_max_length_31')
              )}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.lastName?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_lastName')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
              )}
            />
            <Text style={styles.errorText}>{errors?.jobTitle?.message}</Text>
            <Controller
              name="jobTitle"
              rules={{ required: false }}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.jobTitle?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('additional_info_actual_job')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
              )}
            />
            <Text style={styles.errorText}>{errors?.company?.message}</Text>
            <Controller
              name="company"
              rules={{ required: false }}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.company?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('additional_info_actual_company')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
              )}
            />
            {/* <Text style={styles.errorText}>{errors?.phone?.message}</Text>
            <Controller
              name="phone"
              control={control}
              rules={PHONE_VALIDATION(
                getLabel('rules_obligatory'),
                getLabel('rules_min_length_8'),
                getLabel('rules_max_length_31'),
                getLabel('rules_phone_regex')
              )}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.phone?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_phone')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
              )}
            /> */}
          </View>
          <View style={styles.bottomContainer}>
            <Button
              containerStyle={[styles.buttonContainer, styles.continueButton]}
              buttonStyle={styles.registerButton}
              titleStyle={styles.registerTextButton}
              onPress={handleSubmit((data) => onRegister(data))}
              title={getLabel('generic_continue_button_title')}
              loading={isLoading}
            />
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={[styles.registerButton, styles.doItLaterButton]}
              titleStyle={[styles.registerTextButton, styles.doItLaterText]}
              onPress={doItLater}
              title={getLabel('generic_continue_later_button_title')}
              disabled={isLoading}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
      <Modal
        isVisible={modalOpenUploadPhoto}
        backdropTransitionOutTiming={0}
        deviceHeight={windowDimensions.height}
        deviceWidth={windowDimensions.width}
        backdropOpacity={0.7}
        backdropColor="white"
        onBackdropPress={toggleModalOpenPhoto}
        animationIn="slideInUp"
        animationOut="slideOutDown"
      >
        <View style={styles.modalContainer}>
          <View>
            <Text style={styles.modalTitle}>{getLabel('photo_upload_title')}</Text>
            <Text style={styles.modalSubtext}>{getLabel('photo_max_size')}</Text>
          </View>
          <View>
            <Image source={image ? { uri: image } : require('../../../../assets/Images/Guest-Picture.png')} style={styles.avatarImage} />
          </View>
          <View>
            <Text style={styles.modalSubtext}>{getLabel('additional_info_extra')}</Text>
            <View style={styles.buttonRowContainer}>
              <Button
                containerStyle={styles.buttonFromRow}
                buttonStyle={styles.registerButton}
                titleStyle={styles.registerTextButton}
                onPress={pickTakePhotoImage}
                title={getLabel('photo_camera_title')}
                icon={(
                  <Icon
                    style={styles.modalIcon}
                    name="camera"
                  />
                )}
              />
              <View style={{ flex: 1 }} />
              <Button
                containerStyle={styles.buttonFromRow}
                buttonStyle={styles.registerButton}
                titleStyle={styles.registerTextButton}
                onPress={pickGalleryImage}
                title={getLabel('photo_gallery_title')}
                icon={(
                  <Icon
                    style={styles.modalIcon}
                    name="image"
                  />
                )}
              />
            </View>
          </View>
          <View>
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.registerButton}
              titleStyle={styles.registerTextButton}
              onPress={confirmPhoto}
              title={getLabel('generic_accept_button_title')}
            />
            {image ? (
              <Button
                containerStyle={[styles.buttonContainer, { marginTop: 10 }]}
                buttonStyle={[styles.registerButton, styles.deleteButton]}
                titleStyle={styles.registerTextButton}
                onPress={deletePhoto}
                title={getLabel('photo_delete')}
              />
            ) : null }
          </View>
        </View>
      </Modal>
    </View>
  );
};
