import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppointments } from 'src/services/events/interface';
import { INITIAL_STATE } from './state';
import type { RootState } from '../rootReducer';

const eventSlice = createSlice({
  name: 'event',
  initialState: INITIAL_STATE,
  reducers: {
    eventSetParticipants: (state, action: PayloadAction<IAppointments[]>) => {
      state.emailsGuest = action.payload;
    },
    eventResetState: () => INITIAL_STATE
  }
});

export const {
  eventSetParticipants,
  eventResetState
} = eventSlice.actions;

export default eventSlice.reducer;

export const selectEventParticipants = (state: RootState) => state.event.emailsGuest;
