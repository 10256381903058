import { combineReducers } from '@reduxjs/toolkit';
import user from './user/index';
import agendaOrganizer from './agendaOrganizer/index';
import event from './event/index';
import schedules from './schedules/index';
import global from './global/index';
import language from './language/index';
import billing from './billing/index';

const rootReducer = combineReducers({
  user,
  agendaOrganizer,
  event,
  schedules,
  global,
  language,
  billing
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
