import { StyleSheet } from 'react-native';
import { Colors, Fonts } from '../../configs/enums';

const HeaderStyles = StyleSheet.create({
  icon: {
    fontSize: 25,
    alignSelf: 'center'
  }
});

export default HeaderStyles;
