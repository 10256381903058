import { Picker } from '@react-native-picker/picker';
import React, { useState } from 'react';
import {
  Pressable, Text, useWindowDimensions, View
} from 'react-native';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Feather';
import Modal from 'react-native-modal';
import { useSelector } from 'react-redux';
import { isIos, isWeb } from '../../utils';
import { NativePickerStyles as styles } from './styles';
import { selectLanguageKeys } from '../../redux/language';

interface Props {
  data: {[key: string | number]: string},
  value: string | number;
  setValue: any;
  enabled?: boolean;
}

export default ({
  data, value, setValue, enabled
}: Props): React.ReactElement => {
  const [modalVisible, setModalVisible] = useState(false);
  const getLabel = useSelector(selectLanguageKeys);
  const windowDimensions = useWindowDimensions();

  return (
    <View>
      {isWeb ? (
        <Picker
          style={styles.input}
          selectedValue={value}
          onValueChange={(val) => setValue(val)}
          enabled={!enabled}
        >
          {Object.keys(data).map((element) => (
            <Picker.Item key={element} label={data[element]} value={element} />
          ))}
        </Picker>
      ) : (
        <View style={!isIos ? styles.androidPickercontainer : styles.mobilePickercontainer}>
          {isIos ? (
            <Pressable disabled={enabled} style={styles.iosPickerContainer} onPress={() => setModalVisible(!modalVisible)}>
              <Text style={styles.mobileTextPickerIOS}>{data[value]}</Text>
              <Icon
                style={styles.dropdownIcon}
                name="chevron-down"
              />
            </Pressable>
          ) : (
            <Picker
              selectedValue={value.toString()}
              onValueChange={(val) => setValue(val)}
              enabled={!enabled}
            >
              {Object.keys(data).map((element) => (
                <Picker.Item key={element} label={data[element]} value={element} />
              ))}
            </Picker>
          )}
        </View>
      )}
      <Modal
        isVisible={modalVisible}
        backdropTransitionOutTiming={0}
        deviceHeight={windowDimensions.height}
        deviceWidth={windowDimensions.width}
        backdropOpacity={0}
        onBackdropPress={() => setModalVisible(!modalVisible)}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <View style={styles.modalContainer}>
          <Picker
            style={styles.picker}
            selectedValue={value}
            onValueChange={(val) => setValue(val)}
          >
            {Object.keys(data).map((element) => (
              <Picker.Item key={element} label={data[element]} value={element} />
            ))}
          </Picker>
          <Button
            containerStyle={styles.buttonReminderContainer}
            buttonStyle={styles.closeReminderButton}
            titleStyle={styles.closeReminderTextButton}
            onPress={() => setModalVisible(!modalVisible)}
            title={getLabel('component_mobilePicker_button')}
          />
        </View>
      </Modal>
    </View>
  );
};
