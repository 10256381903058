import {
  StyleSheet
} from 'react-native';
import { Colors, Fonts } from '../../configs/enums';

export const AgendaDayStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    paddingHorizontal: 10,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    marginBottom: 15,
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  textContainer: {
    flexDirection: 'row'
  },
  name: {
    fontSize: 15
  },
  range: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    color: Colors.PLACEHOLDER_TEXT_COLOR,
    marginLeft: 20
  },
  trashIcon: {
    fontSize: 20
  },
  emptyFillView: {
    height: 36.3,
    width: 36
  }
});
