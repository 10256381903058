export enum BillingStatus {
  ACTIVE = 'ACTIVE',
  PAST_DUE = 'PAST_DUE',
  UNPAID = 'UNPAID',
  CANCELED = 'CANCELED',
  INCOMPLETE = 'INCOMPLETE',
  INCOMPLETE_EXPIRED = 'INCOMPLETE_EXPIRED',
  TRIALING = 'TRIALING',
  PAUSED = 'PAUSED'
}

export interface BillingState {
  billingInfo: {
    roles: string[];
    platform: 'android' | 'ios' | 'web';
    subscriptionStatus: 'ACTIVE' | 'INACTIVE' | 'BLOCKED';
    problem: boolean;
    problemDetails: string;
  } | null;
}

export const INITIAL_STATE: BillingState = {
  billingInfo: null
};
