import React, { useState, useEffect } from 'react';
import {
  View, Text, useWindowDimensions, TouchableOpacity
} from 'react-native';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Feather';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
  CodeField,
  Cursor,
  useBlurOnFulfill,
  useClearByFocusCell
} from 'react-native-confirmation-code-field';
import { useDispatch, useSelector } from 'react-redux';
import { USER_STORAGE_KEY } from '@env';
import { useNavigation } from '@react-navigation/native';
import { selectUserInfo, userModifyFirstLogin } from '../../redux/user';
import styles, { functionStyles } from './style';
import { showToastError, showToastSuccess } from '../../utils/toast';
import { resendVerificationAccountCode, verifyAccount } from '../../services/auth';
import { CELL_COUNT, RESET_TIMER } from './constants';
import { selectLanguageKeys } from '../../redux/language';
import { userMarkAsNotFirstLogin } from '../../services/user';
import { storeAsyncStorageData } from '../../utils';

export default (): React.ReactElement => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const [value, setValue] = useState('');
  const refCodeField = useBlurOnFulfill({ value, cellCount: CELL_COUNT });
  const [props, getCellOnLayoutHandler] = useClearByFocusCell({
    value,
    setValue
  });
  const [isLoading, setIsLoading] = useState(false);
  const [timer, setTimer] = useState(RESET_TIMER);
  const [canResend, setCanResend] = useState(false);
  const getLabel = useSelector(selectLanguageKeys);

  const userData = useSelector(selectUserInfo);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResend(true);
    }
  }, [timer]);

  const onVerifyOTP = async () => {
    setIsLoading(true);

    const response = await verifyAccount(value, userData.jwt);
    if (response.error) {
      showToastError(getLabel('error'), getLabel(response.errorMessage!));
      setIsLoading(false);
    } else {
      await userMarkAsNotFirstLogin(userData.jwt);
      await storeAsyncStorageData({ ...userData, firstLogin: false, verified: true }, USER_STORAGE_KEY);
      dispatch(userModifyFirstLogin(false));
      setIsLoading(false);

      navigation.reset({
        index: 0,
        routes: [{
          key: 'Main-1',
          name: 'Root',
          params: {
            screen: 'Main',
            params: {
              screen: 'HomeStack'
            }
          }
        }]
      });
    }
  };

  const onResendCode = async () => {
    setCanResend(false);
    setTimer(RESET_TIMER);
    const response = await resendVerificationAccountCode(userData.jwt);
    if (response.error) {
      showToastError(getLabel('error'), getLabel(response.errorMessage!));
    } else {
      showToastSuccess(getLabel('code_toast_code_verification'), getLabel('code_toast_code_verification_message'));
    }
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View style={styles.middleContainer}>
          <View style={styles.textContainer}>
            <Icon
              style={styles.icon}
              name="inbox"
            />
            <Text style={styles.title}>{getLabel('verify-account')}</Text>
            <Text style={styles.text}>
              {getLabel('code_subTitle')}
            </Text>
          </View>
        </View>
        <View style={styles.controllersContainer}>
          <CodeField
            ref={refCodeField}
            {...props}
            value={value}
            onChangeText={setValue}
            cellCount={CELL_COUNT}
            rootStyle={styles.codeFieldRoot}
            keyboardType="number-pad"
            textContentType="oneTimeCode"
            renderCell={({ index, symbol, isFocused }) => (
              <Text
                key={index}
                style={[styles.cell, isFocused && styles.focusCell]}
                onLayout={getCellOnLayoutHandler(index)}
              >
                {symbol || (isFocused ? <Cursor /> : null)}
              </Text>
            )}
          />
          <TouchableOpacity disabled={!canResend} onPress={onResendCode}>
            <Text style={styles.resendCode}>
              {!canResend
                ? getLabel('code_resend_code_begin') + timer + getLabel('code_resend_code_end')
                : getLabel('code_resend')}
            </Text>
          </TouchableOpacity>
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.verifyButton}
            titleStyle={styles.verifyTextButton}
            onPress={onVerifyOTP}
            title={getLabel('code_verify_button')}
            loading={isLoading}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
