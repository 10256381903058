import { StyleSheet } from 'react-native';
import { Colors, Fonts } from '../../configs/enums';

const DrawerStyles = StyleSheet.create({
  drawer: {
    flex: 1
  },
  userContainer: {
    paddingVertical: 40,
    borderBottomWidth: 1,
    borderBottomColor: Colors.DRAWER_SEPARATION_COLOR
  },
  screensContainer: {
    flex: 1
  },
  bottomContainer: {
    padding: 20,
    borderTopWidth: 1,
    borderTopColor: Colors.DRAWER_SEPARATION_COLOR
  },
  bottomItemsContainer: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  avatarImage: {
    height: 100,
    width: 100,
    borderRadius: 50,
    marginBottom: 10,
    borderColor: Colors.PRIMARY_BUTTON_COLOR,
    borderWidth: 1,
    alignSelf: 'center'
  },
  textGreeting: {
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  icon: {
    fontSize: 18
  },
  closeSession: {
    fontSize: 15,
    marginLeft: 5,
    color: Colors.PRIMARY_BUTTON_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  }
});

export default DrawerStyles;
