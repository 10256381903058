import React, { memo } from 'react';
import { Pressable, Text, View } from 'react-native';
import { HoursCalendar } from '../../interfaces';
import { functionStyles, HourStyles as styles } from './styles';

interface Props {
  item: HoursCalendar;
  dayMode: boolean;
  onNavigateToEvent: any;
}

const Hour = ({ item, dayMode, onNavigateToEvent }: Props): React.ReactElement => (
  <Pressable style={styles.mainContainer} onPress={onNavigateToEvent}>
    <View style={functionStyles.container(dayMode)}>
      <View style={functionStyles.hourContainer(dayMode)}>
        <Text style={functionStyles.hour(dayMode)}>{item.hourStartsStr}</Text>
      </View>
    </View>
  </Pressable>
);

export default memo(Hour);
