import React from 'react';
import { Text, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { useSelector } from 'react-redux';
import { selectLanguageKeys } from '../../redux/language';
import { CheckoutFinishedStyles as styles } from './styles';

interface Props {
  route: { params: { result: string } };
}

export default ({ route }: Props): React.ReactElement => {
  const getLabel = useSelector(selectLanguageKeys);

  const showSuccessOrFailed = () => {
    const { result } = route.params;

    if (result === 'failed') {
      return (
        <>
          <View style={styles.subContainer}>
            <Icon
              style={styles.iconFailed}
              name="x"
            />
          </View>
          <View style={styles.subContainer}>
            <Text style={styles.text}>{getLabel('checkout_failed')}</Text>
          </View>
        </>
      );
    }
    if (result === 'success') {
      return (
        <>
          <View style={styles.subContainer}>
            <Icon
              style={styles.iconSuccess}
              name="check"
            />
          </View>
          <View style={styles.subContainer}>
            <Text style={styles.text}>{getLabel('checkout_success')}</Text>
          </View>
        </>
      );
    }
    return (
      <>
        <View style={styles.subContainer}>
          <Icon
            style={styles.iconFailed}
            name="x"
          />
        </View>
        <View style={styles.subContainer}>
          <Text style={styles.text}>{getLabel('checkout_invalid')}</Text>
        </View>
      </>
    );
  };

  return (
    <View style={styles.container}>
      {showSuccessOrFailed()}
    </View>
  );
};
