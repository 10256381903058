import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const ScheduleStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  backgroundPic: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  firstContainer: {
    paddingTop: 10
  },
  textContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row'
  },
  icon: {
    fontSize: 20,
    alignSelf: 'center'
  },
  text: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    flex: 1
  },
  secondContainer: {
    paddingTop: 30
  },
  calendarSeparator: {
    marginBottom: 20
  },
  calendarContainer: {
    flexDirection: 'row',
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    alignItems: 'center',
    height: 50
  },
  calendarTextContainer: {
    flexDirection: 'row',
    flex: 1
  },
  calendarName: {
    fontSize: 15,
    marginLeft: 10,
    flex: 1
  },
  settingsIcon: {
    fontSize: 16,
    color: Colors.PRIMARY_BUTTON_COLOR
  },
  settingsIconEnable: {
    color: Colors.BACKGROUND_COLOR
  },
  buttonContainer: {
    width: '100%',
    marginBottom: 15
  },
  mainButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    width: '100%',
    height: 50
  },
  deleteButton: {
    backgroundColor: Colors.DELETE_BUTTON_COLOR
  },
  editbutton: {
    backgroundColor: Colors.EDIT_BUTTON_COLOR
  },
  cancelButton: {
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderColor: Colors.SECONDARY_BORDER_COLOR,
    borderWidth: 1
  },
  mainTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  cancelTextButton: {
    color: Colors.SECONDARY_TEXT_COLOR
  },
  modalDeleteContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 40,
    paddingHorizontal: 50,
    marginHorizontal: 20
  },
  title: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    alignSelf: 'center'
  },
  modalTitle: {
    textAlign: 'center',
    paddingBottom: 50
  },
  modalTitleWithInput: {
    textAlign: 'center',
    paddingBottom: 10
  },
  actionButtonView: {
    position: 'absolute',
    bottom: 20,
    right: 20
  },
  actionButton: {
    width: 80,
    height: 80,
    borderRadius: 50,
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignSelf: 'flex-end'
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    marginBottom: 40
  },
  errorText: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 10,
    alignSelf: 'flex-start',
    marginTop: 8,
    marginBottom: 2
  },
  toolTipText: {
    fontSize: 12,
    fontFamily: Fonts.MANROPE_LIGTH
  },
  toolTipSettingsIcon: {
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderRadius: 5,
    marginLeft: 10
  },
  toolTipContainer: {
    borderRadius: 10
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20,
    backgroundColor: Colors.BACKGROUND_COLOR
  })
};

export default ScheduleStyles;
