import {
  StyleSheet
} from 'react-native';
import { Colors, Fonts } from '../../configs/enums';

export const SingleNotificationStyles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    padding: 10,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    marginBottom: 20,
    alignItems: 'center',
    flex: 1
  },
  infoContainer: {
    flex: 1,
    paddingHorizontal: 5
  },
  title: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  subInfoContainer: {
    flexDirection: 'column'
  },
  eachInfoContainer: {
    flexDirection: 'row',
    paddingRight: 5
  },
  icon: {
    fontSize: 16,
    alignSelf: 'center',
    paddingRight: 2
  },
  information: {
    fontSize: 12,
    fontFamily: Fonts.MANROPE_LIGTH
  },
  buttonContainer: {},
  subButtonContainer: {},
  detailButton: {
    backgroundColor: Colors.TERTIARY_BUTTON_COLOR,
    borderRadius: 10,
    padding: 20
  },
  detailTextButton: {
    fontSize: 10,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.TERTIARY_TEXT_COLOR
  }
});

export default SingleNotificationStyles;
