import React, {
  useEffect,
  useState
} from 'react';
import {
  Linking,
  Text,
  View,
  useWindowDimensions
} from 'react-native';
import Modal from 'react-native-modal';
import { Button } from 'react-native-elements';
import * as Notifications from 'expo-notifications';
import Icon from 'react-native-vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import axios from 'axios';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import {
  globalSetStartupLinkProcessed,
  selectStartupLinkProcessed
} from '../../redux/global';
import { showToastError } from '../../utils/toast';
import { DAY_ITEMS_QTY } from '../../components/Calendar/configs';
import {
  getDaysForCalendar,
  isMobile,
  getAsyncStorageData,
  storeAsyncStorageData
} from '../../utils';
import styles, {
  functionStyles
} from './styles';
import Calendar from '../../components/Calendar';
import { FloatingButton } from '../../components/FloatingButton';
import Routes from '../../navigation/Routes';
import {
  Colors,
  Fonts
} from '../../configs/enums';
import { selectLanguageKeys } from '../../redux/language';

export default (): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const getLabel = useSelector(selectLanguageKeys);

  const startupLinkProcessed = useSelector(selectStartupLinkProcessed);

  const [dayMode, setDayMode] = useState(true);

  const [firstTimeTutorial, setFirstTimeTutorial] = useState(false);

  const [selectedDate, setSelectedDate] = useState(getDaysForCalendar(dayjs())[DAY_ITEMS_QTY / 2]);

  const [notificationsModalVisible, setNotificationsModalVisible] = useState(false);

  const addEvent = () => {
    if (firstTimeTutorial) {
      setFirstTimeTutorial(false);
    } else {
      navigation.navigate(Routes.Event, {
        startTime: undefined,
        startTimeNumber: undefined,
        dayMode,
        date: selectedDate.date.format('YYYY-MM-DD')
      });
    }
  };

  async function checkNotificationPermission() {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    const modalTimesOpened = await getAsyncStorageData('@notifications_modal_opened');
    if (!modalTimesOpened) {
      if (existingStatus !== 'granted') {
        setNotificationsModalVisible(true);
        await storeAsyncStorageData(true, '@notifications_modal_opened');
      }
    }
  }

  function openNotifications() {
    setNotificationsModalVisible(false);
    Linking.openSettings();
  }

  async function handleIncomingLink(event: any) {
    const path = event.url;
    if (!path.includes('www.2meetup.app')) {
      try {
        navigation.navigate('EventFromIcs', { path });
      } catch (err) {
        showToastError('Error ', 'Cannot access to ics processing');
        console.info('Err !path.includes(\'www.2meetup.app\') ', err);
      }
    } else if (path.includes('www.2meetup.app/Schedule')) {
      try {
        const response = await axios.get(path);
        const redirectUrl = response.request.responseURL;
        await Linking.openURL(redirectUrl);
      } catch (err) {
        console.info('Err path.includes(\'www.2meetup.app/Schedule\') ', err);
      }
    }
  }

  async function processStartupLink() {
    const initialUrl = await Linking.getInitialURL();
    if (initialUrl) {
      dispatch(globalSetStartupLinkProcessed(true));
      await handleIncomingLink({ url: initialUrl });
    }
  }

  useEffect(() => {
    if (!startupLinkProcessed && isMobile) processStartupLink();
  }, []);

  useEffect(() => {
    if (isMobile) checkNotificationPermission();
  }, []);

  return (
    <View style={styles.container}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <>
          <Calendar
            dayMode={dayMode}
            setDayMode={setDayMode}
            homeMode
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
          />
          <View style={styles.actionButtonView}>
            <FloatingButton
              buttonStyle={styles.actionButton}
              icon="add"
              iconSize={40}
              iconColor="white"
              onPressFunction={addEvent}
            />
          </View>
        </>
      </View>
      <Modal
        backdropTransitionOutTiming={0}
        isVisible={notificationsModalVisible}
      >
        <View
          style={{
            backgroundColor: 'white',
            paddingHorizontal: 20,
            paddingVertical: 40,
            borderRadius: 10
          }}
        >
          <View style={{
            alignSelf: 'center',
            marginBottom: 20
          }}
          >
            <Icon
              style={{ fontSize: 50 }}
              name="bell"
              color={Colors.PRIMARY_BUTTON_COLOR}
            />
          </View>
          <Text
            style={{
              fontFamily: Fonts.MANROPE_REGULAR,
              fontSize: 18,
              textAlign: 'center',
              marginBottom: 20
            }}
          >
            {getLabel('activate_push_notifications')}
          </Text>
          <Button
            title={getLabel('open_settings_for_push')}
            onPress={() => openNotifications()}
            buttonStyle={{
              backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 10,
              height: 50,
              marginBottom: 10
            }}
          />
          <Button
            title={getLabel('activate_later')}
            onPress={() => setNotificationsModalVisible(false)}
            buttonStyle={{
              backgroundColor: 'white',
              alignItems: 'center',
              justifyContent: 'center',
              borderColor: Colors.PRIMARY_BUTTON_COLOR,
              borderWidth: 2,
              borderRadius: 10,
              height: 50
            }}
            titleStyle={{
              color: Colors.PRIMARY_BUTTON_COLOR
            }}
          />
        </View>
      </Modal>
    </View>
  );
};
