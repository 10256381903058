/* eslint-disable global-require */
import React from 'react';
import { Provider } from 'react-redux';
import {
  LogBox,
  SafeAreaView, StatusBar, StyleSheet
} from 'react-native';
import Toast from 'react-native-toast-message';
import store from './src/redux/store';
import AppNavigator from './src/navigation/AppNavigator';
import { isIos } from './src/utils/index';

LogBox.ignoreLogs(['expo-app-loading', "export 'createElement'"]);

const styles = StyleSheet.create({
  safeContainer: {
    flex: 1,
    paddingTop: isIos ? 0 : StatusBar.currentHeight
  }
});

export default function App() {
  return (
    <Provider store={store}>
      <SafeAreaView style={styles.safeContainer}>
        <AppNavigator />
        <Toast />
      </SafeAreaView>
    </Provider>
  );
}
