import React, { useState } from 'react';
import {
  View, Text, useWindowDimensions, TextInput
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Button } from 'react-native-elements';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form';
import { USER_STORAGE_KEY } from '@env';
import Icon from 'react-native-vector-icons/Feather';
import { selectUserInfo, userSetModifyEmail } from '../../../../redux/user';
import styles, { functionStyles } from './styles';
import { showToastError, showToastSuccess } from '../../../../utils/toast';
import { MAIL_VALIDATION } from '../../../../utils/rules';
import { userModifyUserEmail } from '../../../../services/user';
import { authValidateEmail } from '../../../../services/auth';
import { storeAsyncStorageData, getAsyncStorageData } from '../../../../utils';
import { Colors } from '../../../../configs/enums';
import { selectLanguageKeys } from '../../../../redux/language';

export default (): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const getLabel = useSelector(selectLanguageKeys);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);

  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit, control, formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    },
    shouldFocusError: true
  });

  const onChangeMail = async (data: { email: string}) => {
    setIsLoading(true);
    data.email = data.email.toLowerCase();
    const isValidateEmail = await authValidateEmail(data.email);
    if (isValidateEmail.error) {
      showToastError(getLabel('profile_toast_error_validation_title'), isValidateEmail.errorMessage);
    } else {
      const response = await userModifyUserEmail(data, userInfo.jwt);
      if (response.error) {
        showToastError(getLabel('changeEmail_toast_error_mail_title'), getLabel(response.errorMessage!));
      } else {
        const storagedData: any = await getAsyncStorageData(USER_STORAGE_KEY);
        await storeAsyncStorageData({ ...storagedData, email: data.email }, USER_STORAGE_KEY);
        dispatch(userSetModifyEmail(data.email));
        showToastSuccess(getLabel('changeEmail_toast_success_title'), getLabel('changeEmail_toast_success_text'));
        navigation.goBack();
      }
    }
    setIsLoading(false);
  };

  return (
    <KeyboardAwareScrollView style={styles.container}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View style={styles.warningContainer}>
          <Text style={styles.warningText}>
            {getLabel('email_recovery_warning')}
          </Text>
        </View>
        <View style={styles.titleContainer}>
          <Text style={styles.text}>{getLabel('profile_button_changeEmail')}</Text>
        </View>
        <View style={styles.controllersContainer}>
          <Text style={styles.errorText}>{errors?.email?.message}</Text>
          <Controller
            name="email"
            control={control}
            rules={MAIL_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_6'),
              getLabel('rules_max_length_31'),
              getLabel('rules_mail_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[styles.input, errors?.email?.message ? styles.invalid : styles.valid]}
                blurOnSubmit
                onBlur={onBlur}
                onChangeText={(inputValue) => onChange(inputValue)}
                value={value}
                placeholder={getLabel('placeholder_email')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                autoCorrect={false}
              />
            )}
          />
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.acceptButton}
            titleStyle={styles.acceptTextButton}
            onPress={handleSubmit(onChangeMail)}
            title={getLabel('profile_button_accept')}
            loading={isLoading}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
