import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from 'src/services/auth/interfaces';
import { IUserDataModify } from '../../services/user/interfaces';
import type { RootState } from '../rootReducer';
import { INITIAL_STATE } from './state';

const userSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {
    userSetData: (state, action: PayloadAction<UserData>) => {
      state.userData = action.payload;
    },
    userSetJwt: (state, action: PayloadAction<string>) => {
      state.userData.jwt = action.payload;
    },
    userSetNotifications: (state, action: PayloadAction<boolean>) => {
      state.userData.allowNotifications = action.payload;
    },
    userSetLogged: (state, action: PayloadAction<boolean>) => {
      state.logged = action.payload;
    },
    userSetLanguage: (state, action: PayloadAction<string>) => {
      state.userData.language = action.payload;
    },
    userSetModifyPersonalInformation: (state, action: PayloadAction<IUserDataModify>) => {
      state.userData.firstName = action.payload.firstName;
      state.userData.lastName = action.payload.lastName;
      state.userData.birthdate = action.payload.birthdate;
      state.userData.phone = action.payload.phone;
      state.userData.language = action.payload.language;
    },
    userSetModifyEmail: (state, action: PayloadAction<string>) => {
      state.userData.email = action.payload;
    },
    userSetModifyOptionalEmail: (state, action: PayloadAction<string>) => {
      state.userData.optionalEmail = action.payload;
    },
    userModifyFirstLogin: (state, action: PayloadAction<boolean>) => {
      state.userData.firstLogin = action.payload;
    },
    userSetAvatar: (state, action: PayloadAction<string>) => {
      state.userData.avatar = action.payload;
    },
    userSetRole: (state, action: PayloadAction<string[]>) => {
      state.userData.roles = action.payload;
    },
    userSetVerified: (state, action: PayloadAction<boolean>) => {
      state.userData.verified = action.payload;
    },
    userResetState: () => INITIAL_STATE
  }
});

export const {
  userSetData,
  userSetJwt,
  userSetNotifications,
  userSetLogged,
  userSetLanguage,
  userSetModifyPersonalInformation,
  userSetModifyEmail,
  userSetModifyOptionalEmail,
  userModifyFirstLogin,
  userSetAvatar,
  userSetRole,
  userSetVerified,
  userResetState
} = userSlice.actions;

export default userSlice.reducer;

export const selectUserLogged = (state: RootState) => state.user.logged;

export const selectUserLanguage = (state: RootState) => state.user.userData.language;

export const selectUserInfo = (state: RootState) => state.user.userData;

export const selectUserFirstLogin = (state: RootState) => state.user.userData.firstLogin;

export const selectUserAccountType = (state: RootState) => state.user.userData.roles[0];
