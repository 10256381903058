import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Theme } from 'react-native-calendars/src/types';
import { Colors, Fonts } from '../../configs/enums';

export const CalendarStyles = StyleSheet.create({
  container: {
    flex: 1,
    marginTop: 20,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  hourContainer: {
    width: 40
  },
  weekContainer: {
    marginBottom: 20
  },
  eventsContainer: {
    marginLeft: 50
  },
  daysContainer: {
    height: 100,
    marginTop: 15
  },
  hoursContainer: {
    flex: 1,
    marginTop: 20
  },
  dateNameContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 15
  },
  dateNameText: {
    fontSize: 20,
    fontFamily: Fonts.MANROPE_EXTRABOLD,
    color: Colors.SECONDARY_TEXT_COLOR
  },
  showCalendarIcon: {
    fontSize: 22,
    alignSelf: 'center'
  },
  calendar: {
    color: Colors.ERROR_COLOR
  },
  switchContainer: {
    alignSelf: 'center'
  },
  reloadContainer: {
    alignSelf: 'center'
  },
  refreshIcon: {
    fontSize: 22
  },
  switchAndReloadContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  toolTipText: {
    fontSize: 12,
    fontFamily: Fonts.MANROPE_LIGTH
  },
  toolTipCarousel: {
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderRadius: 10
  },
  toolTipContainer: {
    borderRadius: 10
  },
  failedHashLinkText: {
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_REGULAR,
    fontSize: 20
  }
});

export const functionStyles = {
  hourContainer: (dayMode: boolean): TextStyle & ViewStyle => ({
    width: dayMode ? 40 : 30
  })
};

export const calendarTheme: Theme = {
  selectedDayBackgroundColor: Colors.EVENT_BLUE,
  selectedDayTextColor: '#ffffff',
  todayTextColor: Colors.EVENT_BLUE,
  textDayFontWeight: '500',
  textMonthFontWeight: 'bold',
  textDayHeaderFontWeight: '300',
  textDayFontSize: 16,
  textMonthFontSize: 16,
  textDayHeaderFontSize: 16,
  arrowColor: Colors.SECONDARY_TEXT_COLOR
};
