import React from 'react';
import { View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/Feather';
import { Button } from 'react-native-elements';
import { TYPEOFREQUEST } from '../../configs/enums';
import styles from './styles';
import { PendingRequestsLight } from '../../services/events/interface';
import { selectLanguageKeys } from '../../redux/language';

interface Props {
  pendingEvent: PendingRequestsLight
  onPress: () => any;
}

export default ({
  pendingEvent, onPress
}: Props): React.ReactElement => {
  const getLabel = useSelector(selectLanguageKeys);

  return (
    <View style={styles.container}>
      <View style={styles.infoContainer}>
        <View>
          <Text style={styles.title}>
            {pendingEvent.typeOfRequest === TYPEOFREQUEST.CHANGE_EVENT
              ? 'Solicitud de cambio'
              : getLabel('notifications_singleComponent_title_info')}

          </Text>
        </View>
        <View style={styles.subInfoContainer}>
          <View style={styles.eachInfoContainer}>
            <Icon
              style={styles.icon}
              name="user"
            />
            <Text style={styles.information}>{pendingEvent.ownerName}</Text>
          </View>
          <View style={styles.eachInfoContainer}>
            <Icon
              style={styles.icon}
              name="clock"
            />
            <Text style={styles.information}>{`${getLabel('from_notif')} ${pendingEvent.range.from} ${getLabel('to_notif')} ${pendingEvent.range.to}`}</Text>
          </View>
          <View style={styles.eachInfoContainer}>
            <Icon
              style={styles.icon}
              name="calendar"
            />
            <Text style={styles.information}>{pendingEvent.date}</Text>
          </View>

        </View>
      </View>
      <View style={styles.buttonContainer}>
        <Button
          containerStyle={styles.subButtonContainer}
          buttonStyle={styles.detailButton}
          titleStyle={styles.detailTextButton}
          onPress={onPress}
          title={getLabel('notifications_singleComponent_button_detail')}
        />
      </View>
    </View>
  );
};
