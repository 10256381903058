export class FetcherResponse<T> {
  error: boolean;

  errorMessage?: string;

  response?: T;

  constructor(error: boolean, errorMessage?: string, response?: T) {
    this.error = error;
    this.errorMessage = errorMessage;
    this.response = response;
  }
}
