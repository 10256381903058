import React, { useCallback, useEffect, useState } from 'react';
import {
  View, Text, TextInput, useWindowDimensions, Pressable, Switch
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/Feather';
import { useForm, Controller } from 'react-hook-form';
import { Button } from 'react-native-elements';
import Modal from 'react-native-modal';
import { useSelector, useDispatch } from 'react-redux';
import { Picker } from '@react-native-picker/picker';
import dayjs from 'dayjs';
import { IEventCreate } from '../../services/events/interface';
import NativePicker from '../../components/NativePicker';
import styles, { functionStyles } from './style';
import { selectUserInfo } from '../../redux/user';
import { selectEventParticipants, eventResetState } from '../../redux/event';
import { selectSelectedSchedule } from '../../redux/schedules';
import { showToastError, showToastInfo, showToastSuccess } from '../../utils/toast';
import { CustomModal } from '../../components/CustomModal';
import { WebPicker } from '../../components/WebPicker';
import { MobilePicker } from '../../components/MobilePicker';
import {
  isWeb,
  getOneHourMore,
  getHourSubtracted,
  isDateInPast,
  getCorrespondingDateForDateSelector,
  scheduleLocalNotification
} from '../../utils';
import {
  TIME_HOURS_REGEX
} from '../../utils/constants';
import {
  EVENT_TITLE_VALIDATION, EVENT_DATE_VALIDATION, EVENT_DESCRIPTION_VALIDATION
} from '../../utils/rules';
import Routes from '../../navigation/Routes';
import {
  Colors, OCCASIONTYPE, PERIODICITY_END, PERIODICITY, TYPEOFNOTIFICATION
} from '../../configs/enums';
import { eventCreate } from '../../services/events';
import { globalSetMustResetCalendar } from '../../redux/global';
import { selectLanguageKeys } from '../../redux/language';
import NativeDateTimePicker from '../../components/NativeDateTimePicker';

interface DataEditForm {
  scheduleUuid: string;
  title: string;
  eventDate: string;
  occasionMeetData: string;
  emailsGuest: string;
  description: string | undefined;
}

interface Props {
  route: {
    params: {
      startTime: string | undefined,
      startTimeNumber: number | undefined,
      dayMode: boolean,
      date: string
    }
  };
}

export default ({ route }: Props): React.ReactElement => {
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const selectedParticipants = useSelector(selectEventParticipants);
  const selectedSchedule = useSelector(selectSelectedSchedule);

  const getLabel = useSelector(selectLanguageKeys);

  const { jwt } = useSelector(selectUserInfo);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dateVisible, setDateVisible] = useState<boolean>(false);
  const [dateVisiblePeriodicity, setDateVisiblePeriodicity] = useState<boolean>(false);
  const [isAllDayLong, setisAllDayLong] = useState<boolean>(false);
  const [isModalSetTimeVisible, setIsModalSetTimeVisible] = useState(false);
  const [beginTime, setBeginTime] = useState(route.params.startTime ? route.params.startTime : dayjs().format('HH:mm'));
  const [endTime, setEndTime] = useState(route.params.startTime ? getOneHourMore(route.params.startTime) : getOneHourMore(dayjs().format('HH:mm')));
  const [errorMessaje, setErrorMessaje] = useState('');

  const [modalOccasionTypeIOS, setModalOcassionTypeIOS] = useState(false);

  const [timePlaceholder, setTimePlaceholder] = useState(!route.params);

  const [isBookedTime, setIsBookedTime] = useState(false);

  const [occasionType, setOccasionType] = useState('NONE');

  const [periodicityType, setPeriodicityType] = useState(PERIODICITY.NEVER);
  const [periodicityEndType, setPeriodicityEndType] = useState(PERIODICITY_END.NEVER);
  const [periodicityEndDate, setPeriodicityEndDate] = useState(dayjs().format('YYYY-MM-DD'));

  const [meetingReminder, setMeetingReminder] = useState(0);
  const [preTimeEvent, setPreTimeEvent] = useState(0);
  const [postTimeEvent, setPostTimeEvent] = useState(0);

  const [prevBeginTime, setPrevBeginTime] = useState('');

  const [modalEventCreateError, setModalEventCreateError] = useState(false);
  const [guestsToNotInvite, setGuestsToNotInvite] = useState<string[]>([]);

  const defaultValues = {
    scheduleUuid: '',
    title: '',
    eventDate: getCorrespondingDateForDateSelector(route.params.date),
    occasionMeetData: '',
    emailsGuest: '',
    description: ''
  };

  const {
    handleSubmit, control, formState: { errors }, getValues
  } = useForm({
    defaultValues
  });

  const handleKeydown = useCallback(
    (event: any) => {
      if (event.key === 'Escape') {
        navigation.goBack();
      }
    },
    []
  );

  useEffect(() => {
    if (isWeb) {
      window.addEventListener('keydown', handleKeydown);
    }

    return () => {
      if (isWeb) {
        window.removeEventListener('keydown', handleKeydown);
      }
    };
  }, [navigation]);

  const showCalendar = () => {
    setDateVisible(true);
  };

  const showCalendarPeriodicity = () => {
    setDateVisiblePeriodicity(true);
  };

  const openTimeModal = () => {
    setIsModalSetTimeVisible(!isModalSetTimeVisible);
    if (!isModalSetTimeVisible) {
      setPrevBeginTime(beginTime);
    }
  };

  const closeTime = () => {
    setIsModalSetTimeVisible(!isModalSetTimeVisible);
    setErrorMessaje('');
    setTimePlaceholder(true);
    setBeginTime(dayjs().format('HH:mm'));
    setEndTime(getOneHourMore(dayjs().format('HH:mm')));
  };

  const onChangeBeginTime = (newBeginTime: string) => {
    const defaultDate = dayjs().format('YYYY-MM-DD');

    const minutesDiff = dayjs(`${defaultDate} ${newBeginTime}`).diff(`${defaultDate} ${prevBeginTime}`, 'minutes');

    const newEndTime = dayjs(`${defaultDate} ${endTime}`).add(minutesDiff, 'minutes').format('HH:mm');

    setPrevBeginTime(newBeginTime);
    setBeginTime(newBeginTime);

    const newBeginHour = Number(newBeginTime.split(':')[0]);
    const newEndHour = Number(newEndTime.split(':')[0]);

    if (newBeginHour > newEndHour) {
      setEndTime('23:59');
    } else {
      setEndTime(newEndTime);
    }
  };

  const onChangeEndTime = (value: string) => {
    setEndTime(value);
  };

  const addTime = () => {
    if (beginTime!.match(TIME_HOURS_REGEX) === null || endTime.match(TIME_HOURS_REGEX) === null) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_validTime'));
    } else if (Number(beginTime.replace(':', '')) > Number(endTime.replace(':', ''))) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_beforeTime'));
    } else if (Number(beginTime.replace(':', '')) === Number(endTime.replace(':', ''))) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_matchingTimes'));
    } else {
      setIsModalSetTimeVisible(false);
      setTimePlaceholder(false);
      setErrorMessaje('');
    }
  };

  const allowIsAllDay = () => {
    setisAllDayLong(!isAllDayLong);
  };

  const bookPrePostEvent = () => {
    setIsBookedTime(!isBookedTime);
  };

  const onSetPreTimeEvent = (value: number) => {
    setPreTimeEvent(value);
  };

  const onSetPostTimeEvent = (value: number) => {
    setPostTimeEvent(value);
  };

  const onSetReminder = (value: number) => {
    setMeetingReminder(value);
  };

  const onSetOccasionType = (value: string) => {
    setOccasionType(value);
  };

  const toggleOcassionTypeModal = () => {
    setModalOcassionTypeIOS(!modalOccasionTypeIOS);
  };

  const addPeople = () => {
    const eventDate = getValues('eventDate');

    if (eventDate === '') {
      showToastInfo(getLabel('global_warning'), getLabel('event_warning_choose_date'));
    } else {
      navigation.navigate(Routes.AddPeople, { from: beginTime, to: endTime, eventDate });
    }
  };

  const addEvent = async (data: DataEditForm) => {
    if (isDateInPast(data.eventDate)) {
      showToastError(getLabel('error'), getLabel('backend_error_event_is_not_present_or_future'));
      setIsLoading(false);
      return;
    }

    if (periodicityType !== PERIODICITY.NEVER
      && periodicityEndType !== PERIODICITY_END.NEVER
      && isDateInPast(periodicityEndDate)) {
      showToastError(getLabel('error'), getLabel('backend_error_periodicityto_is_not_present_or_future'));
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    if (!isDateInPast(data.eventDate)) {
      if (timePlaceholder && !isAllDayLong) {
        showToastError(getLabel('event_time_title'), getLabel('event_warning_choose_time'));
      } else {
        const request: IEventCreate = {
          scheduleUuid: selectedSchedule.uuid,
          title: data.title ?? '',
          eventDate: data.eventDate,
          emailsGuest: selectedParticipants.map((participant) => participant.email),
          alarmMinutesBefore: meetingReminder,
          occasionType,
          description: data.description ?? '',
          ...(isBookedTime) && { minutesBeforeToBlock: preTimeEvent, minutesAfterToBlock: postTimeEvent },
          ...(!isBookedTime) && { minutesBeforeToBlock: 0, minutesAfterToBlock: 0 },
          ...(!isAllDayLong) && { hourRange: { from: beginTime, to: endTime } },
          ...(isAllDayLong) && { hourRange: { from: '09:00', to: '18:00' } },
          ...(occasionType !== OCCASIONTYPE.NONE) && { occasionMeetData: data.occasionMeetData },
          periodicity: periodicityType !== PERIODICITY.NEVER,
          ...(periodicityType !== PERIODICITY.NEVER) && { periodicityType },
          ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
        };

        const response = await eventCreate(request, jwt);
        if (response.error) {
          showToastError(getLabel('event_creation_error_title'), getLabel(response?.errorMessage!));
        } else {
          if (response?.response?.code && response.response.code === 'backend_error_guest_not_available') {
            setGuestsToNotInvite(response?.response?.guests || []);
            setModalEventCreateError(true);
          } else {
            if (!isWeb) {
              await scheduleLocalNotification({
                title: data.title,
                body: data.description ?? '',
                date: data.eventDate,
                hour: getHourSubtracted(data.eventDate, beginTime, 0),
                uuid: response.response?.uuid!,
                type: TYPEOFNOTIFICATION.DEFAULT,
                periodicity: periodicityType !== PERIODICITY.NEVER,
                ...(periodicityType !== PERIODICITY.NEVER) && { typeOfPeriodicity: periodicityType },
                ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
              });
            }

            if (meetingReminder !== 0 && !isWeb) {
              await scheduleLocalNotification({
                title: data.title,
                body: data.description ?? '',
                date: data.eventDate,
                hour: getHourSubtracted(data.eventDate, beginTime, meetingReminder),
                uuid: response.response?.uuid!,
                type: TYPEOFNOTIFICATION.ALARM,
                periodicity: periodicityType !== PERIODICITY.NEVER,
                ...(periodicityType !== PERIODICITY.NEVER) && { typeOfPeriodicity: periodicityType },
                ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
              });
            }

            dispatch(eventResetState());
            dispatch(globalSetMustResetCalendar(true));
            showToastSuccess(getLabel('event_created_title'), getLabel('event_success_creation'));
            navigation.goBack();
          }
        }
      }
    } else {
      showToastError(getLabel('error'), getLabel('event_past_error_title'));
    }
    setIsLoading(false);
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false} showsVerticalScrollIndicator={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        {/* Titulo */}
        <View style={styles.headerContainer}>
          <Text style={styles.title}>{getLabel('event_title')}</Text>
        </View>
        <View style={styles.controllersContainer}>
          <Controller
            name="title"
            rules={EVENT_TITLE_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_max_length_30')
            )}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <View>
                <Text style={styles.labelText}>{getLabel('event_title_of_meet')}</Text>
                <TextInput
                  style={[styles.input, errors?.title?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
              </View>
            )}
          />
          {errors?.title?.message && <Text style={styles.errorText}>{errors?.title?.message}</Text>}

          {/* Fecha */}
          <Text style={styles.errorText}>{errors?.eventDate?.message}</Text>
          <Controller
            name="eventDate"
            rules={EVENT_DATE_VALIDATION(getLabel('rules_obligatory'))}
            control={control}
            render={({ field: { onChange, value } }) => (
              <Pressable disabled={route.params.dayMode} style={{ width: '100%' }} onPress={showCalendar}>
                <View pointerEvents="none">
                  <Text style={styles.labelText}>{getLabel('event_date_of_meet')}</Text>
                  <TextInput
                    style={[
                      styles.input,
                      errors?.eventDate?.message
                        ? styles.invalid : styles.valid]}
                    onChange={onChange}
                    value={value}
                    placeholder={getLabel('placeholder_select_date')}
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  />
                  <View>
                    {isWeb ? (
                      <WebPicker
                        setDate={onChange}
                        isVisible={dateVisible}
                        setIsVisible={setDateVisible}
                        limitDateYears={-100}
                        startDate={dayjs(getCorrespondingDateForDateSelector(route.params.date))}
                      />
                    ) : (
                      <MobilePicker
                        setDate={onChange}
                        isVisible={dateVisible}
                        setIsVisible={setDateVisible}
                        limitDateYears={0}
                        startDate={dayjs(getCorrespondingDateForDateSelector(route.params.date))}
                      />
                    )}
                  </View>
                </View>
              </Pressable>
            )}
          />

          {/* Hora de la reunión */}
          <Pressable style={isWeb ? styles.timeImput : styles.timeImputIOS} onPress={isAllDayLong ? () => {} : openTimeModal}>
            <View pointerEvents="none">
              <Text style={styles.labelText}>{getLabel('event_hour_of_meet')}</Text>
              <TextInput
                style={[
                  styles.input,
                  isAllDayLong
                    ? styles.allDayLong : styles.valid]}
                value={timePlaceholder ? '' : `${`${beginTime} ${getLabel('to_connector')} ${endTime}`}hs`}
                placeholder={getLabel('placeholder_select_time')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
              />
            </View>
          </Pressable>

          {/* Todo el dia switch */}
          <View style={isWeb ? [styles.input, styles.subInputAllowAllDay] : styles.allowNotificationsContainer}>
            <Text style={styles.textAllowNotifications}>{getLabel('event_switch')}</Text>
            <Switch
              trackColor={{ false: '#767577', true: '#31267C69' }}
              thumbColor={isAllDayLong ? '#305fab' : '#f4f3f4'}
              ios_backgroundColor="#31267C69"
              onValueChange={allowIsAllDay}
              value={isAllDayLong}
            />
          </View>

          {/* Pre post evento switch */}
          <View style={isWeb ? [styles.input, styles.subInputAllowAllDay] : styles.allowNotificationsContainer}>
            <Text style={styles.textAllowNotifications}>{getLabel('event_switch_pre_post')}</Text>
            <Switch
              trackColor={{ false: '#767577', true: '#31267C69' }}
              thumbColor={isBookedTime ? '#305fab' : '#f4f3f4'}
              ios_backgroundColor="#31267C69"
              onValueChange={bookPrePostEvent}
              value={isBookedTime}
            />
          </View>
          {isBookedTime ? (
            <View>
              <View style={styles.someMargin}>
                <Text style={styles.labelText}>{getLabel('event_pre')}</Text>
                <NativePicker
                  data={getLabel('book_time_info_before')}
                  value={preTimeEvent}
                  setValue={onSetPreTimeEvent}
                />
              </View>

              <View style={styles.someMargin}>
                <Text style={styles.labelText}>{getLabel('event_post')}</Text>
                <NativePicker
                  data={getLabel('book_time_info_after')}
                  value={postTimeEvent}
                  setValue={onSetPostTimeEvent}
                />
              </View>
            </View>
          ) : null}

          {/* Alarma */}
          <View style={styles.someMargin}>
            <Text style={styles.labelText}>{getLabel('event_alarm')}</Text>
            <NativePicker
              data={getLabel('meet_time_reminder')}
              value={meetingReminder}
              setValue={onSetReminder}
            />
          </View>

          {/* Tipo de reunión */}
          <View style={styles.someMargin}>
            <Text style={styles.labelText}>{getLabel('event_type_meet')}</Text>
            <NativePicker
              data={getLabel('occasion_type_info')}
              value={occasionType}
              setValue={onSetOccasionType}
            />
          </View>

          {/* Descripción tipo de reunión */}
          {occasionType !== OCCASIONTYPE.NONE && (
            <View style={[styles.pressableWidth]}>
              <Text style={styles.errorText}>{errors?.occasionMeetData?.message}</Text>
              <Controller
                name="occasionMeetData"
                rules={{
                  maxLength: {
                    value: 500,
                    message: getLabel('rules_max_length_500')
                  }
                }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[styles.input, errors?.occasionMeetData?.message ? styles.invalid : styles.valid]}
                    onBlur={onBlur}
                    onChangeText={(text) => onChange(text)}
                    value={value}
                    placeholder={getLabel('placeholder_type_meet')}
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  />
                )}
              />
            </View>
          )}

          {/* Invitados a la reunión */}
          <Pressable style={[styles.addPeopleContainer, selectedParticipants.length > 0 ? styles.addPeopleYesContainer : styles.addPeopleNoContainer]} onPress={addPeople}>
            <Text style={[styles.addPeopleText, selectedParticipants.length > 0 ? styles.addPeopleYesText : styles.addPeopleNoText]}>
              {selectedParticipants.length > 0 ? selectedParticipants.map((participant) => `${participant.email}; `) : getLabel('event_add_people')}
            </Text>
          </Pressable>
        </View>

        {/* Periodicidad */}
        <View style={styles.middleContainer}>
          <Text style={styles.subTitle}>{getLabel('periodicity_repeat_title')}</Text>
          <View style={styles.someMargin}>
            <NativePicker
              data={getLabel('occasion_periodicity')}
              value={periodicityType}
              setValue={setPeriodicityType}
            />
          </View>
        </View>

        {
          periodicityType !== PERIODICITY.NEVER && (
            <View style={styles.someMargin}>
              <Text style={styles.labelText}>{getLabel('periodicity_end_title')}</Text>
              <NativePicker
                data={getLabel('occasion_periodicity_end')}
                value={periodicityEndType}
                setValue={setPeriodicityEndType}
              />
            </View>
          )
        }

        {
          (periodicityType !== PERIODICITY.NEVER
          && periodicityEndType !== PERIODICITY_END.NEVER) && (
            <View>
              <View style={styles.someMargin}>
                <Pressable style={{ width: '100%' }} onPress={showCalendarPeriodicity}>
                  <View pointerEvents="none">
                    <Text style={styles.labelText}>{getLabel('periodicity_date_title')}</Text>
                    <TextInput
                      style={styles.input}
                      value={periodicityEndDate}
                    />
                    <View>
                      {isWeb ? (
                        <WebPicker
                          setDate={setPeriodicityEndDate}
                          isVisible={dateVisiblePeriodicity}
                          setIsVisible={setDateVisiblePeriodicity}
                          limitDateYears={-100}
                          startDate={dayjs()}
                        />
                      ) : (
                        <MobilePicker
                          setDate={setPeriodicityEndDate}
                          isVisible={dateVisiblePeriodicity}
                          setIsVisible={setDateVisiblePeriodicity}
                          limitDateYears={0}
                          startDate={dayjs()}
                        />
                      )}
                    </View>
                  </View>
                </Pressable>
              </View>
            </View>
          )
        }

        {/* Descripción del evento */}
        <View style={styles.middleContainer}>
          <Text style={styles.subTitle}>{getLabel('event_subTitle')}</Text>
          <Text style={styles.errorText}>{errors?.description?.message}</Text>
          <Controller
            name="description"
            rules={EVENT_DESCRIPTION_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_max_length_500')
            )}
            control={control}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                multiline
                numberOfLines={4}
                textAlignVertical="top"
                style={[styles.input, { height: 100 }, errors?.description?.message ? styles.invalid : styles.valid]}
                onBlur={onBlur}
                onChangeText={(text) => onChange(text)}
                value={value}
                placeholder={getLabel('placeholder_add_description')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
              />
            )}
          />
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={handleSubmit((data) => addEvent(data))}
            title={getLabel('event_button_add_event')}
            loading={isLoading}
          />
        </View>

        <Modal
          isVisible={modalOccasionTypeIOS}
          backdropTransitionOutTiming={0}
          deviceHeight={windowDimensions.height}
          deviceWidth={windowDimensions.width}
          backdropOpacity={0}
          onBackdropPress={toggleOcassionTypeModal}
          animationIn="fadeIn"
          animationOut="fadeOut"
        >
          <View style={styles.modalContainer}>
            <Picker
              style={styles.picker}
              selectedValue={occasionType}
              onValueChange={(occasion) => setOccasionType(occasion)}
            >
              {Object.keys(OCCASIONTYPE).map((element, index) => (
                <Picker.Item key={index} value={element} />
              ))}
            </Picker>
            <Button
              containerStyle={styles.buttonReminderContainer}
              buttonStyle={styles.closeReminderButton}
              titleStyle={styles.closeReminderTextButton}
              onPress={toggleOcassionTypeModal}
              title={getLabel('landing_modal_button')}
            />
          </View>
        </Modal>
      </View>

      <CustomModal isVisible={isModalSetTimeVisible}>
        <View style={styles.modalContainer}>
          <View style={styles.modalTitleContainer}>
            <Text style={styles.modalTitle}>{getLabel('agendaOrganizer_modal_title')}</Text>
            <Button
              onPress={closeTime}
              type="clear"
              icon={(
                <Icon
                  style={styles.modalIcon}
                  name="x-circle"
                />
              )}
            />
          </View>
          <View style={styles.pickerTimeContainer}>
            <View style={styles.divisorSpace}>
              <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_start_time')}</Text>
              <NativeDateTimePicker
                value={beginTime}
                setValue={onChangeBeginTime}
                inputStyle={styles.pickerContainer}
                mode="time"
              />
            </View>

            <View style={styles.divisorSpace}>
              <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_end_time')}</Text>
              <NativeDateTimePicker
                value={endTime}
                setValue={onChangeEndTime}
                inputStyle={styles.pickerContainer}
                mode="time"
              />
            </View>
          </View>
          <View>
            <Text style={styles.errorMessage}>{errorMessaje}</Text>
          </View>
          <View>
            <Button
              containerStyle={styles.modalButtonContainer}
              buttonStyle={styles.addEventButton}
              titleStyle={styles.addEventTextButton}
              onPress={addTime}
              title={getLabel('agendaOrganizer_modal_button')}
            />
          </View>
        </View>
      </CustomModal>

      <Modal
        isVisible={modalEventCreateError}
        backdropTransitionOutTiming={0}
        deviceHeight={windowDimensions.height}
        deviceWidth={windowDimensions.width}
        backdropOpacity={0}
        onBackdropPress={() => setModalEventCreateError(false)}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <View style={styles.eventCreateErrorModalContainer}>
          <Text style={styles.eventCreateErrorTitleText}>
            {getLabel('event_guests_error_create')}
          </Text>
          <Text style={styles.eventCreateErrorSubTitleText}>
            {getLabel('event_guests_error_title')}
          </Text>
          {guestsToNotInvite && guestsToNotInvite.map((guest) => (
            <Text style={styles.guestText} key={guest}>{`${guest}`}</Text>
          ))}
          <Text style={styles.eventCreateErrorSubTitleText}>
            {getLabel('event_guests_error_subtitle')}
          </Text>
          <Button
            containerStyle={styles.guestsButtonContainer}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={() => setModalEventCreateError(false)}
            title={getLabel('agendaOrganizer_modal_button')}
          />
        </View>
      </Modal>
    </KeyboardAwareScrollView>
  );
};
