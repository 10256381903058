import React, { useEffect, useRef } from 'react';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import {
  createDrawerNavigator,
  DrawerContentComponentProps
} from '@react-navigation/drawer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import * as Notifications from 'expo-notifications';
import { LocalNotificationData, PushNotificationData } from '../utils/interfaces';
import { STATUS, TYPEOFNOTIFICATION, TYPEOFPUSHNOTIF } from '../configs/enums';
import { EventLight } from '../components/Calendar/interfaces';
import VerifyAccount from '../features/VerifyAccount';
import AdditionalInfo from '../features/Register/AdditionalInfo';
import {
  RootNavigationRoute,
  navigatorScreenOptions,
  homeScreenOptions,
  profileScreenOptions,
  agendaOrganizerScreenOptions,
  notificationsScreenOptions,
  noHeaderScreenOptions,
  goBackHeaderOptions,
  paymentsScreenOptions,
  registerExtraScreensOptions,
  helpScreenOptions
} from './helpers';
import {
  AuthRouter,
  HomeRouter,
  ProfileRouter,
  AgendaRouter,
  NotificationsRouter,
  PaymentsRouter,
  PaymentsHomeRouter,
  ExternalRouter,
  HelpRouter
} from './Routers';
import { CustomDrawer } from '../components/CustomDrawer';
import Routes from './Routes';
import { selectUserFirstLogin, selectUserInfo, selectUserLogged } from '../redux/user';
import { selectSchedules } from '../redux/schedules';
import AgendaOrganizer from '../features/AgendaOrganizer';
import { selectLanguageKeys } from '../redux/language';
import { globalSetMustResetCalendar } from '../redux/global';
import { updateSubscription } from '../utils';
import { showToastError } from '../utils/toast';

const Stack = createNativeStackNavigator<RootNavigationRoute>();
const Drawer = createDrawerNavigator();

export const AuthNavigator = (): React.ReactElement => (
  <Stack.Navigator initialRouteName={Routes.Landing}>
    {
        AuthRouter.map((item) => (
          <Stack.Screen
            key={item.name}
            name={item.name}
            component={item.component}
            options={item.options}
          />
        ))
      }
  </Stack.Navigator>
);

export const HomeNavigator = () : React.ReactElement => {
  const schedule = useSelector(selectSchedules);
  const getLabel = useSelector(selectLanguageKeys);
  return (
    <Stack.Navigator initialRouteName={Routes.Home}>
      {
        HomeRouter.map((item) => (
          <Stack.Screen
            key={item.name}
            name={item.name}
            component={item.component}
            options={item.name === Routes.Home ? item.options(schedule, getLabel('my_calendar_title')) : item.options}
          />
        ))
      }
    </Stack.Navigator>
  );
};

export const AgendaNavigator = () : React.ReactElement => (
  <Stack.Navigator initialRouteName={Routes.Schedules}>
    {
        AgendaRouter.map((item) => (
          <Stack.Screen
            key={item.name}
            name={item.name}
            component={item.component}
            options={item.options}
          />
        ))
      }
  </Stack.Navigator>
);

export const ProfileNavigator = (): React.ReactElement => (
  <Stack.Navigator initialRouteName={Routes.Profile}>
    {
        ProfileRouter.map((item) => (
          <Stack.Screen
            key={item.name}
            name={item.name}
            component={item.component}
            options={item.options}
          />
        ))
      }
  </Stack.Navigator>
);

export const HelpNavigator = (): React.ReactElement => (
  <Stack.Navigator initialRouteName={Routes.Profile}>
    {
        HelpRouter.map((item) => (
          <Stack.Screen
            key={item.name}
            name={item.name}
            component={item.component}
            options={item.options}
          />
        ))
      }
  </Stack.Navigator>
);

export const PaymentsHomeNavigator = (): React.ReactElement => (
  <Stack.Navigator initialRouteName={Routes.Profile}>
    {
        PaymentsHomeRouter.map((item) => (
          <Stack.Screen
            key={item.name}
            name={item.name}
            component={item.component}
            options={item.options}
          />
        ))
      }
  </Stack.Navigator>
);

export const NotificationsNavigator = (): React.ReactElement => (
  <Stack.Navigator initialRouteName={Routes.Notifications}>
    {
    NotificationsRouter.map((item) => (
      <Stack.Screen
        key={item.name}
        name={item.name}
        component={item.component}
        options={item.options}
      />
    ))
    }
  </Stack.Navigator>
);

export const PaymentsNavigator = (): React.ReactElement => (
  <Stack.Navigator>
    {PaymentsRouter.map((item) => (
      <Stack.Screen
        key={item.name}
        name={item.name}
        component={item.component}
        options={item.options}
      />
    ))}
  </Stack.Navigator>
);

export const MainNavigator = (): React.ReactElement => {
  const getLabel = useSelector(selectLanguageKeys);

  const customDrawer = (props: DrawerContentComponentProps) => (
    <CustomDrawer {...props} />
  );

  return (
    <Drawer.Navigator
      backBehavior="none"
      initialRouteName={Routes.Home}
      drawerContent={customDrawer}
      screenOptions={navigatorScreenOptions}
      useLegacyImplementation
    >
      <Drawer.Screen
        key={0}
        name={Routes.HomeStack}
        component={HomeNavigator}
        options={() => homeScreenOptions(getLabel('app_navigator_drawer')[0])}
      />
      <Drawer.Screen
        key={2}
        name={Routes.AgendaStack}
        component={AgendaNavigator}
        options={() => agendaOrganizerScreenOptions(getLabel('app_navigator_drawer')[1])}
      />
      <Drawer.Screen
        key={4}
        name={Routes.NotificationsStack}
        component={NotificationsNavigator}
        options={() => notificationsScreenOptions(getLabel('app_navigator_drawer')[3])}
      />
      <Drawer.Screen
        key={1}
        name={Routes.PaymentsHome}
        component={PaymentsHomeNavigator}
        options={() => paymentsScreenOptions(getLabel('app_navigator_drawer')[4])}
      />
      <Drawer.Screen
        key={3}
        name={Routes.ProfileStack}
        component={ProfileNavigator}
        options={() => profileScreenOptions(getLabel('app_navigator_drawer')[2])}
      />
      <Drawer.Screen
        key={5}
        name="HelpStack"
        component={HelpNavigator}
        options={() => helpScreenOptions(getLabel('help'))}
      />
    </Drawer.Navigator>
  );
};

export const RootNavigator = (): React.ReactElement => {
  const dispatch = useDispatch();
  const firstLogin = useSelector(selectUserFirstLogin);
  const userData = useSelector(selectUserInfo);
  const userLogged = useSelector(selectUserLogged);
  const getLabel = useSelector(selectLanguageKeys);

  const navigation = useNavigation();

  const notificationListener = useRef<any>();
  const notificationResponseListener = useRef<any>();

  useEffect(() => {
    notificationListener.current = Notifications.addNotificationReceivedListener(async (notification) => {
      // console.info('notification.request.content.data.type ', notification.request.content.data.type);
      if (notification.request.trigger.type === 'push') {
        if (notification.request.content.data.type === TYPEOFPUSHNOTIF.SUBSCRIPTION) {
          const res = await updateSubscription(dispatch, userData);

          if (!res) {
            showToastError(getLabel('error'), getLabel('update_payment_error_title'));
          }
        } else {
          dispatch(globalSetMustResetCalendar(true));
        }
      }
    });

    notificationResponseListener.current = Notifications.addNotificationResponseReceivedListener(async (notification) => {
      const notificationInfoContent = notification.notification.request.content;
      const notificationData = notification.notification.request.content.data as unknown as PushNotificationData;

      // console.info('NOTIFICATION TYPE ', notificationData.type);

      if (notificationData.data) {
        if (notificationData.type === TYPEOFPUSHNOTIF.VISIBLE) {
          navigation.navigate(Routes.ViewEvent, { params: notificationData.data as unknown as EventLight });
        } else if (notificationData.type === TYPEOFPUSHNOTIF.SUBSCRIPTION) {
          const res = await updateSubscription(dispatch, userData);

          if (!res) {
            showToastError(getLabel('error'), getLabel('update_payment_error_title'));
          }
        }
      } else {
        const localNotificationData = notification.notification.request.content.data as unknown as LocalNotificationData;
        if (localNotificationData.type === TYPEOFNOTIFICATION.ALARM
          || localNotificationData.type === TYPEOFNOTIFICATION.DEFAULT
          || localNotificationData.type === TYPEOFNOTIFICATION.PRE) {
          if (localNotificationData?.uuid) {
            const eventLight: EventLight = {
              uuid: localNotificationData.uuid,
              title: notificationInfoContent.title!,
              date: '',
              range: { from: '09:00', to: '18:00' },
              status: STATUS.ACCEPTED
            };

            navigation.navigate(Routes.ViewEvent, { params: eventLight });
          }
        }
      }
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(notificationResponseListener.current);
    };
  }, []);

  return (
    <Stack.Navigator
      initialRouteName={firstLogin ? Routes.AdditionalInfo : Routes.Main}
    >
      <Stack.Screen
        name={Routes.Main}
        component={MainNavigator}
        options={noHeaderScreenOptions}
      />
      <Stack.Screen
        key={Routes.AgendaOrganizer}
        name={Routes.AgendaOrganizer}
        component={AgendaOrganizer}
        options={firstLogin ? registerExtraScreensOptions : goBackHeaderOptions}
      />
      <Stack.Screen
        name={Routes.AdditionalInfo}
        component={AdditionalInfo}
        options={registerExtraScreensOptions}
      />
      <Stack.Screen
        name={Routes.VerifyAccount}
        component={VerifyAccount}
        options={registerExtraScreensOptions}
      />
      {PaymentsRouter.map((item) => (
        <Stack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={item.options}
        />
      ))}
    </Stack.Navigator>
  );
};

export const getNavigators = (isLogged: boolean): React.ReactElement => (
  <Stack.Navigator>
    {!isLogged ? (
      AuthRouter.map((item) => (
        <Stack.Screen
          key={item.name}
          name={item.name}
          component={item.component}
          options={item.options}
        />
      ))
    ) : (
      <Stack.Screen
        name={Routes.Root}
        component={RootNavigator}
        options={noHeaderScreenOptions}
      />
    )}
    {ExternalRouter.map((item) => (
      <Stack.Screen
        key={item.name}
        name={item.name}
        component={item.component}
        options={item.options}
      />
    ))}
  </Stack.Navigator>
);
