import React, { useEffect, useMemo, useState } from 'react';
import {
  View, Text, useWindowDimensions, Pressable, ScrollView, TextInput
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import dayjs from 'dayjs';
import ContentLoader, { Rect } from 'react-content-loader/native';
import {
  acceptOrRejectAppointmentRequest,
  acceptOrRejectExternalInvite,
  acceptOrRejectInternalInvite,
  appointmentInternalChange,
  getPendingRequestsDetails
} from '../../../services/events';
import {
  IAcceptOrRejectEditAppointmentRequest,
  IAcceptOrRejectEventInternalRequest,
  IAcceptRejectEventExternalRequest,
  IAppointmentChangeInternal,
  IAppointments,
  PendingRequestDetails,
  PendingRequestsLight
} from '../../../services/events/interface';
import styles, { functionStyles } from './style';
import { selectUserInfo } from '../../../redux/user';
import { CustomModal } from '../../../components/CustomModal';
import NativeDateTimePicker from '../../../components/NativeDateTimePicker';
import {
  getHourSubtracted, isWeb, removeNotification, scheduleLocalNotification
} from '../../../utils';
import {
  Colors, PERIODICITY_END, PERIODICITY, TYPEOFEVENT, TYPEOFNOTIFICATION, TYPEOFREQUEST
} from '../../../configs/enums';
import NativePicker from '../../../components/NativePicker';
import { showToastError, showToastSuccess } from '../../../utils/toast';
import { globalSetMustResetCalendar, globalSetMustResetInvites } from '../../../redux/global';
import { selectLanguageKeys } from '../../../redux/language';

interface Props {
  route: { params: { pendingEvent: PendingRequestsLight } };
}

const DetailNotificationLoader = (width: number) => (
  <View>
    <ContentLoader
      speed={2}
      width={isWeb ? 600 : width}
      height={700}
      viewBox={isWeb ? '0 00 600 700' : `0 00 ${width} 700`}
      backgroundColor={Colors.BACKGROUND_LOADER_COLOR}
      foregroundColor={Colors.FOREGROUND_LOADER_COLOR}
    >
      <Rect x="0" y="20" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="80" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="140" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="200" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="260" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="320" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="380" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="440" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="500" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="560" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="620" rx="4" ry="4" width="90%" height="40" />
    </ContentLoader>
  </View>
);

export default ({ route }: Props): React.ReactElement => {
  const dispatch = useDispatch();

  const pendingEventParams = useMemo(() => route.params.pendingEvent, []);

  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const getLabel = useSelector(selectLanguageKeys);
  const { jwt } = useSelector(selectUserInfo);

  const [startNewTime, setStartNewTime] = useState('09:00');
  const [endNewTime, setEndNewTime] = useState('18:00');

  const [appointments, setAppointments] = useState<IAppointments[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [openModalShowParticipants, setOpenModalShowParticipants] = useState(false);

  const [meetingReminder, setMeetingReminder] = useState(0);
  const [preTimeEvent, setPreTimeEvent] = useState(0);
  const [postTimeEvent, setPostTimeEvent] = useState(0);

  const [openModalAcceptInvite, setOpenModalAcceptInvite] = useState(false);
  const [openModalRejectInvite, setOpenModalRejectInvite] = useState(false);
  const [openModalChangeInvite, setOpenModalChangeInvite] = useState(false);
  const [isLoadingActionOfAcceptRejectChange, setIsLoadingActionOfAcceptRejectChange] = useState(false);

  const [newDate, setNewDate] = useState(dayjs().year(dayjs().year()).format('YYYY-MM-DD'));
  const [requestData, setRequestData] = useState<PendingRequestDetails>();

  const [periodicityType, setPeriodicityType] = useState(PERIODICITY.NEVER);
  const [periodicityEndType, setPeriodicityEndType] = useState(PERIODICITY_END.NEVER);
  const [periodicityEndDate, setPeriodicityEndDate] = useState(dayjs().format('YYYY-MM-DD'));

  useEffect(() => {
    (async () => {
      let wasAnError: boolean = false;

      const requestDetailsResponse = await getPendingRequestsDetails(pendingEventParams.uuid, jwt);

      if (requestDetailsResponse.error) {
        wasAnError = true;
        showToastError(getLabel('error'), getLabel(requestDetailsResponse.errorMessage!));
        navigation.goBack();
      }

      const requestDetails = requestDetailsResponse.response;

      if (!wasAnError && requestDetails) {
        setAppointments(requestDetails!.guestsEmails);
        setRequestData(requestDetails);
        setIsLoading(false);

        requestDetails.periodicity && setPeriodicityType(requestDetails.periodicityType! as PERIODICITY);

        if (requestDetails.periodicity && requestDetails.periodicityTo) {
          setPeriodicityEndType(PERIODICITY_END.IN_DATE);
          setPeriodicityEndDate(requestDetails.periodicityTo);
        }

        !requestDetails.periodicity && setPeriodicityEndType(PERIODICITY_END.NEVER);
      }
    })();
  }, []);

  const successEventAcceptRejectChange = () => {
    dispatch(globalSetMustResetCalendar(true));
    dispatch(globalSetMustResetInvites(true));
    setIsLoadingActionOfAcceptRejectChange(false);
    navigation.goBack();
  };

  const failedEventAcceptRejectChange = () => {
    dispatch(globalSetMustResetCalendar(true));
    dispatch(globalSetMustResetInvites(true));
    setIsLoadingActionOfAcceptRejectChange(false);
  };

  const onAcceptInvite = async () => {
    setIsLoadingActionOfAcceptRejectChange(true);
    let response;

    if (requestData!.typeOfRequest === TYPEOFREQUEST.EVENT) {
      if (requestData!.typeOfEvent === TYPEOFEVENT.EXTERNAL) {
        const request: IAcceptRejectEventExternalRequest = {
          uuid: requestData!.uuid,
          alarmMinutesBefore: meetingReminder,
          minutesBeforeToBlock: preTimeEvent,
          minutesAfterToBlock: postTimeEvent,
          accept: true
        };

        response = await acceptOrRejectExternalInvite(request, jwt);
      } else {
        const request: IAcceptOrRejectEventInternalRequest = {
          scheduleUuid: requestData!.scheduleUuid,
          occasionUuid: requestData!.uuid,
          alarmMinutesBefore: meetingReminder,
          minutesBeforeToBlock: preTimeEvent,
          minutesAfterToBlock: postTimeEvent,
          accept: true
        };

        response = await acceptOrRejectInternalInvite(request, jwt);
      }

      if (response.response) {
        if (!isWeb) {
          await scheduleLocalNotification({
            title: requestData!.title,
            body: requestData!.description ?? '',
            date: requestData!.date,
            hour: getHourSubtracted(requestData!.date, requestData!.range.from, 0),
            uuid: requestData!.uuid,
            type: TYPEOFNOTIFICATION.DEFAULT,
            periodicity: periodicityType === PERIODICITY.NEVER,
            ...(periodicityType !== PERIODICITY.NEVER) && { typeOfPeriodicity: periodicityType },
            ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
          });
        }

        if (meetingReminder !== 0) {
          await scheduleLocalNotification({
            title: requestData!.title,
            body: requestData!.description ?? '',
            date: requestData!.date,
            hour: getHourSubtracted(requestData!.date, requestData!.range.from, meetingReminder),
            uuid: requestData!.uuid,
            type: TYPEOFNOTIFICATION.ALARM,
            periodicity: periodicityType === PERIODICITY.NEVER,
            ...(periodicityType !== PERIODICITY.NEVER) && { typeOfPeriodicity: periodicityType },
            ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
          });
        }

        showToastSuccess(getLabel('global_success'), getLabel('event_accept_success'));
        successEventAcceptRejectChange();
      } else {
        showToastError(getLabel('error'), getLabel('event_accept_error'));
        failedEventAcceptRejectChange();
      }
    }

    if (requestData!.typeOfRequest === TYPEOFREQUEST.CHANGE_EVENT) {
      const request: IAcceptOrRejectEditAppointmentRequest = {
        uuid: requestData!.uuid,
        accept: true
      };

      response = await acceptOrRejectAppointmentRequest(request, jwt);

      if (response.response) {
        await removeNotification(requestData!.uuid, TYPEOFNOTIFICATION.ALARM);
        await removeNotification(requestData!.uuid, TYPEOFNOTIFICATION.DEFAULT);
        await removeNotification(requestData!.uuid, TYPEOFNOTIFICATION.PRE);

        if (preTimeEvent !== 0) {
          await scheduleLocalNotification({
            title: requestData!.title,
            body: requestData!.description ?? '',
            date: requestData!.date,
            hour: getHourSubtracted(requestData!.date, requestData!.range.from, preTimeEvent),
            uuid: requestData!.uuid,
            type: TYPEOFNOTIFICATION.PRE,
            periodicity: periodicityType === PERIODICITY.NEVER,
            ...(periodicityType !== PERIODICITY.NEVER) && { typeOfPeriodicity: periodicityType },
            ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
          });
        }

        if (meetingReminder !== 0) {
          await scheduleLocalNotification({
            title: requestData!.title,
            body: requestData!.description ?? '',
            date: requestData!.date,
            hour: getHourSubtracted(requestData!.date, requestData!.range.from, preTimeEvent),
            uuid: requestData!.uuid,
            type: TYPEOFNOTIFICATION.ALARM,
            periodicity: periodicityType === PERIODICITY.NEVER,
            ...(periodicityType !== PERIODICITY.NEVER) && { typeOfPeriodicity: periodicityType },
            ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
          });
        }

        if (meetingReminder === 0 && preTimeEvent === 0) {
          await scheduleLocalNotification({
            title: requestData!.title,
            body: requestData!.description ?? '',
            date: requestData!.date,
            hour: getHourSubtracted(requestData!.date, requestData!.range.from, preTimeEvent),
            uuid: requestData!.uuid,
            type: TYPEOFNOTIFICATION.DEFAULT,
            periodicity: periodicityType === PERIODICITY.NEVER,
            ...(periodicityType !== PERIODICITY.NEVER) && { typeOfPeriodicity: periodicityType },
            ...(periodicityEndType !== PERIODICITY_END.NEVER) && { periodicityTo: periodicityEndDate }
          });
        }

        showToastSuccess(getLabel('global_success'), getLabel('event_accept_success'));
        successEventAcceptRejectChange();
      } else {
        showToastError(getLabel('error'), getLabel('event_accept_error'));
        failedEventAcceptRejectChange();
      }
    }

    setIsLoadingActionOfAcceptRejectChange(false);
    setOpenModalAcceptInvite(false);
  };

  const onRejectInvite = async () => {
    setIsLoadingActionOfAcceptRejectChange(true);
    let response;

    if (requestData!.typeOfRequest === TYPEOFREQUEST.EVENT) {
      if (requestData!.typeOfEvent === TYPEOFEVENT.EXTERNAL) {
        const request: IAcceptRejectEventExternalRequest = {
          uuid: requestData!.uuid,
          accept: false
        };

        response = await acceptOrRejectExternalInvite(request, jwt);
      } else {
        const request: IAcceptOrRejectEventInternalRequest = {
          occasionUuid: requestData!.uuid,
          accept: false
        };

        response = await acceptOrRejectInternalInvite(request, jwt);
      }
    }

    if (requestData!.typeOfRequest === TYPEOFREQUEST.CHANGE_EVENT) {
      const request: IAcceptOrRejectEditAppointmentRequest = {
        uuid: requestData!.uuid,
        accept: false
      };

      response = await acceptOrRejectAppointmentRequest(request, jwt);
    }

    if (response?.response) {
      showToastSuccess(getLabel('success'), getLabel('event_reject_success'));
      successEventAcceptRejectChange();
    } else {
      showToastError(getLabel('error'), getLabel('event_reject_error'));
      failedEventAcceptRejectChange();
    }

    setIsLoadingActionOfAcceptRejectChange(false);
    setOpenModalRejectInvite(false);
  };

  const modifyEventTimeDate = async () => {
    setIsLoadingActionOfAcceptRejectChange(true);
    const request: IAppointmentChangeInternal = {
      occasionUuid: requestData!.uuid,
      range: {
        from: startNewTime,
        to: endNewTime
      },
      date: newDate
    };

    const response = await appointmentInternalChange(request, jwt);

    if (response.response) {
      showToastSuccess(getLabel('success'), getLabel('event_modify_success'));
      successEventAcceptRejectChange();
    } else {
      showToastError(getLabel('error'), getLabel('event_reject_error'));
      failedEventAcceptRejectChange();
    }
    setIsLoadingActionOfAcceptRejectChange(false);
    setOpenModalChangeInvite(false);
  };

  const openParticipantsModal = () => {
    setOpenModalShowParticipants(!openModalShowParticipants);
  };

  const onSetPreTimeEvent = (value: number) => {
    setPreTimeEvent(value);
  };

  const onSetPostTimeEvent = (value: number) => {
    setPostTimeEvent(value);
  };

  const onSetReminder = (value: number) => {
    setMeetingReminder(value);
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        {isLoading ? (
          DetailNotificationLoader(windowDimensions.width)
        ) : (
          <>
            <View style={styles.firstContainer}>
              <Text style={styles.mainTitle}>
                {requestData!.typeOfRequest === TYPEOFREQUEST.CHANGE_EVENT
                  ? 'Solicitud de cambio'
                  : getLabel('notifications_singleComponent_title_info')}

              </Text>
            </View>

            <View style={styles.middleContainer}>
              <View style={styles.scondaryTitlesContainer}>
                <Text style={styles.scondaryTitles}>{getLabel('notificationsDetail_subTitle_1')}</Text>
              </View>

              <Text style={styles.labelText}>{getLabel('notification_details_title')}</Text>
              <View style={styles.infoContainer}>
                <Text>{requestData!.title}</Text>
              </View>

              <Text style={styles.labelText}>{getLabel('notification_details_date')}</Text>
              <View style={styles.infoContainer}>
                <Text>{requestData!.date}</Text>
              </View>
              <View style={styles.hoursContainer}>
                <View>
                  <Text style={styles.labelText}>{getLabel('event_start_time')}</Text>
                  <View style={styles.hourInfoContainer}>
                    <Text>{requestData!.range.from}</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.labelText}>{getLabel('event_end_time')}</Text>
                  <View style={styles.hourInfoContainer}>
                    <Text>{requestData!.range.to}</Text>
                  </View>
                </View>
              </View>

              <Text style={styles.labelText}>{getLabel('event_type_meet')}</Text>
              <View style={styles.infoContainer}>
                <Text>{getLabel('occasion_type_info')[requestData!.occasionType]}</Text>
              </View>

              {requestData!.occasionMeetData && (
                <>
                  <Text>{getLabel('notification_details_detail_type_meet')}</Text>
                  <View style={styles.infoContainer}>
                    <Text>{requestData!.occasionMeetData}</Text>
                  </View>
                </>
              )}
            </View>

            <View style={styles.middleContainer}>
              <View style={styles.scondaryTitlesContainer}>
                <Text style={styles.scondaryTitles}>{getLabel('notificationsDetail_subTitle_2')}</Text>
              </View>
              <Pressable
                style={[styles.addPeopleContainer,
                  styles.addPeopleYesContainer]}
                onPress={appointments.length > 0 ? openParticipantsModal : undefined}
              >
                <Text
                  numberOfLines={1}
                  style={[styles.addPeopleText,
                    styles.addPeopleYesText]}
                >
                  {appointments.length > 0
                    ? appointments.map((participant) => `${participant.email}; `)
                    : 'No hay invitados adicionales'}
                </Text>
              </Pressable>
            </View>

            {/* Periodicidad */}
            <View style={styles.middleContainer}>
              <Text style={styles.scondaryTitles}>{getLabel('periodicity_repeat_title')}</Text>
              <View style={styles.someMargin}>
                <NativePicker
                  data={getLabel('occasion_periodicity')}
                  value={periodicityType}
                  setValue={setPeriodicityType}
                  enabled
                />
              </View>
            </View>

            {
              periodicityType !== PERIODICITY.NEVER && (
                <View style={styles.someMargin}>
                  <Text style={styles.labelText}>{getLabel('periodicity_end_title')}</Text>
                  <NativePicker
                    data={getLabel('occasion_periodicity_end')}
                    value={periodicityEndType}
                    setValue={setPeriodicityEndType}
                    enabled
                  />
                </View>
              )
            }

            {
              periodicityType !== PERIODICITY.NEVER
              && periodicityEndType === PERIODICITY_END.IN_DATE && (
                <View>
                  <View style={styles.someMargin}>
                    <Pressable disabled style={{ width: '100%' }}>
                      <View pointerEvents="none">
                        <Text style={styles.labelText}>{getLabel('periodicity_date_title')}</Text>
                        <TextInput
                          style={styles.input}
                          value={periodicityEndDate}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>
              )
            }

            <View style={styles.middleContainer}>
              <View style={styles.scondaryTitlesContainer}>
                <Text style={styles.scondaryTitles}>{getLabel('notificationsDetail_subTitle_3')}</Text>
              </View>
              <View style={[styles.infoContainer, styles.descriptionHeight]}>
                <Text>{requestData!.description}</Text>
              </View>
            </View>

            <View style={styles.bottomContainer}>
              <View style={styles.subBottomContainer}>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={styles.mainButton}
                  titleStyle={styles.mainTextButton}
                  onPress={() => setOpenModalAcceptInvite(true)}
                  title={getLabel('notificationsDetail_button_accept')}
                />
              </View>
              <View style={styles.subBottomContainer}>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={[styles.mainButton, styles.deleteButton]}
                  titleStyle={styles.mainTextButton}
                  onPress={() => setOpenModalRejectInvite(true)}
                  title={getLabel('notificationsDetail_button_reject')}
                />
              </View>
            </View>
          </>
        )}
      </View>
      <CustomModal
        isVisible={openModalChangeInvite}
      >
        <View style={styles.modalChangeContainer}>
          <Text style={styles.modalTitle}>{getLabel('notification_details_change_time')}</Text>
          <View style={styles.divisorSpace}>
            <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_start_time')}</Text>
            <NativeDateTimePicker
              value={startNewTime}
              setValue={setStartNewTime}
              inputStyle={styles.pickerContainer}
              mode="time"
            />
          </View>
          <View style={styles.divisorSpace}>
            <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_end_time')}</Text>
            <NativeDateTimePicker
              value={endNewTime}
              setValue={setEndNewTime}
              inputStyle={styles.pickerContainer}
              mode="time"
            />
          </View>
          <View style={styles.divisorSpace}>
            <Text style={[styles.labelText, styles.someMargin]}>{getLabel('notification_details_date')}</Text>
            <NativeDateTimePicker
              value={newDate}
              setValue={setNewDate}
              inputStyle={styles.pickerContainer}
              mode="date"
            />
          </View>
          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={modifyEventTimeDate}
            title={getLabel('confirm')}
            loading={isLoadingActionOfAcceptRejectChange}
          />
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.addEventButton, styles.deleteButton]}
            titleStyle={styles.addEventTextButton}
            onPress={() => setOpenModalChangeInvite(false)}
            title={getLabel('cancel')}
            disabled={isLoadingActionOfAcceptRejectChange}
          />
        </View>
      </CustomModal>

      <CustomModal isVisible={openModalShowParticipants}>
        <View style={styles.modalGuestsContainer}>
          <Text style={[styles.title, styles.modalParticipanTitle]}>{getLabel('event_participants')}</Text>
          <ScrollView style={styles.participantList} bounces={false} showsVerticalScrollIndicator={false}>
            {appointments.map((participant) => (
              <View key={participant.email} style={styles.modalInfoContainer}>
                <View style={styles.modalParticipantsContainer}>
                  <Text style={styles.modalParticipantEmail}>{participant.email}</Text>
                </View>
                <View style={[styles.viewCircle,
                  functionStyles.getStatusColor(participant.status)]}
                />
              </View>
            ))}
          </ScrollView>
          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={openParticipantsModal}
            title={getLabel('generic_accept_button_title')}
          />
        </View>
      </CustomModal>

      <CustomModal isVisible={openModalAcceptInvite}>
        <View style={styles.modalAcceptRejectContainer}>
          <Text style={styles.modalAcceptRejectTitle}>{getLabel('event_accept_meeting')}</Text>

          <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_pre')}</Text>
          <NativePicker
            data={getLabel('book_time_info_before')}
            value={preTimeEvent}
            setValue={onSetPreTimeEvent}
          />

          <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_post')}</Text>
          <NativePicker
            data={getLabel('book_time_info_after')}
            value={postTimeEvent}
            setValue={onSetPostTimeEvent}
          />
          <Text style={[styles.labelText, styles.someMargin]}>{getLabel('event_alarm')}</Text>
          <NativePicker
            data={getLabel('meet_time_reminder')}
            value={meetingReminder}
            setValue={onSetReminder}
          />

          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={onAcceptInvite}
            title={getLabel('generic_accept_button_title')}
            loading={isLoadingActionOfAcceptRejectChange}
          />
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.addEventButton, styles.deleteButton]}
            titleStyle={styles.addEventTextButton}
            onPress={() => setOpenModalAcceptInvite(false)}
            title={getLabel('cancel')}
            disabled={isLoadingActionOfAcceptRejectChange}
          />
        </View>
      </CustomModal>

      <CustomModal isVisible={openModalRejectInvite}>
        <View style={styles.modalAcceptRejectContainer}>
          <Text style={styles.modalAcceptRejectTitle}>{getLabel('event_reject_meeting')}</Text>
          <Text style={styles.subTitleReject}>{getLabel('event_reject_meeting_subtitle')}</Text>
          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={onRejectInvite}
            title={getLabel('confirm')}
            loading={isLoadingActionOfAcceptRejectChange}
          />
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.addEventButton, styles.deleteButton]}
            titleStyle={styles.addEventTextButton}
            onPress={() => setOpenModalRejectInvite(false)}
            title={getLabel('cancel')}
            disabled={isLoadingActionOfAcceptRejectChange}
          />
        </View>
      </CustomModal>
    </KeyboardAwareScrollView>
  );
};
