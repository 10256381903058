/* eslint-disable no-unused-vars */
export enum Colors {
  BACKGROUND_COLOR = '#FFFFFF',
  BACKGROUND_SECONDARY_COLOR = '#000000',
  BACKGROUND_TERCIARY_COLOR = '#DCEF00',
  PRIMARY_BUTTON_COLOR = '#305fab',
  PRIMARY_TEXT_COLOR = '#FFFFFF',
  SECONDARY_BUTTON_COLOR = '#FFFFFF',
  SECONDARY_TEXT_COLOR = '#000000',
  SECONDARY_BORDER_COLOR ='#707070',
  TERTIARY_BUTTON_COLOR = '#E2E2E2',
  TERTIARY_TEXT_COLOR = '#000000',
  PLACEHOLDER_TEXT_COLOR ='#707070',
  ERROR_COLOR = '#FF2929',
  ERROR_COLOR_2 = '#FF6161',
  INPUT_BORDER_COLOR = '#707070',
  EVENT_BLUE = '#0031FF9D',
  EVENT_PENDING = '#F7D507',
  EVENT_BUSY = '#C1C1C1',
  DRAWER_SEPARATION_COLOR = '#ccc',
  DELETE_BUTTON_COLOR = '#E25151',
  EDIT_BUTTON_COLOR = '#0089FF',
  NAV_BUTTON_COLOR = '#9B9B9B',
  NAV_BUTTON_DISABLED_COLOR = '#D1D1D1',
  BACKGROUND_LOADER_COLOR = '#ECEBEB',
  FOREGROUND_LOADER_COLOR = '#F3F3F3',
  NOTIFICATION_APROVED = '#00FF45',
  NOTIFICATION_REJECTED = '#FF4343',
  NOTIFICATION_PENDING = '#F7D507'
}

export enum Fonts {
  MANROPE_LIGTH = 'ManropeLight',
  MANROPE_REGULAR = 'ManropeRegular',
  MANROPE_SEMIBOLD = 'ManropeSemiBold',
  MANROPE_BOLD = 'ManropeBold',
  MANROPE_EXTRABOLD = 'ManropeExtraBold',
  UBUNTU_MEDIUM = 'UbuntuMedium'
}

export enum Languages {
  ES = 'Español',
  EN = 'Inglés',
  PT = 'Portugues'
}

export enum ROLE {
  UNDEFINED = 'UNDEFINED',
  NO_REGISTERED = 'NO_REGISTERED',
  STANDARD = 'STANDARD',
  PREMIUM = 'PREMIUM',
  PRO = 'PRO'
}

export enum STATUS {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  NOT_ACCEPTED = 'NOT_ACCEPTED'
}

export enum OCCASIONTYPE {
  VIRTUAL = 'VIRTUAL',
  ADDRESS = 'ADDRESS',
  CALL = 'CALL',
  NONE = 'NONE'
}

export enum TYPEOFEVENT {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL'
}

export enum TYPEOFNOTIFICATION {
  ALARM = 'ALARM',
  PRE = 'PRE',
  DEFAULT = 'DEFAULT'
}

export enum TYPEOFREQUEST {
  EVENT = 'EVENT',
  CHANGE_EVENT = 'CHANGE_EVENT'
}

export enum TYPEOFPUSHNOTIF {
  VISIBLE = 'VISIBLE',
  INFORMATIONAL = 'INFORMATIONAL',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

export enum PERIODICITY {
  NEVER = 'NEVER',
  EVERYDAY = 'EVERYDAY',
  EVERY_WORKDAY = 'EVERY_WORKDAY',
  EVERY_WEEK = 'EVERY_WEEK',
  EACH_TWO_WEEKS = 'EACH_TWO_WEEKS',
  EACH_MONTH = 'EACH_MONTH',
  EACH_YEAR = 'EACH_YEAR'
}

export enum PERIODICITY_END {
  NEVER = 'NEVER',
  IN_DATE = 'IN_DATE'
}
