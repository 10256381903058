import {
  USER_MODIFY_ALLOW_NOTIFICATIONS,
  USER_MODIFY_USER_DATA,
  USER_MODIFY_USER_EMAIL,
  USER_MODIFY_USER_PASSWORD_INITIALIZE,
  USER_MODIFY_USER_PASSWORD_CONFIRM,
  USER_MODIFY_USER_PASSWORD_CHANGE,
  USER_MODIFY_USER_PASSWORD_CONFIRM_CHANGE,
  USER_ACCOUNT_DELETE_INITIALIZE,
  USER_ACCOUNT_DELETE_VERIFICATION,
  USER_ACCOUNT_DELETE_CONFIRM,
  USER_MARK_AS_NOT_FIRST_LOGIN,
  USER_SET_TOKEN,
  STRIPE_INITIALIZE,
  USER_ROLE,
  USER_MODIFY_OPTIONAL_EMAIL,
  STRIPE_CANCEL,
  STRIPE_PLAN_INFO,
  USER_SUPPORT,
  BILLING_INFO
} from '../api';
import {
  IUserAllowNotifications,
  IUserDataModify,
  IUserEmailModify,
  IUserCurrentPassword,
  IUserNewPassword,
  StripeInitializeResponse,
  IBillingUserResponse,
  IRolesUserResponse,
  IUserOptionalEmailModify
} from './interfaces';
import { FetcherResponse } from '../FetcherResponse';
import { API_METHOD, fetcher } from '..';
import {
  authValidateFirstName,
  authValidateLastName
} from '../auth';

export const userModifyAllowNotifications = async (request: IUserAllowNotifications, jwt:string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.PUT, USER_MODIFY_ALLOW_NOTIFICATIONS, request, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const areUserModifyInputsValid = async (formInputs: IUserDataModify): Promise<boolean> => {
  try {
    const promises = [
      authValidateFirstName(formInputs.firstName),
      authValidateLastName(formInputs.lastName)
    ];

    return (await Promise.all(promises)).filter((response) => !response.error).length === promises.length;
  } catch (error: unknown) {
    return false;
  }
};

export const userModifyUserData = async (request: IUserDataModify, jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.PUT, USER_MODIFY_USER_DATA, request, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userModifyUserEmail = async (request: IUserEmailModify, jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.PUT, USER_MODIFY_USER_EMAIL, request, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userModifyUserOptionalEmail = async (request: IUserOptionalEmailModify, jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.PUT, USER_MODIFY_OPTIONAL_EMAIL, request, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userModifyUserPasswordInit = async (jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_MODIFY_USER_PASSWORD_INITIALIZE, {}, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userModifyUserPasswordConfirm = async (request: IUserCurrentPassword, jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_MODIFY_USER_PASSWORD_CONFIRM, request, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userModifyUserPasswordChange = async (jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_MODIFY_USER_PASSWORD_CHANGE, {}, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userModifyUserPasswordConfirmChange = async (request: IUserNewPassword, jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_MODIFY_USER_PASSWORD_CONFIRM_CHANGE, request, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userDeleteAccountInitialize = async (jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_ACCOUNT_DELETE_INITIALIZE, {}, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userDeleteAccountVerification = async (request:IUserCurrentPassword, jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_ACCOUNT_DELETE_VERIFICATION, request, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userDeleteAccountConfirm = async (jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_ACCOUNT_DELETE_CONFIRM, {}, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userMarkAsNotFirstLogin = async (jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_MARK_AS_NOT_FIRST_LOGIN, {}, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userSendPuthToken = async (key: string, jwt: string): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_SET_TOKEN, { key }, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const stripeInitialize = async (product: string, jwt: string): Promise<FetcherResponse<StripeInitializeResponse>> => {
  try {
    const response = await fetcher<StripeInitializeResponse>(API_METHOD.POST, `${STRIPE_INITIALIZE}/${product}`, undefined, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const stripeCancel = async (jwt: string): Promise<FetcherResponse<StripeInitializeResponse>> => {
  try {
    const response = await fetcher<StripeInitializeResponse>(API_METHOD.DELETE, `${STRIPE_CANCEL}`, undefined, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userGetBillingInfo = async (jwt: string): Promise<FetcherResponse<IBillingUserResponse>> => {
  try {
    const response = await fetcher<IBillingUserResponse>(API_METHOD.GET, `${BILLING_INFO}`, undefined, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userGetRoles = async (jwt: string): Promise<FetcherResponse<IRolesUserResponse>> => {
  try {
    const response = await fetcher<IRolesUserResponse>(API_METHOD.GET, `${USER_ROLE}`, undefined, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const stripePlanInfo = async (jwt: string): Promise<FetcherResponse<StripeInitializeResponse>> => {
  try {
    const response = await fetcher<StripeInitializeResponse>(API_METHOD.GET, `${STRIPE_PLAN_INFO}`, undefined, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const userSendSupport = async (name: string, email: string, description: string): Promise<FetcherResponse<any>> => {
  try {
    const response = await fetcher<any>(API_METHOD.POST, `${USER_SUPPORT}`, {
      name,
      email,
      description
    });
    return response;
  } catch (error: any) {
    return error;
  }
};
