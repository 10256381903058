import Help from '../features/Help';
import EventFromIcs from '../features/EventFromIcs';
import SupportScreen from '../features/Support';
import ChangeOptionalEmail from '../features/Profile/Components/ChangeOptionalEmail';
import CheckoutFinished from '../features/CheckoutFinished';
import InvitedCalendar from '../features/InvitedCalendar';
import { IScheduleState } from '../redux/schedules/state';
import Routes from './Routes';
import Login from '../features/Login';
import Landing from '../features/Landing';
import Register from '../features/Register';
import Recover from '../features/Recover/index';
import OTP from '../features/Recover/components/code/index';
import newPass from '../features/Recover/components/newPass/index';
import Home from '../features/Home';
import Schedules from '../features/Schedules';
import Profile from '../features/Profile';
import ChangePassword from '../features/Profile/Components/ChangePassword';
import ChangeEmail from '../features/Profile/Components/ChangeEmail';
import DeleteAccount from '../features/Profile/Components/DeleteAccount';
import Event from '../features/Event';
import AddPeople from '../components/AddPeople';
import ViewEvent from '../features/Event/Components/ViewEvent';
import Notifications from '../features/Notifications';
import DetailNotification from '../features/Notifications/DetailNotification';
import AdditionalInfo from '../features/Register/AdditionalInfo';
import Payments from '../features/Payments';
import {
  noHeaderScreenOptions, onlyBurguerHeadersOptions, goBackHeaderOptions, homeHeaderOptions, goBackHeaderOptionsForEvents, goBackHeaderInvitedCalendar, registerExtraScreensOptions
} from './helpers';
import EventExternal from '../features/EventExternal';
import EventDetailsExternal from '../features/EventDetailsExternal';
import Checkout from '../features/Checkout';

export const AuthRouter = [
  {
    name: Routes.Landing,
    component: Landing,
    options: noHeaderScreenOptions
  },
  {
    name: Routes.Login,
    component: Login,
    options: goBackHeaderOptions
  },
  {
    name: Routes.Register,
    component: Register,
    options: goBackHeaderOptions
  },
  {
    name: Routes.RecoverPassword,
    component: Recover,
    options: goBackHeaderOptions
  },
  {
    name: Routes.OneTimePasscode,
    component: OTP,
    options: goBackHeaderOptions
  },
  {
    name: Routes.NewPassword,
    component: newPass,
    options: goBackHeaderOptions
  }
];

export const HelpRouter = [
  {
    name: Routes.Help,
    component: Help,
    options: onlyBurguerHeadersOptions
  }
];

export const HomeRouter = [
  {
    name: Routes.Home,
    component: Home,
    options: (schedule: IScheduleState, scheduleDefaultLabel: any) => homeHeaderOptions(schedule, scheduleDefaultLabel)
  },
  {
    name: Routes.Event,
    component: Event,
    options: goBackHeaderOptionsForEvents
  },
  {
    name: Routes.AddPeople,
    component: AddPeople,
    options: goBackHeaderOptions
  },
  {
    name: Routes.ViewEvent,
    component: ViewEvent,
    options: goBackHeaderOptionsForEvents
  }
];

export const AgendaRouter = [
  {
    name: Routes.Schedules,
    component: Schedules,
    options: onlyBurguerHeadersOptions
  }
];

export const ProfileRouter = [
  {
    name: Routes.Profile,
    component: Profile,
    options: onlyBurguerHeadersOptions
  },
  {
    name: Routes.ChangeEmail,
    component: ChangeEmail,
    options: goBackHeaderOptions
  },
  {
    name: Routes.ChangeOptionalEmail,
    component: ChangeOptionalEmail,
    options: goBackHeaderOptions
  },
  {
    name: Routes.ChangePassword,
    component: ChangePassword,
    options: goBackHeaderOptions
  },
  {
    name: Routes.DeleteAccount,
    component: DeleteAccount,
    options: goBackHeaderOptions
  }
];

export const NotificationsRouter = [
  {
    name: Routes.Notifications,
    component: Notifications,
    options: onlyBurguerHeadersOptions
  },
  {
    name: Routes.DetailNotification,
    component: DetailNotification,
    options: goBackHeaderOptions
  }
];

export const PaymentsHomeRouter = [
  {
    name: Routes.Payments,
    component: Payments,
    options: onlyBurguerHeadersOptions
  },
  {
    name: Routes.Checkout,
    component: Checkout,
    options: goBackHeaderOptions
  }
];

export const PaymentsRouter = [
  {
    name: Routes.Payments,
    component: Payments,
    options: goBackHeaderOptions
  },
  {
    name: Routes.Checkout,
    component: Checkout,
    options: goBackHeaderOptions
  }
];

export const FirstTimeRouter = [
  {
    name: Routes.AdditionalInfo,
    component: AdditionalInfo,
    options: registerExtraScreensOptions
  }
];

export const ExternalRouter = [
  {
    name: Routes.InvitedCalendar,
    component: InvitedCalendar,
    options: goBackHeaderInvitedCalendar
  },
  {
    name: Routes.EventExternal,
    component: EventExternal,
    options: goBackHeaderOptionsForEvents
  },
  {
    name: Routes.AddPeople,
    component: AddPeople,
    options: goBackHeaderOptions
  },
  {
    name: Routes.EventDetailsExternal,
    component: EventDetailsExternal,
    options: goBackHeaderInvitedCalendar
  },
  {
    name: Routes.CheckoutFinished,
    component: CheckoutFinished,
    options: noHeaderScreenOptions
  },
  {
    name: Routes.Support,
    component: SupportScreen,
    options: noHeaderScreenOptions
  },
  {
    name: Routes.EventFromIcs,
    component: EventFromIcs,
    options: goBackHeaderOptions
  }
];
