/* eslint-disable react/require-default-props */
import dayjs from 'dayjs';
import React from 'react';
import { Text, TouchableOpacity, View } from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { getDayNameFromDate, getLanguageCodeByLanguage } from '../../../../utils';
import { WeeklySchedule } from '../../interfaces';
import { functionStyles, WeekStyles as styles } from './styles';

interface Props {
  goBackWeek: any;
  goNextWeek: any;
  parentWidth: number;
  week: WeeklySchedule;
  blocked: boolean;
  onPressDay: any;
  language: string;
}

const Week = ({
  goBackWeek,
  goNextWeek,
  parentWidth,
  week,
  blocked,
  onPressDay,
  language
}: Props): React.ReactElement => (
  <View style={styles.weekContainer}>
    <TouchableOpacity
      disabled={blocked}
      onPress={goBackWeek}
      style={functionStyles.navContainer(blocked)}
    >
      <Icon
        style={styles.icon}
        name="arrow-left"
      />
    </TouchableOpacity>
    {Object.keys(week).map((dayKey) => (
      <TouchableOpacity
        disabled={blocked}
        key={dayKey}
        style={functionStyles.container(parentWidth)}
        onPress={onPressDay ? () => onPressDay(week[dayKey].date) : undefined}
      >
        <Text style={functionStyles.dayName(parentWidth)}>{getDayNameFromDate(dayjs(week[dayKey].date), 'short', getLanguageCodeByLanguage(language)).toUpperCase().substring(0, 3)}</Text>
        <Text style={functionStyles.dayNumber(parentWidth)}>{dayjs(week[dayKey].date).date()}</Text>
      </TouchableOpacity>
    ))}
    <TouchableOpacity disabled={blocked} onPress={goNextWeek} style={functionStyles.navContainer(blocked)}>
      <Icon
        style={styles.icon}
        name="arrow-right"
      />
    </TouchableOpacity>
  </View>
);

export default Week;
