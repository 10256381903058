import { REGEX_MAIL, REGEX_PASSWORD, REGEX_PHONE } from './constants';

export const MAIL_VALIDATION = (mandatoryField: any, minlength: any, maxlength: any, regex: any) => {
  const email = {
    required: {
      value: true,
      message: mandatoryField
    },
    minLength: {
      value: 6,
      message: minlength
    },
    maxLength: {
      value: 128,
      message: maxlength
    },
    pattern: {
      value: REGEX_MAIL,
      message: regex
    }
  };
  return email;
};

export const OPTIONAL_MAIL_VALIDATION = (opEmail: any, minlength: any, maxlength: any, regex: any) => {
  const optionalEmail = {
    required: {
      value: false,
      message: opEmail
    },
    minLength: {
      value: 6,
      message: minlength
    },
    maxLength: {
      value: 128,
      message: maxlength
    },
    pattern: {
      value: REGEX_MAIL,
      message: regex
    }
  };
  return optionalEmail;
};

export const PASSWD_VALIDATION = (mandatoryField: any, minlength: any, maxlength: any, regex: any) => {
  const password = {
    required: {
      value: true,
      message: mandatoryField
    },
    minLength: {
      value: 8,
      message: minlength
    },
    maxLength: {
      value: 32,
      message: maxlength
    },
    pattern: {
      value: REGEX_PASSWORD,
      message: regex
    }
  };
  return password;
};

export const NAME_VALIDATION = (mandatoryField: any, minlength: any, maxlength: any) => {
  const name = {
    required: {
      value: true,
      message: mandatoryField
    },
    minLength: {
      value: 2,
      message: minlength
    },
    maxLength: {
      value: 31,
      message: maxlength
    }
  };
  return name;
};

export const PHONE_VALIDATION = (mandatoryField: any, minlength: any, maxlength: any, regex: any) => {
  const phone = {
    required: {
      value: true,
      message: mandatoryField
    },
    minLength: {
      value: 8,
      message: minlength
    },
    maxLength: {
      value: 31,
      message: maxlength
    },
    pattern: {
      value: REGEX_PHONE,
      message: regex
    }
  };
  return phone;
};

export const BIRTHDATE_VALIDATION = (mandatoryField: any) => {
  const birthdate = {
    required: {
      value: true,
      message: mandatoryField
    }
  };
  return birthdate;
};

export const EVENT_TITLE_VALIDATION = (mandatoryField: any, maxlength: any) => {
  const title = {
    required: {
      value: true,
      message: mandatoryField
    },
    maxLength: {
      value: 100,
      message: maxlength
    }
  };
  return title;
};

export const EVENT_DATE_VALIDATION = (mandatoryField: any) => {
  const date = {
    required: {
      value: true,
      message: mandatoryField
    }
  };
  return date;
};

export const EVENT_TIME_VALIDATION = (mandatoryField: any) => {
  const time = {
    required: {
      value: true,
      message: mandatoryField
    }
  };
  return time;
};

export const EVENT_LINK_TO_VALIDATION = (mandatoryField: any, maxlength: any) => {
  const videoConference = {
    required: {
      value: true,
      message: mandatoryField
    },
    maxLength: {
      value: 500,
      message: maxlength
    }
  };
  return videoConference;
};

export const EVENT_PLACE_VALIDATION = (mandatoryField: any, maxlength: any) => {
  const place = {
    required: {
      value: true,
      message: mandatoryField
    },
    maxLength: {
      value: 500,
      message: maxlength
    }
  };
  return place;
};

export const EVENT_DESCRIPTION_VALIDATION = (mandatoryField: any, maxlength: any) => {
  const description = {
    required: {
      value: false,
      message: mandatoryField
    },
    maxLength: {
      value: 500,
      message: maxlength
    }
  };
  return description;
};
