import React, { useState } from 'react';
import {
  View, Text, useWindowDimensions, Pressable, TextInput, ScrollView, Platform, StatusBar
} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import { Button } from 'react-native-elements';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { CALENDAR_STORAGE_KEY } from '@env';
import Tooltip from 'react-native-walkthrough-tooltip';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { CustomModal } from '../../components/CustomModal';
import { selectUserInfo } from '../../redux/user';
import styles, { functionStyles } from './styles';
import { FloatingButton } from '../../components/FloatingButton';
import { showToastError, showToastSuccess } from '../../utils/toast';
import Routes from '../../navigation/Routes';
import {
  scheduleSetData, scheduleSetSelectedSchedule, selectSchedules, selectSelectedSchedule
} from '../../redux/schedules';
import { DEFAULT_SCHEDULE, ISchedule } from '../../redux/schedules/state';
import {
  storeAsyncStorageData, isWeb, isObjectEmpty, isProAccount
} from '../../utils';
import {
  scheduleGetAll, scheduleChangeTitle, scheduleCreateOne, scheduleDelete
} from '../../services/schedule';
import { selectLanguageKeys } from '../../redux/language';
import { globalSetMustResetCalendar } from '../../redux/global';

export default (): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const { schedules } = useSelector(selectSchedules);
  const selectedSchedule = useSelector(selectSelectedSchedule);
  const getLabel = useSelector(selectLanguageKeys);
  const { jwt, roles } = useSelector(selectUserInfo);
  const androidHeight = StatusBar.currentHeight;

  const [isEnabled, setIsEnabled] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isSelected, setIsSelected] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [errors, setErrors] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [showToolTipEditSchedule, setShowToolTipEditSchedule] = useState(false);
  const [firstTimeTutorialEditSchedule, setFirstTimeTutorialEditSchedule] = useState(false);
  const [showToolTipEditConfiguration, setShowToolTipEditConfiguration] = useState(false);
  const [firstTimeTutorialEditConfiguration, setFirstTimeTutorialEditConfiguration] = useState(false);
  const [showToolTipAddSchedule, setShowToolTipAddSchedule] = useState(false);
  const [firstTimeTutorialAddSchedule, setFirstTimeTutorialAddSchedule] = useState(false);

  const selectCalendar = (key: string | null, index: number) => {
    if (index > 0) {
      if (isSelected.find((el) => el === key)) {
        setIsSelected(isSelected.filter((el) => el !== key));
      } else {
        setIsSelected([...isSelected!, key!]);
      }
    }
  };

  const allowEdit = () => {
    if (firstTimeTutorialEditSchedule) {
      setShowToolTipEditSchedule(true);
      setFirstTimeTutorialEditSchedule(false);
    } else {
      setIsSelected([]);
      setIsEnabled(!isEnabled);
    }
  };

  const openDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const deleteCalendar = async () => {
    setIsLoading(true);
    const calendarsDeleted = await scheduleDelete(isSelected, jwt);

    if (calendarsDeleted) {
      let newSchedules: ISchedule[] = schedules;
      for (let i = 0; i < isSelected.length; i++) {
        newSchedules = newSchedules.filter((schedule) => schedule.uuid !== isSelected[i]);
      }

      dispatch(scheduleSetData(newSchedules));
      dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
      await storeAsyncStorageData(newSchedules, CALENDAR_STORAGE_KEY);

      setIsOpenDeleteModal(!isOpenDeleteModal);
      setIsEnabled(!isEnabled);

      showToastSuccess(getLabel('schedule_success_delete_toast_title'), getLabel('schedule_success_delete_toast_text'));
    } else {
      setIsOpenDeleteModal(!isOpenDeleteModal);
      setIsEnabled(!isEnabled);

      showToastError(getLabel('success'), getLabel('backend_error_generic'));
    }
    setIsLoading(false);
  };

  const openEditModal = () => {
    setErrors('');
    setInputValue('');
    setIsOpenEditModal(!isOpenEditModal);
  };

  const editCalendar = async () => {
    if (inputValue === '') {
      setErrors(getLabel('schedule_error_message_1'));
    } else if (inputValue.length > 28) {
      setErrors(getLabel('schedule_error_message_2'));
    } else {
      setIsLoading(true);
      const response = await scheduleChangeTitle({ uuid: isSelected[0], name: inputValue }, jwt);
      if (response.error) {
        showToastError(getLabel('event_edition_failed'), getLabel('schedules_edit_error'));
      } else {
        const schedulesFromApi = await scheduleGetAll(jwt);

        if (schedulesFromApi.error) {
          showToastError(getLabel('success'), getLabel(schedulesFromApi.errorMessage!));
        } else {
          if (!isObjectEmpty(schedulesFromApi.response)) {
            const newSchedules: ISchedule[] = [];
            newSchedules.push(DEFAULT_SCHEDULE);
            dispatch(scheduleSetData(newSchedules.concat(schedulesFromApi.response!)));
            dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
            await storeAsyncStorageData(newSchedules.concat(schedulesFromApi.response!), CALENDAR_STORAGE_KEY);
          } else {
            dispatch(scheduleSetData([DEFAULT_SCHEDULE]));
            dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
          }
          showToastSuccess(getLabel('created_label_title'), getLabel('schedules_create_new'));
        }
      }
      openEditModal();
      allowEdit();
      setIsLoading(false);
    }
  };

  const openAddCalendarModal = () => {
    if (firstTimeTutorialAddSchedule) {
      setShowToolTipAddSchedule(true);
      setFirstTimeTutorialAddSchedule(false);
    } else if (schedules.length === 5) {
      showToastError(getLabel('schedule_error_premium_limit_toast_title'), getLabel('schedule_error_premium_limit_toast_text'));
    } else {
      setErrors('');
      setInputValue('');
      setIsOpenAddModal(!isOpenAddModal);
    }
  };

  const addCalendar = async () => {
    if (inputValue === '') {
      setErrors(getLabel('schedule_error_message_1'));
    } else if (inputValue.length > 28) {
      setErrors(getLabel('schedule_error_message_2'));
    } else {
      setIsLoading(true);
      const responseCreate = await scheduleCreateOne({ name: inputValue }, jwt);
      if (responseCreate.error) {
        showToastError(getLabel('error'), getLabel(responseCreate.errorMessage!));
      } else {
        const schedulesFromApi = await scheduleGetAll(jwt);

        if (schedulesFromApi.error) {
          showToastError(getLabel('error'), getLabel(schedulesFromApi.errorMessage!));
        } else {
          if (!isObjectEmpty(schedulesFromApi.response)) {
            const newSchedules: ISchedule[] = [];
            newSchedules.push(DEFAULT_SCHEDULE);
            dispatch(scheduleSetData(newSchedules.concat(schedulesFromApi.response!)));
            dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
            await storeAsyncStorageData(newSchedules.concat(schedulesFromApi.response!), CALENDAR_STORAGE_KEY);
          } else {
            dispatch(scheduleSetData([DEFAULT_SCHEDULE]));
            dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
          }
          showToastSuccess(getLabel('created_label_title'), getLabel('schedules_create_new'));
        }
      }
      setIsOpenAddModal(!isOpenAddModal);
      setInputValue('');
      setIsLoading(false);
    }
  };

  const goAgendaOrganizer = (calendar: ISchedule) => {
    if (firstTimeTutorialEditConfiguration) {
      setShowToolTipEditConfiguration(true);
      setFirstTimeTutorialEditConfiguration(false);
    } else {
      navigation.navigate(Routes.AgendaOrganizer, { params: calendar });
    }
  };

  const goHome = async (schedule: ISchedule) => {
    if (selectedSchedule?.uuid === schedule.uuid) {
      navigation.navigate(Routes.Home);
    } else {
      dispatch(globalSetMustResetCalendar(true));
      dispatch(scheduleSetSelectedSchedule(schedule));
      navigation.navigate(Routes.Home);
    }
  };

  return (
    <View style={styles.container}>
      <View style={
          functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <KeyboardAwareScrollView bounces={false} showsVerticalScrollIndicator={false}>
          <View style={styles.firstContainer}>
            <View style={styles.textContainer}>
              <Text style={styles.text}>{getLabel('schedule_main_title')}</Text>
              <View>
                {isProAccount(roles) ? (
                  <View style={styles.toolTipSettingsIcon}>
                    <Button
                      onPress={allowEdit}
                      type="clear"
                      icon={(
                        <Icon
                          style={styles.icon}
                          name="edit"
                        />
                      )}
                    />
                  </View>
                ) : null}
              </View>
            </View>
          </View>
          <View style={styles.secondContainer}>
            <ScrollView style={{ height: 350 }} bounces={false}>
              {schedules.map((element, index) => (
                <Pressable style={styles.calendarSeparator} key={index} onPress={isEnabled ? () => selectCalendar(element.uuid, index) : () => goHome(element)}>
                  <View style={styles.calendarContainer}>
                    <Tooltip
                      isVisible={isWeb ? false : showToolTipEditConfiguration}
                      content={<Text style={styles.toolTipText}>Con este botón podes editar la configuración de este calendario</Text>}
                      placement="top"
                      onClose={() => setShowToolTipEditConfiguration(false)}
                      topAdjustment={Platform.OS === 'android' ? (-androidHeight!) : 0}
                      contentStyle={styles.toolTipContainer}
                    >
                      <View style={styles.toolTipSettingsIcon}>
                        <Button
                          onPress={isEnabled ? () => selectCalendar(element.uuid, index) : () => goAgendaOrganizer(element)}
                          type="clear"
                          icon={(
                            <Icon
                              style={isEnabled ? [styles.settingsIcon, styles.settingsIconEnable] : styles.settingsIcon}
                              name="settings"
                            />
                          )}
                        />
                      </View>
                    </Tooltip>
                    <Text style={styles.calendarName}>{element.name}</Text>

                    {index === 0 ? (null) : (
                      <View>
                        {isEnabled ? (
                          <Icon
                            style={styles.icon}
                            name={isSelected.find((el) => el === element.uuid) ? 'x-square' : 'square'}
                            onPress={() => selectCalendar(element.uuid, index)}
                          />
                        ) : null}
                      </View>
                    ) }

                  </View>
                </Pressable>
              ))}
            </ScrollView>
          </View>
        </KeyboardAwareScrollView>
        {isProAccount(roles) ? (isEnabled ? (
          <View style={styles.secondContainer}>
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={[styles.mainButton, styles.deleteButton]}
              titleStyle={styles.mainTextButton}
              onPress={openDeleteModal}
              title={getLabel('schedule_button_delete')}
              disabled={isSelected.length === 0}
            />
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={[styles.mainButton, styles.editbutton]}
              titleStyle={styles.mainTextButton}
              onPress={openEditModal}
              title={getLabel('schedule_button_edit')}
              disabled={isSelected.length === 0 || isSelected.length > 1}
            />
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.mainButton}
              titleStyle={styles.mainTextButton}
              onPress={allowEdit}
              title={getLabel('shcedule_button_cancelar')}
            />
          </View>
        ) : (
          <View style={styles.actionButtonView}>
            <Tooltip
              isVisible={isWeb ? false : showToolTipAddSchedule}
              content={<Text style={styles.toolTipText}>Con este botón, y siendo usuario premium, podes agregar más calendarios</Text>}
              placement="top"
              onClose={() => setShowToolTipAddSchedule(false)}
              topAdjustment={Platform.OS === 'android' ? (-androidHeight!) : 0}
              contentStyle={styles.toolTipContainer}
            >
              <FloatingButton
                buttonStyle={styles.actionButton}
                icon="add"
                iconSize={40}
                iconColor="white"
                onPressFunction={openAddCalendarModal}
              />
            </Tooltip>
          </View>
        )) : null}
        <CustomModal isVisible={isOpenDeleteModal}>
          <View style={styles.modalDeleteContainer}>
            <Text style={[styles.title, styles.modalTitle]}>
              {getLabel('schedule_delete_modal_title')}
            </Text>
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.mainButton}
              titleStyle={styles.mainTextButton}
              onPress={deleteCalendar}
              title={getLabel('schedule_button_aceptar')}
              loading={isLoading}
            />
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={[styles.mainButton, styles.cancelButton]}
              titleStyle={[styles.mainTextButton, styles.cancelTextButton]}
              onPress={openDeleteModal}
              title={getLabel('shcedule_button_cancelar')}
              disabled={isLoading}
            />
          </View>
        </CustomModal>
        <CustomModal isVisible={isOpenEditModal}>
          <View style={styles.modalDeleteContainer}>
            <Text style={[styles.title, styles.modalTitleWithInput]}>
              {getLabel('schedule_edit_modal_title')}
            </Text>
            <Text style={styles.errorText}>{errors || (null)}</Text>
            <TextInput style={styles.input} value={inputValue} onChangeText={setInputValue} placeholder={getLabel('placeholder_edit_name')} />
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.mainButton}
              titleStyle={styles.mainTextButton}
              onPress={editCalendar}
              title={getLabel('schedule_button_aceptar')}
              loading={isLoading}
            />
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={[styles.mainButton, styles.cancelButton]}
              titleStyle={[styles.mainTextButton, styles.cancelTextButton]}
              onPress={openEditModal}
              title={getLabel('shcedule_button_cancelar')}
              disabled={isLoading}
            />
          </View>
        </CustomModal>
        <CustomModal isVisible={isOpenAddModal}>
          <View style={styles.modalDeleteContainer}>
            <Text style={[styles.title, styles.modalTitleWithInput]}>
              {getLabel('schedule_add_modal_title')}
            </Text>
            <Text style={styles.errorText}>{errors || (null)}</Text>
            <TextInput style={styles.input} value={inputValue} onChangeText={setInputValue} placeholder={getLabel('placeholder_name_calendar')} />
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.mainButton}
              titleStyle={styles.mainTextButton}
              onPress={addCalendar}
              title={getLabel('schedule_button_aceptar')}
              loading={isLoading}
            />
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={[styles.mainButton, styles.cancelButton]}
              titleStyle={[styles.mainTextButton, styles.cancelTextButton]}
              onPress={openAddCalendarModal}
              title={getLabel('shcedule_button_cancelar')}
              disabled={isLoading}
            />
          </View>
        </CustomModal>

      </View>
    </View>
  );
};
