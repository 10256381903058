import { StyleSheet } from 'react-native';
import { Colors, Fonts } from '../../configs/enums';

const WebPickerStyles = StyleSheet.create({
  modalContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    width: 350
  },
  modalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    alignItems: 'center',
    marginHorizontal: 10
  },
  modalIcon: {
    fontSize: 25
  },
  pickerContainer: {
    flexDirection: 'row',
    alignSelf: 'center'
  },
  subPickerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    paddingVertical: 5
  },
  title: {
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: 22
  },
  regularText: {
    fontFamily: Fonts.MANROPE_REGULAR,
    fontSize: 12
  },
  picker: {
    flex: 1,
    fontSize: 15,
    fontFamily: Fonts.MANROPE_REGULAR,
    color: Colors.SECONDARY_TEXT_COLOR,
    paddingVertical: 12,
    paddingHorizontal: 20,
    alignSelf: 'center',
    borderRadius: 10,
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    marginHorizontal: 10
  },
  buttonContainer: {
    width: 150,
    alignSelf: 'center',
    paddingVertical: 20
  },
  closeButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10
  },
  closeTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  }
});

export default WebPickerStyles;
