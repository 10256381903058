import React, { useState } from 'react';
import {
  View, Text, useWindowDimensions, TextInput, TouchableOpacity
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/Feather';
import { Button } from 'react-native-elements';
import { useSelector, useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { selectUserInfo } from '../../../../redux/user';
import styles, { functionStyles } from './styles';
import { showToastError, showToastSuccess } from '../../../../utils/toast';
import { PASSWD_VALIDATION } from '../../../../utils/rules';
import { CustomModal } from '../../../../components/CustomModal';
import { authValidatePassword } from '../../../../services/auth';
import { logoutUser } from '../../../../utils';
import {
  userDeleteAccountInitialize, userDeleteAccountVerification, userDeleteAccountConfirm
} from '../../../../services/user';
import { Colors } from '../../../../configs/enums';
import { selectLanguageKeys } from '../../../../redux/language';

export default (): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const getLabel = useSelector(selectLanguageKeys);
  const userInfo = useSelector(selectUserInfo);
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [hideSecondPassword, setHideSecondPassword] = useState(true);
  const [hideThirdPassword, setHideThirdPassword] = useState(true);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const {
    handleSubmit, control, formState: { errors }
  } = useForm({
    defaultValues: {
      current: '',
      repeatedPassword: ''
    },
    shouldFocusError: true
  });

  const onToggleSecondSwitch = () => {
    setHideSecondPassword(!hideSecondPassword);
  };

  const onToggleThirdSwitch = () => {
    setHideThirdPassword(!hideThirdPassword);
  };

  const openDeleteModal = () => {
    setIsOpenDeleteModal(!isOpenDeleteModal);
  };

  const validatePassword = async (data : {current: string, repeatedPassword: string}) => {
    setIsLoading(true);
    if (data.current === data.repeatedPassword) {
      const isValidatePassword = await authValidatePassword(data.current);
      if (isValidatePassword.error) {
        showToastError(getLabel('profile_toast_error_validation_title'), isValidatePassword.errorMessage);
      } else {
        openDeleteModal();
      }
    } else {
      showToastError(getLabel('changePassword_toast_success_title'), getLabel('changePassword_toast_error_match_text'));
    }
    setIsLoading(false);
  };

  const deleteAccount = async (data : {current: string, repeatedPassword: string}) => {
    setIsLoading(true);
    const response = await userDeleteAccountInitialize(userInfo.jwt);
    if (response.error) {
      openDeleteModal();
      showToastError(getLabel('deleteAccount_toast_error_title'), getLabel(response.errorMessage!));
    } else {
      const response = await userDeleteAccountVerification({ current: data.current }, userInfo.jwt);
      if (response.error) {
        openDeleteModal();
        showToastError(getLabel('deleteAccount_toast_error_title'), getLabel(response.errorMessage!));
      } else {
        const response = await userDeleteAccountConfirm(userInfo.jwt);
        if (response.error) {
          openDeleteModal();
          showToastError(getLabel('deleteAccount_toast_error_title'), getLabel(response.errorMessage!));
        } else {
          openDeleteModal();
          setTimeout(async () => {
            await logoutUser(dispatch);
            showToastSuccess(getLabel('deleteAccount_toast_success_title'), getLabel('deleteAccount_toast_success_text'));
          }, 500);
        }
      }
    }
    setIsLoading(false);
  };

  return (
    <KeyboardAwareScrollView style={styles.container}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View style={styles.titleContainer}>
          <Text style={styles.text}>{getLabel('deleteAccount_title')}</Text>
        </View>
        <View style={styles.controllersContainer}>

          <Text style={styles.errorText}>{errors?.current?.message}</Text>
          <Controller
            name="current"
            control={control}
            rules={PASSWD_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_8'),
              getLabel('rules_max_length_32'),
              getLabel('rules_password_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.subInputContainer}>
                <TextInput
                  style={[styles.input, errors?.current?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  autoCorrect={false}
                  secureTextEntry={hideSecondPassword}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder="Password"
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
                <TouchableOpacity
                  style={styles.buttonHideIcon}
                  onPress={onToggleSecondSwitch}
                >
                  <Icon
                    style={styles.hideIcon}
                    name={!hideSecondPassword ? 'eye' : 'eye-off'}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
          <Text style={styles.errorText}>{errors?.repeatedPassword?.message}</Text>
          <Controller
            name="repeatedPassword"
            control={control}
            rules={PASSWD_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_8'),
              getLabel('rules_max_length_32'),
              getLabel('rules_password_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.subInputContainer}>
                <TextInput
                  style={[styles.input, errors?.repeatedPassword?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  autoCorrect={false}
                  secureTextEntry={hideThirdPassword}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_password_repeat')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
                <TouchableOpacity
                  style={styles.buttonHideIcon}
                  onPress={onToggleThirdSwitch}
                >
                  <Icon
                    style={styles.hideIcon}
                    name={!hideThirdPassword ? 'eye' : 'eye-off'}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.acceptButton}
            titleStyle={styles.acceptTextButton}
            onPress={handleSubmit((data) => validatePassword(data))}
            title={getLabel('deleteAccount_button')}
            loading={isLoading}
          />
          <CustomModal isVisible={isOpenDeleteModal}>
            <View style={styles.modalDeleteContainer}>
              <Text style={[styles.title, { textAlign: 'center', paddingBottom: 50 }]}>{getLabel('deleteAccount_delete_text')}</Text>
              <Button
                containerStyle={styles.buttonContainer}
                buttonStyle={styles.acceptButton}
                titleStyle={styles.acceptTextButton}
                onPress={handleSubmit((data) => deleteAccount(data))}
                title={getLabel('eventDetail_delete_button_accept')}
                loading={isLoading}
              />
              <Button
                containerStyle={styles.buttonContainer}
                buttonStyle={[styles.acceptButton, styles.cancelButton]}
                titleStyle={[styles.acceptTextButton, styles.cancelTextButton]}
                onPress={openDeleteModal}
                title={getLabel('eventDetail_delete_button_cancel')}
                disabled={!!isLoading}
              />
            </View>
          </CustomModal>
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
