import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const EventStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  backgroundPic: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  headerContainer: {
    flexDirection: 'column'
  },
  icon: {
    fontSize: 32,
    position: 'absolute',
    paddingTop: 15
  },
  title: {
    fontSize: 22,
    fontFamily: Fonts.MANROPE_BOLD,
    alignSelf: 'center'
  },
  controllersContainer: {
    justifyContent: 'space-around',
    marginTop: 10
  },
  middleContainer: {
    marginTop: 10,
    justifyContent: 'center'
  },

  bottomContainer: {
    alignItems: 'center',
    alignContent: 'space-around',
    paddingTop: 25
  },

  subTitle: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    backgroundColor: Colors.BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  valid: {
    borderWidth: 1
  },
  invalid: {
    borderColor: Colors.ERROR_COLOR
  },
  allDayLong: {
    borderColor: Colors.INPUT_BORDER_COLOR,
    opacity: 0.3
  },
  timeImput: {
    width: '100%',
    marginTop: 10
  },
  timeImputIOS: {
    width: '100%',
    marginTop: 10
  },
  buttonReminderContainer: {
    width: '80%',
    alignSelf: 'center'
  },
  closeReminderButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    marginBottom: 25
  },
  closeReminderTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  buttonContainer: {
    width: '100%'
  },
  addEventButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50,
    marginBottom: 20
  },
  addEventTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },

  addParticipantsButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    borderRadius: 10,
    height: 50,
    marginTop: 20

  },
  addParticipantsTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    paddingVertical: 6
  },
  errorText: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 10
  },
  subInputContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR,
    width: '100%'
  },
  allowNotificationsContainer: {
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginTop: 20
  },
  textAllowNotifications: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignSelf: 'center',
    flex: 1
  },
  modalContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1
  },
  modalTitle: {
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: 22
  },
  modalText: {
    alignSelf: 'flex-start',
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 15
  },
  pickerContainer: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  modalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    alignItems: 'center',
    marginHorizontal: 10
  },
  modalButtonContainer: {
    width: '100%',
    paddingHorizontal: 50
  },
  modalIcon: {
    fontSize: 25
  },
  dropdownIcon: {
    fontSize: 18,
    opacity: 0.33,
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingRight: 15
  },
  textContainer: {
    flexDirection: 'row',
    flex: 1
  },
  range: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    opacity: 0.3,
    flex: 3
  },
  errorMessage: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 14,
    alignSelf: 'center',
    marginVertical: 8
  },
  picker: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    paddingVertical: 6,
    paddingHorizontal: 15,
    textAlign: 'left',
    borderRadius: 12,
    width: '100%',
    marginTop: 10
  },
  mobilePickercontainer: {
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 12
  },
  mobileTextPickerIOS: {
    paddingLeft: 20,
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  androidPickercontainer: {
    width: '100%',
    borderRadius: 10,
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    marginTop: 20
  },
  addPeopleContainer: {
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderWidth: 1,
    marginTop: 20,
    height: 50
  },
  addPeopleNoContainer: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    borderColor: Colors.PRIMARY_BUTTON_COLOR
  },
  addPeopleYesContainer: {
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderColor: Colors.INPUT_BORDER_COLOR
  },
  addPeopleText: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25
  },
  addPeopleYesText: {
    color: Colors.SECONDARY_TEXT_COLOR
  },
  addPeopleNoText: {
    color: Colors.PRIMARY_TEXT_COLOR
  },
  subInputAllowAllDay: {
    flexDirection: 'row',
    marginTop: 10
  },
  someMargin: {
    marginTop: 10
  },
  labelText: {
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 12
  },
  pressableWidth: {
    width: '100%'
  },
  pickerTimeContainer: {
    flexDirection: 'column',
    paddingVertical: 5,
    paddingHorizontal: 50
  },
  divisorSpace: {
    marginBottom: 10
  },
  eventCreateErrorModalContainer: {
    paddingHorizontal: 16,
    borderRadius: 15,
    backgroundColor: '#FFE6E6',
    borderWidth: 1
  },
  eventCreateErrorTitleText: {
    marginVertical: 10,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 16,
    textAlign: 'center'
  },
  eventCreateErrorSubTitleText: {
    marginVertical: 10,
    fontFamily: Fonts.MANROPE_REGULAR,
    fontSize: 13,
    textAlign: 'center'
  },
  guestText: {
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 13,
    textAlign: 'center'
  },
  guestsButtonContainer: {
    width: '100%',
    paddingHorizontal: 50,
    marginTop: 16
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  }),
  innerModalContainer: (marginTop: number, height: number): TextStyle & ViewStyle => ({
    borderRadius: 5,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 4,
    height,
    width: 100,
    alignSelf: 'flex-end',
    marginRight: 50,
    marginTop
  })
};

export default EventStyles;
