import React, { useEffect, useState } from 'react';
import {
  FlatList, Text, ScrollView, View, Pressable
} from 'react-native';
import Modal from 'react-native-modal';
import dayjs from 'dayjs';
import { Calendar } from 'react-native-calendars';
import Icon from 'react-native-vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-native-elements';
import {
  IGetLightEventsBetweenDates,
  IGetLightEventsBetweenDatesByHashLinkRequest
} from '../../services/events/interface';
import { showToastError } from '../../utils/toast';
import { globalSetMustResetCalendar, selectGlobalAppMustResetCalendar } from '../../redux/global';
import {
  eventsLightBetweenDates,
  eventsLightBetweenDatesByHashLink,
  eventsLightForADay
} from '../../services/events';
import { selectSelectedSchedule } from '../../redux/schedules';
import { selectUserInfo } from '../../redux/user';
import Routes from '../../navigation/Routes';
import { calendarLocaleConfig } from '../../configs/calendarLocale';
import {
  calculateHourStart,
  capitalize,
  getDaysForCalendar,
  getHours,
  getDayNameFromDate,
  isWeb,
  getMonthNameFromDate,
  weeklyScheduleToEventCalendarArray,
  getStartWeekDate,
  isObjectEmpty,
  transformAgendaConfigToWeeklySchedule,
  getLanguageCodeByLanguage,
  calculateDayEventsOverlap
} from '../../utils';
import Hour from './components/Hour';
import { DayCalendar, EventLight, WeeklySchedule } from './interfaces';
import { CalendarStyles as styles, calendarTheme, functionStyles } from './styles';
import Event from './components/Event';
import Day from './components/Day';
import { DAY_ITEMS_QTY, DAY_ITEM_WIDTH } from './configs';
import HourLine from './components/HourLine';
import Week from './components/Week';
import Mode from './components/Mode';

import { Colors } from '../../configs/enums';
import { selectLanguageKeys } from '../../redux/language';
import FreeHour from './components/FreeHour';
import { scheduleSettingsGetSettingsWithHash } from '../../services/scheduleSettings';
import { mockEventsDayData, mockEventsWeekData } from './mock';

const dayOfYear = require('dayjs/plugin/dayOfYear');

dayjs.extend(dayOfYear);

interface Props {
  dayMode: boolean;
  homeMode: boolean;
  hashLink?: string;
  setDayMode?: (mode: boolean) => void;
  selectedDate: DayCalendar;
  setSelectedDate: (selDate: DayCalendar) => void;
}

const SwitchAndBodyLoader = () => (
  <View>
    <ContentLoader
      speed={2}
      width={isWeb ? 600 : 350}
      height={700}
      viewBox={isWeb ? '0 00 600 700' : '0 00 350 700'}
      backgroundColor={Colors.BACKGROUND_LOADER_COLOR}
      foregroundColor={Colors.FOREGROUND_LOADER_COLOR}
    >
      <Rect x="0" y="5" rx="4" ry="4" width="180" height="30" />
      <Rect x="0" y="50" rx="4" ry="4" width="100%" height="300" />
      <Rect x="0" y="380" rx="4" ry="4" width="100%" height="150" />
    </ContentLoader>
  </View>
);

const getLightEventsForADate = async (
  scheduleUuid: string | null,
  date: string,
  jwt: string,
  setDayEvents: (value: React.SetStateAction<EventLight[] | null>) => void,
  getLabel: any
) => {
  const eventsLightResponse = await eventsLightForADay({ scheduleUuid, date }, jwt);

  if (eventsLightResponse.error) {
    showToastError(getLabel('error'), getLabel(eventsLightResponse.errorMessage!));
    setDayEvents([]);
    return;
  }

  if (eventsLightResponse.response) {
    // TODO: MOCK -> setDayEvents(calculateDayEventsOverlap(mockEventsDayData));
    setDayEvents(calculateDayEventsOverlap(eventsLightResponse.response));
  } else {
    setDayEvents([]);
  }
};

const getLightEventsForAWeek = async (
  scheduleUuid: string | null,
  date: dayjs.Dayjs,
  jwt: string,
  setWeekEvents: (value: React.SetStateAction<WeeklySchedule | null>) => void,
  homeMode: boolean,
  getLabel: any,
  hashLink?: string,
  setInvalidHashLink?: (value: boolean) => void
) => {
  const from = getStartWeekDate(date).format('YYYY-MM-DD');
  const to = getStartWeekDate(date).add(6, 'day').format('YYYY-MM-DD');

  const request: IGetLightEventsBetweenDates = {
    scheduleUuid,
    from,
    to
  };
  let eventsLightWeek;

  if (!homeMode && hashLink) {
    const getLightEventsBetweenDatesByHashLinkRequest: IGetLightEventsBetweenDatesByHashLinkRequest = {
      hashLink,
      from: request.from,
      to: request.to
    };
    eventsLightWeek = await eventsLightBetweenDatesByHashLink(getLightEventsBetweenDatesByHashLinkRequest);
    if (eventsLightWeek.error) {
      showToastError(getLabel('error'), getLabel(eventsLightWeek.errorMessage!));
      setInvalidHashLink!(true);
      return;
    }

    let weekEventsFromUser;

    if (!isObjectEmpty(eventsLightWeek.response)) {
      weekEventsFromUser = weeklyScheduleToEventCalendarArray(eventsLightWeek.response!, date);
    } else {
      weekEventsFromUser = weeklyScheduleToEventCalendarArray(null, date);
    }

    const scheduleSettingsOfUser = await scheduleSettingsGetSettingsWithHash(hashLink);
    if (scheduleSettingsOfUser.response) {
      setWeekEvents(transformAgendaConfigToWeeklySchedule(weekEventsFromUser, scheduleSettingsOfUser.response!));
    } else {
      setInvalidHashLink!(true);
      showToastError(getLabel('error'), getLabel(scheduleSettingsOfUser.errorMessage!));
    }
  } else {
    eventsLightWeek = await eventsLightBetweenDates(request, jwt);
    if (eventsLightWeek.error) {
      showToastError(getLabel('error'), getLabel(eventsLightWeek.errorMessage!));
      return;
    }
    if (eventsLightWeek.response) {
      if (eventsLightWeek.response.length === 0) {
        setWeekEvents(weeklyScheduleToEventCalendarArray(null, date));
      } else {
        // TODO: Mock -> setWeekEvents(weeklyScheduleToEventCalendarArray(mockEventsWeekData, date));
        setWeekEvents(weeklyScheduleToEventCalendarArray(eventsLightWeek.response, date));
      }
    }
  }
};

export default ({
  dayMode, setDayMode, homeMode, hashLink, selectedDate, setSelectedDate
}: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const scrollViewRef = React.createRef<ScrollView>();

  const [now, setNow] = useState(calculateHourStart(`${dayjs().hour()}:${dayjs().minute()}`, false));

  const [dates, setDates] = useState<DayCalendar[]>(getDaysForCalendar(dayjs()));

  const [calendarMonthVisible, setCalendarMonthVisible] = useState(false);

  const [scrollViewWidth, setScrollViewWith] = useState(0);

  const [loading, setLoading] = useState(true);

  const selectedSchedule = useSelector(selectSelectedSchedule);

  const mustReloadCalendar = useSelector(selectGlobalAppMustResetCalendar);

  const getLabel = useSelector(selectLanguageKeys);

  const { jwt, language } = useSelector(selectUserInfo);

  const [dayEvents, setDayEvents] = useState<EventLight[] | null>(null);

  const [blockHeader, setBlockHeader] = useState<boolean>(false);

  const [weekEvents, setWeekEvents] = useState<WeeklySchedule | null>(null);

  const [invalidHashLink, setInvalidHashLink] = useState<boolean>(false);

  useEffect(() => {
    if (mustReloadCalendar) {
      setLoading(true);
      setBlockHeader(true);
      (async () => {
        await getLightEventsForADate(
          selectedSchedule!.uuid,
          selectedDate.date.format('YYYY-MM-DD'),
          jwt,
          setDayEvents,
          getLabel
        );
        await getLightEventsForAWeek(
          selectedSchedule!.uuid,
          selectedDate.date,
          jwt,
          setWeekEvents,
          homeMode,
          getLabel,
          hashLink,
          setInvalidHashLink
        );
        setLoading(false);
      })();
      setBlockHeader(false);
      dispatch(globalSetMustResetCalendar(false));
    }
  }, [mustReloadCalendar]);

  useEffect(() => {
    (async () => {
      setBlockHeader(true);

      if (homeMode) {
        await getLightEventsForADate(
          selectedSchedule!.uuid,
          selectedDate.date.format('YYYY-MM-DD'),
          jwt,
          setDayEvents,
          getLabel
        );
      }

      await getLightEventsForAWeek(
        selectedSchedule!.uuid,
        selectedDate.date,
        jwt,
        setWeekEvents,
        homeMode,
        getLabel,
        hashLink,
        setInvalidHashLink
      );

      setBlockHeader(false);
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    if (!loading && !isWeb) {
      calendarLocaleConfig(getLanguageCodeByLanguage(language));
      setTimeout(() => {
        scrollViewRef.current?.scrollTo({
          animated: true,
          y: now
        });
      }, 1000);
    }
  }, [loading]);

  useEffect(() => {
    setNow(calculateHourStart(`${dayjs().hour()}:${dayjs().minute()}`, dayMode));
  }, [dayMode]);

  /**
   * We're filtering the dates array into two arrays, one with the dates before the selected date and
   * one with the dates after the selected date. We're then mapping over each array and setting the
   * selected property to false. We're then adding the selected date to the first array and
   * concatenating the two arrays together
   * @param {DayCalendar} item - DayCalendar - The item that was clicked
   * @param {number} index - The index of the item in the array
   */
  const onDayPress = async (item: DayCalendar, index: number) => {
    const newDates1 = dates.slice(0, index).map((date) => ({ ...date, selected: false }));
    const newDates2 = dates.slice(index + 1).map((date) => ({ ...date, selected: false }));
    newDates1.push({ ...item, selected: true });
    const finalDates = newDates1.concat(newDates2);
    setDates(finalDates);
    setSelectedDate(item);

    setLoading(true);
    setBlockHeader(true);

    await getLightEventsForADate(
      selectedSchedule!.uuid,
      item.date.format('YYYY-MM-DD'),
      jwt,
      setDayEvents,
      getLabel
    );

    setLoading(false);
    setBlockHeader(false);
  };

  const onChangeDay = async (date: string) => {
    setLoading(true);
    setBlockHeader(true);

    setDates(getDaysForCalendar(dayjs(date)));
    setSelectedDate(getDaysForCalendar(dayjs(date))[DAY_ITEMS_QTY / 2]);
    setCalendarMonthVisible(false);

    await getLightEventsForADate(
      selectedSchedule!.uuid,
      dayjs(date).format('YYYY-MM-DD'),
      jwt,
      setDayEvents,
      getLabel
    );

    setLoading(false);
    setBlockHeader(false);
  };

  const onScrollViewLayout = (event: any) => {
    const { width, height } = event.nativeEvent.layout;
    setScrollViewWith(width);
  };

  const renderDayItem = ({ item, index }: { item: DayCalendar, index: number }) => (
    <View>
      <Day onPress={blockHeader ? undefined : () => onDayPress(item, index)} item={item} language={language} />
    </View>
  );

  const navigateToEvent = (hour: number, date?: string) => {
    navigation.navigate(Routes.Event, {
      startTime: hour < 10 ? `0${hour}:00` : `${hour}:00`,
      startTimeNumber: hour,
      dayMode,
      date: date ? dayjs(date).format('YYYY-MM-DD') : selectedDate.date.format('YYYY-MM-DD')
    });
  };

  const navigateToEventExternal = (hour: number, date?: string) => {
    navigation.navigate(Routes.EventExternal, {
      hashLink: hashLink!,
      startTime: hour < 10 ? `0${hour}:00` : `${hour}:00`,
      startTimeNumber: hour,
      dayMode,
      date: date ? dayjs(date).format('YYYY-MM-DD') : selectedDate.date.format('YYYY-MM-DD')
    });
  };

  const onGoBackWeek = async () => {
    setLoading(true);
    setBlockHeader(true);

    setWeekEvents(weeklyScheduleToEventCalendarArray(null, dayjs(weekEvents!['1'].date).subtract(7, 'day')));

    await getLightEventsForAWeek(
      selectedSchedule!.uuid,
      dayjs(weekEvents!['1'].date).subtract(7, 'day'),
      jwt,
      setWeekEvents,
      homeMode,
      getLabel,
      hashLink,
      setInvalidHashLink
    );

    homeMode && onChangeDay(dayjs(weekEvents!['1'].date).subtract(7, 'day').format('YYYY-MM-DD'));

    setLoading(false);
    setBlockHeader(false);
  };

  const onGoNextWeek = async () => {
    setLoading(true);
    setBlockHeader(true);

    setWeekEvents(weeklyScheduleToEventCalendarArray(null, dayjs(weekEvents!['7'].date).add(6, 'day')));

    await getLightEventsForAWeek(
      selectedSchedule!.uuid,
      dayjs(weekEvents!['7'].date).add(6, 'day'),
      jwt,
      setWeekEvents,
      homeMode,
      getLabel,
      hashLink,
      setInvalidHashLink
    );

    homeMode && onChangeDay(dayjs(weekEvents!['7'].date).add(1, 'day').format('YYYY-MM-DD'));

    setLoading(false);
    setBlockHeader(false);
  };

  const onPressDayInWeek = (date: string) => {
    setDayMode!(true);
    onChangeDay(date);
  };

  const switchDayMode = () => {
    setDayMode!(!dayMode);
  };

  const switchCalendarDay = () => {
    setCalendarMonthVisible(true);
  };

  const onChangeDayMonthView = (date: string) => {
    if (!dayMode) {
      setDayMode!(true);
    }
    onChangeDay(date);
  };

  const calendarHeader = () => (
    <View>
      <View style={styles.toolTipCarousel}>
        {dayMode ? (
          <View>
            {homeMode && (
              <Pressable style={styles.dateNameContainer} onPress={switchCalendarDay}>
                <Text style={styles.dateNameText}>
                  {`${capitalize(getDayNameFromDate(selectedDate.date, 'long', getLanguageCodeByLanguage(language)))}, ${selectedDate.date.date()} de ${getMonthNameFromDate(selectedDate.date, 'long', getLanguageCodeByLanguage(language))}`}
                </Text>
                <Icon style={styles.showCalendarIcon} name="calendar" />
              </Pressable>
            )}
            <FlatList
              horizontal
              keyExtractor={(item) => item.date.toString()}
              showsHorizontalScrollIndicator={!!isWeb}
              data={dates}
              renderItem={renderDayItem}
              getItemLayout={(date, index) => ({
                length: DAY_ITEM_WIDTH,
                offset: DAY_ITEM_WIDTH * index,
                index
              })}
              initialScrollIndex={29}
              style={styles.daysContainer}
            />
          </View>
        ) : (
          <View style={styles.weekContainer}>
            {homeMode ? (
              <Pressable style={styles.dateNameContainer} onPress={blockHeader ? undefined : switchCalendarDay}>
                <Text style={styles.dateNameText}>
                  {`${capitalize(getMonthNameFromDate(weekEvents ? dayjs(weekEvents['1'].date) : selectedDate.date, 'long', getLanguageCodeByLanguage(language)))} ${weekEvents ? dayjs(weekEvents['1'].date).year() : selectedDate.date.year()}`}
                </Text>
                <Icon style={styles.showCalendarIcon} name="calendar" />
              </Pressable>
            ) : (
              <Text style={styles.dateNameText}>
                {`${capitalize(getMonthNameFromDate(weekEvents ? dayjs(weekEvents['1'].date) : selectedDate.date, 'long', getLanguageCodeByLanguage(language)))} ${weekEvents ? dayjs(weekEvents['1'].date).year() : selectedDate.date.year()}`}
              </Text>
            )}
            <View>
              {weekEvents
                && (
                  <Week
                    goBackWeek={onGoBackWeek}
                    goNextWeek={onGoNextWeek}
                    parentWidth={scrollViewWidth}
                    week={weekEvents}
                    blocked={blockHeader}
                    onPressDay={homeMode ? onPressDayInWeek : undefined}
                    language={language}
                  />
                )}
            </View>
          </View>
        )}
      </View>
    </View>
  );

  const calendarSwitchAndReload = () => (
    <View style={styles.switchAndReloadContainer}>
      <View style={styles.switchContainer}>
        <Mode dayMode={dayMode} onChange={switchDayMode} />
      </View>
      <View style={styles.reloadContainer}>
        <Button
          onPress={() => dispatch(globalSetMustResetCalendar(true))}
          disabled={loading}
          type="clear"
          icon={(
            <Icon
              style={styles.refreshIcon}
              name="refresh-cw"
            />
          )}
        />
      </View>
    </View>
  );

  const calendarDayOrWeek = () => {
    if (!loading && dayMode && dayEvents) {
      return (
        <View style={styles.eventsContainer}>
          {dayEvents.map((event, index) => (
            <Event
              key={index}
              event={event}
              dayMode={dayMode}
              homeMode={homeMode}
              parentWidth={scrollViewWidth}
              eventIndex={event.overlapIndex}
            />
          ))}
        </View>
      );
    }
    if (!loading && !dayMode && weekEvents) {
      return (
        <View style={{ marginLeft: 45 }}>
          {
            Object.keys(weekEvents).map((dayKey) => (
              weekEvents[dayKey].events.map((event, index) => (
                <Event
                  key={`${event.uuid}${index}`}
                  event={event}
                  dayMode={dayMode}
                  index={Number(dayKey) - 1}
                  parentWidth={scrollViewWidth}
                  homeMode={homeMode}
                  eventIndex={event.overlapIndex}
                />
              ))
            ))
          }
        </View>
      );
    }
    return null;
  };

  const isHourFree = (dayKey, hour) => {
    let isFree = true;
    let from = 0;
    let to = 0;
    const freeHour = hour * 100;
    for (const events of weekEvents![dayKey].events) {
      from = parseInt(events.range.from.replace(':', ''), 10);
      to = parseInt(events.range.to.replace(':', ''), 10);
      if (freeHour >= from && freeHour < to) {
        isFree = false;
        break;
      }
    }
    return isFree;
  };

  const freeHours = () => {
    if (dayMode) {
      return (
        <View>
          {
            getHours().map((item) => (
              <FreeHour
                key={item.hourStartsNum}
                item={item}
                dayMode={dayMode}
                onPress={homeMode
                  ? () => navigateToEvent(item.hourStartsNum)
                  : () => navigateToEventExternal(item.hourStartsNum)}
                parentWidth={scrollViewWidth}
              />
            ))
          }
        </View>
      );
    }
    return (
      <View style={{ marginLeft: 45 }}>
        {
          Object.keys(weekEvents!).map((dayKey) => (
            getHours().map((item) => (
              <FreeHour
                key={`${dayKey}${item.hourStartsNum}`}
                item={item}
                dayMode={dayMode}
                onPress={homeMode
                  ? () => navigateToEvent(item.hourStartsNum, weekEvents![dayKey].date)
                  : isHourFree(dayKey, item.hourStartsNum) ? () => navigateToEventExternal(item.hourStartsNum, weekEvents![dayKey].date) : null}
                parentWidth={scrollViewWidth}
                index={Number(dayKey) - 1}
              />
            ))
          ))
        }
      </View>
    );
  };

  const calendarBody = () => (
    <View style={styles.hoursContainer}>
      <ScrollView
        ref={scrollViewRef}
        bounces={false}
        showsVerticalScrollIndicator={false}
        onLayout={onScrollViewLayout}
      >
        {freeHours()}

        {/* Events */}
        {calendarDayOrWeek()}

        {/* Current Hour Line */}
        <HourLine dayMode={dayMode} />

        {/* Hours List */}
        <View style={functionStyles.hourContainer(dayMode)}>
          {
            getHours().map((item) => (
              <Hour
                key={item.hourStartsNum}
                item={item}
                dayMode={dayMode}
                onNavigateToEvent={homeMode
                  ? () => navigateToEvent(item.hourStartsNum)
                  : () => navigateToEventExternal(item.hourStartsNum)}
              />
            ))
          }
        </View>
      </ScrollView>
    </View>
  );

  const calendarDayModeModal = () => (
    <Modal
      backdropTransitionOutTiming={0}
      onBackdropPress={() => setCalendarMonthVisible(false)}
      isVisible={calendarMonthVisible}
    >
      <Calendar
        theme={calendarTheme}
        onDayPress={(date) => onChangeDayMonthView(date.dateString)}
        date={selectedDate.date.format('YYYY-MM-DD')}
        renderArrow={(direction) => (direction === 'left'
          ? <Icon style={styles.showCalendarIcon} name="chevron-left" />
          : <Icon style={styles.showCalendarIcon} name="chevron-right" />)}
      />
    </Modal>
  );

  return (
    <View style={styles.container}>
      {!invalidHashLink ? (
        <>
          {calendarHeader()}
          {loading
            ? SwitchAndBodyLoader()
            : (
              <>
                {homeMode && calendarSwitchAndReload()}
                {calendarBody()}
                {calendarDayModeModal()}
              </>
            )}
        </>
      ) : (
        <Text style={styles.failedHashLinkText}>
          {getLabel('agenda_does_not_exist')}
        </Text>
      )}

    </View>
  );
};
