// === AUTH ===

// === AUTH : VALIDATION ===

export const VALIDATION_TOKEN = 'auth/validate';

export const VALIDATION_FIRST_NAME = '/auth/validate/first-name';

export const VALIDATION_LAST_NAME = '/auth/validate/last-name';

export const VALIDATION_BIRTHDATE = '/auth/validate/birthdate';

export const VALIDATION_PHONE = '/auth/validate/phone';

export const VALIDATION_EMAIL = '/auth/validate/email';

export const VALIDATION_PASSWORD = '/auth/validate/password';

// === AUTH : SIGN UP ===

export const USER_SIGNUP = '/auth/sign-up';

// === AUTH : SIGN IN ===

export const USER_SIGNIN = '/auth/sign-in';

export const USER_REFRESH_TOKEN = '/auth/refresh-token';

// === AUTH: OTHER ===

export const USER_MARK_AS_NOT_FIRST_LOGIN = '/auth/mark-as-not-first-login';

// === AUTH : PASSWORD RESET ===

export const PASSWORD_RESET_INIT = '/auth/password-reset-initialize';

export const PASSWORD_RESET_ENABLED = '/auth/password-reset-enabled';

export const PASSWORD_RESET_NEW_PASSWORD = '/auth/password-reset-new-password';

export const PASSWORD_RESET_BLOCK = '/auth/password-reset-blocked';

export const PASSWORD_RESET_RESEND = '/auth/password-resend-code';

// === USER : PROFILE  ===

export const USER_MODIFY_ALLOW_NOTIFICATIONS = '/users/profile/allow-notifications';

export const USER_MODIFY_USER_EMAIL = '/users/profile/email';

export const USER_MODIFY_OPTIONAL_EMAIL = '/users/profile/optional-mail';

export const USER_MODIFY_USER_DATA = '/users/profile/update';

export const USER_MODIFY_USER_PASSWORD_INITIALIZE = '/users/profile/password-initialize';

export const USER_MODIFY_USER_PASSWORD_CONFIRM = '/users/profile/password-confirm';

export const USER_MODIFY_USER_PASSWORD_CHANGE = '/users/profile/password-change';

export const USER_MODIFY_USER_PASSWORD_CONFIRM_CHANGE = '/users/profile/password-confirm-change';

export const USER_ACCOUNT_DELETE_INITIALIZE = '/auth/delete/initialize';

export const USER_ACCOUNT_DELETE_VERIFICATION = '/auth/delete/verification';

export const USER_ACCOUNT_DELETE_CONFIRM = '/auth/delete/confirm';

export const USER_SET_TOKEN = '/users/push-notification-token';

export const USER_ROLE = '/users/info/role';

export const USER_SUPPORT = '/users/support';

// === SCHEDULE : CRUD  ===

export const SCHEDULE_GET_ALL = '/schedules/all';

export const SCHEDULE_CRUD = '/schedules/schedule';

export const SCHEDULE_GET_LINK = '/schedules/schedule/link';

export const SCHEDULE_INFO = '/schedules/info';

// === SCHEDULE : SETTINGS  ===

export const SCHEDULE_SETTINGS_CRUD = '/schedules-settings/settings';

export const SCHEDULE_SETTINGS_HASH = '/schedules-settings/settings/hash';

// === EVENTS : CRUD  ===

export const EVENTS_CRUD = '/events/event';

export const EVENTS_LIGHT_CRUD = '/events/events';

export const EVENTS_LIGHT_BETWEEN_DATES = '/events/between-dates';

export const EVENTS_APPOINTMENTS_STATE = '/events/appointments-state';

export const EVENTS_LIGHT_BETWEEN_DATES_BY_HASHLINK = '/events/between-dates/hash-link';

export const EVENTS_COULD_INVITE = '/events/could-invite';

export const EVENTS_IMPORT = '/events/import';

// === INTERNAL INVITES ===

export const EVENTS_ACCEPT_INTERNAL = '/events/accept';

export const EVENTS_REJECT_INTERNAL = '/events/not-accept';

export const EVENTS_CHANGE_INTERNAL = '/events/appointment';

// === EXTERNAL INVITES ===

export const EVENTS_ACCEPT_REJECT_EXTERNAL = '/events/event/accept/external';

export const EVENTS_BEEING_EXTERNAL_ACCEPT_REJECT_INTERNAL = '/events/event/external-accept-internal';

export const EVENT_GET_BEEING_EXTERNAL = '/events/info';

// === EDIT EVENT INVITES ===

export const EDIT_EVENT_ACCEPT_INVITE = '/events/events/appointment/request/accept';

// === PHOTOS ===

export const PHOTOS_NEW_PROFILE = '/photos/new/profile';

export const PHOTO_GET_PROFILE = '/photos/profile';

// === FIREBASE ===

export const FIREBASE_SHORT_LINK = 'https://firebasedynamiclinks.googleapis.com/v1/shortLinks';

// === LANGUAGE ===

export const LANGUAGE_CODES = '/languages/codes';

export const LANGUAGE_KEYS = '/languages/idiom';

// === SUBSCRIPTIONS ===

export const BILLING_INFO = '/subscription/billing/info';

// === STRIPE ===

export const STRIPE_INITIALIZE = '/stripe/initialize';

export const STRIPE_CANCEL = '/stripe/cancel';

export const STRIPE_PLAN_INFO = '/stripe/plan/info';

// === VERIFICATION ACCOUNT ===

export const VERIFY_ACCOUNT = '/auth/verification-code';

export const RESEND_VERIFY_ACCOUNT = '/auth/resend-verification-email';

// === INVITES ===

export const GET_PENDING_INVITES = '/events/appointments/request';

export const GET_INVITE_DETAILS = '/events/appointments/info';
