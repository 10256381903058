import React, { useEffect, useState } from 'react';
import {
  View, Text, useWindowDimensions
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { ScrollView } from 'react-native-gesture-handler';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Feather';
import { showToastError } from '../../utils/toast';
import { globalSetMustResetInvites, selectGlobalAppMustResetInvites } from '../../redux/global';
import styles, { functionStyles } from './styles';
import Routes from '../../navigation/Routes';
import { selectUserInfo } from '../../redux/user';
import SingleNotification from '../../components/SingleNotification';
import { getPendingRequestsLight } from '../../services/events';
import { PendingRequestsLight } from '../../services/events/interface';
import { isWeb } from '../../utils';
import { Colors } from '../../configs/enums';
import { selectLanguageKeys } from '../../redux/language';

const NotificationsLoader = (width: number) => (
  <View>
    <ContentLoader
      speed={2}
      width={isWeb ? 600 : width}
      height={700}
      viewBox={isWeb ? '0 00 600 700' : `0 00 ${width} 700`}
      backgroundColor={Colors.BACKGROUND_LOADER_COLOR}
      foregroundColor={Colors.FOREGROUND_LOADER_COLOR}
    >
      <Rect x="0" y="0" rx="8" ry="8" width="90%" height="80" />
      <Rect x="0" y="100" rx="8" ry="8" width="90%" height="80" />
      <Rect x="0" y="200" rx="8" ry="8" width="90%" height="80" />
      <Rect x="0" y="300" rx="8" ry="8" width="90%" height="80" />
      <Rect x="0" y="400" rx="8" ry="8" width="90%" height="80" />
      <Rect x="0" y="500" rx="8" ry="8" width="90%" height="80" />
      <Rect x="0" y="600" rx="8" ry="8" width="90%" height="80" />
      <Rect x="0" y="700" rx="8" ry="8" width="90%" height="80" />
    </ContentLoader>
  </View>
);

const fetchPendingEventRequests = async (
  jwt: string,
  setPendingEvents: any,
  setIsLoading: any,
  getLabel: (key: string) => any
) => {
  setIsLoading(true);
  const pendingEventsResponse = await getPendingRequestsLight(jwt);

  if (pendingEventsResponse.response) {
    setPendingEvents(pendingEventsResponse.response);
  } else {
    showToastError(getLabel('error'), getLabel(pendingEventsResponse.errorMessage!));
  }

  setIsLoading(false);
};

export default (): React.ReactElement => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const getLabel = useSelector(selectLanguageKeys);
  const userData = useSelector(selectUserInfo);

  const mustResetInvites = useSelector(selectGlobalAppMustResetInvites);

  const [pendingEvents, setPendingEvents] = useState<PendingRequestsLight[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (mustResetInvites) {
      (async () => {
        await fetchPendingEventRequests(
          userData.jwt,
          setPendingEvents,
          setIsLoading,
          getLabel
        );
      })();
      dispatch(globalSetMustResetInvites(false));
    }
  }, [mustResetInvites]);

  useEffect(() => {
    (async () => {
      await fetchPendingEventRequests(
        userData.jwt,
        setPendingEvents,
        setIsLoading,
        getLabel
      );
    })();
  }, []);

  const onPressDetail = (pendingEvent: PendingRequestsLight) => {
    navigation.navigate(Routes.DetailNotification, { pendingEvent });
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View>
          <View style={styles.textContainer}>
            <Text style={styles.text}>{getLabel('notifications_title')}</Text>
            <Button
              onPress={() => fetchPendingEventRequests(
                userData.jwt,
                setPendingEvents,
                setIsLoading,
                getLabel
              )}
              disabled={isLoading}
              type="clear"
              icon={(
                <Icon
                  style={styles.refreshIcon}
                  name="refresh-cw"
                />
              )}
            />
          </View>
        </View>
        <View style={styles.secondContainer}>
          {isLoading ? (
            NotificationsLoader(windowDimensions.width)
          ) : (
            <View>
              {pendingEvents?.length > 0
                ? (
                  <ScrollView bounces={false}>
                    {pendingEvents?.map((pendingEvent) => (
                      <SingleNotification
                        key={pendingEvent.uuid}
                        pendingEvent={pendingEvent}
                        onPress={() => onPressDetail(pendingEvent)}
                      />
                    ))}
                  </ScrollView>
                ) : (
                  <Text>{getLabel('notifications_empty')}</Text>
                )}
            </View>
          )}
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
