import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const RegisterStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  logoContainer: {
    paddingTop: 15,
    paddingBottom: 20
  },
  middleContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  controllersContainer: {
    justifyContent: 'space-around'
  },
  thirdContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 20
  },
  bottomContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10
  },
  textContainer: {
    width: '100%',
    alignItems: 'flex-start'
  },
  backgroundPic: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  logo: {
    fontSize: 30,
    fontFamily: Fonts.MANROPE_EXTRABOLD
  },
  icon: {
    fontSize: 32
  },
  title: {
    fontSize: 30,
    fontFamily: Fonts.MANROPE_BOLD
  },
  text: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_REGULAR,
    paddingBottom: 15
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    height: 55
  },
  valid: {
    borderWidth: 1
  },
  invalid: {
    borderColor: Colors.ERROR_COLOR
  },
  buttonContainer: {
    width: '100%'
  },
  registerButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 55
  },
  registerTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  errorText: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 10,
    alignSelf: 'flex-start',
    marginTop: 8
  },
  subInputContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  hideIcon: {
    fontSize: 18
  },
  buttonHideIcon: {
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingRight: 15
  },
  checkBoxContainer: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    paddingVertical: 10
  },
  checkBoxTextStyle: {
    textDecorationLine: 'underline'
  },
  modalContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 15,
    flex: 0.8
  },
  termsConditionsTitle: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    marginBottom: 20
  },
  termsConditionText: {
    fontSize: 16,
    fontFamily: Fonts.MANROPE_LIGTH,
    alignContent: 'flex-start'
  },
  containerPhoneInput: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1
  },
  phoneInput: {
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderLeftWidth: 1,
    borderColor: Colors.INPUT_BORDER_COLOR,
    marginRight: 10
  },
  phoneText: {
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  flagPicker: {
    width: '20%'
  },
  webPhone: {
    marginTop: 10,
    width: '100%'
  },
  IosPhone: {
    marginTop: 20,
    width: '100%'
  },
  generalInformation: {
    fontSize: 16,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  generalInformationDetails: {
    fontSize: 12,
    marginTop: 5,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  passwordInformationTitle: {
    fontSize: 16,
    marginTop: 10,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  passwordInformationDetails: {
    fontSize: 12,
    marginTop: 5,
    fontFamily: Fonts.MANROPE_REGULAR
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  })
};

export default RegisterStyles;
