import Toast, { ToastPosition } from 'react-native-toast-message';

interface IOptions {
  type: string;
  text1: string;
  position?: ToastPosition;
  text2?: string;
  visibilityTime?: number;
  autoHide?: boolean;
  topOffset?: number;
  bottomOffset?: number;
  onShow?: () => void;
  onHide?: () => void;
  onPress?: () => void;
}

const defaultOptions = {
  visibilityTime: 8000
};

export const showToast = (options: IOptions) => {
  Toast.show({
    ...defaultOptions,
    ...options
  });
};

export const showToastError = (
  message: string,
  message2: string
) => {
  showToast({
    type: 'error',
    text1: message === '' ? 'Error' : message,
    text2: message2 === '' ? undefined : message2
  });
};

export const showToastSuccess = (
  message: string = '',
  message2: string = ''
) => {
  showToast({
    type: 'success',
    text1: message,
    text2: message2
  });
};

export const showToastInfo = (
  message: string = '',
  message2: string = ''
) => {
  showToast({
    type: 'info',
    text1: message,
    text2: message2
  });
};
