export interface ISchedule {
  uuid: string | null;
  name: string;
}

export interface IScheduleState {
  schedules: ISchedule[];
  selectedSchedule: ISchedule;
}

export const DEFAULT_SCHEDULE = {
  uuid: null,
  name: 'Mi Calendario'
};

export const INITIAL_STATE: IScheduleState = {
  schedules: [{ uuid: null, name: 'Mi Calendario' }],
  selectedSchedule: DEFAULT_SCHEDULE
};
