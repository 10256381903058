import React, { useCallback, useEffect, useState } from 'react';
import {
  Pressable, ScrollView, Text, TextInput, TouchableOpacity, useWindowDimensions, View
} from 'react-native';
import Icon from 'react-native-vector-icons/Feather';
import Modal from 'react-native-modal';
import dayjs from 'dayjs';
import { isWeb } from '../../utils';
import { functionStyles, NativePickerStyles as styles } from './styles';
import { TIME_DATA } from '../../utils/constants';
import { MobileTimePicker } from '../MobileTimePicker';
import { WebPicker } from '../WebPicker';
import { MobilePicker } from '../MobilePicker';

interface Props {
  value: string;
  setValue: any;
  inputStyle: any;
  mode: 'date' | 'time';
}

export default ({
  value,
  setValue,
  inputStyle,
  mode
}: Props): React.ReactElement => {
  const [timeModalVisible, setTimeModalVisible] = useState(false);
  const [dateModalVisible, setDateModalVisible] = useState(false);

  const [initialValue, setInitialValue] = useState(dayjs(`${dayjs().format('YYYY-MM-DD')} ${value}`).subtract(3, 'hour').toDate());

  const windowDimensions = useWindowDimensions();

  const scrollViewRef = React.createRef<ScrollView>();

  useEffect(() => {
    setInitialValue(dayjs(`${dayjs().format('YYYY-MM-DD')} ${value}`).subtract(3, 'hour').toDate());
  }, [value]);

  const renderTimePicker = useCallback(
    () => {
      if (isWeb) {
        return (
          <Modal
            isVisible={timeModalVisible}
            backdropTransitionOutTiming={0}
            deviceHeight={windowDimensions.height}
            deviceWidth={windowDimensions.width}
            backdropOpacity={0}
            onBackdropPress={() => setTimeModalVisible(!timeModalVisible)}
            animationIn="fadeIn"
            animationOut="fadeOut"
          >
            <View style={functionStyles.innerModalContainer(80)}>
              <ScrollView
                ref={scrollViewRef}
                onLayout={() => scrollViewRef.current?.scrollTo(545)}
              >
                {TIME_DATA.map((time: string) => (
                  <TouchableOpacity key={time} onPress={() => setValue(time)}><Text>{time}</Text></TouchableOpacity>
                ))}
              </ScrollView>
            </View>
          </Modal>
        );
      }
      return (
        <MobileTimePicker
          setBeginTime={setValue}
          isVisible={timeModalVisible}
          setIsVisible={setTimeModalVisible}
          initialHour={initialValue}
        />
      );
    },
    [initialValue, timeModalVisible]
  );

  const renderDatePicker = () => {
    if (isWeb) {
      return (
        <WebPicker setDate={setValue} isVisible={dateModalVisible} setIsVisible={setDateModalVisible} limitDateYears={0} />
      );
    }
    return <MobilePicker setDate={setValue} isVisible={dateModalVisible} setIsVisible={setDateModalVisible} limitDateYears={0} />;
  };

  return (
    <View style={styles.inputContainer}>
      <View style={styles.subInputContainer}>
        {isWeb ? (
          <TextInput
            style={inputStyle}
            onChangeText={(text) => setValue(text)}
            value={value}
            maxLength={5}
            textAlign="center"
          />
        ) : (
          <Pressable
            style={inputStyle}
            onPress={mode === 'date' ? () => setDateModalVisible(!dateModalVisible) : () => setTimeModalVisible(!timeModalVisible)}
          >
            <Text>{value}</Text>
          </Pressable>
        )}
        {!dateModalVisible ? (
          <Icon
            style={styles.dropdownIcon}
            name="chevron-down"
            onPress={mode === 'date' ? () => setDateModalVisible(!dateModalVisible) : () => setTimeModalVisible(!timeModalVisible)}
          />
        ) : (
          <Icon
            style={styles.dropdownIcon}
            name="chevron-up"
            onPress={mode === 'date' ? () => setDateModalVisible(!dateModalVisible) : () => setTimeModalVisible(!timeModalVisible)}
          />
        )}
      </View>
      {mode === 'date' ? (
        renderDatePicker()
      ) : (
        renderTimePicker()
      )}
    </View>
  );
};
