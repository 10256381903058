import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const SupportStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  titleLabel: {
    fontSize: 20,
    marginTop: 20
  },
  labelText: {
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 14,
    marginTop: 20
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    backgroundColor: Colors.BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  modalButtonContainer: {
    width: '100%',
    marginTop: 20
  },
  addEventButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50,
    marginBottom: 20
  },
  addEventTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  }),
  innerModalContainer: (marginTop: number, height: number): TextStyle & ViewStyle => ({
    borderRadius: 5,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 4,
    height,
    width: 100,
    alignSelf: 'flex-end',
    marginRight: 50,
    marginTop
  })
};

export default SupportStyles;
