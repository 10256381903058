import * as Localization from 'expo-localization';

import { API_VALIDATE_KEY } from '@env';
import {
  VALIDATION_FIRST_NAME,
  VALIDATION_LAST_NAME,
  VALIDATION_BIRTHDATE,
  VALIDATION_PHONE,
  VALIDATION_EMAIL,
  VALIDATION_PASSWORD,
  USER_SIGNUP,
  PASSWORD_RESET_INIT,
  PASSWORD_RESET_ENABLED,
  PASSWORD_RESET_NEW_PASSWORD,
  USER_SIGNIN,
  VALIDATION_TOKEN,
  USER_REFRESH_TOKEN,
  PASSWORD_RESET_RESEND,
  VERIFY_ACCOUNT,
  RESEND_VERIFY_ACCOUNT
} from '../api';
import {
  AuthSignUpRequest,
  AuthValidateBirthDateRequest,
  AuthValidateEmailRequest,
  AuthValidateFirstNameRequest,
  AuthValidateLastNameRequest,
  AuthValidatePasswordRequest,
  AuthValidatePhoneRequest,
  AuthPassResetInit,
  AuthPassResetEnabled,
  AuthPassResetNewPass,
  AuthValidateSignUpRequest,
  AuthValidateTokenResponse,
  AuthRefreshTokenResponse,
  AuthSignIn,
  UserData,
  AuthPassResetResendCode,
  ValidatorResponse
} from './interfaces';
import { FetcherResponse } from '../FetcherResponse';
import { API_METHOD, fetcher } from '..';

export const authValidateFirstName = async (name: string):
  Promise<FetcherResponse<Record<any, never>>> => {
  const request: AuthValidateFirstNameRequest = {
    name,
    key: API_VALIDATE_KEY
  };
  const response = await fetcher<Record<any, never>>(API_METHOD.POST, VALIDATION_FIRST_NAME, request);
  return response;
};

export const authValidateLastName = async (name: string):
  Promise<FetcherResponse<Record<any, never>>> => {
  const request: AuthValidateLastNameRequest = {
    name,
    key: API_VALIDATE_KEY
  };
  const response = await fetcher<Record<any, never>>(API_METHOD.POST, VALIDATION_LAST_NAME, request);
  return response;
};

export const authValidateBirthDate = async (birthdate: string):
  Promise<FetcherResponse<Record<any, never>>> => {
  const request: AuthValidateBirthDateRequest = {
    birthdate,
    key: API_VALIDATE_KEY
  };
  const response = await fetcher<Record<any, never>>(API_METHOD.POST, VALIDATION_BIRTHDATE, request);
  return response;
};

export const authValidateEmail = async (email: string):
  Promise<FetcherResponse<Record<any, never>>> => {
  const request: AuthValidateEmailRequest = {
    email,
    key: API_VALIDATE_KEY
  };
  const response = await fetcher<Record<any, never>>(API_METHOD.POST, VALIDATION_EMAIL, request);
  return response;
};

export const authValidatePassword = async (password: string):
  Promise<FetcherResponse<Record<any, never>>> => {
  const request: AuthValidatePasswordRequest = {
    password,
    key: API_VALIDATE_KEY
  };
  const response = await fetcher<Record<any, never>>(API_METHOD.POST, VALIDATION_PASSWORD, request);
  return response;
};

export const authValidatePhone = async (phone: string):
  Promise<FetcherResponse<Record<any, never>>> => {
  const request: AuthValidatePhoneRequest = {
    phone,
    key: API_VALIDATE_KEY
  };
  const response = await fetcher<Record<any, never>>(API_METHOD.POST, VALIDATION_PHONE, request);
  return response;
};

export const areRegisterInputsValid = async (formInputs: AuthSignUpRequest): Promise<ValidatorResponse> => {
  try {
    let promises = null;
    if (formInputs.optionalEmail === undefined || formInputs.optionalEmail === '' || formInputs.optionalEmail === null) {
      promises = [
        authValidateFirstName(formInputs.firstName),
        authValidateLastName(formInputs.lastName),
        authValidateEmail(formInputs.email),
        authValidatePassword(formInputs.password)
      ];
    } else {
      promises = [
        authValidateFirstName(formInputs.firstName),
        authValidateLastName(formInputs.lastName),
        authValidateEmail(formInputs.email),
        authValidateEmail(formInputs.optionalEmail),
        authValidatePassword(formInputs.password)
      ];
    }

    const response = await Promise.all(promises);

    const errorCodes = response.filter((response: any) => response.error);

    if (errorCodes.length > 0) {
      return {
        error: true,
        errorCodes
      };
    }
    return {
      error: false
    };
  } catch (error: any) {
    return {
      error: true,
      errorCodes: [error]
    };
  }
};

export const authSignUp = async (request: AuthSignUpRequest): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, USER_SIGNUP, request, {
      'X-Time-Zone': Localization.timezone
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const authPasswordResetInit = async (request: AuthPassResetInit): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, PASSWORD_RESET_INIT, request);
    return response;
  } catch (error: any) {
    return error;
  }
};

export const authPasswordResetResendCode = async (request: AuthPassResetResendCode): Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, PASSWORD_RESET_RESEND, request);
    return response;
  } catch (error: any) {
    return error;
  }
};

export const authPasswordResetEnabled = async (request: AuthPassResetEnabled) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, PASSWORD_RESET_ENABLED, request);
    return response;
  } catch (error: any) {
    return error;
  }
};

export const authPasswordResetNewPassword = async (request: AuthPassResetNewPass) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, PASSWORD_RESET_NEW_PASSWORD, request);
    return response;
  } catch (error: any) {
    return error;
  }
};

export const areLoginInputsValid = async (formInputs: AuthValidateSignUpRequest): Promise<boolean> => {
  try {
    const promises = [
      authValidateEmail(formInputs.email),
      authValidatePassword(formInputs.password)
    ];

    return (await Promise.all(promises)).filter((response) => !response.error).length === promises.length;
  } catch (error: unknown) {
    return false;
  }
};

export const authUserSignIn = async (request: AuthSignIn) : Promise<FetcherResponse<UserData>> => {
  try {
    const response = await fetcher<UserData>(API_METHOD.POST, USER_SIGNIN, request);
    return response;
  } catch (error: any) {
    return error;
  }
};

export const authValidateToken = async (jwt: string) : Promise<FetcherResponse<AuthValidateTokenResponse>> => {
  const response = await fetcher<AuthValidateTokenResponse>(API_METHOD.POST, VALIDATION_TOKEN, {}, {
    Authorization: `Bearer ${jwt}`
  });
  return response;
};

export const authRefreshToken = async (jwt: string) : Promise<FetcherResponse<AuthRefreshTokenResponse>> => {
  const response = await fetcher<AuthRefreshTokenResponse>(API_METHOD.POST, USER_REFRESH_TOKEN, {}, {
    Authorization: `Bearer ${jwt}`
  });
  return response;
};

export const verifyAccount = async (code: string, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, VERIFY_ACCOUNT, { code }, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const resendVerificationAccountCode = async (jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, RESEND_VERIFY_ACCOUNT, undefined, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};
