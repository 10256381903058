/* eslint-disable global-require */
import React, {
  useEffect,
  useState
} from 'react';
import {
  NavigationContainer
} from '@react-navigation/native';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import * as Font from 'expo-font';
import dayjs from 'dayjs';
import * as SplashScreen from 'expo-splash-screen';
import { CALENDAR_STORAGE_KEY } from '@env';
import {
  selectUserLogged,
  userSetLogged
} from '../redux/user';
import {
  isIos,
  loadAsyncStorageSchedules,
  checkAuthentication,
  storeAsyncStorageData,
  isPremiumAccount,
  isObjectEmpty,
  checkUserLanguage,
  checkBilling,
  updateSubscription
} from '../utils';
import { getNavigators } from './AppNavigators';
import {
  scheduleSetData,
  scheduleSetSelectedSchedule
} from '../redux/schedules';
import { scheduleGetAll } from '../services/schedule';
import {
  DEFAULT_SCHEDULE,
  ISchedule
} from '../redux/schedules/state';
import { checkUserPhoto } from '../utils/extras';
import { globalSetLanguagesAvailable } from '../redux/global';
import { lenguagesGetCodes } from '../services/language';

const loadAssets = async () => {
  await Font.loadAsync({
    ManropeLight: require('../../assets/Fonts/Manrope-Light.ttf'),
    ManropeRegular: require('../../assets/Fonts/Manrope-Regular.ttf'),
    ManropeSemiBold: require('../../assets/Fonts/Manrope-SemiBold.ttf'),
    ManropeBold: require('../../assets/Fonts/Manrope-Bold.ttf'),
    ManropeExtraBold: require('../../assets/Fonts/Manrope-ExtraBold.ttf'),
    UbuntuMedium: require('../../assets/Fonts/Ubuntu-Medium.ttf')
  });
};

const loadOtherLibs = () => {
  require('dayjs/locale/es');
  require('dayjs/locale/pt');

  if (!isIos) {
    require('intl');
    require('intl/locale-data/jsonp/en-IN');
    require('intl/locale-data/jsonp/es-AR');
    require('intl/locale-data/jsonp/pt-PT');
  }

  dayjs.locale('es');
};

export default () => {
  const dispatch = useDispatch();

  const userLogged = useSelector(selectUserLogged);

  const [isLoadingApp, setIsLoadingApp] = useState(true);

  const linking: any = {
    prefixes: ['https://www.2meetup.app'],
    config: {
      screens: {
        Landing: 'Landing',
        Login: 'Login',
        Register: 'Register',
        RecoverPassword: 'RecoverPassword',
        Main: {
          screens: {
            HomeStack: {
              screens: {
                Home: 'Home'
              }
            },
            AgendaStack: {
              screens: {
                Schedules: 'Schedules'
              }
            },
            ProfileStack: {
              screens: {
                Profile: 'Profile'
              }
            }
          }
        },
        InvitedCalendar: {
          path: 'agenda/:hashlink',
          parse: {
            hashlink: (hashlink: string) => `${hashlink}`
          }
        },
        EventDetailsExternal: {
          path: 'externalEvent/:occasionUuid/:email'
        },
        CheckoutFinished: {
          path: 'checkoutFinished/:result'
        },
        Support: 'Support'
      }
    }
  };

  const onStopShowingSplash = async () => {
    if (!isLoadingApp) {
      await SplashScreen.hideAsync();
    }
  };

  useEffect(() => {
    const loadResources = async () => {
      try {
        await loadAssets();

        const userLanguagesResponse = await lenguagesGetCodes();

        if (userLanguagesResponse.response) {
          dispatch(globalSetLanguagesAvailable(userLanguagesResponse.response.codes));
        } else {
          dispatch(globalSetLanguagesAvailable(['ES']));
        }

        const userData = await checkAuthentication(dispatch);

        if (userData) {
          await updateSubscription(dispatch, userData);

          await checkUserPhoto(dispatch, userData.jwt);

          await checkUserLanguage(dispatch, userData.language);

          await checkBilling(dispatch);

          dispatch(userSetLogged(true));
        } else {
          await checkUserLanguage(dispatch, 'ES');
        }

        loadOtherLibs();
      } catch (e: any) {
        console.warn(e);
      } finally {
        setIsLoadingApp(false);
      }
    };

    loadResources();
  }, []);

  if (isLoadingApp) {
    return null;
  }

  return (
    <NavigationContainer linking={linking} onReady={onStopShowingSplash}>
      {getNavigators(userLogged)}
    </NavigationContainer>
  );
};
