import React, {
  useEffect,
  useState
} from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  Image
} from 'react-native';
import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList
} from '@react-navigation/drawer';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import Icon from 'react-native-vector-icons/Feather';
import {
  StackActions,
  useNavigation
} from '@react-navigation/native';
import { selectProfilePhoto } from '../../redux/global';
import Routes from '../../navigation/Routes';
import { logoutUser } from '../../utils';
import styles from './styles';
import { selectUserInfo } from '../../redux/user';
import { selectLanguageKeys } from '../../redux/language';

export const CustomDrawer = (props: DrawerContentComponentProps) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const userInfo = useSelector(selectUserInfo);
  const getLabel = useSelector(selectLanguageKeys);
  const userGlobalProfilePhoto = useSelector(selectProfilePhoto);

  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  const closeUserSession = async () => {
    await logoutUser(dispatch);
    navigation.dispatch(StackActions.replace(Routes.Landing));
  };

  function getProfilePhoto() {
    if (userGlobalProfilePhoto) {
      setProfilePicture(userGlobalProfilePhoto);
    }
  }

  useEffect(() => {
    getProfilePhoto();
  }, [userGlobalProfilePhoto]);

  return (
    <View style={styles.drawer}>
      <View style={styles.userContainer}>
        <Image
          source={profilePicture
            ? { uri: profilePicture }
            : require('../../../assets/Images/Guest-Picture.png')}
          style={styles.avatarImage}
        />
        <Text style={styles.textGreeting}>
          {getLabel('component_customDrawer_greetings')}
          {userInfo.firstName || 'Invitado'}
        </Text>
      </View>
      <View style={styles.screensContainer}>
        <DrawerContentScrollView {...props} bounces={false}>
          <DrawerItemList {...props} />
        </DrawerContentScrollView>
      </View>
      <View style={styles.bottomContainer}>
        <TouchableOpacity onPress={closeUserSession}>
          <View style={styles.bottomItemsContainer}>
            <Icon
              style={styles.icon}
              name="log-out"
            />
            <Text style={styles.closeSession}>
              {getLabel('component_customDrawer_close_session')}
            </Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};
