/* eslint-disable react/require-default-props */
import React from 'react';
import { TouchableOpacity } from 'react-native';
import { calculateHourHeight, calculateHourStart, getOneHourMore } from '../../../../utils';
import { HoursCalendar } from '../../interfaces';
import { functionStyles } from './styles';

interface Props {
  item: HoursCalendar;
  dayMode: boolean;
  onPress: any;
  parentWidth: number;
  index?: number;
}

export default ({
  item,
  dayMode,
  onPress,
  parentWidth,
  index
}: Props): React.ReactElement => (
  <TouchableOpacity
    delayPressIn={50}
    onPress={onPress}
    key={item.hourStartsStr}
    style={functionStyles.eventContainer(
      calculateHourStart(item.hourStartsStr, dayMode),
      calculateHourHeight(item.hourStartsStr, getOneHourMore(item.hourStartsStr), dayMode),
      dayMode,
      index,
      parentWidth
    )}
  />
);
