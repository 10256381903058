import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { color } from 'react-native-reanimated';
import { MAX_SPLASH_WIDTH } from '../../../../configs/constants';
import { Colors, Fonts, STATUS } from '../../../../configs/enums';

const ViewEventStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  backgroundPic: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  headerContainer: {
    flexDirection: 'column'
  },
  icon: {
    fontSize: 32,
    paddingTop: 15
  },
  titleContainer: {
    alignSelf: 'flex-start'
  },
  mainTitle: {
    fontSize: 25,
    fontFamily: Fonts.MANROPE_BOLD
  },
  title: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    alignSelf: 'center'
  },
  controllersContainer: {},
  middleContainer: {
    justifyContent: 'center',
    paddingTop: 15
  },
  iconEdit: {
    fontSize: 20
  },

  bottomContainer: {
    alignItems: 'center',
    alignContent: 'space-around',
    paddingTop: 25
  },

  subTitle: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_BOLD,
    flex: 1
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_REGULAR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1,
    color: Colors.SECONDARY_TEXT_COLOR
  },
  valid: {
    borderWidth: 1
  },
  invalid: {
    borderColor: Colors.ERROR_COLOR
  },
  allDayLong: {
    borderColor: Colors.INPUT_BORDER_COLOR,
    opacity: 0.3
  },
  timeImput: {
    width: '100%',
    marginTop: 10
  },
  timeImputIOS: {
    width: '100%',
    marginTop: 20
  },
  buttonReminderContainer: {
    width: '80%',
    alignSelf: 'center'
  },
  closeReminderButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    marginBottom: 25
  },
  closeReminderTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  buttonContainer: {
    width: '100%',
    marginBottom: 15
  },
  addEventButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  addEventTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  cancelButton: {
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderColor: Colors.SECONDARY_BORDER_COLOR,
    borderWidth: 1
  },
  cancelButtonText: {
    color: Colors.SECONDARY_TEXT_COLOR
  },
  addParticipantsButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    borderRadius: 10,
    height: 50,
    marginTop: 20

  },
  addParticipantsTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    paddingVertical: 6
  },
  errorText: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 10,
    alignSelf: 'flex-start'
  },
  labelText: {
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 12,
    alignSelf: 'flex-start'
  },
  labelSubTitleText: {
    fontSize: 14,
    marginBottom: 8,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  subInputContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR,
    width: '100%'
  },
  allowNotificationsContainer: {
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 6,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginTop: 20
  },
  textAllowNotifications: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_REGULAR,
    alignSelf: 'center',
    flex: 1
  },
  modalContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1
  },
  modalDeleteContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 40,
    paddingHorizontal: 50,
    marginHorizontal: 20
  },
  modalTitle: {
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: 22
  },
  modalText: {
    alignSelf: 'flex-start',
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 15
  },
  pickerContainer: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  pickerTimeContainer: {
    flexDirection: 'column',
    paddingVertical: 5,
    paddingHorizontal: 50
  },
  modalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    alignItems: 'center',
    marginHorizontal: 10
  },
  modalButtonContainer: {
    width: '100%',
    paddingHorizontal: 50,
    marginBottom: 30
  },
  modalIcon: {
    fontSize: 25
  },
  dropdownIcon: {
    fontSize: 18,
    opacity: 0.33,
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingRight: 15
  },
  textContainer: {
    flexDirection: 'row'
  },
  range: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    opacity: 0.3,
    flex: 3
  },
  errorMessage: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 14,
    alignSelf: 'center',
    marginVertical: 8
  },
  picker: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    paddingVertical: 6,
    paddingHorizontal: 15,
    textAlign: 'left',
    borderRadius: 12,
    width: '100%',
    marginTop: 10
  },
  mobilePickercontainer: {
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 12
  },
  mobileTextPickerIOS: {
    paddingLeft: 20,
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  androidPickercontainer: {
    width: '100%',
    borderRadius: 10,
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1
  },
  addPeopleContainer: {
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderWidth: 1,
    marginTop: 20,
    height: 50
  },
  addPeopleNoContainer: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    borderColor: Colors.PRIMARY_BUTTON_COLOR
  },
  addPeopleYesContainer: {
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderColor: Colors.INPUT_BORDER_COLOR
  },
  addPeopleText: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25
  },
  addPeopleYesText: {
    color: Colors.SECONDARY_TEXT_COLOR
  },
  addPeopleNoText: {
    color: Colors.PRIMARY_TEXT_COLOR
  },
  subInputAllowAllDay: {
    flexDirection: 'row',
    marginTop: 10
  },
  deleteButton: {
    backgroundColor: Colors.DELETE_BUTTON_COLOR
  },
  modalInfoContainer: {
    flexDirection: 'row',
    paddingVertical: 5,
    alignItems: 'center'
  },
  modalParticipantsContainer: {
    flexDirection: 'column',
    flex: 1
  },
  modalParticipanTitle: {
    fontSize: 22,
    textAlign: 'center',
    alignSelf: 'flex-start',
    paddingBottom: 15
  },
  modalParticipantName: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  modalParticipantEmail: {
    fontSize: 14,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  aproved: {
    backgroundColor: Colors.NOTIFICATION_APROVED
  },
  reject: {
    backgroundColor: Colors.NOTIFICATION_REJECTED
  },
  pending: {
    backgroundColor: Colors.NOTIFICATION_PENDING
  },
  modalButton: {
    marginTop: 35
  },
  viewCircle: {
    height: 30,
    width: 30,
    borderRadius: 15,
    marginLeft: 5
  },
  participantList: {
    maxHeight: 300
  },
  titleInput: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.INPUT_BORDER_COLOR
  },
  pressableWidth: {
    width: '100%'
  },
  someMargin: {
    marginTop: 10
  },
  descriptionHeight: {
    height: 100
  },
  androidPickerItem: {
    marginLeft: 10
  },
  hoursContainer: {
    flexDirection: 'row',
    marginTop: 10
  },
  hourInfoContainer: {
    width: 150,
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderWidth: 1,
    flexDirection: 'row',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 10,
    marginRight: 10
  },
  subBottomContainer: {
    width: '100%'
  },
  mainButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    width: '100%',
    height: 50
  },
  mainTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  modalAcceptRejectContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginHorizontal: 10
  },
  modalChangeContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 20,
    paddingHorizontal: 20,
    marginHorizontal: 10
  },
  modalAcceptRejectTitle: {
    fontSize: 20,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignSelf: 'center'
  },
  subTitleReject: {
    marginTop: 10,
    fontSize: 16,
    fontFamily: Fonts.MANROPE_REGULAR,
    alignSelf: 'center'
  },
  divisorSpace: {
    marginBottom: 10
  },
  eventCreateErrorModalContainer: {
    paddingHorizontal: 16,
    borderRadius: 15,
    backgroundColor: '#FFE6E6',
    borderWidth: 1
  },
  eventCreateErrorTitleText: {
    marginVertical: 10,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 16,
    textAlign: 'center'
  },
  eventCreateErrorSubTitleText: {
    marginVertical: 10,
    fontFamily: Fonts.MANROPE_REGULAR,
    fontSize: 13,
    textAlign: 'center'
  },
  guestText: {
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 13,
    textAlign: 'center'
  },
  guestsButtonContainer: {
    width: '100%',
    paddingHorizontal: 50,
    marginVertical: 16
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  }),
  innerModalContainer: (marginTop: number, height: number): TextStyle & ViewStyle => ({
    borderRadius: 5,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 4,
    height,
    width: 100,
    alignSelf: 'flex-end',
    marginRight: 50,
    marginTop
  }),
  getStatusColor: (status: STATUS) => {
    switch (status) {
      case STATUS.ACCEPTED:
        return {
          backgroundColor: Colors.NOTIFICATION_APROVED
        };
      case STATUS.NOT_ACCEPTED:
        return {
          backgroundColor: Colors.NOTIFICATION_REJECTED
        };
      case STATUS.PENDING:
        return {
          backgroundColor: Colors.NOTIFICATION_PENDING
        };
      default:
        return {
          backgroundColor: Colors.NOTIFICATION_PENDING
        };
    }
  }
};

export default ViewEventStyles;
