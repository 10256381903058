import React from 'react';
import { Pressable, Text } from 'react-native';
import { useSelector } from 'react-redux';
import { selectLanguageKeys } from '../../../../redux/language';
import { ModeStyles as styles } from './styles';

interface Props {
  dayMode: boolean;
  onChange: any;
}

export default ({ dayMode, onChange }: Props): React.ReactElement => {
  const getLabel = useSelector(selectLanguageKeys);

  return (
    <Pressable style={styles.mainContainer} onPress={onChange}>
      <Text style={styles.text}>
        {dayMode ? getLabel('change_to_week_mode') : getLabel('change_to_day_mode')}
      </Text>
    </Pressable>
  );
};
