import React from 'react';
import { Text, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { STATUS } from '../../../../configs/enums';
import { calculateHourHeight, calculateHourStart } from '../../../../utils';
import { EventLight } from '../../interfaces';
import { EventStyles as styles, functionStyles } from './styles';
import Routes from '../../../../navigation/Routes';

interface Props {
  event: EventLight;
  dayMode: boolean;
  index?: number | undefined;
  parentWidth?: number | undefined;
  homeMode: boolean;
  eventIndex?: number
}

export default ({
  event, dayMode, index, parentWidth, homeMode, eventIndex
}: Props): React.ReactElement => {
  const navigation = useNavigation();

  return (
    <TouchableOpacity
      delayPressIn={50}
      onPress={homeMode ? () => navigation.navigate(Routes.ViewEvent, { params: event }) : () => {}}
      key={event.range.from}
      style={functionStyles.eventContainer(
        calculateHourStart(event.range.from, dayMode),
        calculateHourHeight(event.range.from, event.range.to, dayMode),
        dayMode,
        event.status ?? STATUS.ACCEPTED,
        homeMode,
        index,
        parentWidth,
        event.overlapCount,
        eventIndex
      )}
      disabled={!homeMode}
    >
      <Text style={dayMode ? functionStyles.titleForDay(event.overlapCount) : functionStyles.title(parentWidth!, event.overlapCount)}>{homeMode ? event.title : 'Ocupado'}</Text>
      {homeMode && (
        dayMode && <Text style={dayMode ? styles.description : functionStyles.description(parentWidth!, event.overlapCount)}>{event.description}</Text>
      )}
    </TouchableOpacity>
  );
};
