import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Colors, Fonts } from '../../../../configs/enums';

export const WeekStyles = StyleSheet.create({
  weekContainer: {
    flexDirection: 'row'
  },
  container: {
    borderColor: Colors.PRIMARY_BUTTON_COLOR,
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    width: 35,
    height: 80,
    justifyContent: 'center'
  },
  dayName: {
    color: Colors.SECONDARY_TEXT_COLOR,
    fontSize: 10,
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_REGULAR
  },
  dayNumber: {
    color: Colors.SECONDARY_TEXT_COLOR,
    fontSize: 12,
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  icon: {
    fontSize: 28,
    color: Colors.PRIMARY_TEXT_COLOR
  }
});

export const functionStyles = {
  container: (parentWidth: number): TextStyle & ViewStyle => ({
    borderColor: Colors.PRIMARY_BUTTON_COLOR,
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    width: ((parentWidth! / 7) - 17),
    marginRight: 5,
    height: 80,
    justifyContent: 'center'
  }),
  navContainer: (disabled: boolean): TextStyle & ViewStyle => ({
    backgroundColor: disabled ? Colors.NAV_BUTTON_DISABLED_COLOR : Colors.NAV_BUTTON_COLOR,
    borderColor: disabled ? Colors.NAV_BUTTON_DISABLED_COLOR : Colors.NAV_BUTTON_COLOR,
    borderWidth: 1,
    borderRadius: 10,
    padding: 5,
    width: 40,
    marginRight: 5,
    height: 80,
    justifyContent: 'center'
  }),
  dayName: (parentWidth: number): TextStyle & ViewStyle => ({
    color: Colors.SECONDARY_TEXT_COLOR,
    fontSize: parentWidth < 450 ? 9 : 16,
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_REGULAR
  }),
  dayNumber: (parentWidth: number): TextStyle & ViewStyle => ({
    color: Colors.SECONDARY_TEXT_COLOR,
    fontSize: parentWidth < 400 ? 14 : 18,
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_SEMIBOLD
  })
};
