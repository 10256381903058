import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const PaymentsStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  firstContainer: {
    justifyContent: 'space-between',
    paddingBottom: 20,
    flexDirection: 'row'
  },
  middleContainer: {
    paddingBottom: 20
  },
  bottomContainer: {
    paddingBottom: 20
  },
  textContainer: {
    alignItems: 'flex-start',
    alignSelf: 'center'
  },
  icon: {
    fontSize: 32
  },
  title: {
    fontSize: 30,
    fontFamily: Fonts.MANROPE_BOLD
  },
  text: {
    fontSize: 14,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  buttonContainer: {
    width: '100%',
    marginBottom: 20
  },
  selectPlanButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  infoPlanButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  updateButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    borderRadius: 10
  },
  updateButtonContainer: {
    width: 100,
    height: 50
  },
  selectPlanTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  doItLaterButton: {
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    borderColor: Colors.PRIMARY_BUTTON_COLOR,
    borderWidth: 2
  },
  doItLaterText: {
    color: Colors.SECONDARY_TEXT_COLOR
  },
  cardProContainer: {
    backgroundColor: Colors.BACKGROUND_SECONDARY_COLOR,
    padding: 15,
    borderRadius: 22,
    height: 120,
    borderWidth: 2
  },
  cardStandardContainer: {
    backgroundColor: Colors.BACKGROUND_COLOR,
    padding: 15,
    borderRadius: 22,
    height: 120,
    borderWidth: 2
  },
  cardTitle: {
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: 18,
    color: Colors.PRIMARY_TEXT_COLOR
  },
  cardText: {
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 12,
    color: Colors.PRIMARY_TEXT_COLOR
  },
  cardSeparator: {
    marginBottom: 30
  },
  cardPremiumContainer: {
    padding: 15,
    borderRadius: 22,
    height: 120,
    backgroundColor: Colors.BACKGROUND_TERCIARY_COLOR,
    borderWidth: 2
  },
  cardPremiumTitlePriceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  cardPremiumText: {
    color: Colors.SECONDARY_TEXT_COLOR
  },
  selectedColor: {
    borderColor: 'red'
  },
  modalIcon: {
    fontSize: 25
  },
  webViewContainer: {
    borderRadius: 15,
    borderWidth: 1
  },
  buttonWebViewCancel: {
    marginTop: 20
  },
  modalPlanModalContainer: {
    backgroundColor: Colors.BACKGROUND_COLOR,
    padding: 16,
    borderRadius: 8,
    alignItems: 'center',
    width: '95%',
    alignSelf: 'center'
  },
  modalPlanTitle: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20
  },
  modalPlanSubtitle: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 20
  },
  modalPlanAcceptButton: {
    width: '60%',
    marginBottom: 20
  },
  modalPlanCancelButton: {
    width: '60%',
    marginBottom: 20
  },
  modalPlanAccept: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  modalPlanCanel: {
    backgroundColor: Colors.DELETE_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  updateSubscriptionTitle: {
    fontSize: 16,
    marginBottom: 20
  },
  expiredContainer: {
    backgroundColor: Colors.DELETE_BUTTON_COLOR,
    padding: 15,
    borderRadius: 22,
    flexDirection: 'row',
    marginBottom: 20
  },
  expiredText: {
    color: Colors.PRIMARY_TEXT_COLOR,
    marginHorizontal: 5,
    alignSelf: 'center'
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  })
};

export default PaymentsStyles;
