import { Dispatch } from 'react';
import { globalSetProfilePhoto } from '../redux/global';
import { getPhoto } from '../services/photo';

export const checkUserPhoto = async (dispatch: Dispatch<any>, jwt: string): Promise<boolean> => {
  try {
    const responseImage = await getPhoto(jwt);

    if (responseImage) {
      dispatch(globalSetProfilePhoto(responseImage));
    }
    return true;
  } catch (error: any) {
    return false;
  }
};
