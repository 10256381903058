import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IBillingUserResponse } from '../../services/user/interfaces';
import type { RootState } from '../rootReducer';
import { INITIAL_STATE } from './state';

const billingSlice = createSlice({
  name: 'billing',
  initialState: INITIAL_STATE,
  reducers: {
    billingSetData: (state, action: PayloadAction<IBillingUserResponse>) => {
      state.billingInfo = action.payload;
    },
    billingResetState: () => INITIAL_STATE
  }
});

export const {
  billingSetData,
  billingResetState
} = billingSlice.actions;

export default billingSlice.reducer;

export const selectBillingData = (state: RootState) => state.billing.billingInfo;
