import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { Colors, Fonts, STATUS } from '../../../../configs/enums';

export const EventStyles = StyleSheet.create({
  eventContainer: {
    marginHorizontal: 5,
    padding: 10,
    backgroundColor: Colors.EVENT_BLUE,
    flex: 1,
    borderRadius: 10,
    marginVertical: 5
  },
  title: {
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: 14
  },
  description: {
    fontFamily: Fonts.MANROPE_REGULAR,
    fontSize: 10
  }
});

const getEventFontSize = (width: number) => {
  if (width > 400) {
    return 10;
  }
  if (width <= 400 && width > 300) {
    return 7;
  }
  if (width <= 300 && width > 200) {
    return 6;
  }
  if (width <= 200 && width > 100) {
    return 5;
  }
  if (width <= 100) {
    return 5;
  }
  return 8;
};

export const functionStyles = {
  eventContainer: (
    marginTop: number | string,
    height: number,
    dayMode: boolean,
    index?: number,
    parentWidth?: number
  ): TextStyle & ViewStyle => ({
    position: 'absolute',
    width: dayMode ? undefined : ((parentWidth! / 7) - 17),
    marginLeft: dayMode ? undefined : index! * ((parentWidth! / 7) - 12),
    height,
    left: dayMode ? 0 : undefined,
    right: dayMode ? 0 : undefined,
    marginTop,
    marginHorizontal: 5,
    paddingHorizontal: dayMode ? 10 : 3,
    paddingTop: dayMode ? 10 : 0,
    borderColor: Colors.BACKGROUND_SECONDARY_COLOR,
    flex: 1,
    marginVertical: 5
  }),
  title: (parentWidth: number): TextStyle & ViewStyle => ({
    color: Colors.SECONDARY_TEXT_COLOR,
    fontSize: getEventFontSize(parentWidth),
    fontFamily: Fonts.MANROPE_EXTRABOLD
  }),
  description: (parentWidth: number): TextStyle & ViewStyle => ({
    color: Colors.SECONDARY_TEXT_COLOR,
    fontSize: getEventFontSize(parentWidth),
    fontFamily: Fonts.MANROPE_REGULAR,
    marginTop: 10
  })
};
