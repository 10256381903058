import React, { useState } from 'react';
import {
  View, Text, useWindowDimensions, TextInput, TouchableOpacity
} from 'react-native';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Feather';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styles, { functionStyles } from './style';
import { showToastError, showToastSuccess } from '../../../../utils/toast';
import { PASSWD_VALIDATION } from '../../../../utils/rules';
import Routes from '../../../../navigation/Routes';
import { authValidatePassword, authPasswordResetNewPassword } from '../../../../services/auth';
import { Colors } from '../../../../configs/enums';
import { selectLanguageKeys } from '../../../../redux/language';

interface mailRecoverProp {
  navigation: any;
  route: any;
}

export default ({ navigation, route }: mailRecoverProp): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const [hidePassword, setHidePassword] = useState(true);
  const [hideSecondPassword, setHideSecondPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const getLabel = useSelector(selectLanguageKeys);
  const emailParams = route.params.params;

  const {
    handleSubmit, control, formState: { errors }
  } = useForm({
    defaultValues: {
      newPassword: '',
      repeatedPassword: ''
    }
  });

  const onToggleSwitch = () => {
    setHidePassword(!hidePassword);
  };

  const onToggleSecondSwitch = () => {
    setHideSecondPassword(!hideSecondPassword);
  };

  const onConfirmPassword = async (data: {newPassword: string, repeatedPassword: string}) => {
    setIsLoading(true);
    const isValidatePassword = await authValidatePassword(data.newPassword);
    if (isValidatePassword.error) {
      showToastError(getLabel('error'), getLabel(isValidatePassword.errorMessage!));
    } else if (data.newPassword === data.repeatedPassword) {
      if (emailParams) {
        const response = await authPasswordResetNewPassword({ password: data.newPassword, email: emailParams });
        if (response.error) {
          showToastError(getLabel('error'), getLabel(response.errorMessage!));
        } else {
          showToastSuccess(getLabel('newPass_toast_success'), getLabel('newPass_toast_success_message'));
          navigation.navigate(Routes.Login);
        }
      } else {
        showToastError(getLabel('newPass_toast_error_email'), getLabel('newPass_toast_error_email_message'));
      }
    } else {
      showToastError(getLabel('newPass_toast_error_password'), getLabel('newPass_toast_error_password_message'));
    }
    setIsLoading(false);
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View style={styles.middleContainer}>
          <View style={styles.textContainer}>
            <Icon
              style={styles.icon}
              name="check-square"
            />
            <Text style={styles.title}>{getLabel('newPass_title')}</Text>
            <Text style={styles.text}>
              {getLabel('newPass_subTitle')}
            </Text>
          </View>
        </View>
        <View style={styles.controllersContainer}>
          <Text style={styles.errorText}>{errors?.newPassword?.message}</Text>
          <Controller
            name="newPassword"
            control={control}
            rules={PASSWD_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_8'),
              getLabel('rules_max_length_32'),
              getLabel('rules_password_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.subInputContainer}>
                <TextInput
                  style={[styles.input, errors?.newPassword?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  autoCorrect={false}
                  secureTextEntry={hidePassword}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_password')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
                <TouchableOpacity
                  style={styles.buttonHideIcon}
                  onPress={onToggleSwitch}
                >
                  <Icon
                    style={styles.hideIcon}
                    name={!hidePassword ? 'eye' : 'eye-off'}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
          <Text style={styles.errorText}>{errors?.repeatedPassword?.message}</Text>
          <Controller
            name="repeatedPassword"
            control={control}
            rules={PASSWD_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_8'),
              getLabel('rules_max_length_32'),
              getLabel('rules_password_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <View style={styles.subInputContainer}>
                <TextInput
                  style={[styles.input, errors?.repeatedPassword?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  autoCorrect={false}
                  secureTextEntry={hideSecondPassword}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_password_repeat')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                />
                <TouchableOpacity
                  style={styles.buttonHideIcon}
                  onPress={onToggleSecondSwitch}
                >
                  <Icon
                    style={styles.hideIcon}
                    name={!hideSecondPassword ? 'eye' : 'eye-off'}
                  />
                </TouchableOpacity>
              </View>
            )}
          />
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.continueButton}
            titleStyle={styles.continueTextButton}
            onPress={handleSubmit(onConfirmPassword)}
            title={getLabel('newPass_continue_button')}
            loading={isLoading}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
