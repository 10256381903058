import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../rootReducer';
import { INITIAL_STATE } from './state';

const globalSlice = createSlice({
  name: 'global',
  initialState: INITIAL_STATE,
  reducers: {
    globalSetWasOpenedWithDeepLink: (state, action: PayloadAction<boolean>) => {
      state.appOpenedWithLink = action.payload;
    },
    globalSetMustResetCalendar: (state, action: PayloadAction<boolean>) => {
      state.mustResetCalendar = action.payload;
    },
    globalSetMustResetInvites: (state, action: PayloadAction<boolean>) => {
      state.mustResetInvites = action.payload;
    },
    globalSetLanguagesAvailable: (state, action: PayloadAction<string[]>) => {
      state.languagesAvailable = action.payload;
    },
    globalSetMustReloadBilling: (state, action: PayloadAction<boolean>) => {
      state.mustReloadBilling = action.payload;
    },
    globalSetExpoPushToken: (state, action: PayloadAction<string>) => {
      state.expoPushToken = action.payload;
    },
    globalSetIcsRead: (state, action: PayloadAction<string | null>) => {
      state.icsRead = action.payload;
    },
    globalSetStartupLinkProcessed: (state, action: PayloadAction<boolean>) => {
      state.startupLinkProcessed = action.payload;
    },
    globalSetProfilePhoto: (state, action: PayloadAction<string | null>) => {
      state.profileImage = action.payload;
    },
    globalResetState: () => INITIAL_STATE
  }
});

export const {
  globalSetWasOpenedWithDeepLink,
  globalSetMustResetCalendar,
  globalSetMustResetInvites,
  globalSetLanguagesAvailable,
  globalSetMustReloadBilling,
  globalSetExpoPushToken,
  globalSetIcsRead,
  globalSetStartupLinkProcessed,
  globalSetProfilePhoto,
  globalResetState
} = globalSlice.actions;

export default globalSlice.reducer;

export const selectGlobalAppWasOpenedWithDeepLink = (state: RootState) => state.global.appOpenedWithLink;

export const selectGlobalAppMustResetCalendar = (state: RootState) => state.global.mustResetCalendar;

export const selectGlobalAppMustResetInvites = (state: RootState) => state.global.mustResetInvites;

export const selectGlobalLanguagesAvailable = (state: RootState) => state.global.languagesAvailable;

export const selectGlobalAppMustReloadBilling = (state: RootState) => state.global.mustReloadBilling;

export const selectGlobalAppExpoPushToken = (state: RootState) => state.global.expoPushToken;

export const selectGlobalAppIcsRead = (state: RootState) => state.global.icsRead;

export const selectStartupLinkProcessed = (state: RootState) => state.global.startupLinkProcessed;

export const selectProfilePhoto = (state: RootState) => state.global.profileImage;
