import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { View } from 'react-native';
import { WebView, WebViewNavigation } from 'react-native-webview';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '../../redux/user';
import { globalSetMustReloadBilling } from '../../redux/global';
import { isMobile } from '../../utils';
import { CheckoutStyles as styles } from './styles';
import Routes from '../../navigation/Routes';

interface Props {
  route: { params: { uri: string } };
}

export default ({ route }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const navigation = useNavigation();

  const userData = useSelector(selectUserInfo);

  const onNavigationStateChange = (state: WebViewNavigation) => {
    if (isMobile) {
      if (state.url !== route.params.uri) {
        dispatch(globalSetMustReloadBilling(true));
        if (!state.url.includes('billing.stripe.com')) {
          if (userData.firstLogin) {
            navigation.navigate(Routes.AgendaOrganizer, { params: { uuid: null, name: 'Mi Calendario' } });
          } else {
            navigation.goBack();
          }
        }
      }
    }
  };

  return (
    <View style={styles.container}>
      <WebView
        onNavigationStateChange={onNavigationStateChange}
        source={{ uri: route.params.uri }}
        bounces={false}
      />
    </View>
  );
};
