import { TextStyle, ViewStyle } from 'react-native';
import { Colors } from '../../../../configs/enums';

export const functionStyles = {
  currentHourLine: (marginTop: number | string): TextStyle & ViewStyle => ({
    color: Colors.ERROR_COLOR,
    backgroundColor: Colors.ERROR_COLOR,
    height: 2,
    position: 'absolute',
    left: 0,
    right: 0,
    marginTop
  })
};
