/* eslint-disable no-restricted-syntax */
import { Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  USER_STORAGE_KEY,
  CALENDAR_STORAGE_KEY,
  DEEP_LINK_KEY,
  USER_LANGUAGE_KEY,
  USER_BILLING_INFO,
  USER_LOCAL_NOTIF_KEY
} from '@env';
import { Dispatch } from 'react';
import dayjs from 'dayjs';
import * as Linking from 'expo-linking';
import * as Localization from 'expo-localization';
import { EventType } from 'expo-linking';
import {
  NotificationContentInput,
  NotificationRequest,
  NotificationRequestInput
} from 'expo-notifications';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { IBillingUserResponse } from 'src/services/user/interfaces';
import { UserData } from '../services/auth/interfaces';
import { languageUpdateState } from '../redux/language';
import { lenguagesGetKeys } from '../services/language';
import { ILanguageState } from '../redux/language/state';
import {
  DEFAULT_SCHEDULE,
  ISchedule
} from '../redux/schedules/state';
import {
  DayCalendar,
  EventLight,
  HoursCalendar,
  WeeklySchedule
} from '../components/Calendar/interfaces';
import {
  authValidateToken,
  authRefreshToken
} from '../services/auth';
import {
  userResetState,
  userSetData,
  userSetRole
} from '../redux/user';
import {
  scheduleResetState,
  scheduleSetData,
  scheduleSetSelectedSchedule
} from '../redux/schedules';
import { agendaOrganizerResetState } from '../redux/agendaOrganizer';
import { DAY_ITEMS_QTY } from '../components/Calendar/configs';
import {
  PERIODICITY,
  ROLE,
  TYPEOFNOTIFICATION
} from '../configs/enums';
import {
  EventNotification,
  LocalNotificationStore
} from './interfaces';
import { userGetBillingInfo } from '../services/user';
import {
  billingResetState,
  billingSetData
} from '../redux/billing';
import {
  IScheduleSettingsGetSettingsWithHashResponse,
  Range
} from '../services/scheduleSettings/interface';
import { scheduleGetAll } from '../services/schedule';

const { Buffer } = require('buffer');

const isBetween = require('dayjs/plugin/isBetween');

dayjs.extend(isBetween);

export const LOOKUP_DAYS: {[key: string]: string} = {
  mon: '1',
  tues: '2',
  wed: '3',
  thurs: '4',
  fri: '5',
  sat: '6',
  sun: '7'
};

export const LOOKUP_DAYS_DAYJS: {[key: string]: string} = {
  0: 'sun',
  1: 'mon',
  2: 'tues',
  3: 'wed',
  4: 'thurs',
  5: 'fri',
  6: 'sat'
};

/**
 * If the platform is web, return the web object, otherwise return the mobile object.
 * @param {any} webObject - The object to return if the platform is web.
 * @param {any} mobileObject - The object to return if the platform is Android or iOS.
 */
export const getForPlatform = (webObject: any, mobileObject: any): any => {
  if (Platform.OS === 'web') {
    return webObject;
  }
  if (Platform.OS === 'android' || Platform.OS === 'ios') {
    return mobileObject;
  }
  return mobileObject;
};

/* Checking if the platform is web. */
export const isWeb = Platform.OS === 'web';

export const isMobile = Platform.OS === 'android' || Platform.OS === 'ios';

/* Checking if the platform is iOS. */
export const isIos = Platform.OS === 'ios';

/**
 * It returns an array of numbers from 0 to the number passed in, or from the number passed in to 0 if
 * the second argument is passed in.
 * @param {number} to - The number of elements in the array.
 * @param {number} [from] - The starting index of the array.
 */
export const getArrayRange = (to: number, from?: number): number[] => Array(to).fill(0).map((_elem, idx) => idx + (from ?? 0));

/**
 * It takes in a value and a storage key, converts the value to a JSON string, and then stores it in
 * the AsyncStorage
 * @param {any} value - any - The value you want to store in AsyncStorage.
 * @param {string} storageKey - The key to store the data under.
 * @returns A function that takes in a value and a storageKey and returns a boolean.
 */
export const storeAsyncStorageData = async (value: any, storageKey: string) => {
  try {
    const jsonValue = JSON.stringify(value);
    await AsyncStorage.setItem(storageKey, jsonValue);
    return true;
  } catch (error: any) {
    return false;
  }
};

/**
 * It returns the value of the key passed in, or null if the key doesn't exist
 * @param {string} storageKey - The key of the data you want to retrieve.
 * @returns A Promise that resolves to the value of the key in the storage.
 */
export const getAsyncStorageData = async <T>(storageKey: string) => {
  try {
    const jsonValue = await AsyncStorage.getItem(storageKey);
    return jsonValue !== null ? JSON.parse(jsonValue) as T : null;
  } catch (error: any) {
    return null;
  }
};

/**
 * It removes the data from the AsyncStorage with the given key
 * @param {string} storageKey - The key of the data you want to retrieve.
 * @returns A function that takes a string and returns a promise that resolves to a boolean.
 */
export const removeAsyncStorageData = async (storageKey: string) => {
  try {
    await AsyncStorage.removeItem(storageKey);
    return true;
  } catch (error: any) {
    return false;
  }
};

/**
 * It checks if the user is authenticated, if not, it tries to refresh the token, if it fails, it
 * returns false
 * @param dispatch - Dispatch<any> - this is the dispatch function from the redux store.
 * @returns A boolean value.
 */
export const checkAuthentication = async (dispatch: Dispatch<any>): Promise<UserData | null> => {
  try {
    const storedData = await getAsyncStorageData<UserData>(USER_STORAGE_KEY);
    if (storedData) {
      const isValidated = await authValidateToken(storedData.jwt);
      if (isValidated.error) {
        await removeAsyncStorageData(USER_STORAGE_KEY);
        return null;
      }
      if (isValidated.response?.status === 'OK') {
        dispatch(userSetData(storedData));
        return storedData;
      }
      if (isValidated.response?.status !== 'OK') {
        const refreshToken = await authRefreshToken(storedData.refreshToken);
        if (refreshToken.error) {
          await removeAsyncStorageData(USER_STORAGE_KEY);
          return null;
        }
        await storeAsyncStorageData({ ...storedData, refreshToken: refreshToken.response!.jwt }, USER_STORAGE_KEY);
        dispatch(userSetData({ ...storedData, jwt: refreshToken.response!.jwt }));
        return { ...storedData, refreshToken: refreshToken.response!.jwt };
      }
      await removeAsyncStorageData(USER_STORAGE_KEY);
      return null;
    }
    return null;
  } catch (error: any) {
    return null;
  }
};

export const checkBilling = async (dispatch: Dispatch<any>): Promise<IBillingUserResponse | null> => {
  try {
    const storedData = await getAsyncStorageData<IBillingUserResponse>(USER_BILLING_INFO);
    if (storedData) {
      dispatch(billingSetData(storedData));
      return storedData;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const checkUserLanguage = async (dispatch: Dispatch<any>, language: string): Promise<boolean> => {
  try {
    const storedData = await getAsyncStorageData<ILanguageState>(USER_LANGUAGE_KEY);

    const languageKeysResponse = await lenguagesGetKeys(language);
    const languages = languageKeysResponse.response;

    if (languages) {
      if (!storedData) {
        dispatch(languageUpdateState({ selectedLanguage: language, keys: languages }));
        await storeAsyncStorageData({ selectedLanguage: language }, USER_LANGUAGE_KEY);
        return true;
      }

      if (storedData.selectedLanguage === language) {
        dispatch(languageUpdateState({ selectedLanguage: language, keys: languages }));
        return true;
      }

      if (storedData.selectedLanguage !== language) {
        dispatch(languageUpdateState({ selectedLanguage: language, keys: languages }));
        await removeAsyncStorageData(USER_LANGUAGE_KEY);
        await storeAsyncStorageData({ selectedLanguage: language }, USER_LANGUAGE_KEY);
        return true;
      }
    }

    if (!languages) {
      if (storedData) {
        dispatch(languageUpdateState({ selectedLanguage: storedData.selectedLanguage, keys: storedData.keys }));
        return true;
      }
    }
    return true;
  } catch (error: any) {
    return false;
  }
};

/**
 * It removes the user data from the AsyncStorage and resets the user state
 * @param dispatch - Dispatch<any>
 */
export const logoutUser = async (dispatch: Dispatch<any>) => {
  try {
    await removeAsyncStorageData(USER_STORAGE_KEY);
    await removeAsyncStorageData(CALENDAR_STORAGE_KEY);
    await removeAsyncStorageData(USER_BILLING_INFO);
    await removeAsyncStorageData(USER_LOCAL_NOTIF_KEY);
    dispatch(scheduleResetState());
    dispatch(userResetState());
    dispatch(agendaOrganizerResetState());
    dispatch(billingResetState());
  } catch (error: any) {
    dispatch(userResetState());
  }
};

/**
 * It takes an array of ranges and returns an array of ranges that are not in the input array
 * @param {string | any[]} ranges - string | any[]
 * @returns An array of objects with the following structure:
 * [
 *   {
 *     from: '00:00',
 *     to: '08:00'
 *   },
 *   {
 *     from: '09:00',
 *     to: '10:00'
 *   },
 *   {
 *     from: '11:00',
 *     to: '12:00'
 */
export const getFreeRangesAux = (ranges: string | any[]) => {
  const INITIAL_HOUR = 0;
  const FINAL_HOUR = '23:59';
  const freeRangesAux = [];
  let pointer = '00:00';
  for (let i = 0; i < ranges.length; i++) {
    const range = ranges[i];
    if (Number(pointer.replace(':', '')) < Number(ranges[i].from.replace(':', ''))) {
      freeRangesAux.push({ from: pointer, to: range.from });
      pointer = range.to;
    } else if (Number(pointer.replace(':', '')) === Number(ranges[i].from.replace(':', ''))) {
      pointer = range.to;
    }
  }
  const lastRange = ranges[ranges.length - 1];
  if (Number(lastRange.to.replace(':', '')) < Number(FINAL_HOUR.replace(':', ''))) {
    freeRangesAux.push({ from: lastRange.to, to: FINAL_HOUR });
  }
  return freeRangesAux;
};

/**
 * It takes an array of objects and sorts them by the value of the "from" property
 * @param {any[]} unorderArray - The array that needs to be sorted.
 */
export const arrangeUnorderArray = (unorderArray: any[]) => unorderArray.sort((a, b) => ((a.from > b.from) ? 1 : -1));

/**
 * It checks if the start and end time of a meeting is within the free time of a person
 * @param {string | any[]} freeTime - string | any[]
 * @param {string} startTime - The start time of the event
 * @param {string} endTime - The end time of the event
 * @returns A boolean value.
 */
export const isTimeOverlap = (freeTime: string | any[], startTime: string, endTime: string) => {
  for (let i = 0; i < freeTime.length; i++) {
    if (Number(freeTime[i].from.replace(':', '')) <= Number(startTime.replace(':', '')) && Number(freeTime[i].to.replace(':', '')) >= Number(startTime.replace(':', '')) && Number(freeTime[i].from.replace(':', '')) <= Number(endTime.replace(':', '')) && Number(freeTime[i].to.replace(':', '')) >= Number(endTime.replace(':', ''))) {
      return false;
    }
  }
  return true;
};

/**
 * It returns an array of objects, each object containing a string and a number
 */
export const getHours = (): HoursCalendar[] => getArrayRange(24, 0).map((elem) => ({
  hourStartsStr: `${elem}:00`,
  hourStartsNum: elem
}));

/**
 * Given two points, return the value of the line at the given x value.
 * @param {number} x - The value to interpolate
 * @param {number} x1 - The first x value
 * @param {number} x2 - The value of x at the end of the range.
 * @param {number} y1 - The value of the first point on the y-axis
 * @param {number} y2 - the value of the second y-coordinate
 */
export const interpolate = (x: number, x1: number, x2: number, y1: number, y2: number) => x * ((y2 - y1) / (x2 - x1));

export const calculateHourStart = (start: string, dayMode: boolean) => {
  const hourArr = start.split(':');
  return (Number(hourArr[0]) * (dayMode ? 100 : 60) + interpolate(Number(hourArr[1]), 60, 0, (dayMode ? 100 : 60), 0));
};

/**
 * It takes a start time and end time, and returns the height of the element that should be rendered
 * @param {string} start - string - the start time of the event
 * @param {string} end - string - the end time of the event
 * @returns A function that takes in a start and end time and returns the height of the hour block.
 */
export const calculateHourHeight = (start: string, end: string, dayMode: boolean) => {
  const hourArrStart = start.split(':');
  const hourArrEnd = end === '00:00' ? '23:59'.split(':') : end.split(':');
  return (Number(hourArrEnd[0]) * (dayMode ? 100 : 60) + interpolate(Number(hourArrEnd[1]), 60, 0, (dayMode ? 100 : 60), 0)) - (Number(hourArrStart[0]) * (dayMode ? 100 : 60) + interpolate(Number(hourArrStart[1]), 60, 0, (dayMode ? 100 : 60), 0));
};

/**
 * It returns an array of objects, each object containing a date property
 * @param now - dayjs.Dayjs - the current date
 * @returns An array of objects with a date property.
 */
export const getDaysForCalendar = (now: dayjs.Dayjs) => {
  const res: DayCalendar[] = [];
  let i = 0;
  let date = now.date() - (DAY_ITEMS_QTY / 2);
  while (i < DAY_ITEMS_QTY) {
    res.push({
      selected: i === DAY_ITEMS_QTY / 2,
      date: dayjs(now).date(date)
    });
    date++;
    i++;
  }
  return res;
};

/**
 * It takes a date, a size, and a language, and returns the name of the day of the week in that
 * language
 * @param date - dayjs.Dayjs - The date you want to get the name of the day from.
 * @param {'long' | 'short' | 'narrow' | undefined} size - 'long' | 'short' | 'narrow' | undefined
 * @param {'es-AR' | 'en-IN' | 'pt-PT'} language - The language you want to use.
 */
export const getDayNameFromDate = (date: dayjs.Dayjs, size: 'long' | 'short' | 'narrow' | undefined, language: 'es-AR' | 'en-IN' | 'pt-PT') => date.toDate().toLocaleString(language, { weekday: size });

/**
 * It returns the month name of a given date in a given language and size
 * @param date - The date you want to get the month name from.
 * @param {'long' | 'short' | 'narrow' | undefined} size - 'long' | 'short' | 'narrow' | undefined
 * @param {'es-AR' | 'en-IN' | 'pt-PT'} language - The language to use for the month name.
 */
export const getMonthNameFromDate = (date: dayjs.Dayjs, size: 'long' | 'short' | 'narrow' | undefined, language: 'es-AR' | 'en-IN' | 'pt-PT') => date.toDate().toLocaleString(language, { month: size });

/**
 * Capitalize takes a string and returns a new string with the first letter capitalized.
 * @param {string} data - string - This is the data that we're going to capitalize.
 */
export const capitalize = (data: string) => data[0].toUpperCase() + data.substring(1);

export const loadAsyncStorageSchedules = async () => {
  const isLoadedOnPhone = await getAsyncStorageData<ISchedule[] | null>(CALENDAR_STORAGE_KEY);
  return isLoadedOnPhone;
};

/**
 * It returns true if the app was opened with a link, and false if it wasn't
 * @returns A boolean.
 */
export const appWasOpenedWithLink = async () => {
  const url = await Linking.getInitialURL();

  console.log('url ', url);

  if (url) {
    const { path } = Linking.parse(url!);

    if (path) {
      return true;
    }
    return false;
  }
  return false;
};

export interface HashLinkParams {
  hashlink: string;
  path: string | null;
}

/**
 * It takes a query parameter name as an argument and returns the value of that query parameter from
 * the URL
 * @param {string} param - The query parameter you want to get from the URL.
 * @returns A function that returns a promise that resolves to a string.
 */
export const getQueryParamFromUrl = async (param: string): Promise<HashLinkParams | null> => {
  const url = await Linking.getInitialURL();
  console.log('url ', url);

  const { queryParams, path } = Linking.parse(url!);

  if (queryParams[param]) {
    return { hashlink: queryParams[param], path };
  }
  return null;
};

/**
 * It listens to the url changes and redirects to the correct screen
 * @param {EventType} event - EventType
 */
export const handleDeepLinking = async (event: EventType) => {
  const { hostname, queryParams } = Linking.parse(event.url);

  if (hostname && hostname === DEEP_LINK_KEY) {
    await Linking.openURL(`${Linking.createURL('/')}InvitedCalendar/${queryParams.hashlink}`);
  }
};

/**
 * It takes a date and returns an object with two dates, one being the date one week before the input
 * date, and the other being the date one day before the input date
 * @param date - dayjs.Dayjs - the date to get the week before
 */
export const getOneWeekBeforeDate = (date: dayjs.Dayjs) => ({
  from: date.subtract(7, 'day'),
  to: date.subtract(1, 'day')
});

/**
 * "Given a date, return an object with the next day and the day after that."
 *
 * The function is written in TypeScript, but it's not really doing anything that's specific to
 * TypeScript. It's just using the dayjs library to manipulate dates
 * @param date - dayjs.Dayjs - the date to start from
 */
export const getOneWeekAfterDate = (date: dayjs.Dayjs) => ({
  from: date.add(1, 'day'),
  to: date.add(7, 'day')
});

/**
 * "Given a date, return the date of the Monday of the week that the date is in."
 *
 * The first line of the function is a TypeScript type annotation. It says that the function takes a
 * dayjs.Dayjs object as an argument and returns a dayjs.Dayjs object
 * @param date - dayjs.Dayjs - the date you want to get the start of the week for
 * @returns A date object that is the first day of the week.
 */
export const getStartWeekDate = (date: dayjs.Dayjs) => {
  const dayInWeek = date.day();

  if (dayInWeek === 1) {
    return date;
  }
  if (dayInWeek === 0) {
    return dayjs(date).subtract(6, 'day');
  }
  return dayjs(date).subtract(date.day() - 1, 'day');
};

export const getOneHourMore = (hour: string) => {
  const newHour = hour.split(':');
  const hr = Number(newHour[0]);
  const min = newHour[1];
  if (hr < 9) {
    return `0${hr + 1}:${min}`;
  }
  if (hr >= 9 && hr < 23) {
    return `${hr + 1}:${min}`;
  }
  return '23:59';
};

export const getNextMonday = (date: string) => {
  const currentDate = dayjs(date);

  if (currentDate.day() === 0) {
    return dayjs(date).day(1).format('YYYY-MM-DD');
  }

  return dayjs(date).add(1, 'week').day(1).format('YYYY-MM-DD');
};

export const isPremiumAccount = (roles: string[]): boolean => {
  if (roles[0] === ROLE.PREMIUM || roles[0] === ROLE.PRO) {
    return true;
  }
  return false;
};

export const isProAccount = (roles: string[]): boolean => {
  if (roles[0] === ROLE.PRO) {
    return true;
  }
  return false;
};

export const isObjectEmpty = (data: any): boolean => Object.entries(data).length === 0;

export function calculateDayEventsOverlap(eventsDay: EventLight[]) {
  if (eventsDay.length > 1) {
    const events = eventsDay.sort((a, b) => {
      const dateA = new Date(`1970-01-01T${a.range.from}:00Z`);
      const dateB = new Date(`1970-01-01T${b.range.from}:00Z`);

      return (dateA - dateB);
    });

    let overlapCount = 1;
    let overlapIndex = 0;
    let fromGlobal = parseInt(events[0].range.from.replace(':', ''), 10);
    let toGlobal = parseInt(events[0].range.to.replace(':', ''), 10);

    for (let i = 1; i < events.length; i += 1) {
      const from = parseInt(events[i].range.from.replace(':', ''), 10);
      const to = parseInt(events[i].range.to.replace(':', ''), 10);

      if (toGlobal > from && toGlobal <= to) { // 1er caso
        overlapCount += 1;
        overlapIndex += 1;
        toGlobal = to;
        events[i - 1].isOverlapped = true;
        events[i - 1].overlapIndex = overlapIndex - 1;
        events[i].isOverlapped = true;
        events[i].overlapIndex = overlapIndex;
      } else if (fromGlobal <= from && toGlobal >= to) { // 2do caso
        overlapCount += 1;
        overlapIndex += 1;
        events[i - 1].isOverlapped = true;
        events[i - 1].overlapIndex = overlapIndex - 1;
        events[i].isOverlapped = true;
        events[i].overlapIndex = overlapIndex;
      } else if (fromGlobal < to && toGlobal >= to) { // 3er caso
        overlapCount += 1;
        overlapIndex += 1;
        fromGlobal = from;
        events[i - 1].isOverlapped = true;
        events[i - 1].overlapIndex = overlapIndex - 1;
        events[i].isOverlapped = true;
        events[i].overlapIndex = overlapIndex;
      } else {
        fromGlobal = from;
        toGlobal = to;
        for (let j = 0; j < i; j += 1) {
          if (events[j].isOverlapped && events[j].isOverlapped === true) {
            events[j].overlapCount = overlapCount;
            events[j].isOverlapped = false;
          }
        }
        overlapCount = 1;
        overlapIndex = 0;
      }
    }

    for (let i = 0; i < events.length; i += 1) {
      if (events[i].isOverlapped && events[i].isOverlapped === true) {
        events[i].overlapCount = overlapCount;
      }
    }
    return events;
  }
  return eventsDay;
}

function calculateEventHourOverlap(week: WeeklySchedule) {
  Object.keys(week).forEach((weekDay) => {
    if (week[weekDay].events.length > 1) {
      const events = week[weekDay].events.sort((a, b) => {
        const dateA = new Date(`1970-01-01T${a.range.from}:00Z`);
        const dateB = new Date(`1970-01-01T${b.range.from}:00Z`);

        return (dateA - dateB);
      });

      let overlapCount = 1;
      let overlapIndex = 0;
      let fromGlobal = parseInt(events[0].range.from.replace(':', ''), 10);
      let toGlobal = parseInt(events[0].range.to.replace(':', ''), 10);

      for (let i = 1; i < events.length; i += 1) {
        const from = parseInt(events[i].range.from.replace(':', ''), 10);
        const to = parseInt(events[i].range.to.replace(':', ''), 10);

        if (toGlobal > from && toGlobal <= to) { // 1er caso
          overlapCount += 1;
          overlapIndex += 1;
          toGlobal = to;
          events[i - 1].isOverlapped = true;
          events[i - 1].overlapIndex = overlapIndex - 1;
          events[i].isOverlapped = true;
          events[i].overlapIndex = overlapIndex;
        } else if (fromGlobal <= from && toGlobal >= to) { // 2do caso
          overlapCount += 1;
          overlapIndex += 1;
          events[i - 1].isOverlapped = true;
          events[i - 1].overlapIndex = overlapIndex - 1;
          events[i].isOverlapped = true;
          events[i].overlapIndex = overlapIndex;
        } else if (fromGlobal < to && toGlobal >= to) { // 3er caso
          overlapCount += 1;
          overlapIndex += 1;
          fromGlobal = from;
          events[i - 1].isOverlapped = true;
          events[i - 1].overlapIndex = overlapIndex - 1;
          events[i].isOverlapped = true;
          events[i].overlapIndex = overlapIndex;
        } else {
          fromGlobal = from;
          toGlobal = to;
          for (let j = 0; j < i; j += 1) {
            if (events[j].isOverlapped && events[j].isOverlapped === true) {
              events[j].overlapCount = overlapCount;
              events[j].isOverlapped = false;
            }
          }
          overlapCount = 1;
          overlapIndex = 0;
        }
      }

      for (let i = 0; i < events.length; i += 1) {
        if (events[i].isOverlapped && events[i].isOverlapped === true) {
          events[i].overlapCount = overlapCount;
        }
      }
    }
  });

  return week;
}

export const weeklyScheduleToEventCalendarArray = (events: EventLight[] | null, selDate: dayjs.Dayjs): WeeklySchedule => {
  const startWDay = getStartWeekDate(selDate);

  let week: WeeklySchedule = {};
  let currentDay;

  if (events) {
    const sortedEventsByDate = events.sort((a, b) => dayjs(a.date).diff(dayjs(b.date), 'days'));

    sortedEventsByDate.reduce((a: any, b: any) => {
      if (a.date === b.date) {
        a.events.push(b);
        currentDay = dayjs(a.date).day();
        week[currentDay === 0 ? 7 : currentDay] = a;
      } else {
        a = { events: [], date: b.date };
        a.events.push(b);
        currentDay = dayjs(b.date).day();
        week[currentDay === 0 ? 7 : currentDay] = a;
      }

      return a;
    }, { events: [], date: events[0].date });
  }

  // Aca debe agregarse la logica del overlap
  week = calculateEventHourOverlap(week);

  [...Array(7)]
    .forEach((e, i) => {
      ((i + 1).toString() in week) ? null : week[(i + 1).toString()] = { date: startWDay.add(i, 'day').format('YYYY-MM-DD'), events: [] };
    });

  return week;
};

export const applyTrimToFields = <T>(data: T | any, keys: string[]) => {
  keys.forEach((key) => { key in data && data[key] ? data[key] = data[key].trim() : null; });
};

export const getFileNameFromUrl = (url: string): string => {
  const splittedUrl = url.split('/');
  return splittedUrl[splittedUrl.length - 1];
};

export const getFormatFromUrl = (url: string): string => {
  const splittedUrl = url.split('.');
  return `image/${splittedUrl[splittedUrl.length - 1]}`;
};

/**
 * If the value is null or undefined, return false, otherwise return true.
 * @param {TValue | null | undefined} value - TValue | null | undefined
 * @returns A function that takes a value of type TValue or null or undefined and returns true if the
 * value is not null or undefined.
 */
export function notEmptyFilter<TValue>(value: TValue | null | undefined): value is TValue {
  if (value === null || value === undefined) return false;
  const testDummy: TValue = value;
  return true;
}

/**
 * "Check if a date is between two other dates."
 *
 * The function takes three parameters:
 *
 * - `date`: The date to check.
 * - `from`: The start date.
 * - `to`: The end date
 * @param {string} date - The date to check
 * @param {string} from - The start date
 * @param {string} to - string - The end date of the range.
 * @returns A boolean value
 */
export function isDateBetween(date: string, from: string, to: string): boolean {
  return dayjs(date).isBetween(from, to, 'day', '[]');
}

export const isDateLowerOrEqual = (dateA: string, dateB: string): boolean => {
  const diff = dayjs(dateA).diff(dayjs(dateB), 'days');
  if (diff > 0) {
    return false;
  }
  return true;
};

/**
 * It takes a binary image and converts it to a base64 string
 * @param {any} data - The image data to convert to base64.
 */
export const imageToBase64 = (data: any) => Buffer.from(data, 'binary').toString('base64');

export const checkNotificationPermissions = async (): Promise<boolean> => {
  try {
    if (!Device.isDevice) {
      return false;
    }

    const notificationPermissionsStatus = await Notifications.getPermissionsAsync();

    const permissionStatus = (notificationPermissionsStatus.status !== 'granted'
      ? await Notifications.requestPermissionsAsync()
      : notificationPermissionsStatus.status);

    if (permissionStatus !== 'granted') {
      return false;
    }

    return true;
  } catch (error: any) {
    return false;
  }
};

export const getPushToken = async (): Promise<string | null> => {
  try {
    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        lightColor: '#305FAB'
      });
    }

    const userHasPermission = await checkNotificationPermissions();

    if (userHasPermission) {
      const tokenData = await Notifications.getExpoPushTokenAsync({
        experienceId: '@focusmediadeveloper/2meetup'
      });
      return tokenData.data;
    }
    return null;
  } catch (error) {
    console.info('Error getPushToken ', error);
    return null;
  }
};

export const getDatesTwoWeeks = (date: string, endDate?: string) => {
  const dates = [];
  let repeat = 40;
  let week = 0;
  let newDate;

  while (repeat > 0) {
    newDate = dayjs(date).add(week, 'week').format('YYYY-MM-DD');
    if (endDate && !isDateLowerOrEqual(newDate, endDate)) {
      break;
    }
    dates.push(newDate);
    week += 2;
    repeat--;
  }

  return dates;
};
const getDatesMonth = (date: string, endDate?: string) => {
  const dates = [];
  let repeat = 24;
  let monthDate;
  let month = 0;

  while (repeat > 0) {
    monthDate = dayjs(date).add(month, 'month').format('YYYY-MM-DD');
    if (endDate && !isDateLowerOrEqual(monthDate, endDate)) {
      break;
    }
    dates.push(monthDate);
    month++;
    repeat--;
  }

  return dates;
};

export const scheduleNotification = async (notification: EventNotification): Promise<boolean> => {
  try {
    const notificationReqInput: NotificationRequestInput = {
      content: {
        title: notification.title,
        body: notification.body,
        data: {
          uuid: notification.uuid,
          type: notification.type
        },
        priority: 'high'
      },
      trigger: {
        date: dayjs(`${notification.date} ${notification.hour}`).toDate(),
        repeats: false
      }
    };

    await Notifications.scheduleNotificationAsync(notificationReqInput);
    return true;
  } catch (error: any) {
    return false;
  }
};

export const schedulePeriodicNotification = async (notification: EventNotification): Promise<boolean> => {
  try {
    let notificationReqInput: NotificationRequestInput | undefined;

    const content: NotificationContentInput = {
      title: notification.title,
      body: notification.body,
      data: {
        uuid: notification.uuid,
        type: notification.type,
        periodicity: notification.periodicity,
        typeOfPeriodicity: notification.typeOfPeriodicity,
        periodicityTo: notification.periodicityTo
      }
    };

    const splittedHour = notification.hour.split(':');
    const hour = Number(splittedHour[0]);
    const minute = Number(splittedHour[1]);

    switch (notification.typeOfPeriodicity) {
      case PERIODICITY.EVERYDAY:
        notificationReqInput = {
          content,
          trigger: {
            repeats: true,
            hour,
            minute
          }
        };
        await Notifications.scheduleNotificationAsync(notificationReqInput!);
        break;
      case PERIODICITY.EVERY_WEEK:
        notificationReqInput = {
          content,
          trigger: {
            repeats: true,
            hour,
            minute,
            weekday: dayjs(notification.date).day() + 1
          }
        };
        await Notifications.scheduleNotificationAsync(notificationReqInput!);
        break;
      case PERIODICITY.EACH_YEAR:
        notificationReqInput = {
          content,
          trigger: {
            repeats: true,
            month: dayjs(notification.date).month() + 1,
            day: dayjs(notification.date).date(),
            hour,
            minute
          }
        };
        await Notifications.scheduleNotificationAsync(notificationReqInput!);
        break;
      case PERIODICITY.EACH_TWO_WEEKS: {
        const dates = getDatesTwoWeeks(notification.date, dayjs(notification.date).add(1, 'week').format('YYYY-MM-DD'));
        const firstDateToSchedule = {
          content,
          trigger: {
            date: dayjs(`${dates[0][0]} ${notification.hour}`).toDate(),
            repeats: false
          }
        };
        const secondDateToSchedule = {
          content,
          trigger: {
            date: dayjs(`${dates[0][1]} ${notification.hour}`).toDate(),
            repeats: false
          }
        };
        await Notifications.scheduleNotificationAsync(firstDateToSchedule);
        await Notifications.scheduleNotificationAsync(secondDateToSchedule);
        break;
      }
      case PERIODICITY.EACH_MONTH:
        notificationReqInput = {
          content,
          trigger: {
            date: dayjs(`${notification.date} ${notification.hour}`).toDate(),
            repeats: false
          }
        };
        await Notifications.scheduleNotificationAsync(notificationReqInput!);
        break;
      case PERIODICITY.EVERY_WORKDAY:
        notificationReqInput = {
          content,
          trigger: {}
        };
        await Notifications.scheduleNotificationAsync({
          ...notificationReqInput!,
          trigger: {
            repeats: true,
            hour,
            minute,
            weekday: 2
          }
        });
        await Notifications.scheduleNotificationAsync({
          ...notificationReqInput!,
          trigger: {
            repeats: true,
            hour,
            minute,
            weekday: 3
          }
        });
        await Notifications.scheduleNotificationAsync({
          ...notificationReqInput!,
          trigger: {
            repeats: true,
            hour,
            minute,
            weekday: 4
          }
        });
        await Notifications.scheduleNotificationAsync({
          ...notificationReqInput!,
          trigger: {
            repeats: true,
            hour,
            minute,
            weekday: 5
          }
        });
        await Notifications.scheduleNotificationAsync({
          ...notificationReqInput!,
          trigger: {
            repeats: true,
            hour,
            minute,
            weekday: 6
          }
        });
        break;
      default:
        break;
    }
    return true;
  } catch (error: any) {
    return false;
  }
};

/**
 * No soporta caso especial cada dos semanas
 */
export const getNextNotificationForSchedulePeriodicNotification = (notification: EventNotification): EventNotification => {
  switch (notification.typeOfPeriodicity) {
    case PERIODICITY.EVERYDAY:
      return { ...notification, date: getNextMonday(notification.date) };
    case PERIODICITY.EVERY_WEEK:
      return { ...notification, date: dayjs(notification.date).add(1, 'week').format('YYYY-MM-DD') };
    case PERIODICITY.EACH_YEAR:
      return { ...notification, date: dayjs(notification.date).add(1, 'year').format('YYYY-MM-DD') };
    case PERIODICITY.EACH_MONTH:
      return { ...notification, date: dayjs(notification.date).add(1, 'month').format('YYYY-MM-DD') };
    case PERIODICITY.EVERY_WORKDAY:
      return { ...notification, date: getNextMonday(notification.date) };
    default:
      return { ...notification, date: getNextMonday(notification.date) };
  }
};

export const getNextNotificationsForTwoWeeks = (notification: EventNotification) => {
  let dates: string[];
  const nextTwoWeeksNotifications: EventNotification[] = [];

  if (notification.periodicityTo) {
    dates = getDatesTwoWeeks(notification.date, notification.periodicityTo);
  } else {
    dates = getDatesTwoWeeks(notification.date);
  }

  dates.forEach((nextDate) => {
    nextTwoWeeksNotifications.push({ ...notification, date: nextDate });
  });

  return nextTwoWeeksNotifications;
};

/**
 * If there's no local notifications store, create one and schedule the notification. If there's a
 * local notifications store, update it and schedule the notification
 * @param {EventNotification} notification - EventNotification
 * @returns A function that takes a notification as a parameter and returns a promise.
 */
export const scheduleLocalNotification = async (notification: EventNotification): Promise<any> => {
  try {
    const localNotifications = await getAsyncStorageData<LocalNotificationStore>(USER_LOCAL_NOTIF_KEY);

    if (!localNotifications) {
      const nextMonday = getNextMonday(dayjs().format('YYYY-MM-DD'));

      const notifDateIsLowerOrEqualThanNextMonday = isDateLowerOrEqual(notification.date, nextMonday);

      if (notification.periodicity) {
        if (notification.typeOfPeriodicity === PERIODICITY.EACH_TWO_WEEKS) {
          const nextTwoWeeksNotificationsToSchedule = getNextNotificationsForTwoWeeks(notification);

          notifDateIsLowerOrEqualThanNextMonday
            && await scheduleNotification(nextTwoWeeksNotificationsToSchedule[0]);

          const localNotificationStore: LocalNotificationStore = {
            nextUpdate: nextMonday,
            data: notifDateIsLowerOrEqualThanNextMonday
              ? nextTwoWeeksNotificationsToSchedule.slice(1, nextTwoWeeksNotificationsToSchedule.length)
              : nextTwoWeeksNotificationsToSchedule.slice(0, nextTwoWeeksNotificationsToSchedule.length)
          };

          await storeAsyncStorageData(localNotificationStore, USER_LOCAL_NOTIF_KEY);
          return;
        }

        if (notification.typeOfPeriodicity === PERIODICITY.EVERYDAY || notification.typeOfPeriodicity === PERIODICITY.EVERY_WORKDAY) {
          await schedulePeriodicNotification(notification);
          return;
        }

        if (notifDateIsLowerOrEqualThanNextMonday) {
          await scheduleNotification(notification);

          const nextNotificationToSchedule = getNextNotificationForSchedulePeriodicNotification(notification);

          if (notification.periodicityTo && !isDateLowerOrEqual(nextNotificationToSchedule.date, notification.periodicityTo)) {
            const localNotificationStore: LocalNotificationStore = {
              nextUpdate: nextMonday,
              data: []
            };

            await storeAsyncStorageData(localNotificationStore, USER_LOCAL_NOTIF_KEY);
            return;
          }

          const localNotificationStore: LocalNotificationStore = {
            nextUpdate: nextMonday,
            data: [nextNotificationToSchedule]
          };

          await storeAsyncStorageData(localNotificationStore, USER_LOCAL_NOTIF_KEY);
          return;
        }

        if (!notifDateIsLowerOrEqualThanNextMonday) {
          const localNotificationStore: LocalNotificationStore = {
            nextUpdate: nextMonday,
            data: [notification]
          };

          await storeAsyncStorageData(localNotificationStore, USER_LOCAL_NOTIF_KEY);
          return;
        }
      }

      if (!notification.periodicity) {
        if (notifDateIsLowerOrEqualThanNextMonday) {
          await scheduleNotification(notification);
        }

        if (!notifDateIsLowerOrEqualThanNextMonday) {
          const localNotificationStore: LocalNotificationStore = {
            nextUpdate: nextMonday,
            data: [notification]
          };

          await storeAsyncStorageData(localNotificationStore, USER_LOCAL_NOTIF_KEY);
        }
        return;
      }
    }

    if (localNotifications) {
      const notifDateIsLowerOrEqualThanNextUpdate = isDateLowerOrEqual(notification.date, localNotifications.nextUpdate);
      if (notification.periodicity) {
        if (notification.typeOfPeriodicity === PERIODICITY.EACH_TWO_WEEKS) {
          const nextTwoWeeksNotificationsToSchedule = getNextNotificationsForTwoWeeks(notification);

          notifDateIsLowerOrEqualThanNextUpdate
            && await scheduleNotification(nextTwoWeeksNotificationsToSchedule[0]);

          await storeAsyncStorageData({
            ...localNotifications,
            data:
            notifDateIsLowerOrEqualThanNextUpdate
              ? localNotifications.data.concat(nextTwoWeeksNotificationsToSchedule.slice(1, nextTwoWeeksNotificationsToSchedule.length))
              : localNotifications.data.concat(nextTwoWeeksNotificationsToSchedule.slice(0, nextTwoWeeksNotificationsToSchedule.length))
          }, USER_LOCAL_NOTIF_KEY);

          return;
        }

        if (notification.typeOfPeriodicity === PERIODICITY.EVERYDAY || notification.typeOfPeriodicity === PERIODICITY.EVERY_WORKDAY) {
          await schedulePeriodicNotification(notification);
          return;
        }

        if (notifDateIsLowerOrEqualThanNextUpdate) {
          await scheduleNotification(notification);

          const nextNotificationToSchedule = getNextNotificationForSchedulePeriodicNotification(notification);

          if (notification.periodicityTo && !isDateLowerOrEqual(nextNotificationToSchedule.date, notification.periodicityTo)) {
            return;
          }

          await storeAsyncStorageData({
            ...localNotifications,
            data: localNotifications.data.concat(nextNotificationToSchedule)
          }, USER_LOCAL_NOTIF_KEY);

          return;
        }

        if (!notifDateIsLowerOrEqualThanNextUpdate) {
          await storeAsyncStorageData({
            ...localNotifications,
            data: localNotifications.data.concat(notification)
          }, USER_LOCAL_NOTIF_KEY);

          return;
        }
      }

      if (!notification.periodicity) {
        if (notifDateIsLowerOrEqualThanNextUpdate) {
          await scheduleNotification(notification);
        }

        if (!notifDateIsLowerOrEqualThanNextUpdate) {
          await storeAsyncStorageData({
            ...localNotifications,
            data: localNotifications.data.concat(notification)
          }, USER_LOCAL_NOTIF_KEY);
        }
        return;
      }
    }
  } catch (error: any) {
    console.info('ERROR ', error);
  }
};

export const checkForLocalNotifications = async (): Promise<any> => {
  try {
    const localNotifications = await getAsyncStorageData<LocalNotificationStore>(USER_LOCAL_NOTIF_KEY);
    console.info('localNotifications ', localNotifications);
    if (!localNotifications) {
      return;
    }

    if (localNotifications) {
      const today = dayjs().format('YYYY-MM-DD');

      const todayDateIsLowerOrEqualThanNextUpdate = isDateLowerOrEqual(today, localNotifications.nextUpdate);

      if (todayDateIsLowerOrEqualThanNextUpdate) {
        return;
      }

      if (!todayDateIsLowerOrEqualThanNextUpdate) {
        if (localNotifications.data.length > 0) {
          const notificationsToScheduleThisWeek = localNotifications.data.filter((notifData) => isDateLowerOrEqual(notifData.date, getNextMonday(today)));

          if (notificationsToScheduleThisWeek.length > 0) {
            const notificationPromises = notificationsToScheduleThisWeek.map((notification) => scheduleNotification(notification));

            await Promise.all(notificationPromises);

            const newNotificationsToSchedule: EventNotification[] = [];

            notificationsToScheduleThisWeek.forEach(async (notification) => {
              if (notification.periodicity) {
                if (notification.periodicityTo && isDateLowerOrEqual(getNextMonday(today), notification.periodicityTo)) {
                  newNotificationsToSchedule.push(getNextNotificationForSchedulePeriodicNotification(notification));
                  return;
                }
                if (!notification.periodicityTo) {
                  newNotificationsToSchedule.push(getNextNotificationForSchedulePeriodicNotification(notification));
                }
              }
            });

            const notificationsToNotScheduleThisWeek = localNotifications.data.filter((notifData) => !isDateLowerOrEqual(notifData.date, getNextMonday(today)));

            await storeAsyncStorageData({
              nextUpdate: getNextMonday(today),
              data: notificationsToNotScheduleThisWeek.concat(newNotificationsToSchedule)
            }, USER_LOCAL_NOTIF_KEY);
          }
        }
      }
    }
  } catch (error: any) {
    console.info('ERROR ', error);
  }
};

export const checkForPastPeriodicNotifications = async (): Promise<any> => {
  try {
    const notifications = await Notifications.getAllScheduledNotificationsAsync();

    console.info('notifications ', notifications);

    const potentialNotificationsToCancel: NotificationRequest[] = [];

    notifications.forEach((notification) => {
      if (notification.content.data.typeOfPeriodicity) {
        if (notification.content.data.typeOfPeriodicity === PERIODICITY.EVERYDAY
          || notification.content.data.typeOfPeriodicity === PERIODICITY.EVERY_WORKDAY) {
          potentialNotificationsToCancel.push(notification);
        }
      }
    });

    if (potentialNotificationsToCancel.length > 0) {
      const today = dayjs().format('YYYY-MM-DD');
      potentialNotificationsToCancel.forEach((notification) => {
        if (notification.content.data.periodicityTo && !isDateLowerOrEqual(today, notification.content.data.periodicityTo as string)) {
          Notifications.cancelScheduledNotificationAsync(notification.identifier);
        }
      });
    }

    return;
  } catch (error: any) {
    console.info('ERROR ', error);
  }
};

export const removeNotification = async (uuid: string, type: TYPEOFNOTIFICATION): Promise<any> => {
  try {
    const notifications = await Notifications.getAllScheduledNotificationsAsync();

    const notification = notifications.find((notification) => (notification.content.data.uuid === uuid && notification.content.data.type === type));

    if (notification) {
      await Notifications.cancelScheduledNotificationAsync(notification.identifier);
    }

    const localNotifications = await getAsyncStorageData<LocalNotificationStore>(USER_LOCAL_NOTIF_KEY);

    if (localNotifications) {
      const notificationsToKeep = localNotifications.data.filter((notifData) => notifData.uuid !== uuid);

      if (notificationsToKeep.length !== localNotifications.data.length) {
        await storeAsyncStorageData({
          ...localNotifications,
          data: notificationsToKeep
        }, USER_LOCAL_NOTIF_KEY);
      }
    }

    return false;
  } catch (error: any) {
    return false;
  }
};

export const getScheduledNotifications = async (): Promise<NotificationRequest[] | null> => {
  try {
    return await Notifications.getAllScheduledNotificationsAsync();
  } catch (error) {
    return null;
  }
};

export const getHourSubtracted = (date: string, initialHour: string, minutesToSubtract: number): string => dayjs(`${date} ${initialHour}`).subtract(minutesToSubtract, 'minutes').format('HH:mm');

export const convertBase64ToBlob = async (base64: string) => {
  const response = await fetch(base64);
  const blob = await response.blob();
  return blob;
};

export const getBase64Type = (base64Image: string) => base64Image.substring('data:image/'.length, base64Image.indexOf(';base64'));

export async function updateSubscription(dispatch: Dispatch<any>, userData: UserData) {
  try {
    const billingResponse = await userGetBillingInfo(userData.jwt);

    if (billingResponse.response) {
      dispatch(billingSetData(billingResponse.response));
      await storeAsyncStorageData(billingResponse.response, USER_BILLING_INFO);
    } else {
      return false;
    }

    dispatch(userSetRole(billingResponse.response.roles));
    await storeAsyncStorageData({ ...userData, roles: billingResponse.response.roles }, USER_STORAGE_KEY);

    if (billingResponse.response.roles[0] !== ROLE.PRO) {
      dispatch(scheduleSetData([DEFAULT_SCHEDULE]));
      dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
      await storeAsyncStorageData([DEFAULT_SCHEDULE], CALENDAR_STORAGE_KEY);
      return true;
    }

    const schedulesFromApi = await scheduleGetAll(userData.jwt);
    if (!isObjectEmpty(schedulesFromApi.response)) {
      const newSchedules: ISchedule[] = [];
      newSchedules.push(DEFAULT_SCHEDULE);
      dispatch(scheduleSetData(newSchedules.concat(schedulesFromApi.response!)));
      dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
      await storeAsyncStorageData(newSchedules.concat(schedulesFromApi.response!), CALENDAR_STORAGE_KEY);
    } else {
      dispatch(scheduleSetData([DEFAULT_SCHEDULE]));
      dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
      await storeAsyncStorageData([DEFAULT_SCHEDULE], CALENDAR_STORAGE_KEY);
    }

    return true;
  } catch (error) {
    return false;
  }
}

export const isDateInPast = (date: string): boolean => {
  const diff = dayjs(date).diff(dayjs(), 'days');
  if (diff >= 0) {
    return false;
  }
  return true;
};

export const getCorrespondingDateForDateSelector = (date: string): string => {
  if (isDateInPast(date)) {
    return dayjs().format('YYYY-MM-DD');
  }
  return date;
};

export const isHourAGreater = (hourA: string, hourB: string) => {
  const currentDate = dayjs().format('YYYY-MM-DD');
  const diff = dayjs(`${currentDate} ${hourA}`).diff(dayjs(`${currentDate} ${hourB}`), 'minutes');

  return diff > 0;
};

export const isHourAGreaterDiff = (hourA: string, hourB: string) => {
  const currentDate = dayjs().format('YYYY-MM-DD');
  const diff = dayjs(`${currentDate} ${hourA}`).diff(dayjs(`${currentDate} ${hourB}`), 'minutes');

  return diff;
};

const getNumberFromHour = (hour: string): number => {
  const splittedHour = hour.split(':');
  return (Number(splittedHour[0]) * 100) + Number(splittedHour[1]);
};

const getMaxHours = (n1: string, n2: string) => {
  const number1 = getNumberFromHour(n1);
  const number2 = getNumberFromHour(n2);
  return number1 > number2 ? n1 : n2;
};

const addHour = (hour: string, minutesToAdd: number) => {
  if (getNumberFromHour(hour) + minutesToAdd > 2359) {
    return '23:59';
  }
  return dayjs(`${dayjs().format('YYYY-MM-DD')} ${hour}`).add(minutesToAdd, 'minutes').format('HH:mm');
};

export const getNewRanges = (events: Range[]) => {
  let i = 0;

  while (i + 1 < events.length) {
    const curEventFrom = events[i].from;
    const curEventTo = events[i].to;
    const nextEventFromNum = getNumberFromHour(events[i + 1].from);
    const nextEventTo = events[i + 1].to;

    if (nextEventFromNum < getNumberFromHour(addHour(curEventTo, 5))) {
      events = events.slice(0, i)
        .concat([{ from: curEventFrom, to: getMaxHours(curEventTo, nextEventTo) }])
        .concat(events.slice(i + 2));
    } else {
      i++;
    }
  }

  return events;
};

export const transformAgendaConfigToWeeklySchedule = (weekEvents: WeeklySchedule | null, agenda: IScheduleSettingsGetSettingsWithHashResponse): WeeklySchedule => {
  const week: WeeklySchedule = {};

  let ranges: Range[] = [];

  Object.keys(agenda).forEach((day) => {
    const eventsForDay = weekEvents![LOOKUP_DAYS[day]];

    ranges = getNewRanges((ranges.concat(agenda[day]!).concat(eventsForDay.events.map((event) => ({ from: event.range.from, to: event.range.to }))))
      .sort((a, b) => isHourAGreaterDiff(a.from, b.from)));

    week[LOOKUP_DAYS[day]] = {
      date: eventsForDay.date,
      events: ranges.map((range) => ({
        date: eventsForDay.date,
        range
      }))
    };

    ranges = [];
  });

  return week;
};

export const getTimeZone = () => Localization.timezone;

export const getLanguageCodeByLanguage = (language: string) => {
  switch (language) {
    case 'ES':
      return 'es-AR';
    case 'PT':
      return 'pt-PT';
    case 'EN':
      return 'en-IN';
    default:
      return 'en-IN';
  }
};
