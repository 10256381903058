import React, { useState } from 'react';
import {
  View, Text, useWindowDimensions, TextInput
} from 'react-native';
import { useSelector } from 'react-redux';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Feather';
import { useNavigation } from '@react-navigation/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useForm, Controller } from 'react-hook-form';
import styles, { functionStyles } from './styles';
import { showToastError } from '../../utils/toast';
import { MAIL_VALIDATION } from '../../utils/rules';
import Routes from '../../navigation/Routes';
import { authPasswordResetInit } from '../../services/auth';
import { AuthPassResetInit } from '../../services/auth/interfaces';
import { Colors } from '../../configs/enums';
import { selectLanguageKeys } from '../../redux/language';

export default (): React.ReactElement => {
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const getLabel = useSelector(selectLanguageKeys);
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit, control, formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    },
    shouldFocusError: true
  });

  const onSendMail = async (data: AuthPassResetInit) => {
    setIsLoading(true);
    data.email = data.email.toLowerCase();
    const response = await authPasswordResetInit(data);
    if (response.error) {
      showToastError(getLabel('error'), getLabel(response.errorMessage!));
    } else {
      navigation.navigate(Routes.OneTimePasscode, { params: data.email });
    }
    setIsLoading(false);
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View style={styles.middleContainer}>
          <View style={styles.textContainer}>
            <Icon
              style={styles.icon}
              name="life-buoy"
            />
            <Text style={styles.title}>{getLabel('recover_title')}</Text>
            <Text style={styles.text}>
              {getLabel('recover_subTitle')}
            </Text>
          </View>
        </View>
        <View style={styles.controllersContainer}>
          <Text style={styles.errorText}>{errors?.email?.message}</Text>
          <Controller
            name="email"
            control={control}
            rules={MAIL_VALIDATION(
              getLabel('rules_obligatory'),
              getLabel('rules_min_length_6'),
              getLabel('rules_max_length_31'),
              getLabel('rules_mail_regex')
            )}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextInput
                style={[styles.input, errors?.email?.message ? styles.invalid : styles.valid]}
                blurOnSubmit
                onBlur={onBlur}
                onChangeText={(inputValue) => onChange(inputValue)}
                value={value}
                placeholder={getLabel('placeholder_email')}
                placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                autoCompleteType="email"
              />
            )}
          />
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.continueButton}
            titleStyle={styles.continueTextButton}
            onPress={handleSubmit(onSendMail)}
            title={getLabel('recover_continue_button')}
            loading={isLoading}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
