import {
  StyleSheet
} from 'react-native';
import { Fonts } from '../../configs/enums';

export const CheckoutFinishedStyles = StyleSheet.create({
  container: {
    flex: 1
  },
  subContainer: {
    flexDirection: 'row',
    justifyContent: 'center'
  },
  iconFailed: {
    fontSize: 200,
    color: 'red'
  },
  iconSuccess: {
    fontSize: 200,
    color: 'green'
  },
  text: {
    fontSize: 20,
    fontFamily: Fonts.MANROPE_REGULAR
  }
});
