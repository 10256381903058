import { BASE_URL } from '@env';
import { API_METHOD, fetcher } from '..';
import {
  convertBase64ToBlob,
  getBase64Type,
  getFileNameFromUrl, getFormatFromUrl, imageToBase64, isIos, isWeb
} from '../../utils';
import { PHOTOS_NEW_PROFILE, PHOTO_GET_PROFILE } from '../api';

export const uploadPhoto = async (fileUrl: string, jwt: string) : Promise<string | null> => {
  try {
    const data = new FormData();
    const blob = await convertBase64ToBlob(fileUrl);
    if (isWeb) {
      data.append('image', blob, `image.${getBase64Type(fileUrl)}`);
    } else {
      data.append('image', {
        name: getFileNameFromUrl(fileUrl),
        type: getFormatFromUrl(fileUrl),
        uri: !isIos
          ? fileUrl
          : fileUrl.replace('file://', '')
      }, 'image.png');
    }

    const response = await fetch(`${BASE_URL}${PHOTOS_NEW_PROFILE}`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${jwt}`,
        accept: '*/*'
      }),
      body: data
    });

    const responseParsed = await response.json();

    if (responseParsed.uuid) {
      return responseParsed.uuid;
    }
    return null;
  } catch (error: any) {
    return null;
  }
};

export const getPhoto = async (jwt: string) : Promise<string | null> => {
  try {
    const response = await fetcher<string | null>(API_METHOD.GET, `${PHOTO_GET_PROFILE}`, undefined, {
      Authorization: `Bearer ${jwt}`
    }, undefined, undefined, 'arraybuffer');

    const base64Image = imageToBase64(response.response);

    if (base64Image === '') return null;

    return `data:image/png;base64,${base64Image}`;
  } catch (error: any) {
    return error;
  }
};
