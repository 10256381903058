import * as React from 'react';
import Svg, {
  Defs, G, Path, Text, TSpan
} from 'react-native-svg';
import { Fonts } from '../configs/enums';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function LogoLarge(props: any) {
  return (
    <Svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 480.68 115.27"
      {...props}
    >
      <Defs />
      <G id="Layer_1-2" data-name="Layer 1">
        <Path
          d="M102.36 8.59H83.29V1.03C81.61.38 79.8 0 77.89 0s-3.73.37-5.4 1.03V8.6H57.93V1.03C56.25.38 54.44 0 52.53 0s-3.73.37-5.4 1.03V8.6H32.57V1.03C30.89.38 29.08 0 27.17 0s-3.73.37-5.4 1.03V8.6H2.83C1.26 8.6 0 9.87 0 11.43v89.67c0 7.83 6.35 14.17 14.17 14.17h76.85c7.83 0 14.17-6.35 14.17-14.17V11.43c0-1.57-1.27-2.83-2.83-2.83z"
          fill="#335faa"
        />
        <Text
          transform="translate(117.63 93.7)"
          fill="#335faa"
          fontFamily={Fonts.UBUNTU_MEDIUM}
          fontSize="93.26px"
        >
          <TSpan x={0} y={0}>
            2Meetup
          </TSpan>
        </Text>
        <Path
          d="M61.13 28.98S33.88 76.6 31.75 75.6C27.23 73.48 14 60.6 14 60.6l-7.61 7.41s16.96 17.52 27.87 21.12c.02.01.4.11.44.13 7.93-4.98 36.36-55.91 35.8-55.41l-9.38-4.88zM100.09 33.85l-9.38-4.88S63.46 76.59 61.33 75.59c-1.15-.54-2.87-1.78-4.78-3.33-1.97 3.15-3.93 6.2-5.8 8.99 4.43 3.45 9.16 6.56 13.1 7.86.02.01.4.11.44.13 7.93-4.98 36.36-55.91 35.8-55.41z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
}

export default LogoLarge;
