import React, {
  useEffect,
  useState
} from 'react';
import {
  View,
  Text,
  useWindowDimensions,
  Image,
  TextInput,
  Pressable,
  Switch,
  Platform,
  StatusBar,
  ActivityIndicator
} from 'react-native';
import { Button } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Feather';
import Modal from 'react-native-modal';
import {
  useForm,
  Controller
} from 'react-hook-form';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { Picker } from '@react-native-picker/picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
  USER_STORAGE_KEY,
  USER_LANGUAGE_KEY,
  APP_VERSION
} from '@env';
import * as ImagePicker from 'expo-image-picker';
import Tooltip from 'react-native-walkthrough-tooltip';
import {
  globalSetProfilePhoto,
  selectProfilePhoto
} from '../../redux/global';
import { UserData } from '../../services/auth/interfaces';
import styles, { functionStyles } from './styles';
import {
  NAME_VALIDATION
} from '../../utils/rules';
import {
  showToastError,
  showToastSuccess
} from '../../utils/toast';
import Routes from '../../navigation/Routes';
import {
  isWeb,
  isIos,
  storeAsyncStorageData,
  getAsyncStorageData,
  removeAsyncStorageData
} from '../../utils';
import {
  Languages,
  Colors
} from '../../configs/enums';
import {
  selectUserLanguage,
  userSetLanguage,
  selectUserInfo,
  userSetNotifications,
  userSetModifyPersonalInformation,
  userSetAvatar
} from '../../redux/user';
import {
  userModifyAllowNotifications,
  areUserModifyInputsValid,
  userModifyUserData
} from '../../services/user';
import { FloatingButton } from '../../components/FloatingButton';
import {
  getPhoto,
  uploadPhoto
} from '../../services/photo';
import {
  languageUpdateKeys,
  languageUpdateSelectedLanguage,
  selectLanguageKeys
} from '../../redux/language';
import { lenguagesGetKeys } from '../../services/language';

interface IEditData {
  firstName: string;
  lastName: string;
  birthdate: string;
  phone: string;
  language: string;
  company: string;
  workPosition: string;
  workingHoursFrom: string;
  workingHoursTo: string;
}

export default (): React.ReactElement => {
  const userLanguage = useSelector(selectUserLanguage);
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const userInfo = useSelector(selectUserInfo);
  const userGlobalProfilePhoto = useSelector(selectProfilePhoto);

  const androidHeight = StatusBar.currentHeight;

  const getLabel = useSelector(selectLanguageKeys);

  const [birthDateVisible, setBirthdateVisible] = useState<boolean>(false);
  const [modalCalendar, setModalCalendar] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [sendNotifications, setSendNotifications] = useState<boolean>(userInfo.allowNotifications);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(userLanguage);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showModalPhoto, setShowModalPhoto] = useState(false);

  const [showToolTipChangePic, setShowToolTipChangePic] = useState(false);
  const [firstTimeTutorialChangePic, setFirstTimeTutorialChangePic] = useState(false);
  const [showToolTipEditProfile, setShowToolTipEditProfile] = useState(false);
  const [firstTimeTutorialEditProfile, setFirstTimeTutorialEditProfile] = useState(false);

  const [profilePicture, setProfilePicture] = useState<string | null>(null);

  const defaultValues = {
    firstName: userInfo.firstName,
    lastName: userInfo.lastName,
    workPosition: userInfo.workPosition,
    company: userInfo.company,
    language: userInfo.language
  };

  const {
    handleSubmit, reset, control, formState: { errors }
  } = useForm({
    defaultValues
  });

  const showCalendar = () => {
    if (isEnabled) {
      setBirthdateVisible(!modalCalendar);
    }
  };

  const toggleModalLanguage = () => {
    if (isEnabled) {
      setIsModalVisible(!isModalVisible);
    }
  };

  const allowNotifications = async () => {
    setSendNotifications(!sendNotifications);
    const response = await userModifyAllowNotifications({ allowNotifications: !sendNotifications }, userInfo.jwt);

    if (response.error) {
      showToastError(getLabel('profile_toast_error_notifications_title'), response.errorMessage!);
      setSendNotifications(!sendNotifications);
    } else {
      dispatch(userSetNotifications(!sendNotifications));
      const storagedData = await getAsyncStorageData<UserData>(USER_STORAGE_KEY);
      await storeAsyncStorageData({ ...storagedData, allowNotifications: !sendNotifications }, USER_STORAGE_KEY);
    }
  };

  const allowEdit = () => {
    if (firstTimeTutorialEditProfile) {
      setShowToolTipEditProfile(true);
      setFirstTimeTutorialEditProfile(false);
    } else {
      setIsEnabled(!isEnabled);
    }
  };

  const changeLanguage = async (itemValue: string) => {
    setSelectedLanguage(itemValue);
    dispatch(userSetLanguage(itemValue));

    setSelectedLanguage(itemValue);
    const language = await lenguagesGetKeys(itemValue);

    if (language.response) {
      dispatch(languageUpdateKeys(language.response));
      dispatch(languageUpdateSelectedLanguage(itemValue));

      await removeAsyncStorageData(USER_LANGUAGE_KEY);
      await storeAsyncStorageData({ keys: language.response, selectedLanguage: itemValue }, USER_LANGUAGE_KEY);
    }
  };

  const changeEmail = () => {
    navigation.navigate(Routes.ChangeEmail);
  };

  const changeOptionalEmail = () => {
    navigation.navigate(Routes.ChangeOptionalEmail);
  };

  const changePassword = () => {
    navigation.navigate(Routes.ChangePassword);
  };

  const goDeleteAccount = () => {
    navigation.navigate(Routes.DeleteAccount);
  };

  const editInfo = async (userData: IEditData) => {
    setIsLoading(true);
    userData.language = userLanguage;
    const validatedInputsResponse = await areUserModifyInputsValid(userData);
    if (validatedInputsResponse) {
      const response = await userModifyUserData(userData, userInfo.jwt);
      if (response.error) {
        showToastError(getLabel('profile_toast_error_edit_title'), response.errorMessage!);
        reset();
      } else {
        const storedData = await getAsyncStorageData<UserData>(USER_STORAGE_KEY);
        await storeAsyncStorageData({
          ...storedData,
          firstName: userData.firstName,
          lastName: userData.lastName,
          birthdate: userData.birthdate,
          phone: userData.phone,
          language: userData.language,
          company: userData.company,
          workPosition: userData.workPosition
        }, USER_STORAGE_KEY);
        dispatch(userSetModifyPersonalInformation(userData));
        showToastSuccess(getLabel('profile_toast_success_title'), getLabel('profile_toast_success_text'));
      }
    } else {
      showToastError(getLabel('profile_toast_error_validation_title'), getLabel('profile_toast_error_validation_text'));
    }
    setIsEnabled(false);
    setIsLoading(false);
  };

  const cancelEdit = () => {
    reset();
    setIsEnabled(false);
  };

  const toogleModalPhoto = () => {
    if (firstTimeTutorialChangePic) {
      setShowToolTipChangePic(true);
      setFirstTimeTutorialChangePic(false);
    } else {
      setShowModalPhoto(!showModalPhoto);
    }
  };

  const uploadImage = async (url: string) => {
    setIsLoadingImage(true);

    if (url) {
      const responseUuid = await uploadPhoto(url, userInfo.jwt);
      if (responseUuid) {
        showToastSuccess(getLabel('success'), getLabel('profile_photo_upload_success'));
        dispatch(userSetAvatar(responseUuid));
        await storeAsyncStorageData({ ...userInfo, avatar: responseUuid }, USER_STORAGE_KEY);

        const responseImage = await getPhoto(userInfo.jwt);

        if (responseImage) {
          dispatch(globalSetProfilePhoto(responseImage));
        }
      } else {
        showToastError(getLabel('error'), getLabel('profile_photo_upload_failed'));
      }
    }

    setIsLoadingImage(false);
  };

  const pickTakePhotoImage = async () => {
    const permit = await ImagePicker.requestCameraPermissionsAsync();
    if (permit.granted) {
      const result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.5
      });
      toogleModalPhoto();
      if (!result.cancelled) {
        setProfilePicture(result.uri);
        await uploadImage(result.uri);
      }
    } else {
      showToastError(getLabel('profile_toast_error_Permissions_title'), getLabel('profile_toast_error_Permissions_text_camera'));
    }
  };

  const pickGalleryImage = async () => {
    const permit = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (permit.granted) {
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        aspect: [1, 1],
        quality: 0.5
      });
      toogleModalPhoto();
      if (!result.cancelled) {
        setProfilePicture(result.uri);
        await uploadImage(result.uri);
      }
    } else {
      showToastError(getLabel('profile_toast_error_Permissions_title'), getLabel('profile_toast_error_Permissions_text_gallery'));
    }
  };

  function getProfilePhoto() {
    if (userGlobalProfilePhoto) {
      setProfilePicture(userGlobalProfilePhoto);
    }
  }

  useEffect(() => {
    getProfilePhoto();
  }, []);

  return (
    <View
      style={styles.mainContainer}
    >
      <KeyboardAwareScrollView style={styles.container} bounces={false}>
        <View style={
          functionStyles.mainContainer(windowDimensions.width)
        }
        >
          <View style={styles.topContainer}>
            <View>
              <Image
                source={profilePicture
                  ? { uri: profilePicture }
                  : require('../../../assets/Images/Guest-Picture.png')}
                style={styles.avatarImage}
              />
              {isLoadingImage && (
                <View style={styles.loadingImageContainer}>
                  <View style={styles.loadingImage}>
                    <ActivityIndicator size="large" color={Colors.PRIMARY_BUTTON_COLOR} />
                  </View>
                </View>
              )}
              <View style={styles.actionButtonView}>
                <Tooltip
                  isVisible={isWeb ? false : showToolTipChangePic}
                  content={<Text style={styles.toolTipText}>Presionando este botón, podrás cambiar tu foto de perfil</Text>}
                  placement="top"
                  onClose={() => setShowToolTipChangePic(false)}
                  topAdjustment={Platform.OS === 'android' ? (-androidHeight!) : 0}
                  contentStyle={styles.toolTipContainer}
                >
                  <FloatingButton
                    buttonStyle={styles.actionButton}
                    icon="camera-alt"
                    iconSize={15}
                    iconColor="white"
                    onPressFunction={toogleModalPhoto}
                  />
                </Tooltip>
              </View>
            </View>
            <View style={styles.userContainer}>
              <Text style={styles.textUserName}>
                {userInfo.firstName}
                {' '}
                {userInfo.lastName}
              </Text>
              <Text style={styles.textUserEmail}>{userInfo.company}</Text>
            </View>
          </View>

          <View style={styles.middleContainer}>
            <View style={styles.textContainer}>
              <Text style={styles.text}>{getLabel('profile_title_info')}</Text>
              {!isEnabled ? (
                <Tooltip
                  isVisible={isWeb ? false : showToolTipEditProfile}
                  content={<Text style={styles.toolTipText}>Presionando este botón, podrás editar tus datos personales</Text>}
                  placement="top"
                  onClose={() => setShowToolTipEditProfile(false)}
                  topAdjustment={Platform.OS === 'android' ? (-androidHeight!) : 0}
                  contentStyle={styles.toolTipContainer}
                >
                  <View style={styles.toolTipSettingsIcon}>
                    <Button
                      onPress={allowEdit}
                      type="clear"
                      icon={(
                        <Icon
                          style={styles.icon}
                          name="edit"
                        />
                      )}
                    />
                  </View>
                </Tooltip>
              ) : null}

            </View>
            <View style={styles.controllersContainer}>
              <Text style={styles.errorText}>{errors?.firstName?.message}</Text>
              <Controller
                name="firstName"
                rules={NAME_VALIDATION(
                  getLabel('rules_obligatory'),
                  getLabel('rules_min_length_2'),
                  getLabel('rules_max_length_31')
                )}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    style={[styles.input, errors?.firstName?.message ? styles.invalid : styles.valid]}
                    onChangeText={(text) => onChange(text)}
                    value={value}
                    placeholder={getLabel('placeholder_firstName')}
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                    editable={isEnabled}
                  />
                )}
              />
              <Text style={styles.errorText}>{errors?.lastName?.message}</Text>
              <Controller
                name="lastName"
                rules={NAME_VALIDATION(
                  getLabel('rules_obligatory'),
                  getLabel('rules_min_length_2'),
                  getLabel('rules_max_length_31')
                )}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextInput
                    style={[styles.input, errors?.lastName?.message ? styles.invalid : styles.valid]}
                    onChangeText={(text) => onChange(text)}
                    value={value}
                    placeholder={getLabel('placeholder_lastName')}
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                    editable={isEnabled}
                  />
                )}
              />
              <Text style={styles.errorText}>{errors?.workPosition?.message}</Text>
              <Controller
                name="workPosition"
                rules={{ required: false }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[styles.input, errors?.workPosition?.message ? styles.invalid : styles.valid]}
                    onBlur={onBlur}
                    onChangeText={(text) => onChange(text)}
                    value={value}
                    placeholder={getLabel('additional_info_actual_job')}
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                    editable={isEnabled}
                  />
                )}
              />
              <Text style={styles.errorText}>{errors?.company?.message}</Text>
              <Controller
                name="company"
                rules={{ required: false }}
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextInput
                    style={[styles.input, errors?.company?.message ? styles.invalid : styles.valid]}
                    onBlur={onBlur}
                    onChangeText={(text) => onChange(text)}
                    value={value}
                    placeholder={getLabel('additional_info_actual_company')}
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                    editable={isEnabled}
                  />
                )}
              />

              {isWeb ? (
                <Picker
                  style={styles.picker}
                  selectedValue={selectedLanguage}
                  onValueChange={changeLanguage}
                  enabled={isEnabled}
                >
                  {!isEnabled ? (
                    <Picker.Item key={0} label={Languages[userLanguage as keyof typeof Languages]} value={0} />
                  ) : (
                    getLabel('landing_language_label').map((language: string, index: number) => (
                      <Picker.Item key={language} label={language} value={Object.keys(Languages)[index]} />
                    ))) }
                </Picker>
              ) : (
                <View>
                  { isIos ? (
                    <Pressable
                      disabled={!isEnabled}
                      style={styles.mobilePickercontainer}
                      onPress={() => toggleModalLanguage()}
                    >
                      <Text style={{ paddingLeft: 10, flex: 1 }}>{Languages[selectedLanguage as keyof typeof Languages]}</Text>
                      <Icon
                        style={styles.dropdownIcon}
                        name="chevron-down"
                      />
                    </Pressable>
                  ) : (
                    <View style={[styles.androidPickercontainer]}>
                      <Picker
                        selectedValue={selectedLanguage}
                        onValueChange={changeLanguage}
                        enabled={isEnabled}
                      >
                        {!isEnabled ? (
                          <Picker.Item key={0} label={Languages[userLanguage as keyof typeof Languages]} value={0} />
                        ) : (
                          getLabel('landing_language_label').map((language: string, index: number) => (
                            <Picker.Item key={language} label={language} value={Object.keys(Languages)[index]} />
                          ))) }
                      </Picker>
                    </View>
                  )}
                </View>
              )}
            </View>
          </View>

          <View style={styles.subBottomContainer}>
            {isEnabled ? (
              <View>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={styles.registerButton}
                  titleStyle={styles.registerTextButton}
                  onPress={handleSubmit((data: any) => editInfo(data))}
                  title={getLabel('profile_button_accept')}
                  loading={isLoading}
                />
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={[styles.registerButton, styles.deleteButton]}
                  titleStyle={styles.registerTextButton}
                  onPress={cancelEdit}
                  title={getLabel('profile_button_cancel')}
                />
              </View>
            ) : null}
          </View>

          <View style={styles.subBottomContainer}>
            <Text style={styles.textNotifications}>{getLabel('profile_title_notifications')}</Text>
            <View style={styles.allowNotificationsContainer}>
              <Text style={styles.textAllowNotifications}>{getLabel('profile_allowNotifications')}</Text>
              <Switch
                trackColor={{ false: '#767577', true: '#31267C69' }}
                thumbColor={sendNotifications ? '#305fab' : '#f4f3f4'}
                ios_backgroundColor="#31267C69"
                onValueChange={allowNotifications}
                value={sendNotifications}
              />
            </View>
          </View>

          <View style={styles.bottomContainer}>
            <View style={styles.subBottomContainer}>
              <Text style={styles.textNotifications}>{getLabel('profile_title_account')}</Text>
              <View style={styles.subBottomContainer}>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={styles.registerButton}
                  titleStyle={styles.registerTextButton}
                  onPress={changeEmail}
                  title={getLabel('profile_button_changeEmail')}
                  disabled={isEnabled}
                />
              </View>
              <View style={styles.subBottomContainer}>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={styles.registerButton}
                  titleStyle={styles.registerTextButton}
                  onPress={changeOptionalEmail}
                  title={getLabel('change_optional_email_message')}
                  disabled={isEnabled}
                />
              </View>
              <View style={styles.subBottomContainer}>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={styles.registerButton}
                  titleStyle={styles.registerTextButton}
                  onPress={changePassword}
                  title={getLabel('profile_button_changePassword')}
                  disabled={isEnabled}
                />
              </View>
              <View style={styles.subBottomContainer}>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={[styles.registerButton, styles.deleteButton]}
                  titleStyle={styles.registerTextButton}
                  onPress={goDeleteAccount}
                  title={getLabel('profile_button_delete')}
                  disabled={isEnabled}
                />
              </View>
            </View>

          </View>

          <Text style={styles.versionCode}>{APP_VERSION}</Text>

        </View>
      </KeyboardAwareScrollView>
      <Modal
        isVisible={showModalPhoto}
        backdropTransitionOutTiming={0}
        deviceHeight={windowDimensions.height}
        deviceWidth={windowDimensions.width}
        backdropOpacity={0.7}
        backdropColor="white"
        onBackdropPress={toogleModalPhoto}
        animationIn="slideInUp"
        animationOut="slideOutDown"
        style={{ justifyContent: 'flex-end' }}
      >
        <View style={isWeb ? [functionStyles.modalPhotoWebContainer(windowDimensions.width), styles.modalPhotoContainer] : styles.modalPhotoContainer}>
          <Text style={styles.photoTitle}>{getLabel('profile_modal_permissions_title')}</Text>
          <View style={styles.photoIconsContainer}>
            <View style={styles.subPhotoIconsContainer}>
              <FloatingButton
                buttonStyle={styles.photoIcons}
                icon="camera"
                iconSize={24}
                iconColor="white"
                onPressFunction={pickTakePhotoImage}
              />
              <Text style={styles.photoText}>{getLabel('profile_modal_icon_camera')}</Text>
            </View>
            <View style={styles.subPhotoIconsContainer}>
              <FloatingButton
                buttonStyle={styles.photoIcons}
                icon="photo"
                iconSize={24}
                iconColor="white"
                onPressFunction={pickGalleryImage}
              />
              <Text style={styles.photoText}>{getLabel('profile_modal_icon_gallery')}</Text>
            </View>
          </View>
        </View>
      </Modal>
      <Modal
        isVisible={isModalVisible}
        backdropTransitionOutTiming={0}
        deviceHeight={windowDimensions.height}
        deviceWidth={windowDimensions.width}
        backdropOpacity={0}
        onBackdropPress={toggleModalLanguage}
        animationIn="fadeIn"
        animationOut="fadeOut"
      >
        <View style={styles.modalContainer}>
          <Picker
            selectedValue={selectedLanguage}
            onValueChange={changeLanguage}
          >
            {getLabel('landing_language_label').map((language:string, index:number) => (
              <Picker.Item key={index} label={language} value={Object.keys(Languages)[index]} />
            )) }
          </Picker>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.registerButton}
            titleStyle={styles.registerTextButton}
            onPress={toggleModalLanguage}
            title={getLabel('landing_modal_button')}
          />
        </View>
      </Modal>
    </View>
  );
};
