import React from 'react';
import { TextStyle } from 'react-native';
import { Button, Icon } from 'react-native-elements';

interface Props {
  buttonStyle: TextStyle;
  icon: string;
  iconSize: number;
  iconColor: string;
  onPressFunction: any;
}
export const FloatingButton = ({
  buttonStyle,
  icon,
  iconSize,
  iconColor,
  onPressFunction
}: Props) => (
  <Button
    buttonStyle={buttonStyle}
    onPress={onPressFunction}
    icon={
      <Icon name={icon} type="material" size={iconSize} color={iconColor} />
    }
  />
);
