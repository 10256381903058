import React, { useEffect, useState } from 'react';
import {
  View, Text, useWindowDimensions, ScrollView, Pressable, TextInput
} from 'react-native';
import { useSelector } from 'react-redux';
import { Button } from 'react-native-elements';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { StackActions, useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import NativePicker from '../../components/NativePicker';
import { acceptOrRejectInternalInviteFromExternal, eventGetFromExternal } from '../../services/events';
import { showToastError, showToastSuccess } from '../../utils/toast';
import {
  IAcceptOrRejectEventFromExternalRequest,
  IAppointments,
  IGetEventFromExternalResponse
} from '../../services/events/interface';
import styles, { functionStyles } from './style';
import { CustomModal } from '../../components/CustomModal';
import { isWeb } from '../../utils';
import {
  Colors, PERIODICITY, PERIODICITY_END, STATUS
} from '../../configs/enums';
import { selectLanguageKeys } from '../../redux/language';
import { selectUserLogged } from '../../redux/user';

const ViewEventLoader = (width: number) => (
  <View>
    <ContentLoader
      speed={2}
      width={isWeb ? 600 : width}
      height={700}
      viewBox={isWeb ? '0 00 600 700' : `0 00 ${width} 700`}
      backgroundColor={Colors.BACKGROUND_LOADER_COLOR}
      foregroundColor={Colors.FOREGROUND_LOADER_COLOR}
    >
      <Rect x="0" y="20" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="80" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="140" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="200" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="260" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="320" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="380" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="440" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="500" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="560" rx="4" ry="4" width="90%" height="40" />
      <Rect x="0" y="620" rx="4" ry="4" width="90%" height="40" />
    </ContentLoader>
  </View>
);

interface Props {
  route: any;
}

export default ({ route }: Props): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const navigation = useNavigation();
  const getLabel = useSelector(selectLanguageKeys);

  const [appointments, setAppointments] = useState<IAppointments[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [openModalShowParticipants, setOpenModalShowParticipants] = useState(false);

  const [openModalAcceptInvite, setOpenModalAcceptInvite] = useState(false);
  const [openModalRejectInvite, setOpenModalRejectInvite] = useState(false);

  const [isLoadingActionOfAcceptOrReject, setIsLoadingActionOfAcceptOrReject] = useState(false);

  const [periodicityType, setPeriodicityType] = useState(PERIODICITY.NEVER);
  const [periodicityEndType, setPeriodicityEndType] = useState(PERIODICITY_END.NEVER);
  const [periodicityEndDate, setPeriodicityEndDate] = useState(dayjs().format('YYYY-MM-DD'));

  const [externalEvent, setExternalEvent] = useState<IGetEventFromExternalResponse | null>(null);

  const userLogged = useSelector(selectUserLogged);

  const goBackScreen = () => {
    if (userLogged) {
      navigation.dispatch(StackActions.replace('Root', {
        screen: 'Main',
        params: {
          screen: 'HomeStack'
        }
      }));
    } else {
      navigation.dispatch(StackActions.replace('Landing'));
    }
  };

  useEffect(() => {
    (async () => {
      const response = await eventGetFromExternal(route.params.occasionUuid);

      const requestDetails = response.response;

      if (requestDetails) {
        setExternalEvent(requestDetails);

        requestDetails.periodicity && setPeriodicityType(requestDetails.periodicityType! as PERIODICITY);

        if (requestDetails.periodicity && requestDetails.periodicityTo) {
          setPeriodicityEndType(PERIODICITY_END.IN_DATE);
          setPeriodicityEndDate(requestDetails.periodicityTo);
        }

        !requestDetails.periodicity && setPeriodicityEndType(PERIODICITY_END.NEVER);

        setIsLoading(false);
      } else {
        showToastError(getLabel('error'), getLabel(response.errorMessage!));
        goBackScreen();
      }
    })();
  }, []);

  const openParticipantsModal = () => {
    setOpenModalShowParticipants(!openModalShowParticipants);
  };

  const onAcceptInvite = async () => {
    setIsLoadingActionOfAcceptOrReject(true);
    const request: IAcceptOrRejectEventFromExternalRequest = {
      occasionUuid: route.params.occasionUuid,
      guestEmail: route.params.email,
      accept: true
    };

    const response = await acceptOrRejectInternalInviteFromExternal(request);

    if (response.response) {
      showToastSuccess(getLabel('global_success'), getLabel('event_accept_success'));
      goBackScreen();
    } else {
      showToastError(getLabel('error'), getLabel('event_accept_error'));
    }

    setIsLoadingActionOfAcceptOrReject(false);
    setOpenModalAcceptInvite(false);
  };

  const onRejectInvite = async () => {
    setIsLoadingActionOfAcceptOrReject(true);
    const request: IAcceptOrRejectEventFromExternalRequest = {
      occasionUuid: route.params.occasionUuid,
      guestEmail: route.params.email,
      accept: false
    };

    const response = await acceptOrRejectInternalInviteFromExternal(request);

    if (response.response) {
      showToastSuccess(getLabel('global_success'), getLabel('event_reject_success'));
      goBackScreen();
    } else {
      showToastError(getLabel('error'), getLabel('event_reject_error'));
    }

    setIsLoadingActionOfAcceptOrReject(false);
    setOpenModalRejectInvite(false);
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        {isLoading ? (
          ViewEventLoader(windowDimensions.width)
        ) : (
          <>
            <View style={styles.firstContainer}>
              <Text style={styles.mainTitle}>{getLabel('notifications_singleComponent_title_info')}</Text>
            </View>

            <View style={styles.middleContainer}>
              <Text style={styles.nameTitle}>{`${externalEvent!.ownerName} ${getLabel('external_event_invited_to')}`}</Text>
            </View>

            <View style={styles.middleContainer}>
              <View style={styles.scondaryTitlesContainer}>
                <Text style={styles.scondaryTitles}>{getLabel('notificationsDetail_subTitle_1')}</Text>
              </View>

              <Text style={styles.labelText}>{getLabel('notification_details_title')}</Text>
              <View style={styles.infoContainer}>
                <Text>{externalEvent!.title}</Text>
              </View>

              <Text style={styles.labelText}>{getLabel('notification_details_date')}</Text>
              <View style={styles.infoContainer}>
                <Text>{externalEvent!.date}</Text>
              </View>
              <View style={styles.hoursContainer}>
                <View>
                  <Text style={styles.labelText}>{getLabel('event_start_time')}</Text>
                  <View style={styles.hourInfoContainer}>
                    <Text>{externalEvent!.range.from}</Text>
                  </View>
                </View>
                <View>
                  <Text style={styles.labelText}>{getLabel('event_end_time')}</Text>
                  <View style={styles.hourInfoContainer}>
                    <Text>{externalEvent!.range.to}</Text>
                  </View>
                </View>
              </View>

              <Text style={styles.labelText}>{getLabel('event_type_meet')}</Text>
              <View style={styles.infoContainer}>
                <Text>{getLabel('occasion_type_info')[externalEvent!.occasionType]}</Text>
              </View>

              {externalEvent!.occasionMeetData && (
                <>
                  <Text>{getLabel('notification_details_detail_type_meet')}</Text>
                  <View style={styles.infoContainer}>
                    <Text>{externalEvent!.occasionMeetData}</Text>
                  </View>
                </>
              )}

            </View>

            {/* Periodicidad */}
            <View style={styles.middleContainer}>
              <Text style={styles.scondaryTitles}>{getLabel('periodicity_repeat_title')}</Text>
              <View style={styles.someMargin}>
                <NativePicker
                  data={getLabel('occasion_periodicity')}
                  value={periodicityType}
                  setValue={setPeriodicityType}
                  enabled
                />
              </View>
            </View>

            {
              periodicityType !== PERIODICITY.NEVER && (
                <View style={styles.someMargin}>
                  <Text style={styles.labelText}>{getLabel('periodicity_end_title')}</Text>
                  <NativePicker
                    data={getLabel('occasion_periodicity_end')}
                    value={periodicityEndType}
                    setValue={setPeriodicityEndType}
                    enabled
                  />
                </View>
              )
            }

            {
              periodicityType !== PERIODICITY.NEVER
              && periodicityEndType === PERIODICITY_END.IN_DATE && (
                <View>
                  <View style={styles.someMargin}>
                    <Pressable disabled style={{ width: '100%' }}>
                      <View pointerEvents="none">
                        <Text style={styles.labelText}>{getLabel('periodicity_date_title')}</Text>
                        <TextInput
                          style={styles.input}
                          value={periodicityEndDate}
                        />
                      </View>
                    </Pressable>
                  </View>
                </View>
              )
            }

            <View style={styles.middleContainer}>
              <View style={styles.scondaryTitlesContainer}>
                <Text style={styles.scondaryTitles}>{getLabel('notificationsDetail_subTitle_3')}</Text>
              </View>
              <View style={[styles.infoContainer, styles.descriptionHeight]}>
                <Text>{externalEvent!.description}</Text>
              </View>
            </View>

            <View style={styles.bottomContainer}>
              <View style={styles.subBottomContainer}>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={styles.mainButton}
                  titleStyle={styles.mainTextButton}
                  onPress={() => setOpenModalAcceptInvite(true)}
                  title={getLabel('notificationsDetail_button_accept')}
                />
              </View>
              <View style={styles.subBottomContainer}>
                <Button
                  containerStyle={styles.buttonContainer}
                  buttonStyle={[styles.mainButton, styles.deleteButton]}
                  titleStyle={styles.mainTextButton}
                  onPress={() => setOpenModalRejectInvite(true)}
                  title={getLabel('notificationsDetail_button_reject')}
                />
              </View>
            </View>
          </>
        )}
      </View>

      <CustomModal isVisible={openModalShowParticipants}>
        <View style={styles.modalGuestsContainer}>
          <Text style={[styles.title, styles.modalParticipanTitle]}>{getLabel('event_participants')}</Text>
          <ScrollView style={styles.participantList} bounces={false} showsVerticalScrollIndicator={false}>
            {appointments.map((participant) => (
              <View key={participant.email} style={styles.modalInfoContainer}>
                <View style={styles.modalParticipantsContainer}>
                  <Text style={styles.modalParticipantEmail}>{participant.email}</Text>
                </View>
                <View style={[styles.viewCircle, participant.status === STATUS.ACCEPTED ? styles.aproved : participant.status === STATUS.PENDING ? styles.pending : styles.reject]} />
              </View>
            ))}
          </ScrollView>
          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={openParticipantsModal}
            title={getLabel('generic_accept_button_title')}
          />
        </View>
      </CustomModal>

      <CustomModal isVisible={openModalAcceptInvite}>
        <View style={styles.modalAcceptRejectContainer}>
          <Text style={styles.modalAcceptRejectTitle}>{getLabel('event_accept_meeting')}</Text>

          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={onAcceptInvite}
            title={getLabel('generic_accept_button_title')}
            loading={isLoadingActionOfAcceptOrReject}
          />
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.addEventButton, styles.deleteButton]}
            titleStyle={styles.addEventTextButton}
            onPress={() => setOpenModalAcceptInvite(false)}
            title={getLabel('cancel')}
            disabled={isLoadingActionOfAcceptOrReject}
          />
        </View>
      </CustomModal>

      <CustomModal isVisible={openModalRejectInvite}>
        <View style={styles.modalAcceptRejectContainer}>
          <Text style={styles.modalAcceptRejectTitle}>{getLabel('event_reject_meeting')}</Text>
          <Text style={styles.subTitleReject}>{getLabel('event_reject_meeting_subtitle')}</Text>
          <Button
            containerStyle={[styles.buttonContainer, styles.modalButton]}
            buttonStyle={styles.addEventButton}
            titleStyle={styles.addEventTextButton}
            onPress={onRejectInvite}
            title={getLabel('confirm')}
            loading={isLoadingActionOfAcceptOrReject}
          />
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.addEventButton, styles.deleteButton]}
            titleStyle={styles.addEventTextButton}
            onPress={() => setOpenModalRejectInvite(false)}
            title={getLabel('cancel')}
            disabled={isLoadingActionOfAcceptOrReject}
          />
        </View>
      </CustomModal>
    </KeyboardAwareScrollView>
  );
};
