import { LocaleConfig } from 'react-native-calendars';
import dayjs from 'dayjs';

const localeData = require('dayjs/plugin/localeData');

dayjs.extend(localeData);

export const calendarLocaleConfig = (locale: any) => {
  LocaleConfig.locales[locale] = {
    monthNames: dayjs.months(),
    monthNamesShort: dayjs.monthsShort(),
    dayNames: dayjs.weekdays(),
    dayNamesShort: dayjs.weekdaysShort()
  };

  LocaleConfig.defaultLocale = locale;
};
