import * as React from 'react';
import Svg, {
  Defs, G, Path, Text, TSpan
} from 'react-native-svg';
import { Fonts } from '../configs/enums';

function Logo(props: any) {
  return (
    <Svg
      id="Layer_2"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 105.43 150.81"
      {...props}
    >
      <Defs />
      <G id="Layer_1-2" data-name="Layer 1">
        <Path
          d="M102.48 8.59H83.41V1.03C81.73.38 79.92 0 78.01 0s-3.73.37-5.4 1.03V8.6H58.05V1.03C56.37.38 54.56 0 52.65 0s-3.73.37-5.4 1.03V8.6H32.69V1.03C31.01.38 29.2 0 27.29 0s-3.73.37-5.4 1.03V8.6H2.95a2.821 2.821 0 00-2.83 2.83v89.67c0 7.83 6.35 14.17 14.17 14.17h76.85c7.83 0 14.17-6.35 14.17-14.17V11.43c0-1.57-1.27-2.83-2.83-2.83z"
          fill="#335faa"
        />
        <Text
          transform="translate(0 145.25)"
          fill="#335faa"
          fontFamily={Fonts.UBUNTU_MEDIUM}
          fontSize="27.08px"
        >
          <TSpan x={0} y={0}>
            2Meetup
          </TSpan>
        </Text>
        <Path
          d="M61.25 28.98S34 76.6 31.87 75.6c-4.52-2.12-17.75-15-17.75-15l-7.61 7.41s16.96 17.52 27.87 21.12c.02.01.4.11.44.13 7.93-4.98 36.36-55.91 35.8-55.41l-9.38-4.88zM100.21 33.85l-9.38-4.88S63.58 76.59 61.45 75.59c-1.15-.54-2.87-1.78-4.78-3.33-1.97 3.15-3.93 6.2-5.8 8.99 4.43 3.45 9.16 6.56 13.1 7.86.02.01.4.11.44.13 7.93-4.98 36.36-55.91 35.8-55.41z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
}

export default Logo;
