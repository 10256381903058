import { getTimeZone } from '../../utils';
import {
  EventCalendar, EventCalendarNEW, EventLight, EventLightNEW
} from '../../components/Calendar/interfaces';
import {
  EDIT_EVENT_ACCEPT_INVITE,
  EVENTS_ACCEPT_INTERNAL,
  EVENTS_ACCEPT_REJECT_EXTERNAL,
  EVENTS_APPOINTMENTS_STATE,
  EVENTS_BEEING_EXTERNAL_ACCEPT_REJECT_INTERNAL,
  EVENTS_CHANGE_INTERNAL,
  EVENTS_COULD_INVITE,
  EVENTS_CRUD,
  EVENTS_IMPORT,
  EVENTS_LIGHT_BETWEEN_DATES,
  EVENTS_LIGHT_BETWEEN_DATES_BY_HASHLINK,
  EVENTS_LIGHT_CRUD,
  EVENT_GET_BEEING_EXTERNAL,
  GET_INVITE_DETAILS,
  GET_PENDING_INVITES
} from '../api';
import {
  EventDetails,
  EventDetailsNEW,
  IAcceptOrRejectEditAppointmentRequest,
  IAcceptOrRejectEventFromExternalRequest,
  IAcceptOrRejectEventInternalRequest,
  IAcceptRejectEventExternalRequest,
  IAppointmentChangeInternal,
  IAppointments,
  IAppointmentsGet,
  ICouldInviteRequest,
  IEventCreate,
  IEventCreateExternal,
  IEventCreateResponse,
  IEventEdit,
  IEventExternal,
  IEventExternalNEW,
  IEventFromApi,
  IEventFromApiNEW,
  IEventGet,
  IGetEventFromExternalResponse,
  IGetEventFromExternalResponseNEW,
  IGetLightEvents,
  IGetLightEventsBetweenDates,
  IGetLightEventsBetweenDatesByHashLinkRequest,
  ImportEventFromIcsRequest,
  PendingRequestDetails,
  PendingRequestDetailsNEW,
  PendingRequestsLight,
  PendingRequestsLightNEW
} from './interface';
import { FetcherResponse } from '../FetcherResponse';
import { API_METHOD, fetcher } from '..';

export const eventCreate = async (request: IEventCreate, jwt:string) : Promise<FetcherResponse<IEventCreateResponse>> => {
  try {
    const response = await fetcher<IEventCreateResponse>(API_METHOD.POST, EVENTS_CRUD, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const eventCreateExternal = async (request: IEventCreateExternal, jwt:string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, `${EVENTS_CRUD}/external`, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const eventEdit = async (request: IEventEdit, jwt:string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.PUT, EVENTS_CRUD, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const eventDelete = async (occasionUuid: string, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.DELETE, `${EVENTS_LIGHT_CRUD}/${occasionUuid}`, undefined, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const eventGet = async (request: IEventGet, jwt: string, signal: AbortSignal) : Promise<FetcherResponse<IEventFromApi>> => {
  try {
    const response = await fetcher<IEventFromApiNEW>(API_METHOD.GET, `${EVENTS_CRUD}/${request.uuid}`, undefined, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    }, undefined, signal);

    const eventFromApi = response.response;

    const { from, to, ...rest } = response.response!;

    const newEventFromApi = {
      range: {
        from: eventFromApi!.from.split(' ')[1].substring(0, 5),
        to: eventFromApi!.to.split(' ')[1].substring(0, 5)
      },
      date: eventFromApi!.from.split(' ')[0],
      ...rest
    };

    return new FetcherResponse(
      response.error,
      response.errorMessage,
      newEventFromApi
    );
  } catch (error: any) {
    return error;
  }
};

export const appointmentsGet = async (request: IAppointmentsGet, jwt: string, signal: AbortSignal) : Promise<FetcherResponse<IAppointments[]>> => {
  try {
    const response = await fetcher<IAppointments[]>(API_METHOD.GET, `${EVENTS_APPOINTMENTS_STATE}/${request.uuid}`, undefined, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    }, undefined, signal);
    return response;
  } catch (error: any) {
    return error;
  }
};

export const eventsLightForADay = async (request: IGetLightEvents, jwt: string) : Promise<FetcherResponse<EventLight[]>> => {
  try {
    let url;

    if (request.scheduleUuid) {
      url = `${EVENTS_LIGHT_CRUD}?scheduleUuid=${request.scheduleUuid}&date=${request.date}`;
    } else {
      url = `${EVENTS_LIGHT_CRUD}?date=${request.date}`;
    }

    const response = await fetcher<EventLightNEW[]>(API_METHOD.GET, url, undefined, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });

    let newEventCalendars: EventLight[] = [];

    if (response.response) {
      newEventCalendars = response.response.map((eventCalendar) => {
        const { from, to, ...rest } = eventCalendar;

        return {
          range: {
            from: eventCalendar.from.split(' ')[1].substring(0, 5),
            to: eventCalendar.to.split(' ')[1].substring(0, 5)
          },
          date: eventCalendar.from.split(' ')[0],
          ...rest
        };
      });

      return new FetcherResponse(
        response.error,
        response.errorMessage,
        newEventCalendars
      );
    }

    return new FetcherResponse(
      response.error,
      response.errorMessage,
      []
    );
  } catch (error: any) {
    return error;
  }
};

export const eventsLightBetweenDates = async (request: IGetLightEventsBetweenDates, jwt: string) : Promise<FetcherResponse<EventLight[]>> => {
  try {
    const response = await fetcher<EventLightNEW[]>(API_METHOD.POST, `${EVENTS_LIGHT_BETWEEN_DATES}`, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });

    let newEventCalendars: EventLight[] = [];

    if (response.response) {
      newEventCalendars = response.response.map((eventCalendar) => {
        const { from, to, ...rest } = eventCalendar;

        return {
          range: {
            from: eventCalendar.from.split(' ')[1].substring(0, 5),
            to: eventCalendar.to.split(' ')[1].substring(0, 5)
          },
          date: eventCalendar.from.split(' ')[0],
          ...rest
        };
      });

      return new FetcherResponse(
        response.error,
        response.errorMessage,
        newEventCalendars
      );
    }

    return new FetcherResponse(
      response.error,
      response.errorMessage,
      []
    );
  } catch (error: any) {
    return error;
  }
};

export const eventsLightBetweenDatesByHashLink = async (request: IGetLightEventsBetweenDatesByHashLinkRequest) : Promise<FetcherResponse<EventCalendar[]>> => {
  try {
    const response = await fetcher<EventCalendarNEW[]>(
      API_METHOD.GET,
      `${EVENTS_LIGHT_BETWEEN_DATES_BY_HASHLINK}/${request.hashLink}/${request.from}/${request.to}`,
      undefined,
      {
        'X-Time-Zone': getTimeZone()
      }
    );

    let newEventCalendars: EventCalendar[] = [];

    if (response.response) {
      newEventCalendars = response.response.map((eventCalendar) => {
        const { from, to, ...rest } = eventCalendar;

        return {
          range: {
            from: eventCalendar.from.split(' ')[1].substring(0, 5),
            to: eventCalendar.to.split(' ')[1].substring(0, 5)
          },
          date: eventCalendar.from.split(' ')[0],
          ...rest
        };
      });

      return new FetcherResponse(
        response.error,
        response.errorMessage,
        newEventCalendars
      );
    }

    return new FetcherResponse(
      response.error,
      response.errorMessage,
      []
    );
  } catch (error: any) {
    return error;
  }
};

export const eventsExternal = async (jwt: string) : Promise<FetcherResponse<IEventExternal[]>> => {
  try {
    const response = await fetcher<IEventExternalNEW[]>(API_METHOD.GET, `${EVENTS_CRUD}/external`, undefined, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });

    let newEventsExternal: IEventExternal[] = [];

    if (response.response) {
      newEventsExternal = response.response.map((eventCalendar) => {
        const { from, to, ...rest } = eventCalendar;

        return {
          range: {
            from: eventCalendar.from.split(' ')[1].substring(0, 5),
            to: eventCalendar.to.split(' ')[1].substring(0, 5)
          },
          date: eventCalendar.from.split(' ')[0],
          ...rest
        };
      });

      return new FetcherResponse(
        response.error,
        response.errorMessage,
        newEventsExternal
      );
    }

    return new FetcherResponse(
      response.error,
      response.errorMessage,
      []
    );
  } catch (error: any) {
    return error;
  }
};

export const eventsCouldInvite = async (request: ICouldInviteRequest, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, `${EVENTS_COULD_INVITE}`, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });

    return response;
  } catch (error: any) {
    return error;
  }
};

export const acceptOrRejectInternalInvite = async (request: IAcceptOrRejectEventInternalRequest, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, `${EVENTS_ACCEPT_INTERNAL}`, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const acceptOrRejectExternalInvite = async (request: IAcceptRejectEventExternalRequest, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, `${EVENTS_ACCEPT_REJECT_EXTERNAL}`, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const appointmentInternalChange = async (request: IAppointmentChangeInternal, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.PUT, `${EVENTS_CHANGE_INTERNAL}`, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const acceptOrRejectInternalInviteFromExternal = async (request: IAcceptOrRejectEventFromExternalRequest) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, `${EVENTS_BEEING_EXTERNAL_ACCEPT_REJECT_INTERNAL}`, request, {
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const eventGetFromExternal = async (uuid: string) : Promise<FetcherResponse<IGetEventFromExternalResponse>> => {
  try {
    const response = await fetcher<IGetEventFromExternalResponseNEW>(API_METHOD.GET, `${EVENT_GET_BEEING_EXTERNAL}/${uuid}`, undefined, {
      'X-Time-Zone': getTimeZone()
    });

    const eventExternal = response.response;

    const { from, to, ...rest } = response.response!;

    const newEventCalendars = {
      range: {
        from: eventExternal!.from.split(' ')[1].substring(0, 5),
        to: eventExternal!.to.split(' ')[1].substring(0, 5)
      },
      date: eventExternal!.from.split(' ')[0],
      ...rest
    };

    return new FetcherResponse(
      response.error,
      response.errorMessage,
      newEventCalendars
    );
  } catch (error: any) {
    return error;
  }
};

export const acceptOrRejectAppointmentRequest = async (request: IAcceptOrRejectEditAppointmentRequest, jwt: string) : Promise<FetcherResponse<IAcceptOrRejectEditAppointmentRequest>> => {
  try {
    const response = await fetcher<IAcceptOrRejectEditAppointmentRequest>(API_METHOD.POST, `${EDIT_EVENT_ACCEPT_INVITE}`, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const getEventDetails = async (uuid: string, jwt: string) : Promise<FetcherResponse<EventDetails>> => {
  try {
    const response = await fetcher<EventDetailsNEW>(API_METHOD.GET, `${EVENTS_CRUD}/${uuid}`, undefined, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });

    const eventDetails = response.response;

    const { from, to, ...rest } = response.response!;

    const newEventDetails = {
      range: {
        from: eventDetails!.from.split(' ')[1].substring(0, 5),
        to: eventDetails!.to.split(' ')[1].substring(0, 5)
      },
      date: eventDetails!.from.split(' ')[0],
      ...rest
    };

    return new FetcherResponse(
      response.error,
      response.errorMessage,
      newEventDetails
    );
  } catch (error: any) {
    return error;
  }
};

export const getPendingRequestsLight = async (jwt: string) : Promise<FetcherResponse<PendingRequestsLight[]>> => {
  try {
    const response = await fetcher<PendingRequestsLightNEW[]>(API_METHOD.GET, `${GET_PENDING_INVITES}`, undefined, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });

    let newPendingRequestsLight: PendingRequestsLight[] = [];

    if (response.response) {
      newPendingRequestsLight = response.response.map((pendRequestLight) => {
        const { from, to, ...rest } = pendRequestLight;

        return {
          range: {
            from: pendRequestLight.from.split(' ')[1].substring(0, 5),
            to: pendRequestLight.to.split(' ')[1].substring(0, 5)
          },
          date: pendRequestLight.from.split(' ')[0],
          ...rest
        };
      });

      return new FetcherResponse(
        response.error,
        response.errorMessage,
        newPendingRequestsLight
      );
    }

    return new FetcherResponse(
      response.error,
      response.errorMessage,
      []
    );
  } catch (error: any) {
    return error;
  }
};

export const getPendingRequestsDetails = async (uuid: string, jwt: string) : Promise<FetcherResponse<PendingRequestDetails>> => {
  try {
    const response = await fetcher<PendingRequestDetailsNEW>(API_METHOD.GET, `${GET_INVITE_DETAILS}/${uuid}`, undefined, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });

    const pendingRequestsDetails = response.response;

    const { from, to, ...rest } = response.response!;

    const newPendingRequestsDetails = {
      range: {
        from: pendingRequestsDetails!.from.split(' ')[1].substring(0, 5),
        to: pendingRequestsDetails!.to.split(' ')[1].substring(0, 5)
      },
      date: pendingRequestsDetails!.from.split(' ')[0],
      ...rest
    };

    return new FetcherResponse(
      response.error,
      response.errorMessage,
      newPendingRequestsDetails
    );
  } catch (error: any) {
    return error;
  }
};

export const importEventFromIcs = async (request: ImportEventFromIcsRequest, jwt: string) : Promise<FetcherResponse<any>> => {
  try {
    const response = await fetcher<ImportEventFromIcsRequest>(API_METHOD.POST, `${EVENTS_IMPORT}`, request, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};
