/* eslint-disable no-unused-vars */
import axios, {
  AxiosError, AxiosRequestHeaders, CancelToken, ResponseType
} from 'axios';
import { BASE_URL } from '@env';
import { FetcherResponse } from './FetcherResponse';

export enum API_METHOD {
  PUT = 'put',
  POST = 'post',
  GET = 'get',
  DELETE = 'delete'
}

export enum RESPONSE_CODE {
  SUCCESS_200 = 200,
  SUCCESS_201 = 201,
  FAILED_400 = 400,
  FAILED_401 = 401,
  FAILED_403 = 403,
  FAILED_404 = 404,
  FAILED_409 = 409,
  FAILED_SERVER = 500
}

export enum RESPONSE_ERROR_MESSAGES {
  FAILED_GENERIC = 'backend_error_generic',
  FAILED_CANCELLED = 'backend_error_generic'
}

export interface FetcherErrorResponse {
  error: string;
  description: string;
  code: string;
  userFriendlyMessage: string;
}

/**
 *
 * @param method
 * @param url
 * @param body
 * @param queryParam
 * @returns
 */
export const fetcher = async <T extends unknown>(
  method: API_METHOD,
  url: string,
  data?: any,
  headers?: AxiosRequestHeaders,
  params?: URLSearchParams,
  signal?: AbortSignal,
  responseType?: ResponseType
):
  Promise<FetcherResponse<T>> => {
  try {
    const response = await axios({
      baseURL: BASE_URL,
      method,
      url,
      params,
      data,
      headers,
      signal,
      responseType
    });

    if (response.status === RESPONSE_CODE.SUCCESS_200 || response.status === RESPONSE_CODE.SUCCESS_201) {
      return new FetcherResponse<T>(false, undefined, response.data);
    }

    return new FetcherResponse<T>(true, RESPONSE_ERROR_MESSAGES.FAILED_GENERIC, undefined);
  } catch (error: unknown | AxiosError<FetcherErrorResponse>) {
    if (axios.isAxiosError(error)) {
      const { status } = error.response!;
      const errorData = error.response?.data as FetcherErrorResponse;
      const { code } = errorData;
      const errorMessage = code ?? 'backend_error_generic';

      if (status === RESPONSE_CODE.FAILED_400) {
        return new FetcherResponse<T>(true, errorMessage, undefined);
      }
      if (status === RESPONSE_CODE.FAILED_401) {
        return new FetcherResponse<T>(true, errorMessage, undefined);
      }
      if (status === RESPONSE_CODE.FAILED_403) {
        return new FetcherResponse<T>(true, errorMessage, undefined);
      }
      if (status === RESPONSE_CODE.FAILED_403) {
        return new FetcherResponse<T>(true, errorMessage, undefined);
      }
      if (status === RESPONSE_CODE.FAILED_404) {
        return new FetcherResponse<T>(true, errorMessage, undefined);
      }
      if (status === RESPONSE_CODE.FAILED_409) {
        return new FetcherResponse<T>(true, errorMessage, undefined);
      }
      return new FetcherResponse<T>(true, RESPONSE_ERROR_MESSAGES.FAILED_GENERIC, undefined);
    }
    if (axios.isCancel(error)) {
      return new FetcherResponse<T>(true, RESPONSE_ERROR_MESSAGES.FAILED_CANCELLED, undefined);
    }
    return new FetcherResponse<T>(true, RESPONSE_ERROR_MESSAGES.FAILED_GENERIC, undefined);
  }
};
