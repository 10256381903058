import { StyleSheet } from 'react-native';
import { Colors, Fonts } from '../../configs/enums';

const HelpStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  pressableText: {
    alignSelf: 'center',
    marginTop: 20
  },
  text: {
    fontSize: 20,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  closeWebView: {
    marginHorizontal: 16
  }
});

export default HelpStyles;
