import React, { useEffect, useState } from 'react';
import {
  View, Text, useWindowDimensions
} from 'react-native';
import { Button, CheckBox } from 'react-native-elements';
import Icon from 'react-native-vector-icons/Feather';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { useDispatch, useSelector } from 'react-redux';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { useNavigation } from '@react-navigation/native';
import Routes from '../../navigation/Routes';
import { showToastError, showToastInfo, showToastSuccess } from '../../utils/toast';
import {
  selectorAgendaOrganizerDay, selectorAgendaOrganizerWeek, agendaOrganizerSetDay, agendaOrganizerSetWeek, agendaOrganizerResetState
} from '../../redux/agendaOrganizer';
import {
  selectUserInfo, selectUserFirstLogin
} from '../../redux/user';
import styles, { functionStyles } from './styles';
import { CustomModal } from '../../components/CustomModal';
import { isWeb } from '../../utils';
import { TIME_HOURS_REGEX, WEEK_DAYS } from '../../utils/constants';
import AgendaDay from '../../components/AgendaDay';
import {
  scheduleSettingsCreateSettings, scheduleSettingsGetInfo, scheduleSettingsUpdateSettings
} from '../../services/scheduleSettings';
import { Colors } from '../../configs/enums';
import { IAvailableTime } from '../../services/scheduleSettings/interface';
import NativeDateTimePicker from '../../components/NativeDateTimePicker';
import { selectLanguageKeys } from '../../redux/language';

interface Props {
  route: any;
}

const AgendaDayLoader = () => (
  <View>
    <ContentLoader
      speed={2}
      width={isWeb ? 600 : 350}
      height={700}
      viewBox={isWeb ? '0 00 800 700' : '0 00 350 700'}
      backgroundColor={Colors.BACKGROUND_LOADER_COLOR}
      foregroundColor={Colors.FOREGROUND_LOADER_COLOR}
    >
      <Rect x="0" y="0" rx="4" ry="4" width="40" height="40" />
      <Rect x="0" y="60" rx="4" ry="4" width="80%" height="40" />
      <Rect x="0" y="120" rx="4" ry="4" width="40%" height="40" />

      <Rect x="0" y="200" rx="4" ry="4" width="100%" height="40" />
      <Rect x="0" y="260" rx="4" ry="4" width="100%" height="40" />
      <Rect x="0" y="320" rx="4" ry="4" width="100%" height="40" />
      <Rect x="0" y="380" rx="4" ry="4" width="100%" height="40" />
      <Rect x="0" y="440" rx="4" ry="4" width="100%" height="40" />
      <Rect x="0" y="500" rx="4" ry="4" width="100%" height="40" />
      <Rect x="0" y="560" rx="4" ry="4" width="100%" height="40" />

      <Rect x="20" y="640" rx="4" ry="4" width="90%" height="50" />

    </ContentLoader>
  </View>
);

export default ({ route }: Props): React.ReactElement => {
  const navigation = useNavigation();

  const windowDimensions = useWindowDimensions();
  const dispatch = useDispatch();
  const agendaOrganizerSelectorDay = useSelector(selectorAgendaOrganizerDay);
  const agendaOrganizerSelectorWeek = useSelector(selectorAgendaOrganizerWeek);
  const getLabel = useSelector(selectLanguageKeys);
  const userData = useSelector(selectUserInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [isModalSetTimeVisible, setIsModalSetTimeVisible] = useState(false);
  const [beginTime, setBeginTime] = useState('09:00');
  const [endTime, setEndTime] = useState('18:00');
  const [errorMessaje, setErrorMessaje] = useState('');
  const [selectedDay, setSelectedDay] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [firstTimeModifMon, setFirstTimeModifMon] = useState(true);

  const [unavailableDayCheck, setUnavailableDayCheck] = useState(false);

  const firstLogin = useSelector(selectUserFirstLogin);

  const uuidFromCalendarSelected = route.params.params.uuid;
  const nameFromCalendarSelected = route.params.params.name;

  useEffect(() => () => {
    dispatch(agendaOrganizerResetState());
  }, []);

  useEffect(() => {
    if (firstLogin) {
      setLoading(false);
    } else {
      (async () => {
        const response = await scheduleSettingsGetInfo(uuidFromCalendarSelected, userData.jwt);
        if (response.response) {
          const { uuid, ...newAgendaOrganizer } = response.response;
          dispatch(agendaOrganizerSetWeek(newAgendaOrganizer));
        } else {
          showToastInfo(getLabel('Important'), getLabel('schedule_config_alert_title'));
        }
        setLoading(false);
      })();
    }
  }, []);

  const changeBeginTime = (value: string) => {
    setUnavailableDayCheck(false);
    setBeginTime(value);
  };

  const changeEndTime = (value: string) => {
    setUnavailableDayCheck(false);
    setEndTime(value);
  };

  const openTimeModal = (key: string) => {
    setSelectedDay(key);

    if (agendaOrganizerSelectorDay(key)) {
      setBeginTime(agendaOrganizerSelectorDay(key)![0].from);
      setEndTime(agendaOrganizerSelectorDay(key)![0].to);
    }
    setIsModalSetTimeVisible(!isModalSetTimeVisible);
  };

  const onBlur = () => {
    setBeginTime('09:00');
    setEndTime('18:00');
  };

  const closeTime = () => {
    setIsModalSetTimeVisible(!isModalSetTimeVisible);
    setUnavailableDayCheck(false);
    setErrorMessaje('');
    onBlur();
  };

  const onPressTrash = (day:string) => {
    dispatch(agendaOrganizerSetDay({ key: day, value: null }));
  };

  const addTime = () => {
    if (beginTime.match(TIME_HOURS_REGEX) === null || endTime.match(TIME_HOURS_REGEX) === null) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_validTime'));
    } else if (Number(beginTime.replace(':', '')) > Number(endTime.replace(':', ''))) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_beforeTime'));
    } else if (Number(beginTime.replace(':', '')) === Number(endTime.replace(':', ''))) {
      setErrorMessaje(getLabel('agendaOrganizer_regex_matchingTimes'));
    } else {
      if (selectedDay === 'mon' && firstTimeModifMon) {
        WEEK_DAYS.slice(0, 5).forEach((day) => dispatch(agendaOrganizerSetDay({ key: day, value: { from: beginTime, to: endTime } })));
        setFirstTimeModifMon(false);
      }
      dispatch(agendaOrganizerSetDay({ key: selectedDay!, value: unavailableDayCheck ? null : { from: beginTime, to: endTime } }));
      setIsModalSetTimeVisible(false);
      onBlur();
      setErrorMessaje('');
    }
    setUnavailableDayCheck(false);
  };

  const onConfirmAgenda = async () => {
    setIsLoading(true);

    const request: IAvailableTime = {
      uuid: firstLogin ? null : uuidFromCalendarSelected,
      mon: agendaOrganizerSelectorWeek.mon ? agendaOrganizerSelectorWeek.mon[0] : null,
      tues: agendaOrganizerSelectorWeek.tues ? agendaOrganizerSelectorWeek.tues[0] : null,
      wed: agendaOrganizerSelectorWeek.wed ? agendaOrganizerSelectorWeek.wed[0] : null,
      thurs: agendaOrganizerSelectorWeek.thurs ? agendaOrganizerSelectorWeek.thurs[0] : null,
      fri: agendaOrganizerSelectorWeek.fri ? agendaOrganizerSelectorWeek.fri[0] : null,
      sat: agendaOrganizerSelectorWeek.sat ? agendaOrganizerSelectorWeek.sat[0] : null,
      sun: agendaOrganizerSelectorWeek.sun ? agendaOrganizerSelectorWeek.sun[0] : null
    };

    if (!firstLogin) {
      const scheduleConfig = await scheduleSettingsGetInfo(uuidFromCalendarSelected, userData.jwt);

      if (scheduleConfig.response) {
        const response = await scheduleSettingsUpdateSettings(request, userData.jwt);
        if (response.error) {
          showToastError(getLabel('error'), getLabel(response.errorMessage!));
        } else {
          showToastSuccess(getLabel('global_success'), getLabel('agendaOrganizer_success_edit'));
          navigation.navigate(Routes.Schedules);
        }
      } else {
        const response = await scheduleSettingsCreateSettings(request, userData.jwt);
        if (response.error) {
          showToastError(getLabel('error'), getLabel(response.errorMessage!));
        } else {
          showToastSuccess(getLabel('global_success'), getLabel('agendaOrganizer_success_creation'));
          navigation.navigate(Routes.Schedules);
        }
      }
    } else {
      const updateSettingsResponse = await scheduleSettingsUpdateSettings(request, userData.jwt);
      if (updateSettingsResponse.error) {
        showToastError(getLabel('error'), getLabel(updateSettingsResponse.errorMessage!));
      } else {
        showToastSuccess(getLabel('global_success'), getLabel('agendaOrganizer_success_edit'));
        navigation.navigate(Routes.VerifyAccount);
      }
    }
    setIsLoading(false);
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        {loading ? (AgendaDayLoader()) : (
          <>
            <View style={styles.firstContainer}>
              <View style={styles.textContainer}>
                <View style={styles.subTextContainer}>
                  <Icon
                    style={styles.icon}
                    name="calendar"
                  />
                  <Text style={styles.scheduleTitle}>{nameFromCalendarSelected || ''}</Text>
                </View>
                <Text style={styles.title}>{getLabel('agendaOrganizer_title')}</Text>
              </View>
            </View>
            <View style={styles.controllersContainer}>
              {WEEK_DAYS.map((day: string, index: number) => (
                <AgendaDay
                  key={day}
                  name={getLabel('agendaOrganizer_week')[index]}
                  ranges={agendaOrganizerSelectorDay(day.toLowerCase())}
                  onPress={() => openTimeModal(day.toLowerCase())}
                  onPressTrash={() => onPressTrash(day.toLowerCase())}
                />
              ))}
            </View>
            <View style={styles.bottomContainer}>
              <Button
                containerStyle={styles.buttonContainer}
                buttonStyle={styles.mainButton}
                titleStyle={styles.mainTextButton}
                onPress={onConfirmAgenda}
                title={getLabel('angedaOrganizer_button')}
                loading={isLoading}
              />
            </View>
          </>
        ) }

        <CustomModal isVisible={isModalSetTimeVisible}>
          <View style={styles.modalContainer}>
            <View style={styles.modalTitleContainer}>
              <View>
                <Text style={styles.modalTitle}>{getLabel('agendaOrganizer_modal_title')}</Text>
              </View>
              <View>
                <Button
                  onPress={closeTime}
                  type="clear"
                  icon={(
                    <Icon
                      style={styles.modalIcon}
                      name="x-circle"
                    />
                )}
                />
              </View>
            </View>
            <View style={styles.pickerContainer}>
              <View style={styles.subInputContainer}>
                <Text style={styles.modalText}>{getLabel('agendaOrganizer_modal_begin')}</Text>
                <NativeDateTimePicker
                  value={beginTime}
                  setValue={changeBeginTime}
                  inputStyle={styles.picker}
                  mode="time"
                />
              </View>
            </View>

            <View style={styles.pickerContainer}>
              <View style={styles.subInputContainer}>
                <Text style={styles.modalText}>{getLabel('agendaOrganizer_modal_end')}</Text>
                <NativeDateTimePicker
                  value={endTime}
                  setValue={changeEndTime}
                  inputStyle={styles.picker}
                  mode="time"
                />
              </View>
            </View>

            <View style={styles.noAvailableContainer}>
              <CheckBox
                checked={unavailableDayCheck}
                onPress={() => setUnavailableDayCheck(!unavailableDayCheck)}
                style={styles.noAvailableCheck}
                title={getLabel('make_unavailable_day')}
              />
            </View>

            <View>
              <Text style={styles.errorText}>{errorMessaje}</Text>
            </View>
            <View>
              <Button
                containerStyle={styles.modalButtonContainer}
                buttonStyle={styles.mainButton}
                titleStyle={styles.mainTextButton}
                onPress={addTime}
                title={getLabel('agendaOrganizer_modal_button')}
                disabled={!!((beginTime.length < 5 || endTime.length < 5))}
              />
            </View>
          </View>
        </CustomModal>

      </View>
    </KeyboardAwareScrollView>
  );
};
