import * as Localization from 'expo-localization';

import {
  SCHEDULE_GET_ALL, SCHEDULE_CRUD, SCHEDULE_INFO
} from '../api';
import {
  ICreateSchedule,
  IScheduleGetInfo,
  IScheduleGetOnwerInfo
} from './interface';
import { FetcherResponse } from '../FetcherResponse';
import { API_METHOD, fetcher } from '..';
import { ISchedule } from '../../redux/schedules/state';

export const scheduleGetAll = async (jwt: string) : Promise<FetcherResponse<ISchedule[]>> => {
  try {
    const response = await fetcher<ISchedule[]>(API_METHOD.GET, SCHEDULE_GET_ALL, undefined, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const scheduleGetOwnerInfo = async (hashLink: string) : Promise<FetcherResponse<IScheduleGetOnwerInfo>> => {
  try {
    const response = await fetcher<IScheduleGetOnwerInfo>(API_METHOD.GET, `${SCHEDULE_INFO}/${hashLink}`);
    return response;
  } catch (error: any) {
    return error;
  }
};

export const scheduleChangeTitle = async (request: ISchedule, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.PUT, SCHEDULE_CRUD, request, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const scheduleCreateOne = async (request: ICreateSchedule, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, SCHEDULE_CRUD, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': Localization.timezone
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const scheduleGetInfo = async (jwt: string, scheduleUuid: string | null) : Promise<FetcherResponse<IScheduleGetInfo>> => {
  try {
    const url = scheduleUuid === null
      ? `${SCHEDULE_CRUD}`
      : `${SCHEDULE_CRUD}?scheduleUuid=${scheduleUuid}`;

    const response = await fetcher<IScheduleGetInfo>(API_METHOD.GET, url, undefined, {
      Authorization: `Bearer ${jwt}`
    });

    return response;
  } catch (error: any) {
    return error;
  }
};

export const deleteSchedule = async (uuid: string, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.DELETE, `${SCHEDULE_CRUD}?scheduleUuid=${uuid}`, undefined, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const scheduleDelete = async (uuids: string[], jwt: string) : Promise<boolean> => {
  try {
    const promises = uuids.map((uuid) => deleteSchedule(uuid, jwt));
    return (await Promise.all(promises)).filter((response: any) => !response.error).length === promises.length;
  } catch (error: any) {
    return false;
  }
};
