import { ClientOptions } from 'reactotron-core-client/dist/types/client-options';
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';

const reactotron = Reactotron;

const configuration: ClientOptions = {
  name: 'Thames Frontend'
};

reactotron
  .configure(configuration)
  .use(reactotronRedux())
  .connect();

console.log('Reactotron Configured for Web');

export default reactotron;
