import React, { useEffect, useState } from 'react';
import {
  View, Text, useWindowDimensions, TextInput, ScrollView
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Icon from 'react-native-vector-icons/Feather';
import { Button } from 'react-native-elements';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';
import { useForm, Controller } from 'react-hook-form';
import { IAppointments } from '../../services/events/interface';
import { STATUS } from '../../configs/enums';
import { selectUserInfo } from '../../redux/user';
import { selectEventParticipants, eventSetParticipants } from '../../redux/event';
import styles, { functionStyles } from './styles';
import { showToastError } from '../../utils/toast';
import { MAIL_VALIDATION } from '../../utils/rules';
import { selectLanguageKeys } from '../../redux/language';

interface Props {
  route: {
    params: {
      from: string;
      to: string;
      eventDate: string;
    }
  }
}

export default ({ route }: Props): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const { email } = useSelector(selectUserInfo);
  const navigation = useNavigation();
  const dispatch = useDispatch();
  const selectedParticipants = useSelector(selectEventParticipants);

  const getLabel = useSelector(selectLanguageKeys);

  const { jwt } = useSelector(selectUserInfo);

  const [addedEmails, setAddedEmails] = useState<IAppointments[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedParticipants.length > 0) {
      setAddedEmails(selectedParticipants);
    }
  }, []);

  const {
    handleSubmit, control, reset, formState: { errors }
  } = useForm({
    defaultValues: {
      email: ''
    },
    shouldFocusError: true
  });

  const checkDuplicate = (email: string) => {
    for (let i = 0; i < addedEmails.length; i++) {
      if (addedEmails[i].email === email) {
        return true;
      }
    }
    return false;
  };

  const onAddEmail = async (data: { email: string }) => {
    setIsLoading(true);
    if (checkDuplicate(data.email)) {
      showToastError(getLabel('addPeople_toast_error_title'), getLabel('addPeople_toast_error_message'));
    } else if (data.email === email) {
      showToastError(getLabel('error'), getLabel('email_add_error_title'));
    } else {
      setAddedEmails((addedEmails) => [...addedEmails, { email: data.email, status: STATUS.PENDING }]);
      reset();
    }
    setIsLoading(false);
  };

  const removeFromAddedEmails = (email: string) => {
    const result = addedEmails.filter((appointment) => appointment.email !== email);
    setAddedEmails(result);
  };

  const confirmParticipants = () => {
    dispatch(eventSetParticipants(addedEmails));
    navigation.goBack();
  };

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        <View>
          <View style={styles.logoContainer}>
            <Text style={styles.text}>{getLabel('addPeople_title')}</Text>
          </View>
          <View style={styles.controllersContainer}>
            <Text style={styles.errorText}>{errors?.email?.message}</Text>
            <Controller
              name="email"
              control={control}
              rules={MAIL_VALIDATION(
                getLabel('rules_obligatory'),
                getLabel('rules_min_length_6'),
                getLabel('rules_max_length_31'),
                getLabel('rules_mail_regex')
              )}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.email?.message ? styles.invalid : styles.valid]}
                  blurOnSubmit
                  onBlur={onBlur}
                  onChangeText={(inputValue) => onChange(inputValue)}
                  value={value}
                  placeholder={getLabel('placeholder_email')}
                  autoCorrect={false}
                  autoCapitalize="none"
                  autoCompleteType="email"
                />
              )}
            />
          </View>
          <View style={styles.addButtonContainer}>
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.acceptButton}
              titleStyle={styles.acceptTextButton}
              onPress={handleSubmit(onAddEmail)}
              title={getLabel('addPeople_button')}
              loading={isLoading}
            />
          </View>
          <View style={styles.controllersContainer}>
            <ScrollView style={styles.innerScrollView} bounces={false} showsVerticalScrollIndicator={false}>
              {addedEmails.length === 0 ? null : addedEmails.map((element) => (
                <View style={styles.addedEmailsContainer} key={element.email}>
                  <Text style={styles.addedEmailText}>{element.email}</Text>
                  <Button
                    onPress={() => removeFromAddedEmails(element.email)}
                    type="clear"
                    icon={(
                      <Icon
                        style={styles.trashIcon}
                        name="trash-2"
                      />
                  )}
                  />
                </View>
              ))}
            </ScrollView>
          </View>
        </View>
        <View style={styles.bottomContainer}>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.acceptButton}
            titleStyle={styles.acceptTextButton}
            onPress={confirmParticipants}
            title={getLabel('add_people_confirm_participants')}
            disabled={isLoading}
          />
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};
