import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';
import { isWeb } from '../../utils';

const HomeStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  actionButtonView: {
    position: 'absolute',
    bottom: 20,
    right: 20
  },
  actionButton: {
    width: 80,
    height: 80,
    borderRadius: 50,
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignSelf: 'flex-end'
  },
  toolTipText: {
    fontSize: 12,
    fontFamily: Fonts.MANROPE_LIGTH
  },
  toolTipContainer: {
    borderRadius: 10
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: isWeb ? width : width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: isWeb ? 32 : 5
  })
};

export default HomeStyles;
