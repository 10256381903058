import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { Colors, Fonts, STATUS } from '../../../../configs/enums';

export const EventStyles = StyleSheet.create({
  eventContainer: {
    marginHorizontal: 5,
    padding: 10,
    backgroundColor: Colors.EVENT_BLUE,
    flex: 1,
    borderRadius: 10,
    marginVertical: 5
  },
  description: {
    fontFamily: Fonts.MANROPE_REGULAR,
    fontSize: 10
  }
});

const getEventFontSizeForDay = (overlapCount: number | undefined) => {
  if (overlapCount) {
    if (overlapCount > 3 && overlapCount <= 5) {
      return 10;
    }
    if (overlapCount > 5 && overlapCount <= 7) {
      return 8;
    }
    if (overlapCount > 7) {
      return 6;
    }
  }
  return 14;
};

const getEventFontSize = (width: number, overlapCount: number | undefined) => {
  if (width > 400) {
    if (overlapCount) {
      if (overlapCount >= 3 && overlapCount <= 5) {
        return 6;
      }
      if (overlapCount > 5 && overlapCount <= 7) {
        return 4;
      }
      if (overlapCount > 7) {
        return 3;
      }
    }
    return 10;
  }
  if (width <= 400 && width > 300) {
    return 7;
  }
  if (width <= 300 && width > 200) {
    return 6;
  }
  if (width <= 200 && width > 100) {
    return 5;
  }
  if (width <= 100) {
    return 5;
  }
  return 8;
};

function getEventWidthForWeek(overlapCount: number | undefined, parentWidth: number) {
  if (overlapCount) {
    return ((parentWidth! / 7) - 17) / overlapCount;
  }
  return (parentWidth! / 7) - 17;
}

function getMarginLeftForWeek(overlapCount: number | undefined, parentWidth: number, eventIndex: number, index: number) {
  if (overlapCount) {
    return index! * ((parentWidth! / 7) - 12) + eventIndex * (((parentWidth! / 7) - 17) / overlapCount);
  }
  return index! * ((parentWidth! / 7) - 12);
}

function getEventWidthForDay(overlapCount: number | undefined, parentWidth: number) {
  if (overlapCount) {
    return (parentWidth! / overlapCount) - (60 / overlapCount);
  }
  return undefined;
}

function getMarginLeftForDay(overlapCount: number | undefined, parentWidth: number, eventIndex: number) {
  if (overlapCount) {
    return eventIndex * ((parentWidth! / overlapCount) - (60 / overlapCount));
  }
  return undefined;
}

export const functionStyles = {
  eventContainer: (
    marginTop: number | string,
    height: number,
    dayMode: boolean,
    status: STATUS,
    homeMode: boolean,
    index?: number,
    parentWidth?: number,
    overlapCount?: number,
    eventIndex?: number
  ): TextStyle & ViewStyle => ({
    position: 'absolute',
    width: dayMode ? getEventWidthForDay(overlapCount, parentWidth!) : getEventWidthForWeek(overlapCount, parentWidth!),
    marginLeft: dayMode
      ? getMarginLeftForDay(overlapCount, parentWidth!, eventIndex!)
      : getMarginLeftForWeek(overlapCount, parentWidth!, eventIndex!, index!),
    height,
    left: dayMode ? 0 : undefined,
    right: dayMode ? 0 : undefined,
    marginTop,
    marginHorizontal: 5,
    paddingHorizontal: dayMode ? 10 : 3,
    paddingTop: dayMode ? 10 : 0,
    backgroundColor: homeMode ? (
      status === STATUS.PENDING ? Colors.EVENT_PENDING : Colors.EVENT_BLUE
    ) : (
      Colors.EVENT_BUSY
    ),
    borderColor: Colors.BACKGROUND_SECONDARY_COLOR,
    borderWidth: 0.5,
    flex: 1,
    borderRadius: 10,
    marginVertical: 5
  }),
  title: (parentWidth: number, overlapCount: number | undefined): TextStyle & ViewStyle => ({
    color: Colors.SECONDARY_TEXT_COLOR,
    fontSize: getEventFontSize(parentWidth, overlapCount),
    fontFamily: Fonts.MANROPE_EXTRABOLD
  }),
  description: (parentWidth: number, overlapCount: number | undefined): TextStyle & ViewStyle => ({
    color: Colors.SECONDARY_TEXT_COLOR,
    fontSize: getEventFontSize(parentWidth, overlapCount),
    fontFamily: Fonts.MANROPE_REGULAR,
    marginTop: 10
  }),
  titleForDay: (overlapCount: number | undefined): TextStyle & ViewStyle => ({
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: getEventFontSizeForDay(overlapCount)
  })
};
