import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

export const NativePickerStyles = StyleSheet.create({
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    backgroundColor: Colors.BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 15,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  androidPickercontainer: {
    width: '100%',
    borderRadius: 10,
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    marginTop: 20
  },
  mobilePickercontainer: {
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 12
  },
  mobileTextPickerIOS: {
    paddingLeft: 20,
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  dropdownIcon: {
    fontSize: 18,
    opacity: 0.33,
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingRight: 15
  },
  modalContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1
  },
  picker: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    paddingVertical: 6,
    paddingHorizontal: 15,
    textAlign: 'left',
    borderRadius: 12,
    width: '100%',
    marginTop: 10
  },
  iosPickerContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  buttonReminderContainer: {
    width: '80%',
    alignSelf: 'center'
  },
  closeReminderButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    marginBottom: 25
  },
  closeReminderTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  inputContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  subInputContainer: {
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR,
    width: '100%'
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  }),
  innerModalContainer: (marginTop: number): TextStyle & ViewStyle => ({
    borderRadius: 5,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 4,
    height: 160,
    width: 100,
    alignSelf: 'center',
    marginLeft: 200,
    marginTop
  })
};
