import React from 'react';
import {
  Share
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/Feather';
import {
  useNavigation, DrawerActions, StackActions
} from '@react-navigation/native';
import { Button } from 'react-native-elements';
import { selectUserInfo, selectUserLogged } from '../../redux/user';
import styles from './styles';
import { selectSelectedSchedule } from '../../redux/schedules';
import { isWeb, logoutUser } from '../../utils';
import { showToastError, showToastSuccess } from '../../utils/toast';
import { eventResetState } from '../../redux/event';
import { selectLanguageKeys } from '../../redux/language';

export const CustomHeaderLeft = () => {
  const navigation = useNavigation();

  const toggleDrawer = () => {
    navigation.dispatch(DrawerActions.toggleDrawer());
  };

  return (
    <Button
      onPress={toggleDrawer}
      type="clear"
      icon={(
        <Icon
          style={isWeb ? [styles.icon, { paddingLeft: 20 }] : styles.icon}
          name="menu"
        />
      )}
    />
  );
};

export const CustomFirstTimeHeaderLeft = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const closeUserSession = async () => {
    await logoutUser(dispatch);
    navigation.dispatch(StackActions.replace('Landing'));
  };

  return (
    <Button
      onPress={closeUserSession}
      type="clear"
      icon={(
        <Icon
          style={isWeb ? [styles.icon, { paddingLeft: 20 }] : styles.icon}
          name="x"
        />
      )}
    />
  );
};

export const CustomHeaderRight = () => {
  const { userName } = useSelector(selectUserInfo);
  const selectedSchedule = useSelector(selectSelectedSchedule);
  const getLabel = useSelector(selectLanguageKeys);

  const shareLink = async () => {
    const agendaName = selectedSchedule.uuid ? selectedSchedule.name.replace(/ /g, '_') : null;

    const redirectUrl = `https://www.2meetup.app/Schedule/${userName}${agendaName ? `/${agendaName}` : ''}`;

    if (isWeb) {
      navigator.clipboard.writeText(redirectUrl);
      showToastSuccess(getLabel('clipboard'), getLabel('clipboard_description'));
    } else {
      try {
        await Share.share({
          message: redirectUrl
        });
      } catch (e: any) {
        showToastError(getLabel('error'), e.message);
      }
    }
  };

  return (
    <Button
      onPress={shareLink}
      type="clear"
      icon={(
        <Icon
          style={isWeb ? [styles.icon, { paddingRight: 20 }] : styles.icon}
          name="share-2"
        />
      )}
    />
  );
};

export const CustomReturnHeaderLeft = () => {
  const navigation = useNavigation();

  const goBackScreen = () => {
    navigation.goBack();
  };

  return (
    <Button
      onPress={goBackScreen}
      type="clear"
      icon={(
        <Icon
          style={isWeb ? [styles.icon, { paddingLeft: 20 }] : styles.icon}
          name="arrow-left"
        />
      )}
    />
  );
};

export const InvitedCalendarReturnHeaderLeft = () => {
  const navigation = useNavigation();
  const userLogged = useSelector(selectUserLogged);

  const goBackScreen = () => {
    if (userLogged) {
      navigation.dispatch(StackActions.replace('Root', {
        screen: 'Main',
        params: {
          screen: 'HomeStack'
        }
      }));
    } else {
      navigation.dispatch(StackActions.replace('Landing'));
    }
  };

  return (
    <Button
      onPress={goBackScreen}
      type="clear"
      icon={(
        <Icon
          style={isWeb ? [styles.icon, { paddingLeft: 20 }] : styles.icon}
          name="arrow-left"
        />
      )}
    />
  );
};

export const CustomReturnHeaderLeftForEvents = () => {
  const navigation = useNavigation();
  const dispatch = useDispatch();

  const goBackScreen = () => {
    dispatch(eventResetState());
    navigation.goBack();
  };

  return (
    <Button
      onPress={goBackScreen}
      type="clear"
      icon={(
        <Icon
          style={isWeb ? [styles.icon, { paddingLeft: 20 }] : styles.icon}
          name="arrow-left"
        />
      )}
    />
  );
};
