import dayjs from 'dayjs';
import {
  SCHEDULE_SETTINGS_CRUD, SCHEDULE_SETTINGS_HASH
} from '../api';
import {
  IAvailableTime, IAvailableTimeNEW, IScheduleSettingsGetSettingsWithHashResponse, Range
} from './interface';
import { FetcherResponse } from '../FetcherResponse';
import { API_METHOD, fetcher } from '..';
import { IGetLightEventsBetweenDatesByHashLinkRequest } from '../events/interface';
import { eventsLightBetweenDatesByHashLink } from '../events';
import { getTimeZone, isHourAGreater, LOOKUP_DAYS_DAYJS } from '../../utils';

export const scheduleSettingsGetInfo = async (uuid: string | null, jwt:string) : Promise<FetcherResponse<IAvailableTimeNEW>> => {
  try {
    let url;
    if (uuid) {
      url = `${SCHEDULE_SETTINGS_CRUD}?scheduleSettingsUuid=${uuid}`;
    } else {
      url = `${SCHEDULE_SETTINGS_CRUD}`;
    }
    const response = await fetcher<IAvailableTimeNEW>(API_METHOD.GET, url, undefined, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });

    return response;
  } catch (error: any) {
    return error;
  }
};

export const scheduleSettingsCreateSettings = async (request: IAvailableTime, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.POST, SCHEDULE_SETTINGS_CRUD, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const scheduleSettingsUpdateSettings = async (request: IAvailableTime, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.PUT, SCHEDULE_SETTINGS_CRUD, request, {
      Authorization: `Bearer ${jwt}`,
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const scheduleSettingsDeleteSettings = async (uuid:string, jwt: string) : Promise<FetcherResponse<Record<any, never>>> => {
  try {
    const response = await fetcher<Record<any, never>>(API_METHOD.DELETE, `${SCHEDULE_SETTINGS_CRUD}?scheduleSettingsUuid=${uuid}`, undefined, {
      Authorization: `Bearer ${jwt}`
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const scheduleSettingsGetSettingsWithHash = async (hashlink: string) : Promise<FetcherResponse<IScheduleSettingsGetSettingsWithHashResponse>> => {
  try {
    const response = await fetcher<IScheduleSettingsGetSettingsWithHashResponse>(API_METHOD.GET, `${SCHEDULE_SETTINGS_HASH}/${hashlink}`, undefined, {
      'X-Time-Zone': getTimeZone()
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const getFreeHourRangesFromUserCalendar = async (hashLink: string, date: string) : Promise<Range[] | null> => {
  try {
    const ranges: Range[] = [];

    const getLightEventsBetweenDatesByHashLinkRequest: IGetLightEventsBetweenDatesByHashLinkRequest = {
      hashLink,
      from: date,
      to: date
    };

    const eventsLightForDay = (await eventsLightBetweenDatesByHashLink(getLightEventsBetweenDatesByHashLinkRequest)).response;

    const agenda = (await scheduleSettingsGetSettingsWithHash(hashLink)).response;

    if (eventsLightForDay && agenda) {
      const key = LOOKUP_DAYS_DAYJS[dayjs(date).day()];

      if (agenda[key] !== null) {
        ranges.push(agenda[key]![0]);

        if (agenda[key]!.length > 1) {
          if (eventsLightForDay.length > 0) {
            eventsLightForDay.forEach((event) => {
              if (isHourAGreater(event.range.from, agenda[key]![0].to) && isHourAGreater(agenda[key]![1].from, event.range.to)) {
                ranges.push(event.range);
              }
            });
          }
          ranges.push(agenda[key]![1]);
        }
      }
    }

    return ranges.sort((a, b) => dayjs(`${date} ${a.from}`).diff(dayjs(`${date} ${b.from}`), 'minutes'));
  } catch (error: any) {
    return null;
  }
};
