import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const LoginStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  firstContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 15
  },
  textContainer: {
    width: '100%',
    alignItems: 'flex-start'
  },
  controllersContainer: {
    paddingVertical: 6
  },
  bottomContainer: {
    alignItems: 'center',
    alignContent: 'space-around',
    marginBottom: 20
  },
  icon: {
    fontSize: 32
  },
  title: {
    fontSize: 22,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  text: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  subInput: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  buttonContainer: {
    width: '100%'
  },
  mainButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  mainTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  errorText: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 14,
    alignSelf: 'center',
    marginVertical: 8
  },
  subInputContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR,
    width: '70%'
  },
  modalContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1
  },
  modalTitle: {
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: 22
  },
  modalText: {
    alignSelf: 'flex-start',
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 15
  },
  picker: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  pickerContainer: {
    padding: 20
  },
  modalTitleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 15,
    alignItems: 'center',
    marginHorizontal: 10
  },
  modalButtonContainer: {
    paddingHorizontal: 50,
    marginBottom: 30
  },
  modalIcon: {
    fontSize: 25
  },
  dropdownIcon: {
    fontSize: 18,
    opacity: 0.33,
    alignSelf: 'flex-end',
    position: 'absolute',
    paddingRight: 15
  },
  subTextContainer: {
    flexDirection: 'row'
  },
  scheduleTitle: {
    fontSize: 22,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    alignSelf: 'center',
    paddingLeft: 5
  },
  noAvailableContainer: {
    flexDirection: 'row',
    alignSelf: 'center'
  },
  noAvailableLabel: {
    alignSelf: 'center'
  },
  noAvailableCheck: {
    alignSelf: 'center'
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  }),
  innerModalContainer: (marginTop: number): TextStyle & ViewStyle => ({
    borderRadius: 5,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 4,
    height: 160,
    width: 100,
    alignSelf: 'flex-end',
    marginRight: 50,
    marginTop
  })
};

export default LoginStyles;
