import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const ChangeEmailStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  logoContainer: {
    paddingTop: 20
  },
  textContainer: {
    width: '100%',
    alignItems: 'flex-start'
  },
  controllersContainer: {
    paddingVertical: 0
  },
  bottomContainer: {
    paddingVertical: 20
  },
  addButtonContainer: {
    marginTop: 20
  },
  backgroundPic: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  icon: {
    fontSize: 32
  },
  text: {
    fontSize: 18,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    backgroundColor: Colors.BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  valid: {
    borderWidth: 1
  },
  invalid: {
    borderColor: Colors.ERROR_COLOR
  },
  buttonContainer: {
    width: '100%'
  },
  acceptButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  acceptTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  errorText: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 10,
    alignSelf: 'flex-start',
    marginTop: 8
  },
  innerScrollView: {
    marginTop: 10,
    height: 300,
    width: '100%'
  },
  addedEmailsContainer: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    backgroundColor: Colors.BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 5,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1,
    flexDirection: 'row',
    marginTop: 5,
    marginBottom: 5
  },
  addedEmailText: {
    flex: 1,
    fontSize: 16,
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  trashIcon: {
    fontSize: 22
  },
  secondaryButton: {
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    borderColor: Colors.SECONDARY_BORDER_COLOR,
    borderWidth: 1
  },
  secondaryTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  }

});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20,
    flexDirection: 'column'
  })
};

export default ChangeEmailStyles;
