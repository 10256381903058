import { StyleSheet, TextStyle, ViewStyle } from 'react-native';
import { Colors, Fonts } from '../../../../configs/enums';

export const HourStyles = StyleSheet.create({
  mainContainer: {
    flex: 1
  },
  container: {
    height: 100,
    flexDirection: 'row'
  },
  hourContainer: {
    width: 40
  },
  eventContainer: {
    marginHorizontal: 5,
    padding: 10,
    backgroundColor: Colors.EVENT_BLUE,
    flex: 1,
    borderRadius: 10,
    marginVertical: 5
  },
  title: {
    fontFamily: Fonts.MANROPE_BOLD,
    fontSize: 14
  },
  description: {
    fontFamily: Fonts.MANROPE_REGULAR,
    fontSize: 10
  },
  currentHourLine: {
    color: Colors.ERROR_COLOR,
    backgroundColor: Colors.ERROR_COLOR,
    height: 2
  }
});

export const functionStyles = {
  hourContainer: (dayMode: boolean): TextStyle & ViewStyle => ({
    width: dayMode ? 40 : 30
  }),
  container: (dayMode: boolean): TextStyle & ViewStyle => ({
    height: dayMode ? 100 : 60
  }),
  hour: (dayMode: boolean): TextStyle & ViewStyle => ({
    fontSize: dayMode ? 14 : 10
  })
};
