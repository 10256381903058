import React,
{
  useCallback,
  useEffect,
  useState
} from 'react';
import {
  View,
  Text,
  useWindowDimensions,
  Linking
} from 'react-native';

import { Button } from 'react-native-elements';
import Modal from 'react-native-modal';
import { useNavigation } from '@react-navigation/native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import ContentLoader,
{ Rect } from 'react-content-loader/native';
import {
  CALENDAR_STORAGE_KEY,
  USER_STORAGE_KEY
} from '@env';
import Purchases, {
  PurchasesOfferings
} from 'react-native-purchases';
import {
  scheduleSetData,
  scheduleSetSelectedSchedule
} from '../../redux/schedules';
import { DEFAULT_SCHEDULE } from '../../redux/schedules/state';
import {
  globalSetMustReloadBilling,
  selectGlobalAppMustReloadBilling
} from '../../redux/global';
import {
  isMobile,
  isWeb,
  storeAsyncStorageData,
  updateSubscription
} from '../../utils';
import {
  stripeCancel,
  stripeInitialize,
  userGetBillingInfo,
  userGetRoles
} from '../../services/user';
import {
  showToastError,
  showToastSuccess
} from '../../utils/toast';
import {
  Colors,
  ROLE
} from '../../configs/enums';
import styles, { functionStyles } from './styles';
import Routes from '../../navigation/Routes';
import {
  selectUserAccountType,
  selectUserFirstLogin,
  selectUserInfo,
  userSetRole
} from '../../redux/user';
import { selectLanguageKeys } from '../../redux/language';
import SubscriptionCard from './components/SubscriptionCard';
import { SubscriptionStatus } from './mock';

interface Props {
  route: { params: { firstTime: string | undefined } };
}

function PremiumCard({
  selected,
  onPress
}: any) {
  const getLabel = useSelector(selectLanguageKeys);

  return (
    <SubscriptionCard
      title={getLabel('payments_premium_title')}
      price="$4.99 / month"
      titleStyles={[styles.cardTitle, styles.cardPremiumText]}
      cardColor={Colors.BACKGROUND_TERCIARY_COLOR}
      selected={selected}
      onPress={onPress}
    >
      <Text style={[styles.cardText, styles.cardPremiumText]}>
        {`- ${getLabel('payments_premium_body1')}`}
      </Text>
    </SubscriptionCard>
  );
}

function ProCard({
  selected,
  onPress
}: any) {
  const getLabel = useSelector(selectLanguageKeys);

  return (
    <SubscriptionCard
      title={getLabel('payments_pro_title')}
      price="$9.99 / month"
      titleStyles={[styles.cardTitle]}
      cardColor={Colors.BACKGROUND_SECONDARY_COLOR}
      selected={selected}
      onPress={onPress}
    >
      <Text style={[styles.cardText]}>
        {`- ${getLabel('payments_pro_body1')}\n- ${getLabel('payments_pro_body2')}`}
      </Text>
    </SubscriptionCard>
  );
}

function StandardCard({
  selected
}: any) {
  const getLabel = useSelector(selectLanguageKeys);

  return (
    <SubscriptionCard
      title={getLabel('payments_standard_title')}
      titleStyles={[styles.cardTitle, styles.cardPremiumText]}
      cardColor={Colors.BACKGROUND_COLOR}
      selected={selected}
    >
      <Text style={[styles.cardText, styles.cardPremiumText]}>
        {`- ${getLabel('payments_standard_body1')}\n- ${getLabel('payments_standard_body2')}`}
      </Text>
    </SubscriptionCard>
  );
}

function PaymentsLoading({ width }: any) {
  return (
    <View>
      <ContentLoader
        speed={2}
        width={isWeb ? 600 : width}
        height={700}
        viewBox={isWeb ? '0 00 600 700' : `0 00 ${width} 700`}
        backgroundColor={Colors.BACKGROUND_LOADER_COLOR}
        foregroundColor={Colors.FOREGROUND_LOADER_COLOR}
      >
        <Rect x="0" y="0" rx="8" ry="8" width="90%" height="120" />
        <Rect x="0" y="140" rx="8" ry="8" width="90%" height="120" />
        <Rect x="0" y="280" rx="8" ry="8" width="90%" height="120" />
      </ContentLoader>
    </View>
  );
}

export default ({ route }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const windowDimensions = useWindowDimensions();

  const getLabel = useSelector(selectLanguageKeys);

  const firstLogin = useSelector(selectUserFirstLogin);

  const userData = useSelector(selectUserInfo);

  // const role = useSelector(selectUserAccountType);

  // const billing = useSelector(selectBillingData);

  const mustReloadBilling = useSelector(selectGlobalAppMustReloadBilling);

  const [isLoading, setIsLoading] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(userData.roles[0]);
  const [isLoadingChange, setIsLoadingChange] = useState(false);

  const [isLoadingPayments, setIsLoadingPayments] = useState(false);

  const [offerings, setOfferings] = useState<PurchasesOfferings>();
  const [subscriptionDetails, setSubscriptionDetails] = useState<any>({ roles: ['STANDARD'] });

  async function continueStandardAccount() {
    try {
      return navigation.navigate(Routes.AgendaOrganizer, { params: { uuid: null, name: `${getLabel('my_calendar_title')}` } });
    } catch (err: any) {
      return showToastError(getLabel('error'), err.message);
    }
  }

  function getTypeOfAccountComponent() {
    if (subscriptionDetails.roles[0] === ROLE.STANDARD) {
      return (
        firstLogin ? (
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={[styles.selectPlanButton, styles.doItLaterButton]}
            titleStyle={[styles.selectPlanTextButton, styles.doItLaterText]}
            onPress={() => continueStandardAccount()}
            title={getLabel('payments_button2')}
          />
        ) : <StandardCard />
      );
    }
    if (subscriptionDetails.roles[0] === ROLE.PREMIUM) {
      return (
        <PremiumCard />
      );
    }
    if (subscriptionDetails.roles[0] === ROLE.PRO) {
      return (
        <ProCard />
      );
    }
    return null;
  }

  const selectPlan = (plan: ROLE) => {
    setIsSelected(true);
    setSelectedPlan(plan);
  };

  async function continueWithPlan() {
    try {
      setIsLoading(true);
      const plan = offerings?.all.Monthly.availablePackages[selectedPlan === ROLE.PRO ? 0 : 1];
      console.info('PLAN ', plan);
      const { customerInfo, productIdentifier } = await Purchases.purchasePackage(plan!);
      console.info('<<<< customerInfo productIdentifier >>>>', customerInfo, productIdentifier);
      if (typeof customerInfo.entitlements.active.premium !== 'undefined' || typeof customerInfo.entitlements.active.pro !== 'undefined') {
        // El pago fue exitoso y la suscripción esta en proceso de activarse

        // Recargar pantalla y esperar 15 segundos a que el backend responda con el update
        // de la suscripción
        setIsLoadingPayments(true);
        setTimeout(async () => {
          await updateSubscription(dispatch, userData);
          setIsLoadingPayments(false);
        }, 1000 * 15);
      }
    } catch (e: any) {
      if (!e.userCancelled) {
        showToastError(getLabel('error'), e.message);
      }
    } finally {
      setIsLoading(false);
    }
  }

  async function continueWithPlanWeb() {
    setIsLoading(true);
    const response = await stripeInitialize(selectedPlan.toLowerCase(), userData.jwt);

    if (response.response) {
      Linking.openURL(response.response.url);
    } else {
      showToastError(getLabel('error'), getLabel(response.errorMessage!));
    }
    setIsLoading(false);
  }

  const cancelPlan = async () => {
    try {
      setIsLoading(true);

      const response = await stripeCancel(userData.jwt);

      if (response.response) {
        showToastSuccess(getLabel('global_success'), getLabel('suscription_cancelled_title'));
        const roleResponse = await userGetRoles(userData.jwt);

        if (roleResponse.response) {
          dispatch(userSetRole(roleResponse.response.roles));
          await storeAsyncStorageData({ ...userData, roles: roleResponse.response.roles }, USER_STORAGE_KEY);

          dispatch(scheduleSetData([DEFAULT_SCHEDULE]));
          dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
          await storeAsyncStorageData([DEFAULT_SCHEDULE], CALENDAR_STORAGE_KEY);
        } else {
          showToastError(getLabel('error'), getLabel('error_updating_role'));
        }
      } else {
        showToastError(getLabel('error'), getLabel(response.errorMessage!));
      }
    } catch (err: any) {
      showToastError(getLabel('error'), err.message);
    } finally {
      setIsLoading(false);
      setCancelModal(false);
    }
  };

  const reloadPayments = useCallback(
    async () => {
      setIsLoadingPayments(true);

      await updateSubscription(dispatch, userData);

      setIsLoadingPayments(false);
    },
    []
  );

  async function getSubscriptionDetails() {
    try {
      setIsLoadingPayments(true);
      const userBillingInfo = await userGetBillingInfo(userData.jwt);
      // MOCK: const responseNe = SubscriptionStatus;
      if (userBillingInfo.response) {
        setSubscriptionDetails(userBillingInfo.response);

        if (userBillingInfo.response.roles[0] === ROLE.STANDARD) {
          const offerings = await Purchases.getOfferings();
          if (offerings.current !== null && offerings.current.availablePackages.length !== 0) {
            // Display packages for sale
            console.info('offerings ', offerings);
            setOfferings(offerings);
          }
          return;
        }
      }
    } catch (err: any) {
      console.info('ERR getOfferings: ', err.message);
    } finally {
      setIsLoadingPayments(false);
    }
  }

  useEffect(() => {
    if (userData.roles[0] !== subscriptionDetails.roles[0]) {
      setSubscriptionDetails({ ...subscriptionDetails, roles: userData.roles });
    }
  }, [userData]);

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  useEffect(() => {
    if (mustReloadBilling && isWeb) {
      (async () => {
        setIsLoadingPayments(true);
        const result = await updateSubscription(dispatch, userData);
        if (!result) {
          showToastError(getLabel('error'), getLabel('error_suscription_update'));
        }
        setIsLoadingPayments(false);
        dispatch(globalSetMustReloadBilling(false));
      })();
    }
  }, [mustReloadBilling]);

  return (
    <KeyboardAwareScrollView style={styles.container} bounces={false}>
      <View style={functionStyles.mainContainer(windowDimensions.width)}>

        <View style={styles.firstContainer}>
          <Text style={styles.title}>{getLabel('payments_subscription')}</Text>
          {isWeb && subscriptionDetails.roles[0] === ROLE.STANDARD && (
            <View>
              <Button
                containerStyle={styles.updateButtonContainer}
                buttonStyle={styles.updateButton}
                onPress={reloadPayments}
                title={getLabel('activate')}
                loading={isLoadingPayments}
              />
            </View>
          )}
        </View>

        {isLoadingPayments ? (<PaymentsLoading width={windowDimensions.width} />) : (
          <>
            <View>
              {!firstLogin && (
              <Text style={styles.text}>
                {getLabel('payments_type_of_account')}
              </Text>
              )}
              {getTypeOfAccountComponent()}
            </View>
            <>
              <View>
                {subscriptionDetails.roles[0] === ROLE.STANDARD && (
                  <>
                    <Text style={styles.text}>
                      {getLabel('payments_marketing_title1')}
                    </Text>
                    <PremiumCard
                      selected={selectedPlan === ROLE.PREMIUM}
                      onPress={() => selectPlan(ROLE.PREMIUM)}
                    />
                    <ProCard
                      selected={selectedPlan === ROLE.PRO}
                      onPress={() => selectPlan(ROLE.PRO)}
                    />
                  </>
                )}
              </View>

              <View style={styles.bottomContainer}>
                {subscriptionDetails.roles[0] === ROLE.STANDARD && (
                  <Button
                    containerStyle={[styles.buttonContainer]}
                    buttonStyle={[styles.selectPlanButton]}
                    titleStyle={styles.selectPlanTextButton}
                    onPress={isMobile ? () => continueWithPlan() : () => continueWithPlanWeb()}
                    title={getLabel('payments_button1')}
                    loading={isLoading}
                    disabled={!isSelected}
                  />
                )}
                {subscriptionDetails.roles[0] !== ROLE.STANDARD && !firstLogin && isWeb && (
                  <Button
                    containerStyle={[styles.buttonContainer]}
                    buttonStyle={[styles.selectPlanButton]}
                    titleStyle={styles.selectPlanTextButton}
                    onPress={() => setCancelModal(true)}
                    title={getLabel('plan_cancel')}
                  />
                )}
                {subscriptionDetails.roles[0] !== ROLE.STANDARD && firstLogin && (
                  <Button
                    containerStyle={styles.buttonContainer}
                    buttonStyle={[styles.selectPlanButton, styles.doItLaterButton]}
                    titleStyle={[styles.selectPlanTextButton, styles.doItLaterText]}
                    onPress={() => continueStandardAccount()}
                    title={getLabel('generic_continue_button_title')}
                  />
                )}
              </View>

            </>
          </>
        )}
      </View>
      <Modal
        backdropTransitionOutTiming={0}
        onBackdropPress={() => setCancelModal(false)}
        isVisible={cancelModal}
      >
        <View
          style={styles.modalPlanModalContainer}
        >
          <Text
            style={styles.modalPlanTitle}
          >
            {getLabel('cancel_plan_title')}
          </Text>
          <Text
            style={styles.modalPlanSubtitle}
          >
            {subscriptionDetails.roles[0] === ROLE.PREMIUM
              ? getLabel('cancel_plan_premium_subtitle')
              : getLabel('cancel_plan_pro_subtitle')}
          </Text>
          <Button
            containerStyle={styles.modalPlanAcceptButton}
            buttonStyle={styles.modalPlanAccept}
            titleStyle={styles.selectPlanTextButton}
            onPress={cancelPlan}
            title={getLabel('accept')}
            loading={isLoading}
          />
          <Button
            containerStyle={styles.modalPlanCancelButton}
            buttonStyle={styles.modalPlanCanel}
            titleStyle={styles.selectPlanTextButton}
            onPress={() => setCancelModal(false)}
            title={getLabel('cancel')}
          />
        </View>
      </Modal>
    </KeyboardAwareScrollView>
  );
};
