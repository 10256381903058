import { StyleSheet } from 'react-native';
import { Colors, Fonts } from '../../../../configs/enums';

export const DayStyles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
    borderColor: Colors.PRIMARY_BUTTON_COLOR,
    borderWidth: 1,
    borderRadius: 10,
    padding: 20,
    width: 75,
    height: 80,
    justifyContent: 'center'
  },
  selectedContainer: {
    marginHorizontal: 20,
    borderColor: Colors.PRIMARY_BUTTON_COLOR,
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    borderWidth: 1,
    borderRadius: 10,
    padding: 20,
    width: 75,
    height: 80,
    justifyContent: 'center'
  },
  dayName: {
    color: Colors.SECONDARY_TEXT_COLOR,
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_REGULAR
  },
  dayNumber: {
    color: Colors.SECONDARY_TEXT_COLOR,
    fontSize: 22,
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_SEMIBOLD
  },
  selectedDayName: {
    color: Colors.PRIMARY_TEXT_COLOR,
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_REGULAR
  },
  selectedDayNumber: {
    color: Colors.PRIMARY_TEXT_COLOR,
    fontSize: 22,
    alignSelf: 'center',
    fontFamily: Fonts.MANROPE_SEMIBOLD
  }
});
