import React, { useEffect, useState } from 'react';
import {
  View, Text, useWindowDimensions
} from 'react-native';
import { useNavigation } from '@react-navigation/native';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/Feather';
import ContentLoader, { Rect } from 'react-content-loader/native';
import { scheduleGetOwnerInfo } from '../../services/schedule';
import { IScheduleGetOnwerInfo } from '../../services/schedule/interface';
import Routes from '../../navigation/Routes';
import styles, { functionStyles } from './styles';
import Calendar from '../../components/Calendar';
import { FloatingButton } from '../../components/FloatingButton';
import { showToastError } from '../../utils/toast';
import { getDaysForCalendar, isWeb } from '../../utils';
import { DAY_ITEMS_QTY } from '../../components/Calendar/configs';
import { selectLanguageKeys } from '../../redux/language';
import { Colors } from '../../configs/enums';

interface Props {
  route: { params: { hashlink: string | undefined } };
}

const InvitedCalendarNameLoader = () => (
  <View>
    <ContentLoader
      speed={2}
      width={isWeb ? 600 : 350}
      height={60}
      viewBox={isWeb ? '0 00 800 60' : '0 00 350 60'}
      backgroundColor={Colors.BACKGROUND_LOADER_COLOR}
      foregroundColor={Colors.FOREGROUND_LOADER_COLOR}
    >
      <Rect x="0" y={isWeb ? 10 : 15} rx="4" ry="4" width="60%" height={isWeb ? 40 : 30} />
    </ContentLoader>
  </View>
);

export default ({ route }: Props): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const navigation = useNavigation();

  const getLabel = useSelector(selectLanguageKeys);

  const [selectedDate, setSelectedDate] = useState(getDaysForCalendar(dayjs())[DAY_ITEMS_QTY / 2]);
  const [isLoading, setIsLoading] = useState(false);
  const [ownerInfo, setOwnerInfo] = useState<IScheduleGetOnwerInfo>();

  useEffect(() => {
    (async () => {
      if (route.params.hashlink) {
        setIsLoading(true);
        const response = await scheduleGetOwnerInfo(route.params.hashlink);

        if (response.response) {
          setOwnerInfo(response.response);
        } else {
          showToastError(getLabel('error'), getLabel(response.errorMessage!));
        }
        setIsLoading(false);
      } else {
        showToastError(getLabel('error'), getLabel('link_associated_error'));
      }
    })();
  }, []);

  const addEvent = () => {
    if (route.params) {
      route.params.hashlink && navigation.navigate(Routes.EventExternal, { hashLink: route.params.hashlink });
    } else {
      showToastError(getLabel('error'), getLabel('invited_calendar_link_error'));
    }
  };

  return (
    <View style={styles.container}>
      <View style={
        functionStyles.mainContainer(windowDimensions.width)
      }
      >
        {route.params ? (
          <>
            <View style={styles.titleView}>
              <Icon style={styles.calendarIcon} name="calendar" />
              {isLoading ? (
                <>
                  {InvitedCalendarNameLoader()}
                </>
              ) : (
                ownerInfo && (
                  <Text style={styles.titleName}>{`${getLabel('schedule_label')} ${ownerInfo?.name ? `${ownerInfo?.name} ` : ''}${getLabel('of_label')} ${ownerInfo?.ownerName}`}</Text>
                )
              )}
            </View>
            <Calendar
              dayMode={false}
              homeMode={false}
              hashLink={route.params.hashlink}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
            />
            <View style={styles.actionButtonView}>
              <FloatingButton
                buttonStyle={styles.actionButton}
                icon="add"
                iconSize={40}
                iconColor="white"
                onPressFunction={addEvent}
              />
            </View>
          </>
        ) : (
          <Text>404 Error</Text>
        )}
      </View>
    </View>
  );
};
