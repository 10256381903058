import React from 'react';
import { useWindowDimensions, View } from 'react-native';
import Modal from 'react-native-modal';
import { isWeb } from '../../utils';

interface Props {
  isVisible: boolean;
  children: React.ReactElement;
}

export const CustomModal = ({ isVisible, children }: Props) => {
  const windowDimensions = useWindowDimensions();

  return (
    <View>
      <Modal
        isVisible={isVisible}
        backdropTransitionOutTiming={0}
        deviceHeight={windowDimensions.height}
        deviceWidth={windowDimensions.width}
        style={isWeb ? { alignSelf: 'center' } : undefined}
      >
        { children }
      </Modal>
    </View>
  );
};
