export interface IGlobalState {
  appOpenedWithLink: boolean;
  mustResetCalendar: boolean;
  mustResetInvites: boolean;
  mustReloadBilling: boolean;
  expoPushToken: string | null;
  languagesAvailable: string[];
  icsRead: string | null;
  startupLinkProcessed: boolean;
  profileImage: string | null;
}

export const INITIAL_STATE: IGlobalState = {
  appOpenedWithLink: false,
  mustResetCalendar: false,
  mustResetInvites: false,
  mustReloadBilling: false,
  expoPushToken: null,
  languagesAvailable: [],
  icsRead: null,
  startupLinkProcessed: false,
  profileImage: null
};
