import React, { useState, useRef, useEffect } from 'react';
import {
  View, Text, TextInput, useWindowDimensions, ScrollView, Pressable, TouchableOpacity
} from 'react-native';
import { Button, CheckBox } from 'react-native-elements';
import { useDispatch, useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/Feather';
import { useForm, Controller } from 'react-hook-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import PhoneInput from 'react-native-phone-number-input';
import { DEV_MODE, USER_STORAGE_KEY } from '@env';
import Purchases from 'react-native-purchases';
import { selectGlobalAppExpoPushToken } from '../../redux/global';
import { userSendPuthToken } from '../../services/user';
import {
  userSetData, userSetLogged, selectUserLanguage
} from '../../redux/user';
import { CustomModal } from '../../components/CustomModal';
import { AuthSignIn, AuthSignUpRequest } from '../../services/auth/interfaces';
import {
  MAIL_VALIDATION, PASSWD_VALIDATION, NAME_VALIDATION, BIRTHDATE_VALIDATION, OPTIONAL_MAIL_VALIDATION
} from '../../utils/rules';
import { TERMS_AND_CONDITIONS } from '../../utils/termsConditions';
import styles, { functionStyles } from './styles';
import { showToastError, showToastSuccess } from '../../utils/toast';
import {
  areRegisterInputsValid, authSignUp, authUserSignIn
} from '../../services/auth';
// import { WebPicker } from '../../components/WebPicker';
// import { MobilePicker } from '../../components/MobilePicker';
import {
  isWeb, storeAsyncStorageData, applyTrimToFields, isMobile
} from '../../utils';
import { Colors } from '../../configs/enums';
import { scheduleSetData, scheduleSetSelectedSchedule } from '../../redux/schedules';
import { DEFAULT_SCHEDULE } from '../../redux/schedules/state';
import { selectLanguageKeys } from '../../redux/language';

export default (): React.ReactElement => {
  const windowDimensions = useWindowDimensions();
  const userLanguage = useSelector(selectUserLanguage);

  const getLabel = useSelector(selectLanguageKeys);

  const expoPushToken = useSelector(selectGlobalAppExpoPushToken);

  const dispatch = useDispatch();

  const [hidePassword, setHidePassword] = useState(false);
  const [hideRepeatedPassword, setHideRepeatedPassword] = useState(false);
  const [isModalVisible, setModalVisible] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const [modalCalendar, setModalCalendar] = useState(false);
  const [birthDateVisible, setBirthdateVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const [value, setValue] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [formattedValue, setFormattedValue] = useState('');
  const [valid, setValid] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const phoneInput = useRef<PhoneInput>(null);

  const defaultValues: AuthSignUpRequest = DEV_MODE === '1' ? {
    firstName: '  Jose',
    lastName: '  Perez  ',
    email: 'test22@test.com',
    password: 'Test123123123',
    repeatedPassword: 'Test123123123',
    termsAndConditions: true,
    optionalEmail: undefined,
    language: userLanguage
  } : {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    repeatedPassword: '',
    termsAndConditions: false,
    optionalEmail: undefined,
    language: userLanguage
  };

  const {
    handleSubmit, control, reset, formState: { errors }
  } = useForm({
    defaultValues
  });

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const showCalendar = () => {
    setBirthdateVisible(!modalCalendar);
  };

  const onToggleSwitch = () => {
    setHidePassword(!hidePassword);
  };

  const onToggleSwitchRepeatedPassword = () => {
    setHideRepeatedPassword(!hideRepeatedPassword);
  };

  const onRegister = async (data: AuthSignUpRequest) => {
    try {
      setIsLoading(true);
      if (!isChecked) {
        showToastError(getLabel('error'), getLabel('register_must_accept_terms'));
        setIsLoading(false);
        return;
      }
      data.termsAndConditions = isChecked;
      data.phone = formattedValue;
      data.email = data.email.toLowerCase();
      if (data.optionalEmail === undefined || data.optionalEmail === '') {
        data.optionalEmail = null;
      } else {
        data.optionalEmail = data.optionalEmail!.toLowerCase();
      }
      if (data.password !== data.repeatedPassword) {
        showToastError(getLabel('placeholder_password'), getLabel('changePassword_toast_error_match_text'));
      } else {
        applyTrimToFields(data, [
          'firstName', 'lastName', 'email', 'repeatedPassword', 'optionalEmail'
        ]);
        const validatedInputsResponse = await areRegisterInputsValid(data);

        if (!validatedInputsResponse.error) {
          const register = await authSignUp({ ...data, phone: null, birthdate: null });
          if (register.error) {
            showToastError(getLabel('register_toast_title_register'), getLabel(register.errorMessage!));
          } else {
            showToastSuccess(getLabel('register_toast_title_register'), getLabel('register_toast_success'));
            const signInRequest: AuthSignIn = {
              email: data.email,
              password: data.password
            };
            const response = await authUserSignIn(signInRequest);
            if (response.error) {
              showToastError(getLabel('login_toast_error_title'), getLabel('login_toast_error_message'));
            } else {
              if (isMobile) await Purchases.logIn(response.response?.email!);
              await storeAsyncStorageData(response.response, USER_STORAGE_KEY);
              dispatch(userSetData(response.response!));
              dispatch(scheduleSetData([DEFAULT_SCHEDULE]));
              dispatch(scheduleSetSelectedSchedule(DEFAULT_SCHEDULE));
              await userSendPuthToken(expoPushToken!, response.response!.jwt);
              dispatch(userSetLogged(true));
              reset(defaultValues);
            }
          }
        } else {
          showToastError(getLabel('register_toast_title_validation'), getLabel(validatedInputsResponse.errorCodes![0].errorMessage!));
        }
      }
    } catch (err: any) {
      showToastError(getLabel('register_toast_title_validation'), err.message);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  //   checkPhoneValidation();
  // }, [value]);

  return (
    <View style={styles.container}>
      <KeyboardAwareScrollView bounces={false}>
        <View style={
          functionStyles.mainContainer(windowDimensions.width)
        }
        >
          <View style={styles.middleContainer}>
            <View style={styles.textContainer}>
              <Icon
                style={styles.icon}
                name="key"
              />
              <Text style={styles.title}>{getLabel('register_title')}</Text>
              <Text style={styles.text}>
                {getLabel('register_subTitle')}
              </Text>
            </View>
          </View>

          <Text style={styles.generalInformation}>{getLabel('register_general_info_title')}</Text>

          <Text style={styles.generalInformationDetails}>{getLabel('register_general_info_subtitle')}</Text>

          <View style={styles.controllersContainer}>
            <Text style={styles.errorText}>{errors?.firstName?.message}</Text>
            <Controller
              name="firstName"
              rules={NAME_VALIDATION(
                getLabel('rules_obligatory'),
                getLabel('rules_min_length_2'),
                getLabel('rules_max_length_31')
              )}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.firstName?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_firstName')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  autoCompleteType="name"
                />
              )}
            />
            <Text style={styles.errorText}>{errors?.lastName?.message}</Text>
            <Controller
              name="lastName"
              rules={NAME_VALIDATION(
                getLabel('rules_obligatory'),
                getLabel('rules_min_length_2'),
                getLabel('rules_max_length_31')
              )}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.lastName?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_lastName')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  autoCompleteType="name"
                />
              )}
            />

            {/* <Text style={styles.errorText}>{errors?.birthdate?.message}</Text>
            <Controller
              name="birthdate"
              rules={BIRTHDATE_VALIDATION(getLabel('rules_obligatory'))}
              control={control}
              render={({ field: { onChange, onBlur, value } }) => (
                <Pressable style={{ width: '100%' }} onPress={showCalendar}>
                  <View pointerEvents="none">
                    <TextInput
                      style={[
                        styles.input,
                        errors?.birthdate?.message
                          ? styles.invalid : styles.valid]}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value}
                      placeholder={getLabel('placeholder_birthdate')}
                      placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                    />
                    <View>
                      {isWeb ? (
                        <WebPicker
                          setDate={onChange}
                          isVisible={birthDateVisible}
                          setIsVisible={setBirthdateVisible}
                          limitDateYears={0}
                        />
                      ) : (
                        <MobilePicker
                          setDate={onChange}
                          isVisible={birthDateVisible}
                          setIsVisible={setBirthdateVisible}
                          limitDateYears={18}
                        />
                      )}
                    </View>

                  </View>
                </Pressable>
              )}
            /> */}
            {/* <View style={isWeb ? styles.webPhone : styles.IosPhone}>
              <PhoneInput
                ref={phoneInput}
                defaultValue={value}
                defaultCode="AR"
                layout="first"
                onChangeText={(text) => {
                  setValue(text);
                }}
                onChangeFormattedText={(text) => {
                  setFormattedValue(text);
                  setCountryCode(phoneInput.current?.getCountryCode() || '');
                }}
                countryPickerProps={{ withAlphaFilter: true }}
                disabled={disabled}
                placeholder="Telefono"
                containerStyle={styles.containerPhoneInput}
                textContainerStyle={styles.phoneInput}
                textInputStyle={styles.phoneText}
                countryPickerButtonStyle={styles.flagPicker}
              />
            </View> */}
            <Text style={styles.errorText}>{errors?.email?.message}</Text>
            <Controller
              name="email"
              control={control}
              rules={MAIL_VALIDATION(
                getLabel('rules_obligatory'),
                getLabel('rules_min_length_6'),
                getLabel('rules_max_length_31'),
                getLabel('rules_mail_regex')
              )}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.email?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_email')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  autoCorrect={false}
                  autoCompleteType="email"
                />
              )}
            />
            <Text style={styles.errorText}>{errors?.optionalEmail?.message}</Text>
            <Controller
              name="optionalEmail"
              control={control}
              rules={OPTIONAL_MAIL_VALIDATION(
                getLabel('rules_mail_optional'),
                getLabel('rules_min_length_6'),
                getLabel('rules_max_length_31'),
                getLabel('rules_mail_regex')
              )}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextInput
                  style={[styles.input, errors?.optionalEmail?.message ? styles.invalid : styles.valid]}
                  onBlur={onBlur}
                  onChangeText={(text) => onChange(text)}
                  value={value}
                  placeholder={getLabel('placeholder_optionalEmail')}
                  placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  autoCorrect={false}
                  autoCompleteType="email"
                />
              )}
            />

            <Text style={styles.passwordInformationTitle}>{getLabel('register_security_title')}</Text>

            <Text style={styles.passwordInformationDetails}>{getLabel('register_security_subtitle')}</Text>

            <Text style={styles.errorText}>{errors?.password?.message}</Text>
            <Controller
              name="password"
              control={control}
              rules={PASSWD_VALIDATION(
                getLabel('rules_obligatory'),
                getLabel('rules_min_length_8'),
                getLabel('rules_max_length_32'),
                getLabel('rules_password_regex')
              )}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.subInputContainer}>
                  <TextInput
                    style={[styles.input, errors.password?.message ? styles.invalid : styles.valid]}
                    onBlur={onBlur}
                    autoCorrect={false}
                    secureTextEntry={!hidePassword}
                    onChangeText={(text) => onChange(text)}
                    value={value}
                    placeholder={getLabel('placeholder_password')}
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  />
                  <TouchableOpacity
                    style={styles.buttonHideIcon}
                    onPress={onToggleSwitch}
                  >
                    <Icon
                      style={styles.hideIcon}
                      name={hidePassword ? 'eye' : 'eye-off'}
                    />
                  </TouchableOpacity>
                </View>
              )}
            />
            <Text style={styles.errorText}>{errors?.repeatedPassword?.message}</Text>
            <Controller
              name="repeatedPassword"
              control={control}
              rules={PASSWD_VALIDATION(
                getLabel('rules_obligatory'),
                getLabel('rules_min_length_8'),
                getLabel('rules_max_length_32'),
                getLabel('rules_password_regex')
              )}
              render={({ field: { onChange, onBlur, value } }) => (
                <View style={styles.subInputContainer}>
                  <TextInput
                    style={[styles.input, errors.repeatedPassword?.message ? styles.invalid : styles.valid]}
                    onBlur={onBlur}
                    autoCorrect={false}
                    secureTextEntry={!hideRepeatedPassword}
                    onChangeText={(text) => onChange(text)}
                    value={value}
                    placeholder="Repita la contraseña"
                    placeholderTextColor={Colors.PLACEHOLDER_TEXT_COLOR}
                  />
                  <TouchableOpacity
                    style={styles.buttonHideIcon}
                    onPress={onToggleSwitchRepeatedPassword}
                  >
                    <Icon
                      style={styles.hideIcon}
                      name={hideRepeatedPassword ? 'eye' : 'eye-off'}
                    />
                  </TouchableOpacity>
                </View>
              )}
            />
          </View>
          <View style={styles.thirdContainer}>
            <CheckBox
              title={getLabel('register_termsAndConditions')}
              checked={isChecked}
              onIconPress={() => setChecked(!isChecked)}
              onPress={() => setModalVisible(!isModalVisible)}
              containerStyle={[styles.checkBoxContainer]}
            />
          </View>
          <View style={styles.bottomContainer}>
            <Button
              containerStyle={styles.buttonContainer}
              buttonStyle={styles.registerButton}
              titleStyle={styles.registerTextButton}
              onPress={handleSubmit((data) => onRegister(data))}
              disabled={!isChecked}
              title={getLabel('register_register_button')}
              loading={isLoading}
            />
          </View>
        </View>
      </KeyboardAwareScrollView>
      <CustomModal isVisible={isModalVisible}>
        <View style={styles.modalContainer}>
          <Text style={styles.termsConditionsTitle}>{getLabel('register_modal_title')}</Text>
          <ScrollView
            bounces={false}
          >
            <Text style={styles.termsConditionText}>{ TERMS_AND_CONDITIONS }</Text>
          </ScrollView>
          <Button
            containerStyle={styles.buttonContainer}
            buttonStyle={styles.registerButton}
            titleStyle={styles.registerTextButton}
            onPress={toggleModal}
            title={getLabel('register_modal_close_button')}
          />
        </View>
      </CustomModal>
    </View>
  );
};
