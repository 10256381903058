import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Range } from '../../services/scheduleSettings/interface';
import type { RootState } from '../rootReducer';
import { IAgendaOrganizerState, INITIAL_STATE } from './state';

const andendaOrganizerSlice = createSlice({
  name: 'schedule',
  initialState: INITIAL_STATE,
  reducers: {
    agendaOrganizerSetWeek: (state, action: PayloadAction<IAgendaOrganizerState>) => {
      state.mon = action.payload.mon;
      state.tues = action.payload.tues;
      state.wed = action.payload.wed;
      state.thurs = action.payload.thurs;
      state.fri = action.payload.fri;
      state.sat = action.payload.sat;
      state.sun = action.payload.sun;
    },
    agendaOrganizerSetDay: (state, action: PayloadAction<{key: string, value: Range | null}>) => {
      state[action.payload.key] = action.payload.value ? [action.payload.value] : null;
    },
    agendaOrganizerResetState: () => INITIAL_STATE
  }
});

export const {
  agendaOrganizerSetWeek,
  agendaOrganizerSetDay,
  agendaOrganizerResetState
} = andendaOrganizerSlice.actions;

export default andendaOrganizerSlice.reducer;

export const selectorAgendaOrganizerDay = (state: RootState) => (key: string) => state.agendaOrganizer[key];

export const selectorAgendaOrganizerWeek = (state: RootState) => state.agendaOrganizer;
