import React from 'react';
import { NativeStackNavigationOptions } from '@react-navigation/native-stack';
import Icon from 'react-native-vector-icons/Feather';
import { StyleSheet } from 'react-native';
import { PendingRequestsLight } from '../services/events/interface';
import LogoLarge from '../svg/logo_large';
import { EventLight } from '../components/Calendar/interfaces';
import { Fonts } from '../configs/enums';
import { IScheduleState } from '../redux/schedules/state';
import {
  CustomHeaderLeft, CustomReturnHeaderLeft, CustomHeaderRight, InvitedCalendarReturnHeaderLeft, CustomReturnHeaderLeftForEvents, CustomFirstTimeHeaderLeft
} from '../components/CustomHeader';

export type RootNavigationRoute = {
  Core: undefined;
  Auth: undefined;
  Main: undefined;
  Root: undefined;
  Landing: undefined;
  Login: undefined;
  Register: undefined;
  RecoverPassword: undefined;
  OneTimePasscode: { params: string };
  NewPassword: undefined;
  HomeStack: undefined;
  Home: undefined;
  AgendaStack: undefined;
  Schedules: undefined;
  AgendaOrganizer: { params: { uuid: string | null | undefined, name: string }};
  ProfileStack: undefined;
  Profile: undefined;
  ChangeEmail: undefined;
  ChangeOptionalEmail: undefined;
  ChangePassword: undefined;
  DeleteAccount: undefined;
  Event: { startTime: string | undefined, startTimeNumber: number | undefined, dayMode: boolean, date: string };
  EventExternal: { hashLink: string, startTime?: string | undefined, startTimeNumber?: number | undefined, dayMode: boolean, date: string };
  AddPeople: { from: string, to: string, eventDate: string };
  ViewEvent: { params: EventLight };
  InvitedCalendarStack: undefined;
  InvitedCalendar: { hashlink: string | undefined };
  NotificationsStack: undefined;
  Notifications: undefined;
  DetailNotification: { pendingEvent: PendingRequestsLight };
  AdditionalInfo: undefined;
  Payments: undefined;
  PaymentsHome: undefined;
  FirstTimeStack: undefined;
  EventDetailsExternal: { occasionUuid: string };
  Checkout: { uri: string };
  CheckoutFinished: { result: string },
  VerifyAccount: undefined,
  Support: undefined,
  EventFromIcs: { path: string },
  Help: undefined
}

export const navigatorScreenOptions = () => ({
  headerShown: false,
  drawerLabelStyle: { marginLeft: -25, fontFamily: Fonts.MANROPE_SEMIBOLD },
  drawerActiveBackgroundColor: '#305fab',
  drawerActiveTintColor: 'white',
  drawerInactiveTintColor: '#305fab',
  drawerType: 'front'
});

export const noHeaderScreenOptions: NativeStackNavigationOptions = {
  headerShown: false
};

export const registerExtraScreensOptions: NativeStackNavigationOptions = {
  headerShown: true,
  title: '',
  headerShadowVisible: false,
  headerLeft: () => <CustomFirstTimeHeaderLeft />,
  headerRight: () => <LogoLarge height={30} width={150} />
};

export const homeHeaderOptions: any = (schedule: IScheduleState, scheduleLabel: string) => ({
  headerShown: true,
  title: schedule.selectedSchedule.uuid ? schedule.selectedSchedule?.name : scheduleLabel,
  headerTitleAlign: 'center',
  headerShadowVisible: false,
  headerLeft: () => <CustomHeaderLeft />,
  headerRight: () => <CustomHeaderRight />
});

export const onlyBurguerHeadersOptions: NativeStackNavigationOptions = {
  headerShown: true,
  title: '',
  headerShadowVisible: false,
  headerLeft: () => <CustomHeaderLeft />,
  headerRight: () => <LogoLarge height={30} width={150} />
};

export const goBackHeaderOptions: NativeStackNavigationOptions = {
  headerShown: true,
  title: '',
  headerShadowVisible: false,
  headerLeft: () => <CustomReturnHeaderLeft />,
  headerRight: () => <LogoLarge height={30} width={150} />
};

export const goBackHeaderOptionsForEvents: NativeStackNavigationOptions = {
  headerShown: true,
  title: '',
  headerShadowVisible: false,
  headerLeft: () => <CustomReturnHeaderLeftForEvents />,
  headerRight: () => <LogoLarge height={30} width={150} />
};

export const goBackHeaderInvitedCalendar: NativeStackNavigationOptions = {
  headerShown: true,
  title: '',
  headerLeft: () => <InvitedCalendarReturnHeaderLeft />,
  headerRight: () => <LogoLarge height={30} width={150} />
};

const HelpersStyles = StyleSheet.create({
  iconStyle: {
    fontSize: 18
  }
});

export const homeScreenOptions = (drawerLabel: string) => ({
  drawerIcon: (({ color }: { color: string }) => (
    <Icon
      style={HelpersStyles.iconStyle}
      name="home"
      color={color}
    />
  )),
  drawerLabel
});

export const helpScreenOptions = (drawerLabel: string) => ({
  drawerIcon: (({ color }: { color: string }) => (
    <Icon
      style={HelpersStyles.iconStyle}
      name="help-circle"
      color={color}
    />
  )),
  drawerLabel
});

export const agendaOrganizerScreenOptions = (drawerLabel: string) => ({
  drawerIcon: (({ color }: { color: string }) => (
    <Icon
      style={HelpersStyles.iconStyle}
      name="clipboard"
      color={color}
    />
  )),
  drawerLabel
});

export const profileScreenOptions = (drawerLabel: string) => ({
  drawerIcon: (({ color }: { color: string }) => (
    <Icon
      style={HelpersStyles.iconStyle}
      name="user"
      color={color}
    />
  )),
  drawerLabel
});

export const paymentsScreenOptions = (drawerLabel: string) => ({
  drawerIcon: (({ color }: { color: string }) => (
    <Icon
      style={HelpersStyles.iconStyle}
      name="star"
      color={color}
    />
  )),
  drawerLabel
});

export const notificationsScreenOptions = (drawerLabel: string) => ({
  drawerIcon: (({ color }: { color: string }) => (
    <Icon
      style={HelpersStyles.iconStyle}
      name="bell"
      color={color}
    />
  )),
  drawerLabel
});

declare global {namespace ReactNavigation {interface RootParamList extends RootNavigationRoute {}}}
