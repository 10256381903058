import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../configs/constants';
import { Colors, Fonts } from '../../configs/enums';

const LandingStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  logoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 50,
    marginTop: 40
  },
  logo: {
    fontSize: 30,
    fontFamily: Fonts.MANROPE_EXTRABOLD
  },
  middleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 150
  },
  textContainer: {
    width: '100%',
    alignItems: 'flex-start'
  },
  bottomContainer: {
    alignItems: 'center'
  },
  subBottomContainer: {
    width: '100%',
    marginBottom: 25
  },
  icon: {
    fontSize: 32
  },
  title: {
    fontSize: 30,
    fontFamily: Fonts.MANROPE_BOLD
  },
  text: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  buttonContainer: {
    width: '100%'
  },
  registerButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    width: '100%'
  },
  registerTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  isRegisterButton: {
    backgroundColor: Colors.SECONDARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    borderColor: Colors.SECONDARY_BORDER_COLOR,
    borderWidth: 1
  },
  isRegisterTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.SECONDARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  picker: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    paddingVertical: 6,
    paddingHorizontal: 15,
    textAlign: 'center',
    alignSelf: 'flex-end',
    borderRadius: 12,
    opacity: 0.4
  },
  mobilePickercontainer: {
    flexDirection: 'row',
    alignSelf: 'flex-end',
    padding: 10,
    borderRadius: 10,
    borderColor: Colors.INPUT_BORDER_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    opacity: 0.4
  },
  androidPickerContainer: {},
  androidPicker: {
    flexDirection: 'column',
    borderRadius: 10,
    width: 160,
    borderColor: Colors.TERTIARY_TEXT_COLOR,
    backgroundColor: Colors.BACKGROUND_COLOR,
    color: Colors.TERTIARY_TEXT_COLOR,
    borderWidth: 1,
    opacity: 0.4
  },
  dropdownIcon: {
    fontSize: 18,
    opacity: 0.33
  },
  modalContainer: {
    borderRadius: 15,
    backgroundColor: Colors.BACKGROUND_COLOR,
    borderWidth: 1,
    paddingVertical: 15,
    paddingHorizontal: 15,
    marginHorizontal: 20
  },
  mainLogo: {}
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  })
};

export default LandingStyles;
