import React from 'react';
import { View, Pressable, Text } from 'react-native';
import { useSelector } from 'react-redux';
import Icon from 'react-native-vector-icons/Feather';
import { Button } from 'react-native-elements';
import { Range } from 'src/services/scheduleSettings/interface';
import { AgendaDayStyles as styles } from './styles';
import { selectLanguageKeys } from '../../redux/language';

interface Props {
  name: string;
  ranges: Range[] | null;
  onPress: () => any;
  onPressTrash: () => any;
}

export default ({
  name, ranges, onPress, onPressTrash
}: Props): React.ReactElement => {
  const getLabel = useSelector(selectLanguageKeys);

  return (
    <Pressable style={styles.container} onPress={onPress}>
      <View style={styles.textContainer}>
        <Text style={styles.name}>{name}</Text>
        <Text style={styles.range}>
          {ranges
            ? ranges.map((range: any) => (`${range.from}-${range.to} hs, `))
            : getLabel('placeholder_agendaDay')}
        </Text>
      </View>
      {ranges
        ? (
          <Button
            type="clear"
            onPress={onPressTrash}
            icon={(
              <Icon
                style={styles.trashIcon}
                name="trash-2"
              />
            )}
          />
        )
        : <View style={styles.emptyFillView} />}
    </Pressable>
  );
};
