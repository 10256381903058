import React from 'react';
import { Pressable, Text, View } from 'react-native';

import styles from './styles';

function SubscriptionCard({
  children,
  title,
  titleStyles,
  disabled,
  cardColor,
  selected,
  onPress,
  price
}: any) {
  return (
    <Pressable
      disabled={disabled}
      onPress={onPress}
      style={[
        styles.container
      ]}
    >
      <View
        style={[
          styles.cardContainer,
          {
            backgroundColor: cardColor,
            borderColor: selected ? 'red' : 'black'
          }
        ]}
      >
        <View style={styles.cardPremiumTitlePriceContainer}>
          <Text style={titleStyles}>{title}</Text>
          <Text style={titleStyles}>{price}</Text>
        </View>
        {children}
      </View>
    </Pressable>
  );
}

export default SubscriptionCard;
