import {
  StyleSheet, TextStyle, ViewStyle
} from 'react-native';
import { MAX_SPLASH_WIDTH } from '../../../../configs/constants';
import { Colors, Fonts } from '../../../../configs/enums';

const CodeStyles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  logoContainer: {
    paddingVertical: 40
  },
  logo: {
    fontSize: 30,
    fontFamily: Fonts.MANROPE_EXTRABOLD
  },
  middleContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    paddingVertical: 20
  },
  textContainer: {
    width: '100%',
    alignItems: 'flex-start'
  },
  controllersContainer: {
    alignItems: 'center',
    paddingVertical: 30,
    justifyContent: 'space-around'
  },
  bottomContainer: {
    alignItems: 'center',
    paddingVertical: 20
  },
  backgroundPic: {
    flex: 1,
    resizeMode: 'cover',
    justifyContent: 'center',
    backgroundColor: Colors.BACKGROUND_COLOR
  },
  icon: {
    fontSize: 32
  },
  title: {
    fontSize: 30,
    fontFamily: Fonts.MANROPE_BOLD
  },
  text: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  input: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    backgroundColor: Colors.BACKGROUND_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 20,
    borderRadius: 10,
    width: '100%',
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderWidth: 1
  },
  valid: {
    borderWidth: 1
  },
  invalid: {
    borderColor: Colors.ERROR_COLOR
  },
  buttonContainer: {
    width: '100%'
  },
  verifyButton: {
    backgroundColor: Colors.PRIMARY_BUTTON_COLOR,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 10,
    height: 50
  },
  verifyTextButton: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    color: Colors.PRIMARY_TEXT_COLOR,
    textAlign: 'center',
    paddingVertical: 6
  },
  errorText: {
    color: Colors.ERROR_COLOR,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    fontSize: 10,
    alignSelf: 'flex-start',
    marginTop: 8
  },
  codeFieldRoot: {
    padding: 10
  },
  cell: {
    width: 45,
    height: 45,
    lineHeight: 38,
    fontSize: 24,
    borderWidth: 2,
    borderColor: Colors.INPUT_BORDER_COLOR,
    borderRadius: 10,
    textAlign: 'center',
    marginHorizontal: 5,
    color: Colors.PRIMARY_BUTTON_COLOR,
    fontFamily: Fonts.MANROPE_REGULAR
  },
  focusCell: {
    borderColor: Colors.PRIMARY_BUTTON_COLOR
  },
  resendCode: {
    fontSize: 15,
    fontFamily: Fonts.MANROPE_SEMIBOLD,
    letterSpacing: 0.25,
    paddingTop: 10,
    paddingBottom: 10
  }
});

export const functionStyles = {
  mainContainer: (width: number | undefined): TextStyle & ViewStyle => ({
    flex: 1,
    alignSelf: 'center',
    width: width && width > MAX_SPLASH_WIDTH ? MAX_SPLASH_WIDTH : width,
    paddingHorizontal: 20
  })
};

export default CodeStyles;
